/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import { getToken, rawToken } from "../../app/utils/token.js";
import { getTotalCareOnboarding } from "./actions.js";
import { TotalCareSubmit } from "./TotalCareSubmit.js";
import { TotalCareIntro } from "./TotalCareIntro.js";
import { TotalCareComplete } from "./TotalCareComplete.js";
import { TotalCareNutritionist } from "./TotalCareNutritionist.js";
import { TotalCareFirstWeight } from "./TotalCareFirstWeight.js";
import { TotalCareGoalHeight } from "./TotalCareGoalHeight.js";
import { TotalCareExercise } from "./TotalCareExercise.js";
import { TotalCareDiet } from "./TotalCareDiet.js";
export class TotalCare extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: "intro",
      currentWeight: null,
      goalWeight: null,
      feet: null,
      inches: null,
      dietaryInfo: null,
      exerciseInfo: null,
      allergies: null,
    };
  }

  componentDidMount = () => {
    const token = getToken() || null;
    const raw = rawToken() || null;

    const patientId = token.sub;
    getTotalCareOnboarding(raw, patientId)
      .then((j) => j.json())
      .then((res) => {
        if (res.start_weight) {
          this.setState({
            mode: "complete",
          });
        }
      });
  };

  setMode = (mode) => {
    this.setState({ mode: mode });
    window.scrollTo(0, 0);
  };

  setter = (key) => {
    return (val) => {
      this.state[key] = val;
      this.setState({});
    };
  };

  render() {
    const token = getToken() || null;
    const raw = rawToken() || null;

    const modes = {
      intro: (
        <TotalCareIntro setMode={this.setMode} token={token} rawToken={raw} />
      ),
      nurse: <TotalCareNutritionist setMode={this.setMode} />,
      first_weight: (
        <TotalCareFirstWeight
          setMode={this.setMode}
          token={token}
          setStartWeight={this.setter("currentWeight")}
        />
      ),
      goal_height: (
        <TotalCareGoalHeight
          setMode={this.setMode}
          setGoalWeight={this.setter("goalWeight")}
          setFeet={this.setter("feet")}
          setInches={this.setter("inches")}
          startWeight={this.state.currentWeight}
        />
      ),
      exercise: (
        <TotalCareExercise
          setMode={this.setMode}
          setExercise={this.setter("exerciseInfo")}
        />
      ),
      diet: (
        <TotalCareDiet
          setMode={this.setMode}
          setDiet={this.setter("dietaryInfo")}
          setAllergies={this.setter("allergies")}
        />
      ),
      submit: (
        <TotalCareSubmit
          setMode={this.setMode}
          currentWeight={this.state.currentWeight}
          goalWeight={this.state.goalWeight}
          feet={this.state.feet}
          inches={this.state.inches}
          dietaryInfo={this.state.dietaryInfo}
          exerciseInfo={this.state.exerciseInfo}
          allergies={this.state.allergies}
        />
      ),
      complete: <TotalCareComplete />,
    };

    return modes[this.state.mode];
  }
}
