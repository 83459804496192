import React from "react";

import AthelasLogo from "../assets/AthelasLogo.png";
import CheckmarkIcon from "../assets/Checkmark.png";
import PalomarCheckmarkIcon from "../assets/palomarCheckmark.png";
import AthelasDevicesImage from "../assets/AthelasDevices.png";
import { useMediaQuery } from "react-responsive";
import { useFeatureFlag } from "../../../app/utils/FeatureFlag";

type PropsType = {
  showDeviceImages?: boolean;
  showSiteLogos?: boolean;
  showCornerImages?: boolean;
  site_logo?: null | string;
  site_name?: null | string;
  palomar?: null | boolean;
  nextStep: (...args: any[]) => void;
};

const styles: any = {
  innerContainer: {
    maxWidth: 1000,
    width: "100%",
    margin: "0% auto",
  },
  logoContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    margin: "0% auto",
    marginBottom: 12,
  },
  logo: {
    maxWidth: "140px",
    maxHeight: "80px",
    margin: 12,
    objectFit: "contain",
  },
  flexContainer: {
    flexDirection: "row",
    display: "flex",
    maxWidth: 285,
    margin: "0% auto",
    marginBottom: 16,
    justifyContent: "left",
  },
  mobileFlexContainer: {
    flexDirection: "row",
    display: "flex",
    width: "100%",
    margin: "0% auto",
    marginBottom: 16,
    justifyContent: "left",
  },
  checkmark: {
    width: 36,
    height: 36,
    marginRight: 16,
  },
  explainer: {
    marginTop: 8,
  },
  buttonStyle: {
    width: "100%",
    maxWidth: "240px",
    margin: "0% auto",
    marginBottom: 24,
    fontStyle: "bold",
    padding: 8,
    borderRadius: 4,
    backgroundColor: "#FF6779",
    color: "white",
    cursor: "pointer",
  },
  palomarButtonStyle: {
    width: "100%",
    maxWidth: "240px",
    margin: "0% auto",
    marginBottom: 24,
    fontStyle: "bold",
    padding: 8,
    borderRadius: 4,
    backgroundColor: "#008CC1",
    color: "white",
    cursor: "pointer",
  }
};

export const InitialScreen = (props: PropsType) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const revampedOnboardingEnabled = useFeatureFlag(
    "enable_revamped_onboarding_flow",
    -1
  );
  const palomarText =
    "Palomar Health Medical Group partners with Athelas to help monitor your health from the comfort of your home. Get started below to acquire your devices!";
  
  return (
    <div>
      <div style={styles.innerContainer}>
        {props.showSiteLogos && (
          <div style={styles.logoContainer}>
            <img src={AthelasLogo} style={styles.logo} alt="Athelas logo" />
            {props.site_logo && (
              <img src={props.site_logo} style={styles.logo} alt="Site logo" />
            )}
          </div>
        )}
        <h3>
          <b>Hello, welcome to Athelas!</b>
        </h3>
        <p style={{ maxWidth: 450, margin: "0% auto", marginBottom: 16 }}>
          {props.palomar 
            ? palomarText
            : "Our team " +
              (props.site_name && `at ${props.site_name}`) +
              " has partnered with Athelas to help monitor your health from the comfort of your " +
              "home. Get started below to acquire your devices!"}
        </p>
        <div
          style={
            isTabletOrMobile ? styles.mobileFlexContainer : styles.flexContainer
          }
        >
          {
            props.palomar ?
            <img src={PalomarCheckmarkIcon} style={styles.checkmark} alt="checkmark" />
            :
            <img src={CheckmarkIcon} style={styles.checkmark} alt="checkmark" />
          }
          
          <b style={styles.explainer}>Covered by Your Insurance</b>
        </div>
        <div
          style={
            isTabletOrMobile ? styles.mobileFlexContainer : styles.flexContainer
          }
        >
          {
            props.palomar ?
            <img src={PalomarCheckmarkIcon} style={styles.checkmark} alt="checkmark" />
            :
            <img src={CheckmarkIcon} style={styles.checkmark} alt="checkmark" />
          }
          
          <b style={styles.explainer}>Free Shipping to Your Home</b>
        </div>
        <div
          style={
            isTabletOrMobile ? styles.mobileFlexContainer : styles.flexContainer
          }
        >
          {
            props.palomar ?
            <img src={PalomarCheckmarkIcon} style={styles.checkmark} alt="checkmark" />
            :
            <img src={CheckmarkIcon} style={styles.checkmark} alt="checkmark" />
          }
          <b style={styles.explainer}>Quick & Easy Set Up</b>
        </div>{" "}
        <div
          style={props.palomar ? styles.palomarButtonStyle : styles.buttonStyle}
          onClick={
            revampedOnboardingEnabled
              ? props.nextStep
              : () => window.open("https://onboarding.athelas.com/")
          }
        >
          Get Started
        </div>
        {props.showDeviceImages && (
          <img
            src={AthelasDevicesImage}
            alt="Athelas devices"
            style={{ maxWidth: "300px" }}
          />
        )}
        <br />
      </div>
    </div>
  );
};
