import { NORMANDY_API_SERVER_URL } from "../constants";
import axios from "axios";

export function track(
  action: "view" | "click",
  patient_id: number,
  tag: string,
  info: any
) {
  const payload = {
    action: action,
    data: {
      patient_id: patient_id,
      info: info,
    },
    tag: tag,
    application: "pilltrack_onboarding",
  };

  if (window.location.href.includes("localhost")) {
    console.log("tracked event:");
    console.log(payload);
  }

  return fetch(`https://api.athelas.com/tracking/track`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((res) => {
    console.log("tracked.");
    console.log(res);
  });
}

export const trackEvent = (
  action: "click" | "view",
  patientID: number,
  tag: string,
  info: string
) => {
  track(action, patientID, tag, info);
};

export function trackAthelasOne(
  action: "view" | "click",
  site_id: number,
  tag: string,
  info: any
) {
  const payload = {
    action: action,
    data: {
      site_id: site_id,
      info: info,
    },
    tag: tag,
    application: "onboarding",
  };

  if (window.location.href.includes("localhost")) {
    console.log("tracked event:");
    console.log(payload);
  }

  return fetch(`https://api.athelas.com/tracking/track`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((res) => {
    console.log("tracked.");
    console.log(res);
  });
}
export const trackEventAthelasOne = (
  action: "click" | "view",
  siteID: number,
  tag: string,
  info: string
) => {
  trackAthelasOne(action, siteID, tag, info);
};

export function trackAthelasHome(
  action: "view" | "click",
  patient_id: number,
  tag: string,
  info: any
) {
  const payload = {
    action: action,
    data: {
      patient_id: patient_id,
      info: info,
    },
    tag: tag,
    application: "onboarding",
  };

  if (window.location.href.includes("localhost")) {
    console.log("tracked event:");
    console.log(payload);
  }

  return fetch(`https://api.athelas.com/tracking/track`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((res) => {
    console.log("tracked.");
    console.log(res);
  });
}
export const trackEventAthelasHome = (
  action: "click" | "view",
  patientID: number,
  tag: string,
  info: string
) => {
  trackAthelasHome(action, patientID, tag, info);
};

interface TrackNormandyPayload {
  tag: string;
  action: string;
  data: Record<string, any>;
}

export async function trackNormandy({
  tag,
  action,
  data,
}: TrackNormandyPayload) {
  const payload = {
    action,
    data,
    tag,
    application: "onboarding",
  };

  if (window.location.href.includes("localhost")) {
    console.log("event:", payload);
    return;
  }

  try {
    return axios.post(`${NORMANDY_API_SERVER_URL}/track/`, payload);
  } catch (e) {
    console.log(e);
  }
}
