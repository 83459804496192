import axios from "axios";
import { API_SERVER_URL } from "../../app/constants";
import FormData from "form-data";

export const validateAndCreateGgpProspectivePatient = (
  formData: FormData
) => {

  return axios
  .post(`${API_SERVER_URL}/ggp_prospective_patient/create`, formData,
  {
    headers: {
      'Content-Type': 'multipart/form-data',
  }
  })
}