import React, { useEffect } from "react";
import { createTheme } from "@mui/material/styles";
import { themeOptions } from "./ThemeOptionsPatientPay";
import { useNavigate, useLocation } from "react-router-dom";
import {
  ThemeProvider,
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { trackNormandy } from "../../app/utils/logging";

// Real detail breakdown regarding Patient Balance
export default function PatientPayDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = createTheme(themeOptions);

  const getLineItems = (outstandingEncounters, amountDue) => {
    const lineItems = [];
    let amountLeftover = amountDue;

    for (const encounter of outstandingEncounters) {
      if (amountLeftover < 0) break;

      const tempAmountLeftover =
        amountLeftover - encounter.total_patient_responsibility;
      const amountDueForItem =
        tempAmountLeftover < 0
          ? amountLeftover
          : encounter.total_patient_responsibility;

      amountLeftover = tempAmountLeftover;

      lineItems.push({ ...encounter, amountDue: amountDueForItem });
    }

    return lineItems.filter((encounter) => encounter.amountDue > 0);
  };

  const {
    outstandingEncounters,
    amountDue,
    payingForDependentPatients,
    dependentPatientsOutstandingPRInfo,
    dependentPatientsInfo,
  } = location.state;

  const lineItems = getLineItems(
    outstandingEncounters,
    payingForDependentPatients
      ? amountDue -
          dependentPatientsInfo.reduce(
            (acc, patientInfo) => acc + patientInfo.amount_due_cents,
            0
          )
      : amountDue
  );

  const dependentPatientsLineItems = payingForDependentPatients
    ? dependentPatientsInfo.reduce(
        (acc, patient) => [
          ...acc,
          ...getLineItems(
            dependentPatientsOutstandingPRInfo.find(
              (prInfo) => prInfo.patient_id === patient.patient_id
            ).outstanding_encounters,
            patient.amount_due_cents
          ),
        ],
        []
      )
    : [];

  const displayTotalBalance = (balance) => {
    if (Boolean(balance)) {
      return (balance / 100).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    } else {
      return "$0.00";
    }
  };

  useEffect(() => {
    trackNormandy({
      action: "view",
      tag: "patient_pay_details",
      data: {
        first_name: window.sessionStorage.getItem("first_name"),
        last_name: window.sessionStorage.getItem("last_name"),
        date_of_birth: window.sessionStorage.getItem("date_of_birth"),
        site_id: window.sessionStorage.getItem("site_id"),
        requested_prs: window.sessionStorage.getItem("requested_prs"),
        payment_intent_id: window.sessionStorage.getItem("payment_intent_id"),
      },
    });
  }, []);

  const generateEncounterCards = () => {
    return [...lineItems, ...dependentPatientsLineItems].map((encounter) => {
      if (encounter.procedure_information !== null) {
        return (
          <Grid minWidth="75vw" item xs={12}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "50%",
              }}
            >
              <CardContent>
                <CardContent>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      variant="body1"
                      sx={{ color: "text.secondary" }}
                    >
                      Provider:
                    </Typography>
                    <Typography variant="body1">
                      {encounter.encounter_provider_name}
                    </Typography>
                  </Box>
                </CardContent>
                <CardContent>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      variant="body1"
                      sx={{ color: "text.secondary" }}
                    >
                      Date of Service:
                    </Typography>
                    <Typography variant="body1">
                      {encounter.encounter_date_of_service}
                    </Typography>
                  </Box>
                </CardContent>
                <CardContent>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      variant="body1"
                      sx={{ color: "text.secondary" }}
                    >
                      Your responsibility:
                    </Typography>
                    <Typography variant="body1">
                      {displayTotalBalance(encounter.amountDue)}
                    </Typography>
                  </Box>
                </CardContent>
                <CardContent>
                  <Typography
                    variant="body1"
                    sx={{ color: "text.secondary", textAlign: "center" }}
                  >
                    Procedures done during this visit:
                  </Typography>
                </CardContent>
                {encounter.procedure_information.map((procedure) => {
                  return (
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{ color: "text.secondary" }}
                        >
                          CPT {procedure.procedure_cpt_code}:
                        </Typography>
                        <Typography variant="body1">
                          {procedure.procedure_cpt_code_description}
                        </Typography>
                      </Box>
                    </CardContent>
                  );
                })}
              </CardContent>
            </Card>
          </Grid>
        );
      } else {
        // Display information for copay or other one off charges w/o encounter/procedure information
        return (
          <Grid minWidth="75vw" item xs={12}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <CardContent>
                <CardContent>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      variant="body1"
                      sx={{ color: "text.secondary" }}
                    >
                      Provider:
                    </Typography>
                    <Typography variant="body1">
                      {encounter.appointment_provider_name}
                    </Typography>
                  </Box>
                </CardContent>
                <CardContent>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      variant="body1"
                      sx={{ color: "text.secondary" }}
                    >
                      Reason for Charge:
                    </Typography>
                    <Typography variant="body1">
                      {encounter.line_item_description === null
                        ? encounter.one_off_patient_responsibility_request_reason
                        : encounter.line_item_description}
                    </Typography>
                  </Box>
                </CardContent>
                <CardContent>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      variant="body1"
                      sx={{ color: "text.secondary" }}
                    >
                      Date of Service:
                    </Typography>
                    <Typography variant="body1">
                      {encounter.appointment_date_of_service}
                    </Typography>
                  </Box>
                </CardContent>
                <CardContent>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      variant="body1"
                      sx={{ color: "text.secondary" }}
                    >
                      Your responsibility:
                    </Typography>
                    <Typography variant="body1">
                      {displayTotalBalance(encounter.amountDue)}
                    </Typography>
                  </Box>
                </CardContent>
              </CardContent>
            </Card>
          </Grid>
        );
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "75vh" }}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <Typography variant="h5">
              {displayTotalBalance(amountDue)}
            </Typography>
          </Box>
        </Grid>
        {generateEncounterCards()}
      </Grid>
    </ThemeProvider>
  );
}
