//eslint-disable-next-line no-unused-vars
import { object, string, boolean, InferType, mixed, array, date } from "yup";
import moment from "moment";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const requiredMessage = "This field is required."

export const ggpPatientSchema = object({
  first_name: string().required(requiredMessage),
  last_name: string().required(requiredMessage),
  date_of_birth: string()
    .required("Date of birth is required")
    .test("date_of_birth", "Please enter a valid date", (value) => {
      if (!value) return false;
      try {
        return moment(value).isValid();
      } catch (error) {
        return false;
      }
    }),
  gender: string().oneOf(["Male", "Female", "Other"]).required("Please select one."),
  race: string().required("Please select one."),
  phone_number: string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required(
      "Patient phone number is required, please use cell number over landline"
    ),
  email: string()
    .required(requiredMessage)
    .email("Please enter a valid email address")
    .matches(
      /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
      "Please enter a valid email address"
    ),
  address_line1: string().required(requiredMessage),
  address_line2: string().optional(),
  state_province: string().required(requiredMessage),
  city_locality: string().required(requiredMessage),
  postal_code: string().required(requiredMessage),
  country_code: string().required(requiredMessage),
  shipping_address_1: string().required(requiredMessage),
  shipping_address_2: string().optional(),
  shipping_state: string().required(requiredMessage),
  shipping_city: string().required(requiredMessage),
  shipping_postal_code: string().required(requiredMessage),
  shipping_country_code: string().required(requiredMessage),
  sections: array().of(object().shape({
    has_insurance_card: string().oneOf(['YES', 'NO']).required("Please indicate if you have an insurance card"),
    insurance_type: string().required("Insurance type is required"),
    frontFiles: mixed().when('has_insurance_card', {
      is: 'YES',
      then: mixed().required("Front of insurance card is required"),
      otherwise: mixed().optional()
    }),
    backFiles: mixed().when('has_insurance_card', {
      is: 'YES',
      then: mixed().required("Back of insurance card is required"),
      otherwise: mixed().optional()
    }),
    insurance_name: string().when('has_insurance_card', {
      is: 'NO',
      then: string().required("Insurance name is required"),
      otherwise: string().optional()
    }),
    insurance_id: string().when('has_insurance_card', {
      is: 'NO',
      then: string().required("Insurance ID is required"),
      otherwise: string().optional()
    }),
    insurance_group_number: string().when('has_insurance_card', {
      is: 'NO',
      then: string().required("Group number is required"),
      otherwise: string().optional()
    }),
    insurance_pcn_number: string().when('has_insurance_card', {
      is: 'NO',
      then: string().required("PCN number is required"),
      otherwise: string().optional()
    }),
  })).test('at-least-one-section', 'At least one insurance section is required', (value) => {
    return Boolean(value && value.length > 0);
  }),

  doctor_email: string().when('in_hospital', {
    is: 'NO',
    then: string()
      .required("Doctor's email is required")
      .email("Please enter a valid email address")
      .matches(
        /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
        "Please enter a valid email address"
      ),
    otherwise: string().optional()
  }),
  doctor_phone_number: string().when('in_hospital', {
    is: 'NO',
    then: string()
      .required("Doctor's phone number is required")
      .matches(phoneRegExp, "Phone number is not valid"),
    otherwise: string().optional()
  }),
  hippa: string().oneOf(["YES_HIPPA", "NO_HIPPA"]).required("Please select an option"),
  hippa_file: mixed().when('hippa', {
    is: 'YES_HIPPA',
    then: mixed().required("Please upload your HIPAA authorization form"),
    otherwise: mixed().optional()
  }),
  rep_first_name: string().when("hippa", {
    is: "NO_HIPPA",
    then: string().required(requiredMessage),
  }),
  rep_last_name: string().when("hippa", {
    is: "NO_HIPPA",
    then: string().required(requiredMessage),
  }),  
  rep_capacity: string().when("hippa", {
    is: "NO_HIPPA",
    then: string().required(requiredMessage),
  }),
  rep_address_1: string().when("hippa", {
    is: "NO_HIPPA",
    then: string().required(requiredMessage),
  }),
  rep_state_province: string().when("hippa", {
    is: "NO_HIPPA",
    then: string().required(requiredMessage),
  }),
  rep_city_locality: string().when("hippa", {
    is: "NO_HIPPA",
    then: string().required(requiredMessage),
  }),
  rep_postal_code: string().when("hippa", {
    is: "NO_HIPPA",
    then: string().required(requiredMessage),
  }),
  hippa_signature: string().when("hippa", {
    is: "NO_HIPPA",
    then: string().required(requiredMessage),
  }),
  rep_authority_explanation: string().when("hippa", {
    is: "NO_HIPPA",
    then: string().required(requiredMessage),
  }),
  effective_until_date: date()
  .when("watchEffectivePeriod", {
    is: "EFFECTIVE_PERIOD",
    then: date().required("Please select an effective until date"),
  }),
  patient_name: string().required(requiredMessage),
  relationship_type: string().required(requiredMessage),
  personal_signature: string().required(requiredMessage),
  referral_type: string().required(requiredMessage),

  is_business: string().oneOf(['YES', 'NO']).required("Please specify if you are a business"),
  business_name: string().when('is_business', {
    is: 'YES',
    then: string().required("Business name is required"),
    otherwise: string().optional()
  }),
  attn: string().when('is_business', {
    is: 'YES',
    then: string().required("ATTN is required for businesses"),
    otherwise: string().optional()
  }),
  current_medication_method: string().required("Please select how you currently receive your medication"),
  ggp_interest: string().oneOf(["YES", "NO"], "Please select an option").required("This field is required"),
  allergies: string()
    .oneOf(['YES_ALLERGIES', 'NO_ALLERGIES'])
    .required("Please indicate if you have allergies"),
  allergies_list: string().when('allergies', {
    is: 'YES_ALLERGIES',
    then: string().required("Please list your allergies"),
    otherwise: string().optional()
  }),
  in_hospital: string().required('Please select if patient is in hospital'),
  anticipated_discharge_date: date().when('in_hospital', {
    is: 'YES',
    then: (schema) => schema.required('Anticipated discharge date is required'),
    otherwise: (schema) => schema.nullable()
  }),
 
  outpatient_doctor_name: string().when('in_hospital', {
    is: 'YES',
    then: (schema) => schema.nullable(),
    otherwise: (schema) => schema.nullable()
  }),
  outpatient_doctor_email: string().when('in_hospital', {
    is: 'YES',
    then: (schema) => schema.email('Invalid email').nullable(),
    otherwise: (schema) => schema.nullable()
  }),

  // For non-hospital doctors
  doctor_first_name: string().when('in_hospital', {
    is: 'NO',
    then: string().required("Doctor's first name is required"),
    otherwise: string().optional()
  }),
  doctor_last_name: string().when('in_hospital', {
    is: 'NO',
    then: string().required("Doctor's last name is required"),
    otherwise: string().optional()
  }),

  // For hospital doctors
  hospital_doctor_first_name: string().when('in_hospital', {
    is: 'YES',
    then: (schema) => schema.required('Doctor first name is required'),
    otherwise: (schema) => schema.nullable()
  }),
  hospital_doctor_last_name: string().when('in_hospital', {
    is: 'YES',
    then: (schema) => schema.required('Doctor last name is required'),
    otherwise: (schema) => schema.nullable()
  }),
  hospital_doctor_email: string().when('in_hospital', {
    is: 'YES',
    then: (schema) => schema
      .required("Hospital doctor's email is required")
      .email('Please enter a valid email address')
      .matches(
        /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
        "Please enter a valid email address"
      ),
    otherwise: (schema) => schema.nullable()
  }),
  hospital_doctor_phone: string().when('in_hospital', {
    is: 'YES',
    then: string()
      .required("Hospital doctor's phone number is required")
      .matches(phoneRegExp, "Phone number is not valid"),
    otherwise: string().optional()
  }),
  clozapine_history: string()
    .oneOf(
      ['TAKING_CLOZAPINE', 'NOT_TAKING_CLOZAPINE', 'ONLY_ATHELAS_DEVICE'],
      "Please select your Clozapine status"
    ),
});

// parse and assert validity
// const patient = await patientSchema.validate(await fetchUser());

export type Patient = InferType<typeof ggpPatientSchema>;
/* {
    name: string;
    age: number;
    email?: string | undefined
    website?: string | null | undefined
    createdOn: Date
  }*/
