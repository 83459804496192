import React, { Component } from "react";
import Chart from "react-apexcharts";

export class AtomChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: "series",
          toolbar: {
            tools: {
              zoomin: true,
              zoomout: true,
              download: false,
              selection: false,
              pan: true,
            },
          },
        },
        xaxis: {
          categories: props.categories || [],
          hideOverlappingLabels: true,
          tickAmount: 4,
        },
        stroke: {
          curve: "smooth",
        },
      },
      series: [
        {
          name: "Weight",
          data: props.data || [],
        },
      ],
    };
  }

  render() {
    if (this.props.goal && this.state.series.length === 1) {
      this.state.series.push({
        name: "Goal",
        data: this.state.series[0].data.map((_) => this.props.goal),
      });
      this.setState({});
    }

    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="line"
        width="100%"
        height="300px"
      />
    );
  }
}
