import React, { Component } from "react";
import { ProgressBar } from "../../app/Atoms/ProgressBar/ProgressBar";

export class TotalCareGoalHeight extends Component {
  constructor(props) {
    super(props);

    this.state = {
      goalWeight: null,
      inches: null,
      feet: null,
    };
  }

  nextStep = () => {
    this.props.setMode("exercise");
  };

  render() {
    const disabled = this.state.inches === null || this.state.feet === null;
    return (
      <div
        class="form-container mt-5"
        style={{ marginBottom: "48px", paddingBottom: "24px" }}
      >
        <div class="p-4">
          <h2>Height</h2>

          <p style={{ textAlign: "left" }}>How tall are you?</p>
          <div
            style={{ flexDirection: "row", display: "flex" }}
            className="input-group-lg"
          >
            <div>
              <span>Feet: </span>
              <input
                type="number"
                className={"form-control"}
                style={{ width: "100%" }}
                placeholder={"ex. 5"}
                onChange={(e) => {
                  this.props.setFeet(e.target.value);
                  this.setState({ feet: parseInt(e.target.value) });
                }}
              />
            </div>
            <div>
              <span
                style={{ marginTop: "8px", textAlign: "left", width: "100%" }}
              >
                Inches:{" "}
              </span>
              <input
                type="number"
                className={"form-control"}
                placeholder={"ex. 8"}
                style={{ width: "100%", marginLeft: "2%" }}
                onChange={(e) => {
                  this.props.setInches(e.target.value);
                  this.setState({ inches: parseInt(e.target.value) });
                }}
              />
            </div>
          </div>

          <hr></hr>

          <button
            class="btn light-btn"
            onClick={this.nextStep}
            disabled={disabled}
          >
            Continue
          </button>
        </div>

        <ProgressBar percent={disabled ? 30 : 50} />
      </div>
    );
  }
}
