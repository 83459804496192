import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import styled from "styled-components";

function BackButton(props) {
  return (
    <StyledBackButton>
      <IconButton onClick={props.onClick}>
        <ArrowBackIcon />
      </IconButton>
    </StyledBackButton>
  );
}
const StyledBackButton = styled.div`
  margin-left: 20px;
  margin-top: 20px;
`;

export default BackButton;
