import React, { useState } from "react";

import AthelasLogo from "../assets/AthelasLogo.png";
import AthelasDevicesImage from "../assets/AthelasDevices.png";
import { TextInput } from "./components/TextInput";
import { getPatientOnboardingFromLocalStorage } from "./api";

type PropsType = {
  showDeviceImages?: boolean;
  showSiteLogos?: boolean;
  showCornerImages?: boolean;
  site_logo?: null | string;
  site_name?: null | string;
  palomar?: null | boolean;
  nextStep: (...args: any[]) => void;
};

const styles: any = {
  innerContainer: {
    maxWidth: 1000,
    width: "100%",
    margin: "0% auto",
  },
  logoContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    margin: "0% auto",
    marginBottom: 12,
  },
  logo: {
    maxWidth: "140px",
    maxHeight: "80px",
    margin: 12,
    objectFit: "contain",
  },
  flexContainer: {
    flexDirection: "row",
    display: "flex",
    maxWidth: 285,
    margin: "0% auto",
    marginBottom: 16,
    justifyContent: "left",
  },
  mobileFlexContainer: {
    flexDirection: "row",
    display: "flex",
    width: "100%",
    margin: "0% auto",
    marginBottom: 16,
    justifyContent: "left",
  },
  checkmark: {
    width: 36,
    height: 36,
    marginRight: 16,
  },
  explainer: {
    marginTop: 8,
  },
  buttonStyle: {
    width: "100%",
    maxWidth: "240px",
    margin: "0% auto",
    marginBottom: 24,
    fontStyle: "bold",
    padding: 8,
    borderRadius: 4,
    backgroundColor: "#FF6779",
    marginTop: 24,
    color: "white",
    cursor: "pointer",
  },
  palomarButtonStyle: {
    width: "100%",
    maxWidth: "240px",
    margin: "0% auto",
    marginBottom: 24,
    fontStyle: "bold",
    padding: 8,
    borderRadius: 4,
    backgroundColor: "#008CC1",
    marginTop: 24,
    color: "white",
    cursor: "pointer",
  },
};

export const InsuranceScreen = (props: PropsType) => {
  const [insurance, setInsurance] = useState(
    getPatientOnboardingFromLocalStorage("insurance_company")
  );
  const [policyNumber, setPolicyNumber] = useState(
    getPatientOnboardingFromLocalStorage("insurance_number")
  );

  return (
    <div>
      <div style={styles.innerContainer}>
        {props.showSiteLogos && (
          <div style={styles.logoContainer}>
            <img src={AthelasLogo} style={styles.logo} alt="Athelas logo" />
            {props.site_logo && (
              <img src={props.site_logo} style={styles.logo} alt="Site logo" />
            )}
          </div>
        )}
        <h3>
          <b>Your Insurance</b>
        </h3>
        <p style={{ maxWidth: 450, margin: "0% auto", marginBottom: 16 }}>
          Please review your insurance information. If something is invalid,
          please correct it.
        </p>
        <TextInput
          placeholder="ex. Cigna"
          label="Insurance Name"
          value={insurance}
          onChange={(text) => {
            setInsurance(text);
          }}
        />
        <TextInput
          placeholder="ex. 1920192"
          label="Policy Number"
          value={policyNumber}
          onChange={(text) => {
            setPolicyNumber(text);
          }}
        />

        <div
          style={props.palomar? styles.palomarButtonStyle : styles.buttonStyle}
          onClick={() => props.nextStep(insurance, policyNumber)}
        >
          One last step
        </div>
        {props.showDeviceImages && (
          <img
            src={AthelasDevicesImage}
            alt="Athelas devices"
            style={{ maxWidth: "300px" }}
          />
        )}
        <br />
      </div>
    </div>
  );
};
