import React from 'react';
import Modal from 'react-modal';
import './GgpPaymentPlanOptions.css'; // Import the updated CSS file

const MedicationManagementModal = ({ isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="ggp-payment-options-modal-content"
      overlayClassName="ggp-payment-options-modal-overlay"
      ariaHideApp={false}
    >
      <div className="ggp-payment-options-modal-header">
        <h2 className="ggp-payment-options-modal-title">Medication Management and Synchronization with GGP</h2>
      </div>
      <div className="ggp-payment-options-modal-body">
        <ul>
        <li><strong>Concierge-level</strong> services with the GGP diligently managing refills and lab coordination.</li>
        <li>GGP pharmacy will synchronize <strong>all</strong> prescribed medications, ensuring they arrive concurrently.</li>
        <li>The GGP pharmacy team is <strong>proactive</strong>, collaborating closely with providers and patients to guarantee timely medication delivery.</li>
        </ul>
      </div>
      <div className="ggp-payment-options-modal-footer">
        <button onClick={onRequestClose} className='ggp-payment-options-modal-close-button-select'>
          Close
        </button>
      </div>
    </Modal>
  );
};

export default MedicationManagementModal;


// In Line VERSION

// import React, { useState } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import '../../components/Footer/Footer.css'; // Import the CSS file for Footer
// import './GgpPaymentPlanOptions.css'; // Import the CSS file for this component
// import Header from '../../components/Header/Header'; // Import Header
// import Footer from '../../components/Footer/Footer'; // Import Footer
// import MedicationManagementModal from './MedicationManagementModal'; // Import the modal

// const GgpPaymentPlanOptions = () => {
//   const { first_name, last_name, payment_plans } = useParams();
//   const navigate = useNavigate();
//   const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

//   // Split the payment_plans string into an array and prioritize 'Pharmacy Preferred'
//   const plans = payment_plans ? payment_plans.split(',').sort((a, b) => {
//     if (a === 'Pharmacy Preferred') return -1;
//     if (b === 'Pharmacy Preferred') return 1;
//     return 0;
//   }) : [];

//   const handlePlanSelect = (plan) => {
//     const formattedPlanChoice = encodeURIComponent(plan);
//     navigate(`/ggp_patient_application/select_payment_plan/${encodeURIComponent(first_name)}/${encodeURIComponent(last_name)}/${formattedPlanChoice}`);
//   };

//   const renderPlan = (plan) => {
//     switch(plan) {
//       case 'Pharmacy Preferred':
//         return (
//           <div className="ggp-payment-options-plan" key={plan}>
//             <h3 className="ggp-payment-options-title">
//               Athelas Home with CLOZARIL <span className="ggp-payment-options-highlight copay">Co-Pay Assistance Program</span>
//             </h3>
//             <div className="ggp-payment-options-refill-info">
//               <div className="ggp-payment-options-refill-item">
//                 <span className="ggp-payment-options-refill-mark">&#10003;</span>
//                 <span className="ggp-payment-options-refill-text"><strong>$500</strong> Athelas Home device</span>
//               </div>
//               <div className="ggp-payment-options-refill-item">
//                 <span className="ggp-payment-options-refill-mark">&#10003;</span>
//                 <span className="ggp-payment-options-refill-text">
//                     <strong>$5</strong> per Athelas WBC Test Strip
//                     <div className="ggp-payment-options-subtle-text">
//                     (10 per box, + shipping)
//                     </div>
//                 </span>
//                 </div>
//               <div className="ggp-payment-options-refill-item">
//                 <span className="ggp-payment-options-refill-mark">&#10003;</span>
//                 <span className="ggp-payment-options-refill-text">Fill medications with Golden Gate Pharmacy Services</span>
//               </div>
//               <div className="ggp-payment-options-refill-item">
//                 <span className="ggp-payment-options-refill-mark">&#10003;</span>
//                 <span className="ggp-payment-options-refill-text">$0 Clozaril Co-Pays* (specific rules apply)</span>
//               </div>
//               <div className="ggp-payment-options-refill-item">
//                 <span className="ggp-payment-options-refill-mark">&#10003;</span>
//                 <span className="ggp-payment-options-refill-text">
//                   Medication Management and Synchronization
//                   <button onClick={() => setIsModalOpen(true)} style={{ marginLeft: '0px', fontSize: '12px', color: '#007bff', background: 'none', border: 'none', cursor: 'pointer' }}>
//                     Learn More
//                   </button>
//                 </span>
//               </div>
//               <div className="ggp-payment-options-refill-item">
//                 <span className="ggp-payment-options-refill-mark">&#10003;</span>
//                 <span className="ggp-payment-options-refill-text">Complimentary Pill Packing Services once dosage is stabilized</span>
//               </div>
//               <div className="ggp-payment-options-refill-item">
//                 <span className="ggp-payment-options-refill-mark">&#10003;</span>
//                 <span className="ggp-payment-options-refill-text">Complimentary 2-day FedEx delivery for all medications</span>
//               </div>
//             </div>
//             <div className="ggp-payment-options-footer">
//               <button 
//                 onClick={() => handlePlanSelect('Pharmacy Preferred')}
//                 className="ggp-payment-options-select-button"
//               >
//                 Select Payment Plan
//                 <span className="ggp-payment-options-button-icon">→</span>
//               </button>
//             </div>
//           </div>
//         );
//       case 'Pharmacy Choice':
//         return (
//           <div className="ggp-payment-options-plan" key={plan}>
//             <h3 className="ggp-payment-options-title">
//               Athelas Home with <span className="ggp-payment-options-highlight management">Medication Management</span>
//             </h3>
//             <div className="ggp-payment-options-refill-info">
//                 <div className="ggp-payment-options-refill-item">
//                 <span className="ggp-payment-options-refill-mark">&#10003;</span>
//                 <span className="ggp-payment-options-refill-text"><strong>$1000</strong> Athelas Home device</span>
//               </div>
//               <div className="ggp-payment-options-refill-item">
//                 <span className="ggp-payment-options-refill-mark">&#10003;</span>
//                 <span className="ggp-payment-options-refill-text">
//                     <strong>$5</strong> per Athelas WBC Test Strip
//                     <div className="ggp-payment-options-subtle-text">
//                     (10 per box, + shipping)
//                     </div>
//                 </span>
//                 </div>
//               <div className="ggp-payment-options-refill-item">
//                 <span className="ggp-payment-options-refill-mark">&#10003;</span>
//                 <span className="ggp-payment-options-refill-text">Fill medications with Golden Gate Pharmacy Services</span>
//               </div>
//               <div className="ggp-payment-options-refill-item">
//                 <span className="ggp-payment-options-refill-mark">&#10003;</span>
//                 <span className="ggp-payment-options-refill-text">
//                   Medication Management and Synchronization
//                   <button onClick={() => setIsModalOpen(true)} style={{ marginLeft: '0px', fontSize: '12px', color: '#007bff', background: 'none', border: 'none', cursor: 'pointer' }}>
//                     Learn More
//                   </button>
//                 </span>
//               </div>
//               <div className="ggp-payment-options-refill-item">
//                 <span className="ggp-payment-options-refill-mark">&#10003;</span>
//                 <span className="ggp-payment-options-refill-text">Complimentary Pill Packing Services once dosage is stabilized</span>
//               </div>
//               <div className="ggp-payment-options-refill-item">
//                 <span className="ggp-payment-options-refill-mark">&#10003;</span>
//                 <span className="ggp-payment-options-refill-text">Complimentary 2-day FedEx delivery for all medications</span>
//               </div>
//             </div>
//             <div className="ggp-payment-options-footer">
//               <button 
//                 onClick={() => handlePlanSelect('Pharmacy Choice')}
//                 className="ggp-payment-options-select-button"
//               >
//                 Select Payment Plan
//                 <span className="ggp-payment-options-button-icon">→</span>
//               </button>
//             </div>
//           </div>
//         );
//       case 'Athelas Preferred':
//         return (
//           <div className="ggp-payment-options-plan" key={plan}>
//             <h3 className="ggp-payment-options-title">
//               Athelas Home without Medication Management
//             </h3>
//             <div className="ggp-payment-options-refill-info">
//                 <div className="ggp-payment-options-refill-item">
//                 <span className="ggp-payment-options-refill-mark">&#10003;</span>
//                 <span className="ggp-payment-options-refill-text"><strong>$1500</strong> Athelas Home Device</span>
//               </div>
//               <div className="ggp-payment-options-refill-item">
//                 <span className="ggp-payment-options-refill-mark">&#10003;</span>
//                 <span className="ggp-payment-options-refill-text">
//                     <strong>$5</strong> per Athelas WBC Test Strip
//                     <div className="ggp-payment-options-subtle-text">
//                     (10 per box, + shipping)
//                     </div>
//                 </span>
//                 </div>
//               <div className="ggp-payment-options-refill-item">
//                 <span className="ggp-payment-options-refill-mark">&#10003;</span>
//                 <span className="ggp-payment-options-refill-text">Fill medication with the pharmacy of your choice</span>
//               </div>
//             </div>
//             <div className="ggp-payment-options-footer">
//               <button 
//                 onClick={() => handlePlanSelect('Athelas Preferred')}
//                 className="ggp-payment-options-select-button"
//               >
//                 Select Payment Plan
//                 <span className="ggp-payment-options-button-icon">→</span>
//               </button>
//             </div>
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div className="ggp-payment-options-container">
//       <Header /> {/* Add Header */}
//       <div className="ggp-payment-options-content">
//         <h2 className="ggp-payment-options-header">Your Plan Options</h2>
//         <div className="ggp-payment-options-plans">
//           {plans.map(plan => renderPlan(plan))}
//         </div>
//       </div>
//       <MedicationManagementModal
//         isOpen={isModalOpen}
//         onRequestClose={() => setIsModalOpen(false)}
//       />
//     </div>
//   );
// };

// export default GgpPaymentPlanOptions;
