import React from 'react'
import './Faq.css'


export default class Faq extends React.Component {

    render() {

        let questions = this.props.questions
        return (
            <div class="container pb-5">
                <h1 class="faq-header pb-3" id="faq">FAQ</h1>
                {
                    Object.keys(questions).map(function(key, index) {
                        return (
                            <div class="row accordion__single py-3">
                                <div class="col no-padding text-left">
                                    <input class="accordion__single__hidden" id={"question"+index} type="checkbox"/>
                                    <label class="accordion__single__question" for={"question"+index}>{key}</label>
                                    <label class="accordion__single__question__label" for={"question"+index}></label>
                                    <p class="accordion__single__answer">{questions[key]}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }

}