import React, { useState } from "react";

type PropsType = {};

const styles: any = {
  container: {
    textAlign: "center",
    maxWidth: "1000px",
    margin: "0% auto",
    color: "#12334B",
    marginTop: 48,
  },
};

const questions = [
  {
    question: "What is Athelas?",
    answer:
      "Athelas is a biotechnology company that has partnered with your prescriber to provide you with remote health devices. Our program allows your prescriber to take an active role in your care to help ensure that you do not experience any unforeseen health issues.",
  },
  {
    question: "How does the Athelas Nurse Program work?",
    answer:
      "Athelas pairs you with a clinically certified nurse who regularly reviews your medical information and calls/texts you on a monthly basis to check in on your health. Our nurses are available during business hours in your respective timezone to answer any questions you may have about your health.",
  },
  {
    question: "Why was I identified for this program?",
    answer:
      "Based on your care from your provider, they have identified you as being at higher than normal risk and therefore a candidate for the Athelas program.",
  },
  {
    question: "Who pays for this?",
    answer:
      "Athelas has already pre-screened your insurance information to ensure that your insurance covers this program.",
  },
  {
    question: "I'm having trouble with my device - who can help?",
    answer:
      "If you are having any issues with your device, or have any questions at all, you can call or text us at 408-359-4783 or email us at support@athelas.com. We will get back to you within 24 hours.",
  },
  {
    question: "Is my information secure?",
    answer:
      "Always - your privacy and security are our #1 priority. Everything we do is protected by HIPAA.",
  },
];

const QuestionModule = (props: any) => {
  const [active, setActive] = useState(false);

  return (
    <button
      onClick={() => {
        setActive(!active);
      }}
      style={{
        width: "96%",
        maxWidth: "1000px",
        border: "solid 1px #E0E0E0",
        padding: 24,
        background: "white",
        textAlign: "left",
        marginBottom: 16,
        marginLeft: "2%",
        marginRight: "2%",
        flexDirection: "row",
        display: "flex",
      }}
    >
      <div style={{ width: "95%" }}>
        <p
          style={{
            fontSize: "120%",
            fontWeight: "bold",
            marginBottom: 0,
            color: active ? "#FC5378" : "",
          }}
        >
          {props.question}
        </p>

        {active && <p style={{ marginTop: 8 }}>{props.answer}</p>}
      </div>
      <div style={{ width: "5%", textAlign: "center" }}>
        {active ? (
          <p
            style={{
              fontSize: "200%",
              fontWeight: "bold",
              margin: 0,
              height: 20,
              width: 20,
              marginTop: -10,
            }}
          >
            -
          </p>
        ) : (
          <p
            style={{
              fontSize: "200%",
              fontWeight: "bold",
              color: "#FC5378",
              margin: 0,
              height: 20,
              width: 20,
              marginTop: -10,
            }}
          >
            +
          </p>
        )}
      </div>
    </button>
  );
};

export const FAQSection = (props: PropsType) => {
  return (
    <div style={styles.container}>
      <h3 style={{ fontWeight: "bold", marginBottom: 16 }}>
        Frequently Asked Questions
      </h3>
      <p style={{ maxWidth: 400, margin: "0% auto", marginBottom: 36 }}>
        Below you’ll find answers to some most commonly asked questions. If you
        have questions that aren’t answered below, feel free to call{" "}
        <span style={{ color: "#FC5378" }}>1-833-524-1318</span> or email{" "}
        <span
          style={{ color: "#FC5378", cursor: "pointer" }}
          onClick={() => window.open("mailto:support@athelas.com", "_blank")}
        >
          support@athelas.com
        </span>{" "}
        and we will be happy to answer them for you
      </p>

      {questions.map((data) => {
        const question = data.question;
        const answer = data.answer;

        return <QuestionModule question={question} answer={answer} />;
      })}
    </div>
  );
};
