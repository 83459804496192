import { object, InferType, number } from "yup";

export const partialPaymentSchema = (maxAmount: number) =>
  object({
    amount_to_pay: number()
      .typeError("Please enter a valid number")
      .positive("Amount cannot be negative")
      .min(1, "Amount cannot be less than 1")
      .max(maxAmount, "Amount cannot be greater than the total amount")
      .required("Please enter the amount to pay"),
  });

export type PartialPaymentSchema = InferType<
  ReturnType<typeof partialPaymentSchema>
>;
