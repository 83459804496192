import React from "react";

import AthelasLogo from "../PartnershipEmbed/assets/AthelasLogo.png";
import AthelasDevicesImage from "../revampedOnboarding/assets/AthelasDevices.png";

type PropsType = {
  site_logo?: null | string;
  site_name?: null | string;
  palomar: boolean;
};

const styles: any = {
  container: {
    width: "100%",
    minHeight: 200,
    margin: "0 auto",
    textAlign: "center",
    backgroundColor: "#F4F8FD",
  },
  logoContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    margin: "0% auto",
    marginBottom: 12,
  },
  logo: {
    maxWidth: "140px",
    maxHeight: "80px",
    margin: 16,
    marginRight: 24,
    marginLeft: 24,
    objectFit: "contain",
  },
  welcomeContainer: {
    width: "100%",
    backgroundColor: "#F4F8FD",
  },
  welcomeContainerInner: {
    maxWidth: "600px",
    padding: 16,
    margin: "0% auto",
    paddingTop: 50,
    paddingBottom: 50,
  },
  buttonStyle: {
    width: "100%",
    maxWidth: "240px",
    fontStyle: "bold",
    padding: 8,
    borderRadius: 30,
    marginTop: 16,
    backgroundColor: "#FF6779",
    fontWeight: "bold",
    color: "white",
    textAlign: "center",
    cursor: "pointer",
    margin: "0% auto",
    marginBottom: "16px",
  },
  buttonStylePalomar: {
    width: "100%",
    maxWidth: "240px",
    fontStyle: "bold",
    padding: 8,
    borderRadius: 30,
    marginTop: 16,
    backgroundColor: "#008CC1",
    fontWeight: "bold",
    color: "white",
    textAlign: "center",
    cursor: "pointer",
    margin: "0% auto",
    marginBottom: "16px",
  },
};

export const WelcomeSection = (props: PropsType) => {
  const fontColor = props.palomar ? "#008CC1" : "#FF6779";
  const palomarText =
    "Our world-class team at Palomar Health Medical Group partners with Athelas to provide an extraordinary level of care. Athelas devices and nurses bring preventative healthcare into your home, enabling you and your doctor to monitor your health with total convenience";
  const palomarHeader = (
    <p style={{ marginTop: "16px", fontSize: "110%" }}>
      {palomarText}
      <br />
      <b
        style={{ color: fontColor, cursor: "pointer" }}
        onClick={() => window.open("/")}
      >
        Sign up today!
      </b>
    </p>
  );
  return (
    <div style={styles.container}>
      <div style={styles.logoContainer}>
        {props.site_logo && (
          <img src={props.site_logo} style={styles.logo} alt="Site logo" />
        )}
        <img src={AthelasLogo} style={styles.logo} alt="Athelas logo" />
      </div>
      <hr style={{ width: "60%", margin: "auto" }}></hr>
      <div style={styles.welcomeContainer}>
        <div style={styles.welcomeContainerInner}>
          <h1 style={{ fontWeight: "bold" }}>
            Athelas, the #1 Provider of{" "}
            <span style={{ color: fontColor }}>Remote Patient Monitoring</span>
          </h1>

          {props.palomar ? (
            palomarHeader
          ) : (
            <p style={{ marginTop: "16px", fontSize: "110%" }}>
              Our team {props.site_name && `at ${props.site_name}`} has
              partnered with Athelas to provide a new level of care. Athelas
              devices and nurses bring preventative healthcare into your home,
              enabling you and your doctor to monitor your health with total
              convenience.{" "}
              <b
                style={{ color: fontColor, cursor: "pointer" }}
                onClick={() => window.open("/")}
              >
                Sign up today!
              </b>
            </p>
          )}

          <div
            style={
              props.palomar ? styles.buttonStylePalomar : styles.buttonStyle
            }
            onClick={() => {
              window.open("https://onboarding.athelas.com/");
            }}
          >
            Get Started
          </div>

          <img
            src={AthelasDevicesImage}
            alt="Athelas devices"
            style={{
              maxWidth: "400px",
              width: "100%",
            }}
          />
        </div>
      </div>
    </div>
  );
};
