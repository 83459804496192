import React, { Component } from 'react';
import axios from "axios";
import { API_SERVER_URL } from "../../app/constants";
import { trackEvent } from "../../app/utils/logging.ts";

class TextReminders extends Component {
    constructor(props) {
        super(props);
        this.state = {enableTextReminders: false, showError: false};

        this._toggleEnableTextReminders = this._toggleEnableTextReminders.bind(this);
        this._nextStep = this._nextStep.bind(this);
    }

    componentDidMount() {
      trackEvent(
        "view",
        this.props.patientId,
        "gem_onboarding_success",
        this.props.first_name + " " + this.props.last_name
      );
    }
  

    _toggleEnableTextReminders() {
        this.setState((prevState) => ({enableTextReminders: !prevState.enableTextReminders}));
    }

    _nextStep() {
        if(this.state.enableTextReminders){
            const headers = {};
            headers["Authorization"] = `Bearer ${this.props.token}`;
            axios.post(`${API_SERVER_URL}/patients/${this.props.patientId}/enable_pilltrack_text_reminders`,
            {}, {headers});
        }
        this.props.nextStep();
    }

  render() {
    return (
        <div class="form-container gem-container my-5">
        <div class="pt-4 pb-2">
          <h1>Text Reminders</h1>
        </div>
        <div class="px-5">
          <p class="pt-3">
            <b> Do you want to receive text reminders for taking your medication? </b> (Only if you haven’t yet taken your dose)
          </p>
        </div>
        <div class="d-inline-flex flex-row">
          <div class="p-2">
            <input type='checkbox' onChange={this._toggleEnableTextReminders}></input>
          </div>
          <div class="p-2">
            <b> Enable Reminders </b>
          </div>
        </div>
        <div class="px-5 py-3 txt_next_steps">
            <b> Press continue to learn how to use your device </b>
        </div>
        <div class="pt-3 pb-4">
          <button
            type="submit"
            class="btn light-btn"
            onClick={this._nextStep}
          >
            Continue
          </button>
        </div>
      </div>
    )
  }
}

export default TextReminders;