import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { OnboardingStepsType, ONBOARDING_FLOW } from "./constants";
import CornerAthelasOne from "../assets/cornerAthelasOne.png";
import CornerWeight from "../assets/cornerWeight.png";
import { useFeatureFlag } from "../../../app/utils/FeatureFlag";
import { OnboardingProgressBar } from "./components/OnboardingProgressBar";
import { Modal, Box, Typography } from "@mui/material";

type PropsType = {
  showDeviceImages?: boolean;
  showSiteLogos?: boolean;
  showCornerImages?: boolean;
  site_logo?: null | string;
  site_name?: null | string;
  palomar?: null | boolean;
};

const styles: any = {
  container: {
    width: "100%",
    minHeight: 400,
    margin: "0px auto",
    textAlign: "center",
    padding: 36,
    paddingTop: 60,
    position: "relative",
  },
  buttonStyle: {
    width: "100%",
    maxWidth: "240px",
    margin: "0% auto",
    marginBottom: 24,
    fontStyle: "bold",
    padding: 8,
    borderRadius: 4,
    backgroundColor: "#FF6779",
    marginTop: 24,
    color: "white",
    cursor: "pointer",
    fontWeight: "bold",
    textAlign: "center",
  },
};

export const OnboardingRouter = (props: PropsType) => {
  const [step, setStep] = useState(OnboardingStepsType.INITIAL);
  const [stepCount, setStepCount] = useState(1);

  const [showModal, setShowModal] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  let source = window.location.pathname;

  const numActiveSteps = Object.keys(ONBOARDING_FLOW)
    .map((x) => ONBOARDING_FLOW[x])
    .filter((x) => x.active).length;

  const nextStep = (...args: any[]) => {
    const nextStep = ONBOARDING_FLOW[step.toString()].next_step;
    const action = ONBOARDING_FLOW[step.toString()].action;

    if (action) {
      action(...args)
        .then((res: any) => {
          if (nextStep != null) {
            setStep(nextStep);
            setStepCount(stepCount + 1);
          }
        })
        .catch((err: any) => {
          if (err.error) {
            setShowModal(true);
          }
        });
    } else {
      if (nextStep != null) {
        setStep(nextStep);
        setStepCount(stepCount + 1);
      }
    }
  };
  const revampedOnboardingEnabled = useFeatureFlag(
    "enable_revamped_onboarding_flow",
    -1
  );

  const Component: any = ONBOARDING_FLOW[step.toString()].component;

  return (
    <div style={styles.container}>
      {showModal && (
        <Modal
          open={showModal}
          sx={{ padding: "16px" }}
          onClose={() => {
            setShowModal(false);
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              padding: 3,
              border: "none",
              margin: "60px auto",
              maxWidth: "400px",
              borderRadius: "16px",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <b
                style={{
                  textAlign: "center",
                }}
              >
                We're Sorry
              </b>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Looks like we are not able to find your information in our Athelas
              records. Please reach out to your doctor to discuss if you are
              eligible for the Athelas Remote Patient Monitoring program.
            </Typography>
            <p
              style={{
                textAlign: "center",
                color: "#888",
                marginTop: 32,
                cursor: "pointer",
              }}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Close
            </p>
          </Box>
        </Modal>
      )}

      {props.showCornerImages && !isTabletOrMobile && (
        <img
          src={CornerAthelasOne}
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            height: "400px",
            zIndex: -1,
          }}
          alt="Athelas One device"
        />
      )}
      {props.showCornerImages && !isTabletOrMobile && (
        <img
          src={CornerWeight}
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            height: "400px",
            zIndex: -1,
          }}
          alt="Weight scale"
        />
      )}

      {revampedOnboardingEnabled && (
        <div style={{ maxWidth: "300px", margin: "0% auto" }}>
          <OnboardingProgressBar
            currentStep={stepCount}
            numSteps={numActiveSteps}
            palomar={props.palomar}
          />
        </div>
      )}

      <Component nextStep={nextStep} source={source} {...props} />
    </div>
  );
};
