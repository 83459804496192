import React from "react";
import { useMediaQuery } from "react-responsive";

type PropsType = {};

const styles: any = {
  videoContainer: {
    width: "100%",
    justifyContent: "center",
  },
};

export const AthelasRPMVideo = (props: PropsType) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  return (
    <div style={styles.videoContainer}>
      <div
        style={{
          width: "100%",
          maxWidth: 1000,
          margin: "0% auto",
          marginTop: 48,
        }}
      >
        <iframe
          title="Athelas Onboarding"
          src="https://player.vimeo.com/video/679226239?h=a3fcd3ee85"
          width="100%"
          height={isTabletOrMobile ? "220px" : "550px"}
          style={{ margin: "0% auto" }}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};
