export const ProgressBar = (props) => {
  // props =
  //      percent: float
  //      color: string

  return (
    <div
      style={{
        margin: "5%",
      }}
    >
      <p style={{ textAlign: "left", marginBottom: "0", fontSize: "70%" }}>
        PROGRESS: {props.percent || 0}%
      </p>
      <div
        style={{
          width: "100%",
          backgroundColor: "#DEDEDE",
          height: "24px",
          borderRadius: "100px",
        }}
      >
        <div
          style={{
            width: `${props.percent || 0}%`,
            backgroundColor: props.color || "#4cbec2",
            height: "100%",
            borderRadius: "100px",
          }}
        ></div>
      </div>
    </div>
  );
};
