import React, { Component } from "react";
import { saveUrlToken } from "../login/LoginSlice";
import { connect } from "react-redux";
import { withRouter } from '../../wrappers/withRouter'
import { Navigate } from 'react-router-dom';
import jwt from "jsonwebtoken";
import { PUBLIC_KEY } from "../../app/constants";
import GemWelcome from "./Welcome";
import EnterMedication from "./Medication";
import Finish from "./Finish";
import Success from "./Success";
import TextReminders from "./TextReminders";
import Alignment from "./Alignment";
import { Bottles } from "./Bottles";
import Internet from "./Internet";
import Failure from "./Failure";
import End from "./End";
import { Instruction1, Instruction2, Instruction3 } from "./Instructions";
import axios from "axios";
import { API_SERVER_URL } from "../../app/constants";

import { trackEvent } from "../../app/utils/logging.ts";

const mapDispatch = { saveUrlToken };

class Gem extends Component {
  constructor(ownProps) {
    super(ownProps);
    const params = new URLSearchParams(ownProps.router.location.search);
    const token = params.get("token");
    if (token) {
      ownProps.saveUrlToken(token);
    }

    this._enterMeds = this._enterMeds.bind(this);
    this._nextStep = this._nextStep.bind(this);
    this._morePills = this._morePills.bind(this);
    this._directions = this._directions.bind(this);
    this._returnHome = this._returnHome.bind(this);
    this._finish = this._finish.bind(this);

    try {
      const decodedToken = jwt.verify(token, PUBLIC_KEY, {
        algorithms: ["RS256"],
      });
      this.state = {
        first_name: decodedToken.first_name,
        last_name: decodedToken.last_name,
        id: decodedToken.sub,
        token: token,
        device_string: decodedToken.device_string,
        redirect: false,
        online: false,
        bottles_present: [false, false, false, false],
        medication_names: ["disabled", "disabled", "disabled", "disabled"],
        schedules: ["DAILY_AM", "DAILY_AM", "DAILY_AM", "DAILY_AM"],
        current_step: 0,
        last_medication_name_entered: "",
        last_tray_entered: 0,
      };
    } catch (e) {
      this.state = {
        redirect: true,
      };
    }
  }

  _scroll() {
    let gem_container = document.querySelector(".gem-container");
    if (gem_container) {
      let offset = gem_container.offsetTop - 20;
      window.scrollTo(0, offset);
    }
  }

  _enterMeds() {
    this._scroll();
    this.setState({ current_step: 4 });
  }

  _nextStep() {
    this._scroll();
    let num_bottles_present = this.state.bottles_present.reduce(
      (a, b) => a + b,
      0
    );

    // fast forward logic
    if (this.state.current_step === 1) {
      if (this.state.online && num_bottles_present === 4) {
        this.setState({ current_step: 4 });
        return;
      } else if (this.state.online) {
        this.setState({ current_step: 3 });
        return;
      }
    } else if (this.state.current_step === 2) {
      if (num_bottles_present === 4) {
        this.setState({ current_step: 4 });
        return;
      }
    }
    this.setState({ current_step: this.state.current_step + 1 });
  }

  _directions() {
    this._scroll();
    this.setState({ current_step: 9 });
  }

  _returnHome() {
    this._scroll();
    this.setState({ current_step: 0 });
  }

  _finish() {
    const {
      token,
      device_string,
      medication_names,
      schedules,
      first_name,
      last_name,
      id,
    } = this.state;
    trackEvent(
      "click",
      id,
      "gem_onboarding_finished",
      first_name + " " + last_name
    );
    const headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    return axios
      .post(
        `${API_SERVER_URL}/medication_devices/${device_string}/update_medication_trays`,
        {
          medication_names: {
            0: medication_names[0],
            1: medication_names[1],
            2: medication_names[2],
            3: medication_names[3],
          },
          schedules: {
            0: schedules[0],
            1: schedules[1],
            2: schedules[2],
            3: schedules[3],
          },
        },
        {
          headers,
        }
      )
      .then((response) => {
        if (response.data.message === "failed to update medication trays") {
          this.setState({ current_step: 7 });
        } else if (
          response.data.message === "medication trays updated successfully"
        ) {
          this.setState({ current_step: this.state.current_step + 1 });
        }
      })
      .catch(() => {
        this.setState({ current_step: 8 });
      });
  }

  _morePills(medication_name, schedule) {
    this._scroll();
    // if we have more or less than one bottle absent, do nothing
    let num_bottles_absent =
      4 - this.state.bottles_present.reduce((a, b) => a + b, 0);
    if (num_bottles_absent !== 1) {
      return;
    }

    if (medication_name.length === 0) {
      return;
    }

    // search for the absent bottle
    let bottle_absent = -1;
    for (let i = 0; i < this.state.bottles_present.length; i += 1) {
      if (!this.state.bottles_present[i]) {
        bottle_absent = i;
        break;
      }
    }

    if (bottle_absent === -1) {
      return;
    }

    const new_medication_names = this.state.medication_names.slice();
    const new_schedules = this.state.schedules.slice();
    const medication_name_capitalized =
      medication_name.charAt(0).toUpperCase() + medication_name.slice(1);
    new_medication_names[bottle_absent] = medication_name_capitalized;
    new_schedules[bottle_absent] = schedule;
    this.setState({
      current_step: this.state.current_step + 1,
      medication_names: new_medication_names,
      last_tray_entered: bottle_absent,
      last_medication_name_entered: medication_name_capitalized,
      schedules: new_schedules,
    });
  }

  deviceOnline = () => {
    const { token, device_string } = this.state;
    const headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    return axios
      .get(`${API_SERVER_URL}/medication_devices/${device_string}/is_online`, {
        headers,
      })
      .then((response) => {
        if (response.data.message === "online") {
          this.setState({ online: true });
        } else {
          this.setState({ online: false });
        }
      });
  };

  componentDidMount() {
    window.setInterval(this.deviceOnline, 1000);
    window.setInterval(this.deviceState, 1000);
    window.addEventListener("load", this._scroll);
  }

  deviceState = () => {
    const { token, device_string } = this.state;
    const headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    return axios
      .get(`${API_SERVER_URL}/medication_devices/${device_string}/state`, {
        headers,
      })
      .then((response) => {
        const new_bottles_present = [false, false, false, false];
        for (let bottle_index in response.data.message) {
          new_bottles_present[response.data.message[bottle_index]] = true;
        }
        this.setState({ bottles_present: new_bottles_present });
      });
  };

  render() {
    const {
      first_name,
      last_name,
      online,
      bottles_present,
      current_step,
      redirect,
      id,
    } = this.state;
    const step = () => {
      switch (current_step) {
        case 0:
          return (
            <GemWelcome
              nextStep={this._nextStep}
              bottles_present={bottles_present}
              online={online}
              first_name={first_name}
              last_name={last_name}
              patient_id={id}
            />
          );
        case 1:
          return <Alignment nextStep={this._nextStep} />;
        case 2:
          return (
            <Internet
              nextStep={this._nextStep}
              online={online}
              first_name={first_name}
              last_name={last_name}
              patient_id={id}
            />
          );
        case 3:
          return (
            <Bottles
              nextStep={this._nextStep}
              online={online}
              bottles_present={bottles_present}
            />
          );
        case 4:
          return (
            <EnterMedication
              medicationNames={this.state.medication_names}
              nextStep={this._morePills}
              bottles_present={bottles_present}
              online={online}
              first_name={first_name}
              last_name={last_name}
              patient_id={id}
            />
          );
        case 5:
          return (
            <Success
              medicationNames={this.state.medication_names}
              medicationName={this.state.last_medication_name_entered}
              nextStep={this._nextStep}
              bottles_present={bottles_present}
              online={online}
              first_name={first_name}
              last_name={last_name}
              patient_id={id}
            />
          );
        case 6:
          return (
            <Finish
              medicationNames={this.state.medication_names}
              medicationName={this.state.last_medication_name_entered}
              bottles_present={bottles_present}
              finish={this._finish}
              nextStep={this._enterMeds}
              tray={this.state.last_tray_entered}
            />
          );
        case 7:
          return (
            <TextReminders
              token={this.state.token}
              first_name={first_name}
              last_name={last_name}
              patientId={this.state.id}
              nextStep={this._directions}
            />
          );
        case 8:
          return (
            <Failure
              nextStep={this._returnHome}
              first_name={first_name}
              last_name={last_name}
              patient_id={id}
            />
          );
        case 9:
          return <Instruction1 nextStep={this._nextStep} />;
        case 10:
          return <Instruction2 nextStep={this._nextStep} />;
        case 11:
          return <Instruction3 nextStep={this._nextStep} />;
        case 12:
          return <End />;
        default:
          return <Navigate to="/" />;
      }
    };

    if (redirect) {
      return <Navigate to="/" />;
    } else {
      return <div> {step()} </div>;
    }
  }
}

export default connect(null, mapDispatch)(withRouter(Gem));
