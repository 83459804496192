//eslint-disable-next-line no-unused-vars
import { object, string, boolean, InferType, number } from "yup";
import moment from "moment";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const patientSchema = object({
  prescriber: number().when("is_update", {
    is: true,
    then: number()
      .typeError("Please select a prescriber for the patient")
      .required("Please select a prescriber for the patient"),
  }),
  first_name: string().required("Please enter the patient's first name"),
  last_name: string().required("Please enter the patient's last name"),
  date_of_birth: string()
    .required()
    .test("date_of_birth", "Please enter a valid date of birth", (value) => {
      return moment().diff(moment(value), "years") >= 18;
    }), // should not be enrolling any minors
  //   site_id: number().required("Invalid link, missing site ID"), //inherent in token
  //   prescriber_id: number().required("Invalid link, missing prescriber ID"), //inherent in token
  gender: string()
    .oneOf(["Male", "Female"])
    .required("Please enter patient sex"),
  phone_number: string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required(
      "Patient phone number is required, please use cell number over landline"
    ),
  email: string().email("Please enter a valid patient email").optional(),
  address_line1: string().required("Please enter patient street address"),
  address_line2: string().optional(),
  state_province: string().required("Please enter patient state"),
  city_locality: string().required("Please enter patient city"),
  postal_code: string().required("Please enter patient zip code"),
  commercial_insurance_company: object().when("complete_insurance_bypass", {
    is: false,
    then: object({
      payer_id: string().required("Please select an insurance company"),
      payer_name: string().required("Please select an insurance company"),
    })
      .typeError("Please select an insurance company")
      .required("Please select an insurance company"),
    otherwise: object().nullable(),
  }),
  commercial_policy_number: string().when("complete_insurance_bypass", {
    is: false,
    then: string().required("Please enter patient's policy insurance number"),
  }),
  conditions: string().when("is_update", {
    is: false,
    then: string().required(
      "Please enter comma separated ICD-10 condition codes"
    ),
  }), // converted to array on backend, can cast as so https://stackoverflow.com/questions/70839641/how-to-cast-a-string-into-an-array-using-yup

  first_test: string().when("is_update", {
    is: false,
    then: string()
      .oneOf(["BLOOD_PRESSURE", "WEIGHT", "GLUCOSE"])
      .required("Please select a device to prescribe the patient"),
  }),
  override_insurance_eligibility_check: boolean(), // bypass insurance checking for patient creation
  enroll_patient: boolean(), // flag to confirm enrollment of patient
  is_self_pay: boolean(),
  complete_insurance_bypass: boolean(),
  for_testing: boolean(), // when true, run endpoint besides patient creation
  is_update: boolean().default(false),
});

// parse and assert validity
// const patient = await patientSchema.validate(await fetchUser());

export type Patient = InferType<typeof patientSchema>;
/* {
    name: string;
    age: number;
    email?: string | undefined
    website?: string | null | undefined
    createdOn: Date
  }*/
