import React, { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import gem03 from "./assets/gem03.png";
import "./styling/styling.css";
import axios from "axios";
import jwt from "jsonwebtoken";
import { saveUrlToken } from "../login/LoginSlice";
import { PUBLIC_KEY } from "../../app/constants";
import { API_SERVER_URL } from "../../app/constants";
import { useLocation } from "react-router-dom";
import Modal from "react-modal";
import { trackEvent } from "../../app/utils/logging.ts";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function PilltrackSurvey() {
  const [numMeds, setNumMeds] = useState(0);
  const [token, setToken] = useState(null);
  const [patientID, setPatientID] = useState(0);
  const [name, setName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [eligible, setEligible] = useState(false);

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  useEffect(() => {
    document.body.className = "gemBody";
    const token = query.get("token");
    if (token) {
      saveUrlToken(token);
    }
    const decodedToken = jwt.verify(token, PUBLIC_KEY, {
      algorithms: ["RS256"],
    });
    setToken(token);
    setName(decodedToken.first_name);
    setPatientID(decodedToken.sub);
  }, [query]);

  const onChange = (event) => {
    setNumMeds(parseInt(event.target.value));
  };

  const onEnroll = () => {
    trackEvent("click", patientID, "gem_survey_enroll", name);
    const headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    return axios
      .post(
        `${API_SERVER_URL}/patients/${patientID}/enroll_in_gem`,
        {
          cohort: 2,
          num_medications: numMeds,
        },
        {
          headers,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setShowModal(true);
          if (numMeds > 4) {
            // modal saying we don't support yet
            setEligible(false);
          } else {
            // modal for success
            setEligible(true);
          }
        }
      });
  };

  const successModal = (success) => {
    const header = `Thank you ${name}!`;
    const body = success
      ? "We'll let you know once your Pilltrack is ready to ship."
      : "Our current device only supports up to 4 medications. You've been added to the waitlist for Pilltrack!";
    return (
      <div>
        <Modal
          isOpen={true}
          onAfterOpen={() => {}}
          onRequestClose={() => {
            setShowModal(false);
          }}
          style={modalStyles}
          contentLabel="Confirmation Modal"
        >
          <h5 className="gemOnboardModalSubHeader">{header}</h5>
          <p className="gemOnboardFont">{body}</p>
        </Modal>
      </div>
    );
  };

  const signUpText = `Hey ${name}! Sign Up for PillTrack`;
  const whyPillTrack = "Why PillTrack?";
  const pilltrackBody =
    "PillTrack gives you easy reminders for when to take your medications -- ensuring you never forget a dose. You can view all of your data on your patient portal, and everything is covered by insurance.";
  const question =
    "Please enter the number of medications you are currently taking";

  return (
    <div class="container">
      <div class="row justify-content-md-center">
        <h3 className="gemOnboardFontHeader">{signUpText}</h3>
      </div>
      <div class="row justify-content-md-center">
        <img className="center" src={gem03} alt="PillTrack" />
      </div>
      <div class="row">
        <div class="col">
          <h5 className="gemOnboardFontSubHeader">{whyPillTrack}</h5>
          <p className="gemOnboardFont">{pilltrackBody}</p>
        </div>
      </div>
      <div class="shadow p-3 mb-5 bg-body rounded">
        <h5 className="gemOnboardFontSubHeader">{question}</h5>
        <div class="input-group mb-3">
          <span class="input-group-text"># Medications</span>
          <input
            type="number"
            class="form-control"
            aria-label="Number of medications"
            onChange={onChange}
          />
        </div>
        <div>
          <Button
            disabled={!numMeds}
            variant="primary"
            size="lg"
            className="ButtonSurvey"
            onClick={onEnroll}
          >
            <h5 className="gemOnboardButtonFont">Enroll Now</h5>
          </Button>
        </div>
      </div>
      <div>{showModal && successModal(eligible)}</div>
    </div>
  );
}
export default PilltrackSurvey;
