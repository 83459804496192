import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_SERVER_URL, PUBLIC_KEY } from '../../app/constants'
import jwt from 'jsonwebtoken'
import { trackEvent } from '../../app/utils/tracking';

if ("token" in localStorage) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;
}

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    token: null,
  },
  reducers: {
    saveToken: (state, action) => {
      state.token = action.payload;
      axios.defaults.headers.common["Authorization"] = `Bearer ${state.token}`;
      localStorage.setItem("token", state.token);
    },
    saveUrlToken: (state, action) => {
      state.urlToken = action.payload;
    },
  },
});

export const { saveToken, saveUrlToken } = loginSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const login =
  (first_name, last_name, date_of_birth) => (dispatch, getState) => {
    const headers = {};
    const urlToken = selectUrlToken(getState());
    if (urlToken) {
      headers["Authorization"] = `Bearer ${urlToken}`;
    } else {
      headers["Authorization"] = "";
    }
    return (
      axios
        .post(
          `${API_SERVER_URL}/patients/onboarding_sign_in`,
          {
            first_name,
            last_name,
            date_of_birth,
          },
          {
            headers,
          }
        )
        // return Promise.resolve({data: {token: 'TOKEN'}})
        .then((response) => {
          const token = response.data.token;
          const decodedToken = jwt.verify(token, PUBLIC_KEY, {
            algorithms: ["RS256"],
          });
          const patient_id = decodedToken.sub;
          if (response.status === 200) {
            console.log(
              "Tracked Onboarding Patient -- 'onboarding_sign_in' endpoint hit, success."
            );
            trackEvent("click", "onboarding_signin_success", {
              status_code: response.status,
              patient_id: patient_id,
            });
          } else {
            console.log(
              "Tracked Onboarding Patient -- 'onboarding_sign_in' endpoint hit, failure."
            );
            trackEvent("click", "onboarding_signin_failure", {
              status_code: response.status,
              patient_id: patient_id,
            });
          }
          return dispatch(saveToken(response.data.token));
        })
    );
  };

export const linkClicked = (id) => (dispatch, getState) => {
  if (!id) {
    return new Promise((res, rej) => {
      res(false);
    });
  }
  return (
    axios
      .post(`${API_SERVER_URL}/patients/onboarding_link_clicked`, {
        id: id,
      })
      // return Promise.resolve({data: {token: 'TOKEN'}})
      .then((response) => {
        return;
      })
  );
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectToken = (state) => state.login.token;
export const selectUrlToken = (state) => state.login.urlToken;

export default loginSlice.reducer;
