import InputMask from "react-input-mask";

type PropsType = {
  label?: string;
  placeholder?: string;
  onChange?: (text: string) => void;
  value?: string;
  maskDate?: boolean;
};

export const TextInput = (props: PropsType) => {
  const input = props.maskDate ? (
    <InputMask
      mask="99/99/9999"
      maskPlaceholder="dd/mm/yyyy"
      placeholder={props.placeholder}
      value={props.value}
      onChange={(e) => props.onChange && props.onChange(e.target.value)}
      style={{
        border: "none",
        borderBottom: "solid 1px #EEE",
        outline: "none",
        width: "100%",
        paddingBottom: "4px",
        paddingTop: "8px",
        maxWidth: "300px",
      }}
    ></InputMask>
  ) : (
    <input
      placeholder={props.placeholder}
      value={props.value}
      onChange={(e) => props.onChange && props.onChange(e.target.value)}
      style={{
        border: "none",
        borderBottom: "solid 1px #EEE",
        outline: "none",
        width: "100%",
        paddingBottom: "4px",
        paddingTop: "8px",
        maxWidth: "300px",
      }}
    ></input>
  );
  return (
    <>
      <p
        style={{
          fontSize: "90%",
          textAlign: "left",
          maxWidth: "300px",
          margin: "0% auto",
          marginTop: "24px",
        }}
      >
        {props.label}
      </p>
      {input}
    </>
  );
};
