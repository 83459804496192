import React from "react";
import WhiteAthelasLogo from "../revampedOnboarding/assets/WhiteAthelasLogo.png";
import TwitterLogo from "../revampedOnboarding/assets/Twitter.png";
import LinkedinLogo from "../revampedOnboarding/assets/Linkedin.png";
import { useMediaQuery } from "react-responsive";

type PropsType = {};

const styles: any = {
  container: {
    backgroundColor: "#12334B",
    color: "white",
    padding: 60,
    marginTop: 48,
  },
  innerContainer: {
    maxWidth: "1000px",
    margin: "0% auto",
  },
  buttonStyle: {
    width: "100%",
    maxWidth: "240px",
    fontStyle: "bold",
    padding: 8,
    borderRadius: 30,
    marginTop: 16,
    backgroundColor: "#FF6779",
    fontWeight: "bold",
    color: "white",
    textAlign: "center",
    cursor: "pointer",
  },
  flexBox: {
    flexDirection: "row",
    display: "flex",
  },
  link: {
    fontSize: "85%",
    marginBottom: 8,
    textAlign: "right",
    cursor: "pointer",
  },
};

export const FooterSection = (props: PropsType) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  if (isTabletOrMobile) {
    return (
      <div style={styles.container}>
        <div style={styles.innerContainer}>
          <div style={styles.flexBox}>
            <div style={{ width: "90%" }}>
              <img
                src={WhiteAthelasLogo}
                alt="White Athelas Logo"
                style={{ maxWidth: "200px" }}
              />
              <p style={{ margin: 0, marginTop: 24 }}>160 S. Whisman Rd,</p>
              <p style={{ margin: 0 }}>Mountain View, CA 94041</p>
              <p style={{ margin: 0, marginTop: 8, marginBottom: 8 }}>
                {" "}
                contact@athelas.com
              </p>
              <p style={{ margin: 0 }}>+1 (833) 524-1318</p>

              <p style={{ margin: 0, marginTop: 48 }}>
                2021 Athelas Incorporated. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <div style={styles.flexBox}>
          <div style={{ width: "50%" }}>
            <h2 style={{ maxWidth: "300px" }}>
              <b>Get Started with Athelas today</b>
            </h2>
            <div
              style={styles.buttonStyle}
              onClick={() => {
                window.open("https://onboarding.athelas.com/");
              }}
            >
              Get Started
            </div>
          </div>

          <div style={{ width: "50%", flexDirection: "row", display: "flex" }}>
            <div style={{ marginRight: "80px" }}>
              <p
                style={styles.link}
                onClick={() =>
                  window.open(
                    "https://get.athelas.com/remote-patient-monitoring-rpm/",
                    "_blank"
                  )
                }
              >
                Remote Program
              </p>
              <p
                style={styles.link}
                onClick={() =>
                  window.open(
                    "https://www.athelas.com/products/blood-pressure-monitor",
                    "_blank"
                  )
                }
              >
                Blood pressure cuff
              </p>
              <p
                style={styles.link}
                onClick={() =>
                  window.open(
                    "https://www.athelas.com/products/glucometer",
                    "_blank"
                  )
                }
              >
                Glucometer
              </p>
              <p
                style={styles.link}
                onClick={() =>
                  window.open(
                    "https://www.athelas.com/products/weight-scale",
                    "_blank"
                  )
                }
              >
                Weight Scale
              </p>
            </div>
            <div style={{ marginRight: "80px" }}>
              <p
                style={styles.link}
                onClick={() =>
                  window.open(
                    "https://www.athelas.com/products/pilltrack",
                    "_blank"
                  )
                }
              >
                Pilltrack
              </p>
              <p
                style={styles.link}
                onClick={() =>
                  window.open(
                    "https://www.athelas.com/products/total-care",
                    "_blank"
                  )
                }
              >
                Total Care
              </p>
              <p
                style={styles.link}
                onClick={() =>
                  window.open(
                    "https://www.athelas.com/products/athelas-one",
                    "_blank"
                  )
                }
              >
                Athelas One
              </p>
            </div>
            <div>
              <p
                style={styles.link}
                onClick={() =>
                  window.open("https://www.athelas.com/about", "_blank")
                }
              >
                Company
              </p>
              <p
                style={styles.link}
                onClick={() =>
                  window.open("https://www.athelas.com/careers", "_blank")
                }
              >
                Career
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            height: "1px",
            width: "100%",
            backgroundColor: "white",
            marginTop: 24,
            marginBottom: 24,
          }}
        ></div>

        <div style={styles.flexBox}>
          <div style={{ width: "90%" }}>
            <img
              src={WhiteAthelasLogo}
              alt="White Athelas Logo"
              style={{ maxWidth: "200px" }}
            />
            <p style={{ margin: 0, marginTop: 24 }}>160 S. Whisman Rd,</p>
            <p style={{ margin: 0 }}>Mountain View, CA 94041</p>
            <p style={{ margin: 0, marginTop: 8, marginBottom: 8 }}>
              {" "}
              contact@athelas.com
            </p>
            <p style={{ margin: 0 }}>+1 (833) 524-1318</p>

            <p style={{ margin: 0, marginTop: 48 }}>
              2021 Athelas Incorporated. All Rights Reserved.
            </p>
          </div>

          <div style={{ width: "10%" }}>
            <p style={{ marginTop: 60, flexDirection: "row", display: "flex" }}>
              <img
                src={TwitterLogo}
                onClick={() => {
                  window.open("https://twitter.com/athelas", "_blank");
                }}
                alt="Twitter Logo"
                style={{
                  width: 24,
                  height: 20,
                  marginRight: 20,
                  objectFit: "contain",
                }}
              />
              <img
                src={LinkedinLogo}
                alt="Linkedin Logo"
                onClick={() => {
                  window.open(
                    "https://www.linkedin.com/company/athelas/",
                    "_blank"
                  );
                }}
                style={{
                  width: 24,
                  height: 20,
                  marginRight: 20,
                  objectFit: "contain",
                }}
              />
            </p>
            <p
              style={{ marginTop: 120 }}
              onClick={() => {
                window.open("https://www.athelas.com/privacy-policy", "_blank");
              }}
            >
              Privacy
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
