import React, { useMemo, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { Container } from "./styles";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { partialPaymentSchema, PartialPaymentSchema } from "./schema";
import { useLocation, useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import jwt from "jsonwebtoken";
import { PUBLIC_KEY } from "../../../../app/constants";
import {
  getPatientResponsibility,
  getPaymentIntentInfoV1,
} from "../../PatientPayAPI";
import { trackNormandy } from "../../../../app/utils/logging";

type PartialPaymentProps = {
  setShowPartialPayment: React.Dispatch<React.SetStateAction<boolean>>;
};

const PartialPayment: React.FC<PartialPaymentProps> = ({
  setShowPartialPayment,
}) => {
  const location = useLocation();
  const state = useMemo<any>(() => location?.state ?? {}, [location?.state]);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();

  const displayTotalBalance = (balance: any) =>
    (balance / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      partialPaymentSchema(state.amountChargedInUSDCents / 100)
    ),
    defaultValues: {
      amount_to_pay: state.amountChargedInUSDCents / 100,
    },
  });

  const onSubmit: SubmitHandler<PartialPaymentSchema> = async (values) => {
    trackNormandy({
      action: "confirm_amount",
      tag: "patient_pay_choose_amount_to_pay",
      data: {
        first_name: window.sessionStorage.getItem("first_name"),
        last_name: window.sessionStorage.getItem("last_name"),
        date_of_birth: window.sessionStorage.getItem("date_of_birth"),
        site_id: window.sessionStorage.getItem("site_id"),
        requested_prs: window.sessionStorage.getItem("requested_prs"),
        payment_intent_id: window.sessionStorage.getItem("payment_intent_id"),
      },
    });

    try {
      setLoading(true);
      const token = window.sessionStorage.getItem("token");
      if ((!token || !PUBLIC_KEY) && !state.usingFixedLink) {
        navigate("/patient_pay/unauthorized", {
          state: {},
        });
        return;
      }

      let decodedToken = null;
      if (token && PUBLIC_KEY) {
        decodedToken = jwt.verify(token, PUBLIC_KEY, {
          algorithms: ["RS256"],
        }) as any;
      }

      let isPayingForDependentPatients = false;

      if (
        decodedToken &&
        decodedToken.dependent_patients &&
        decodedToken.dependent_patients.length > 0
      ) {
        isPayingForDependentPatients = true;
      }

      const requestedPRs = decodedToken?.patient_responsibility_ids ?? [];
      const dependentPatientsInfo = JSON.parse(
        window.sessionStorage.getItem("dependent_patients_info") as string
      );

      const dateOfBirth = window.sessionStorage.getItem(
        "date_of_birth"
      ) as string;

      const { data: patientResponsibility } = await getPatientResponsibility({
        first_name: state.firstName,
        last_name: state.lastName,
        dob: dateOfBirth,
        site_id: decodedToken?.site_id ?? state.siteID ?? -1,
        patient_responsibility_ids: requestedPRs,
        ignore_prs_from_dependent_patients: isPayingForDependentPatients,
      });

      const { data } = await getPaymentIntentInfoV1({
        patient_first_name: state.firstName,
        patient_last_name: state.lastName,
        patient_date_of_birth: dateOfBirth,
        site_id: decodedToken?.site_id ?? state.siteID ?? -1,
        patient_responsibility_request_ids: isPayingForDependentPatients
          ? requestedPRs
          : patientResponsibility.patient_responsibility_request_ids,
        amount_due_cents: values.amount_to_pay * 100,
        apply_credits:
          decodedToken?.apply_credits ?? state.applyCredits ?? true,
        batch_id: decodedToken?.batch_id ?? undefined,
        facility_id_to_send_payment_to:
          decodedToken?.facility_id_to_send_payment_to ?? undefined,
        provider_id_to_send_payment_to:
          decodedToken?.provider_id_to_send_payment_to ?? undefined,
        dependent_patients: dependentPatientsInfo,
        note_id: decodedToken?.note_id,
        apply_fees: false,
        appointment_id: decodedToken?.appointment_id ?? undefined,
        outstanding_balance_in_usd_cents:
          decodedToken?.outstanding_balance_in_usd_cents ?? undefined,
        sum_of_line_items_in_usd_cents:
          decodedToken?.sum_of_line_items_in_usd_cents ?? undefined,
      });

      window.sessionStorage.setItem(
        "stripe_connect_id",
        data.stripe_connect_account_id
      );
      window.sessionStorage.setItem("client_secret", data.client_secret);
      window.sessionStorage.setItem("payment_intent_id", data.intent_id);

      setShowPartialPayment(false);
      // need to add this to the state so that when the user goes to breakdown and comes back we know what to show them
      navigate(location.pathname, {
        state: { ...(location.state as any), ...{ chose_custom_amount: true } },
        replace: true,
      });
    } catch (error) {
      console.log(error);
      trackNormandy({
        action: "error_confirming_amount",
        tag: "patient_pay_choose_amount_to_pay",
        data: {
          first_name: window.sessionStorage.getItem("first_name"),
          last_name: window.sessionStorage.getItem("last_name"),
          date_of_birth: window.sessionStorage.getItem("date_of_birth"),
          site_id: window.sessionStorage.getItem("site_id"),
          requested_prs: window.sessionStorage.getItem("requested_prs"),
          payment_intent_id: window.sessionStorage.getItem("payment_intent_id"),
        },
      });
      alert("Error while processing payment. Please try again later.");
    }
    setLoading(false);
  };

  return (
    <Grid item xs={12}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <Typography
            align="center"
            variant="h5"
            sx={{ color: "text.primary" }}
          >
            Choose amount to pay
          </Typography>

          <Controller
            name="amount_to_pay"
            control={control}
            render={({ field: { ref, onChange, ...field } }) => (
              <NumericFormat
                {...field}
                onValueChange={(v) => {
                  if (v.value === "") {
                    onChange(null);
                  } else {
                    onChange(v.value);
                  }
                }}
                thousandSeparator=","
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale
                prefix="$"
                customInput={TextField}
                label="Amount to pay"
                placeholder="0.00"
                max={state.amountChargedInUSDCents / 100}
                InputProps={{
                  endAdornment: (
                    <Typography
                      variant="body1"
                      sx={{ whiteSpace: "nowrap", color: "text.secondary" }}
                    >
                      / {displayTotalBalance(state.amountChargedInUSDCents)}
                    </Typography>
                  ),
                }}
                inputRef={ref}
                error={!!errors?.amount_to_pay?.message}
                helperText={errors?.amount_to_pay?.message}
                sx={{ width: "100%" }}
              />
            )}
          />
          <Button
            variant="contained"
            type="submit"
            disabled={loading}
            style={{
              width: "100%",
              height: "56px",
              textAlign: "center",
            }}
          >
            {loading ? (
              <CircularProgress color="inherit" size="24px" />
            ) : (
              "Confirm Amount"
            )}
          </Button>
        </Container>
      </form>
    </Grid>
  );
};

export default PartialPayment;
