import "./Gem.css";
import React, { Component } from "react";
import { BottleStatus } from "./Bottles";

class Finish extends Component {
  render() {
    let bottle_one_filled = this.props.medicationNames[0] !== "disabled";
    let bottle_two_filled = this.props.medicationNames[1] !== "disabled";
    let bottle_three_filled = this.props.medicationNames[2] !== "disabled";
    let bottle_four_filled = this.props.medicationNames[3] !== "disabled";
    let num_bottles_filled = 0;
    for (let name of this.props.medicationNames) {
      if (name !== "disabled") {
        num_bottles_filled += 1;
      }
    }
    let num_bottles_present = this.props.bottles_present.reduce(
      (a, b) => a + b,
      0
    );

    return (
      <div class="form-container gem-container my-5">
        {num_bottles_present === 4 && (
          <div>
            <div class="pt-4 pb-2">
              <h1> Setup </h1>
            </div>
            <div class="bottles">
              <div
                class={
                  bottle_one_filled ? "bottle bottle-one-filled" : "bottle"
                }
              >
                <div
                  class={
                    bottle_two_filled
                      ? "bottle bottle-two bottle-filled"
                      : "bottle bottle-two"
                  }
                ></div>
              </div>
              <div
                class={
                  bottle_three_filled
                    ? "bottle bottle-three-filled mt-3"
                    : "bottle mt-3"
                }
              >
                <div
                  class={
                    bottle_four_filled
                      ? "bottle bottle-four bottle-filled"
                      : "bottle bottle-four"
                  }
                ></div>
              </div>
            </div>
            {num_bottles_filled !== 4 ? (
              <p class="mt-3">
                {" "}
                You have filled {num_bottles_filled}{" "}
                {num_bottles_filled === 1 ? "bottle" : "bottles"} so far!{" "}
              </p>
            ) : (
              <p class="mt-3"> You have filled all bottles! </p>
            )}
            <div>
              <div class="pt-3 pb-4">
                <button
                  type="submit"
                  class="btn light-btn"
                  onClick={this.props.nextStep}
                >
                  Enter another medication or edit an existing one
                </button>
              </div>
              <h2> OR </h2>
              <div class="p-4">
                <button
                  type="submit"
                  class="btn light-btn"
                  onClick={this.props.finish}
                >
                  I'm all done!{" "}
                </button>
              </div>
            </div>
          </div>
        )}
        {num_bottles_present < 4 && (
          <div class="pt-3 px-5">
            <BottleStatus bottles_present={this.props.bottles_present} />
            <p class="pt-3">
              {" "}
              <b>
                {" "}
                Some bottles are missing. Please insert all 4 included bottles
                to continue.
              </b>{" "}
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default Finish;
