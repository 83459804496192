import { useState } from "react";

export const MultipleChoice = (props) => {
  const [activeIndex, selectIndex] = useState(-1);

  const values = props.values;
  const labels = props.labels;
  return (
    <div style={{ textAlign: "left" }}>
      {labels.map((res, index) => {
        return (
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              marginBottom: "36px",
            }}
            onClick={() => {
              selectIndex(index);
              props?.selectValue(values[index]);
            }}
          >
            <div
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#eb638c",
                borderRadius: "100px",
              }}
            >
              <div
                style={{
                  width: "22px",
                  height: "22px",
                  border: "solid 4px white",
                  margin: "4px",
                  borderRadius: "100px",
                  backgroundColor: activeIndex === index ? "" : "white",
                  cursor: "pointer",
                }}
              ></div>
            </div>

            <div
              style={{ marginLeft: "8px", marginTop: "2px", fontSize: "110%" }}
            >
              {res}
            </div>
          </div>
        );
      })}
    </div>
  );
};
