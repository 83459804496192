import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import styled from 'styled-components'
import { withRouter } from '../../wrappers/withRouter'
import { updateAdditionalQuestions } from '../patientInfo/PatientInfoSlice'
import { useNavigate } from 'react-router-dom';

const mapDispatch = { updateAdditionalQuestions };

const styles = {
  buttonStyle: {
    width: "100%",
    maxWidth: "240px",
    margin: "0% auto",
    marginBottom: 24,
    fontStyle: "bold",
    padding: 8,
    borderRadius: 4,
    backgroundColor: "#FF6779",
    marginTop: 24,
    color: "white",
    cursor: "pointer",
  }
}

function AdditionalQuestions() {
  const [hasElectricalImplant, setHasElectricalImplant] = useState(false);
  const [isPregnant, setIsPregnant] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  const updateAdditionalQuestions = () => {
    let token = "";
    if ("token" in localStorage) {
      token = localStorage.getItem("token");
    }

    console.log('Attempting to update patient info based on additional questions ... ')

    /*
    fetch(`https://api.athelas.com/patients/update_onboarding_additional_questions?token=${token}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        implant: hasElectricalImplant,
        pregnant: isPregnant,
      }),
    }).then((res) => {
      alert(res)
    });
    */

    fetch(`https://api.athelas.com/patients/update_onboarding_additional_questions?token=${token}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        implant: hasElectricalImplant,
        pregnant: isPregnant,
      }),
    }).catch(() => {
      navigate("/failure")
    }).then(response => response.json()).then(data => console.log('Updated Patient Info based on Additional Questions' + data));


    console.log('Updated patient info based on additional questions.')

    navigate("/confirm");
  };

  return (
    <StyledContainer>
      <AdditionalQuestionsHeader>
        <h1>Additional Questions</h1>
      </AdditionalQuestionsHeader>
      <AdditionalQuestionList>
        <p>We need to check on a few important things before we start, so we can tailor your Athelas experience to your needs.</p>
        <hr />
        <AdditionalQuestion>
          <p>Do you currently have an implanted electrical device (e.g. a pacemaker)?</p>

          <label style={{ fontSize: "80%", maxWidth: "400px" }}>
            <input
              type="checkbox"
              onChange={(text) => setHasElectricalImplant(text.target.checked)}
            />
            <span style={{ marginLeft: "8px" }}>
              By checking this box, I confirm that I currently have an implanted electrical device and as a result will be considered ineligible for the Athelas Blood Pressure Cuff and Heart Rate monitor.
            </span>
          </label>
        </AdditionalQuestion>
        
        <AdditionalQuestion>
          <p>Are you currently pregnant? (If applicable).</p>

          <label style={{ fontSize: "80%", maxWidth: "400px" }}>
            <input
              type="checkbox"
              onChange={(text) => setIsPregnant(text.target.checked)}
            />
            <span style={{ marginLeft: "8px" }}>
              By checking this box, I confirm that I am currently pregnant, and as a result, will be considered ineligible for the Athelas Blood Pressure Cuff and Heart Rate monitor.
            </span>
          </label>
        </AdditionalQuestion>
        <div
          style={styles.buttonStyle}
          onClick={ updateAdditionalQuestions }
        >
          I confirm all that I have indicated above is correct, let's continue!
        </div>
 
        <hr />

      </AdditionalQuestionList>
      <Explanation>
        <h4>Why are you asking me this?</h4>
        <p style={{ fontSize: "80%" }}>Our Blood Pressure Cuff and Heart Rate monitor <strong>should not</strong> be used by patients who have implanted pacemaker devices, or by patients who are pregnant, as stated in the 'contraindications' section of the provided instruction manual. You will not be considered eligible for the Blood Pressure Cuff and Heart Rate monitor if you answer in the affirmative to any of the above questions. </p>
      </Explanation>

    </StyledContainer>
  )
}

export default connect(null, mapDispatch)(withRouter(AdditionalQuestions));

const StyledContainer = styled.div`
  margin: 0 auto;
  background-color: white;
  border: 2px solid #F8F8F8;
  box-sizing: border-box;
  box-shadow: 0px 25px 50px rgba(38, 73, 92, 0.15);
  border-radius: 8px;
  width: 30vw;
  min-width: 300px;
  max-width: 500px;
  margin-top: 20px;
`;

const AdditionalQuestionsHeader = styled.div`
  margin-top:20px;
`;

const AdditionalQuestionList = styled.div`
  padding-left: 42px;
  padding-right: 42px;
  padding-bottom: 12px;
  margin-top: 20px;
`

const AdditionalQuestion = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
`

const Explanation = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 42px;
  padding-right: 42px;
`
