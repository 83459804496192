import React, { Component } from "react";
import { ProgressBar } from "../../app/Atoms/ProgressBar/ProgressBar";

import { getToken, rawToken } from "../../app/utils/token";
import { getTotalCareNurseProfile } from "./actions";

export class TotalCareNutritionist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nurse: {},
    };
  }

  nextStep = () => {
    this.props.setMode("first_weight");
  };

  componentDidMount = () => {
    const token = getToken() || null;
    const raw = rawToken() || null;

    const patientId = token.sub;
    getTotalCareNurseProfile(raw, patientId)
      .then((j) => j.json())
      .then((res) => {
        this.setState({ nurse: res });
      });
  };

  render() {
    return (
      <div
        class="form-container mt-5"
        style={{ marginBottom: "48px", paddingBottom: "24px" }}
      >
        <div class="p-4">
          <h2>Your Nutritionist</h2>
          <img
            src={this.state.nurse?.profile_photo}
            style={{
              width: "100px",
              height: "100px",
              objectFit: "cover",
              borderRadius: "200px",
              marginBottom: "16px",
              marginTop: "16px",
            }}
            alt="Nurse profile"
          />
          <p style={{ textAlign: "left" }}>
            Meet {this.state.nurse?.first_name}, your personal, clinically
            trained nurse who will be reviewing your weight data and offering
            guidance to achieve your weight goals.
          </p>

          <p>
            <b>A Note from {this.state.nurse?.first_name}: </b>
          </p>
          <i style={{ fontSize: "90%" }}>
            {this.state.nurse?.bio}
            <br></br>
          </i>

          <br />

          <button class="btn light-btn" onClick={this.nextStep}>
            Get Started
          </button>
        </div>

        <ProgressBar percent={12.5} />
      </div>
    );
  }
}
