import React, { Component } from 'react';
import { trackEvent } from "../../app/utils/logging.ts";

class Internet extends Component {

  componentDidMount() {
    trackEvent(
      "view",
      this.props.patient_id,
      "gem_onboarding_internet",
      this.props.first_name + " " + this.props.last_name
    );
  }

  render() {

    const missing_internet = () => {
      return (
        <div>
          <p> <b> Your device is automatically connecting to the internet - no need to do anything. </b> </p>
          <p> If you have to wait longer than 5 minutes, please reboot your device.</p>
          <p> If this problem persists, please contact support@athelas.com </p>
        </div>
      )
    }

    return (
      <div class="form-container gem-container my-5">
        <div class="pt-4 pb-4">
          <h1> Internet </h1>
        </div>
        {this.props.online
          ? <div class="wifi wifi-clear-animation my-4"> <div class="wifi-outer wifi-outer-clear-animation"> </div></div>
          : <div class="wifi my-4"> <div class="wifi-outer"> </div></div>
        }
        <div class="px-5">
          {this.props.online
            ? <p> Your device is connected to the internet! </p>
            : missing_internet()
          }
        </div>
        {this.props.online &&
          <div class="pt-3 pb-4">
            <button type="submit" class="btn light-btn" onClick={this.props.nextStep}>Next</button>
          </div>
        }
      </div>
    )
  }

}

export default Internet;
