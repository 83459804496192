import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { submitMedication, getSetUpBottles, getLastBottle } from "./API";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import styled from "styled-components";
import Log from "../../app/logging/Log.js";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { createTheme, ThemeProvider } from "@mui/material";
import PillTrackEnterMedicationError from "./PillTrackEnterMedicationError";

function PillTrackEnterMedication(props) {
  const location = useLocation();
  let [checked, setChecked] = useState(location.state.schedules ?? []);
  let [medicationName, setMedicationName] = useState(
    location.state.medicationName ?? ""
  );
  let [nameCollision, setNameCollision] = useState(false);
  let [medNames, setMedNames] = useState([]);
  let [bottleNums, setBottleNums] = useState([]);
  let [invalidOptions, setInvalidOptions] = useState([]);
  let [reminderTimes, setReminderTimes] = useState(
    new Map([
      ["DAILY_AM", moment("08:00", "HH:mm")],
      ["AFTERNOON", moment("13:00", "HH:mm")],
      ["DAILY_PM", moment("20:00", "HH:mm")],
    ])
  );
  const edit = location.state.edit ?? false;
  const timePickerOptions = {
    DAILY_AM: {
      min: moment("03:00", "HH:mm"),
      max: moment("10:00", "HH:mm"),
    },
    AFTERNOON: {
      min: moment("10:00", "HH:mm"),
      max: moment("13:00", "HH:mm"),
    },
    DAILY_PM: {
      min: moment("13:00", "HH:mm"),
      max: moment("24:00", "HH:mm"),
    },
  };

  const instructions =
    "Enter medication name you plan to fill for this bottle:";
  const navigate = useNavigate();
  console.log("Bottle Number", location.state.bottleNumber);

  const scheduleOptions = [
    { value: "DAILY_AM", label: "Every Morning" },
    { value: "AFTERNOON", label: "Every Afternoon" },
    { value: "DAILY_PM", label: "Every Evening" },
  ];
  const AM_PM = ["DAILY_AM", "DAILY_PM"];
  const AM_NOON = ["DAILY_AM", "AFTERNOON"];
  const NOON_EVENING = ["DAILY_PM", "AFTERNOON"];

  useEffect(() => {
    let isMounted = true;
    getSetUpBottles(props.deviceString, props.token)
      .then((resp) => {
        if (isMounted) {
          setMedNames(resp.medication_names);
          setBottleNums(resp.bottle_numbers);

          let newReminders = reminderTimes;
          Object.entries(resp.reminder_times).forEach(([key, value]) => {
            newReminders.set(moment(value, "HH:mm"));
          });
          setReminderTimes(newReminders);
        }
      })
      .catch((error) => console.log(error));
    return () => {
      isMounted = false;
    };
  });

  useEffect(() => {
    const interval = setInterval(() => {
      getLastBottle(props.deviceString, props.token)
        .then((response) => {
          const bottlesOut = response.bottles_out;
          if (bottlesOut.length === 0 && !edit) {
            navigate("/pilltrack/alignment");
          }
          if (bottlesOut.length >= 2) {
            navigate("/pilltrack/bottles_out", {
              state: {
                bottlesOut: bottlesOut,
              },
            });
          }
        })
        .catch((error) => console.log(error));
    }, 250);
    return () => clearInterval(interval);
  });

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleChange = (event) => {
    if (
      (medNames.some(
        (x) => x.toLowerCase() === event.target.value.toLowerCase()
      ) &&
        !location.state.edit) ||
      (location.state.edit &&
        medNames.filter(
          (x) => x.toLowerCase() === event.target.value.toLowerCase()
        ).length >= 1)
    ) {
      setNameCollision(true);
    } else {
      setNameCollision(false);
    }
    setMedicationName(event.target.value);
  };

  const handleInvalidTime = () => {
    let invalidTime = false;
    if (invalidOptions !== undefined) {
      invalidOptions.forEach((element) => {
        if (checked.includes(element)) {
          invalidTime = true;
        }
      });
    }
    return invalidTime;
  };

  const transformSchedule = (arr) => {
    const isAM_PM = AM_PM.every((i) => arr.includes(i));
    const isAM_NOON = AM_NOON.every((i) => arr.includes(i));
    const isNOON_EVENING = NOON_EVENING.every((i) => arr.includes(i));
    if (arr.length === 1) {
      return arr[0];
    }
    if (arr.length === 3) {
      return "TID";
    }
    if (isAM_PM) {
      return "BI_DAILY";
    }
    if (isAM_NOON) {
      return "AM_NOON";
    }
    if (isNOON_EVENING) {
      return "NOON_EVENING";
    }
  };

  const get_text_reminder_times = () => {
    let text_reminder_time = {};

    // copy over times that are applicable in HH:MM
    ["DAILY_AM", "AFTERNOON", "DAILY_PM"].forEach((time) => {
      if (checked.indexOf(time) !== -1) {
        let hhmm = reminderTimes.get(time).format("HH:mm");
        text_reminder_time[time] = hhmm;
      }
    });

    return text_reminder_time;
  };

  const enterMedication = () => {
    submitMedication(
      props.deviceString,
      props.token,
      location.state.bottleNumber,
      medicationName,
      transformSchedule(checked),
      get_text_reminder_times()
    )
      .then((resp) => {
        if (resp === "OK") {
          if (location.state.edit) {
            navigate("/pilltrack/medication_list");
          } else {
            const curVal = JSON.parse(
              window.sessionStorage.getItem("numBottlesSetup")
            );
            console.log("Current number bottles set up", curVal);
            if (!bottleNums.includes(location.state.bottleNumber)) {
              window.sessionStorage.setItem(
                "numBottlesSetup",
                JSON.stringify(parseInt(curVal) + 1)
              );
            }

            navigate("/pilltrack/fill", {
              state: {
                medicationName: medicationName,
                bottleNumber: location.state.bottleNumber,
              },
            });
          }
        }
      })
      .catch((error) => {
        console.log("Failed to submit medication", error);
      });
  };

  const handleTimePick = (key) => (newTime) => {
    setReminderTimes(new Map(reminderTimes.set(key, newTime)));
  };

  const timePickerTheme = createTheme({
    palette: { primary: { main: "#E85B81" } },
  });

  const timePicker = (value) => {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={timePickerTheme}>
          <TimePicker
            toolbarTitle="Text message reminder time"
            disableIgnoringDatePartForTimeValidation={true}
            minTime={timePickerOptions[value.value].min}
            maxTime={timePickerOptions[value.value].max}
            minutesStep={15}
            value={reminderTimes.get(value.value)}
            onChange={handleTimePick(value.value)}
            InputProps={{
              disableUnderline: true,
              sx: {
                padding: "10px",
                border: "2px #E1E1E1BF solid",
                borderRadius: "6px",
                maxHeight: "40px",
                fontSize: "16px",
                fontWeight: 400,
                maxWidth: "150px",
                lineHeight: "normal",
                fontFamily: "Manrope",
                flex: 1,
              },
            }}
            sx={{
              fontFamily: "Manrope",
              border: "0",
              borderRadius: "6px",
            }}
            renderInput={(params) => (
              <TextField variant="standard" {...params} />
            )}
          />
        </ThemeProvider>
      </LocalizationProvider>
    );
  };

  return (
    <Log
      logImpression={true}
      patientID={props.patientID}
      action="view"
      tag="pilltrack_enter_medication"
    >
      <div>
        <TitlePaddingTop>Medication Name</TitlePaddingTop>
        <StyledH5>{instructions}</StyledH5>
        <CenterPadding>
          <TextField
            autoFocus
            id="outlined-medication"
            type="text"
            variant="standard"
            onChange={handleChange}
            placeholder="Medication Name"
            color="error"
            value={medicationName}
            error={nameCollision}
            inputProps={{
              style: { textAlign: "center" },
            }}
            InputProps={{
              disableUnderline: true,
              sx: {
                paddingTop: "1%",
              },
            }}
            sx={{
              backgroundColor: "white",
              border: "2px #E1E1E1 solid",
              height: "10vw",
              fontSize: "20px",
              borderRadius: "6px",
              fontWeight: 400,
              lineHeight: "normal",
              minWidth: "250px",
            }}
          />
        </CenterPadding>
        {nameCollision && (
          <ErrorText>
            Name Collision: You've already set up a medication with this name
          </ErrorText>
        )}
        <Title>Medication Schedule</Title>
        <StyledH5>Please select all that apply:</StyledH5>
        <div>
          <List
            sx={{
              width: "100%",
              mx: "auto",
              maxWidth: 360,
              bgcolor: "background.paper",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            {scheduleOptions.map((value) => {
              const labelId = `${value.label}`;
              return (
                <ListItem
                  key={value.label}
                  style={{ flexWrap: "wrap" }}
                  disablePadding
                >
                  <ListItemButton onClick={handleToggle(value.value)}>
                    <ListItemIcon sx={{ minWidth: "40px" }}>
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(value.value) !== -1}
                        tabIndex={-1}
                        inputProps={{ "aria-labelledby": labelId }}
                        sx={{
                          color: "#E85B81",
                          "&.Mui-checked": {
                            color: "#E85B81",
                          },
                        }}
                      />
                    </ListItemIcon>
                    <StyledListItemText
                      id={labelId}
                      primary={`${value.label}`}
                    />
                  </ListItemButton>
                  <FlexboxBreak />
                  {checked.indexOf(value.value) === -1 ? (
                    <></>
                  ) : (
                    <>
                      <StyledTextReminderDiv>
                        <StyledReminderTimeText>
                          Text me at{" "}
                        </StyledReminderTimeText>
                        {timePicker(value)}
                      </StyledTextReminderDiv>
                      <StyledErrorDiv>
                        <PillTrackEnterMedicationError
                          timePickerOptions={timePickerOptions}
                          value={value.value}
                          reminderTimes={reminderTimes}
                          checked={checked}
                          setInvalidOptions={setInvalidOptions}
                          invalidOptions={invalidOptions}
                        ></PillTrackEnterMedicationError>
                      </StyledErrorDiv>
                    </>
                  )}
                </ListItem>
              );
            })}
          </List>
        </div>
        <Center>
          <StyledButton
            variant="primary"
            size="lg"
            onClick={enterMedication}
            disabled={
              !Boolean(medicationName) ||
              checked.length === 0 ||
              nameCollision ||
              handleInvalidTime()
            }
          >
            <StyledH4>Confirm</StyledH4>
          </StyledButton>
        </Center>
      </div>
    </Log>
  );
}

const Center = styled.div`
  text-align: center;
`;

const CenterPadding = styled.div`
  text-align: center;
  padding-bottom: 20px;
  padding-top: 20px;
`;

const StyledH4 = styled.h4`
  font-family: "Manrope", sans-serif;
  text-align: center;
  padding-top: 5px;
`;

const Title = styled.h2`
  color: #e85b81;
  font-family: "Manrope", sans-serif;
  text-align: center;
  font-weight: bold;
  padding-left: 48px;
  padding-right: 48px;
`;

const TitlePaddingTop = styled.h2`
  color: #e85b81;
  font-family: "Manrope", sans-serif;
  text-align: center;
  font-weight: bold;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 60px;
`;

const StyledButton = styled(Button)`
  background: #e85b81;
  :disabled {
    background: #d1d1d1;
    border-color: #d1d1d1;
  }
  :focus {
    background: #e85b81;
    border-color: #e85b81;
  }
  :active {
    background: #e85b81;
    border-color: #e85b81;
  }
  :hover {
    background: #e85b81;
    border-color: #e85b81;
  }
  border-radius: 10px;
  border-color: #e85b81;
  width: 260px;
  text-align: center;
`;

const StyledH5 = styled.h5`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #666666;
  padding-top: 10px;
  padding-left: 48px;
  padding-right: 48px;
`;

const ErrorText = styled.h6`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #ff0000;
  padding-left: 48px;
  padding-right: 48px;
`;

const StyledListItemText = styled(ListItemText)`
  color: #666666;
  font-family: "Manrope", sans-serif;
`;

const StyledTextReminderDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledErrorDiv = styled.div`
  display: flex;
  align-items: center;
  padding-top: 15px;
`;

const StyledReminderTimeText = styled(ListItemText)`
  flex: 1;
  margin-left: 56px;
  margin-right: 16px;
  color: #8a8a8a;
  font-family: "Manrope", sans-serif;
`;

const FlexboxBreak = styled.div`
  height: 0;
  flex-basis: 100%;
`;

export default PillTrackEnterMedication;
