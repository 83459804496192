import { useEffect, useState } from "react";

import PillTrackWelcome from "./PillTrackWelcome";
import PillTrackAlignment from "./PillTrackAlignment";
import jwt from "jsonwebtoken";
import { PUBLIC_KEY } from "../../app/constants";

import { Routes, Route, useSearchParams } from "react-router-dom";
import PillTrackEnterMedication from "./PillTrackEnterMedication";
import PillTrackFillMedication from "./PillTrackFillMedication";
import PillTrackLabelMedication from "./PillTrackLabelMedication";
import PillTrackReturnBottle from "./PillTrackReturnBottle";
import PillTrackSuccess from "./PillTrackSuccess";
import PillTrackClosed from "./PillTrackClosed";
import PillTrackOpened from "./PillTrackOpened";
import PillTrackComplete from "./PillTrackComplete";
import PillTrackAnySlot from "./PillTrackAnySlot";
import PillTrackConnect from "./PillTrackConnect";
import PillTrackDownloadApp from "./PillTrackDownloadApp";
import PillTrackReset from "./PillTrackReset";
import PillTrackResetReturn from "./PillTrackResetReturn";
import PillTrackMoreThanOneBottleOut from "./PillTrackMoreThanOneBottleOut";
import PillTrackSetUpMedications from "./PillTrackSetUpMedications";

function PillTrackOnboarding() {
  let [searchParams] = useSearchParams();
  let [token, setToken] = useState("");
  let [deviceString, setDeviceString] = useState("");
  let [patientID, setPatientID] = useState(0);
  let [firstName, setFirstName] = useState("");
  let [lastName, setLastName] = useState("");

  useEffect(() => {
    document.body.className = "gemBody";
    window.sessionStorage.setItem("numBottlesSetup", JSON.stringify(0));
    try {
      const token = searchParams.get("token");
      const decodedToken = jwt.verify(token, PUBLIC_KEY, {
        algorithms: ["RS256"],
      });
      setToken(token);
      setDeviceString(decodedToken.device_string);
      setPatientID(decodedToken.sub);
      setFirstName(decodedToken.first_name);
      setLastName(decodedToken.last_name);
    } catch (e) {
      console.log("No valid token");
    }
  }, [searchParams]);

  console.log("storage", window.sessionStorage.getItem("numBottlesSetup"));
  console.log(deviceString, firstName, lastName, patientID);

  window.zESettings = {
    webWidget: {
      chat: {
        suppress: true,
      },
      contactForm: {
        suppress: true,
      },
      helpCenter: {
        suppress: true,
      },
      talk: {
        suppress: true,
      },
      answerBot: {
        suppress: true,
      },
    },
  };

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={<PillTrackWelcome patientID={patientID} />}
      />
      <Route
        path="alignment"
        element={
          <PillTrackAlignment
            deviceString={deviceString}
            patientID={patientID}
            token={token}
          />
        }
      />
      <Route
        path="bottles_out"
        element={
          <PillTrackMoreThanOneBottleOut
            deviceString={deviceString}
            patientID={patientID}
            token={token}
          />
        }
      />
      <Route
        path="connect"
        element={
          <PillTrackConnect
            deviceString={deviceString}
            patientID={patientID}
            token={token}
          />
        }
      />
      <Route
        path="medication"
        element={
          <PillTrackEnterMedication
            deviceString={deviceString}
            token={token}
            patientID={patientID}
          />
        }
      />
      <Route
        path="reset"
        element={
          <PillTrackReset
            deviceString={deviceString}
            token={token}
            patientID={patientID}
          />
        }
      />
      <Route
        path="reset_return"
        element={
          <PillTrackResetReturn
            deviceString={deviceString}
            token={token}
            patientID={patientID}
          />
        }
      />
      <Route
        path="fill"
        element={<PillTrackFillMedication patientID={patientID} />}
      />
      <Route
        path="label"
        element={<PillTrackLabelMedication patientID={patientID} />}
      />
      <Route
        path="return"
        element={
          <PillTrackReturnBottle
            deviceString={deviceString}
            patientID={patientID}
            token={token}
          />
        }
      />
      <Route
        path="success"
        element={
          <PillTrackSuccess
            deviceString={deviceString}
            patientID={patientID}
            token={token}
          />
        }
      />
      <Route
        path="medication_list"
        element={
          <PillTrackSetUpMedications
            deviceString={deviceString}
            patientID={patientID}
            token={token}
          />
        }
      />
      <Route
        path="closed"
        element={<PillTrackClosed patientID={patientID} />}
      />
      <Route path="open" element={<PillTrackOpened patientID={patientID} />} />
      <Route
        path="slots"
        element={<PillTrackAnySlot patientID={patientID} />}
      />
      <Route
        path="complete"
        element={<PillTrackComplete patientID={patientID} />}
      />
      <Route
        path="download"
        element={<PillTrackDownloadApp patientID={patientID} />}
      />
    </Routes>
  );
}
export default PillTrackOnboarding;
