import React, { Component } from "react";
import { getToken, rawToken } from "../../app/utils/token";
import LoadingGif from "./assets/loading-scale.gif";

import { getTotalCareLastWeight } from "./actions";
import { ProgressBar } from "../../app/Atoms/ProgressBar/ProgressBar";

export class TotalCareFirstWeight extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startWeight: -1,
      interval: null,
      fetchWeightRunCount: 0,
    };
  }
  nextStep = () => {
    this.props.setMode("goal_height");
  };

  componentDidMount() {
    const interval = setInterval(this.fetchWeight, 1000);
    this.setState({ interval: interval });
  }

  fetchWeight = () => {
    // make network request here for final weight for given patient
    this.setState({
      startWeight: -1,
      fetchWeightRunCount: this.state.fetchWeightRunCount + 1,
    });
    const raw = rawToken();
    const token = getToken();
    const patientId = token.sub;

    getTotalCareLastWeight(raw, patientId)
      .then((j) => j.json())
      .then((res) => {
        const weight = res["weight"];

        this.props.setStartWeight(weight);
        this.setState({ startWeight: weight });

        if (weight !== -1 && this.state.interval) {
          clearInterval(this.state.interval);
        }
      });
  };

  render() {
    const weightString =
      this.state.startWeight !== -1 ? this.state.startWeight + " lbs" : "";
    return (
      <div
        class="form-container mt-5"
        style={{ marginBottom: "48px", paddingBottom: "24px" }}
      >
        <div class="p-4">
          <h2>Get Started</h2>
          <p style={{ textAlign: "left" }}>
            To kick things off, get your scale and set it on the floor.
          </p>
          <hr></hr>
          <p style={{ textAlign: "left" }}>
            Step 1. After putting your scale on a flat surface, step on the
            scale for 10 seconds until the number on the screen stops changing.{" "}
            <b>Note: Ensure that the scale is on a flat, hard surface.</b>
          </p>

          <hr></hr>
          <p style={{ textAlign: "left" }}>
            Step 2. Step off the scale and you'll see a spinning animation on
            the scale's screen.
          </p>
          <hr></hr>
          <p style={{ textAlign: "left" }}>
            Step 3. Your weight should appear automatically below.{" "}
            <b>
              <i>Note: This step may take up to 2 minutes</i>
            </b>
          </p>

          <p
            style={{
              fontSize: "200%",
              border: "solid 6px #888",
              fontWeight: "bold",
              width: 200,
              margin: "0% auto",
              borderRadius: "8px",
              backgroundColor: "#BBB",
              height: 200,
            }}
          >
            <div
              style={{
                border: "solid 3px black",
                height: "96%",
                width: "96%",
                backgroundColor: "#111",
                borderRadius: 2,
                margin: "2%",
              }}
            >
              {weightString !== "" ? (
                <p style={{ marginTop: 16, color: "#F54D4D" }}>
                  {weightString}
                </p>
              ) : (
                <>
                  <img
                    src={LoadingGif}
                    alt="loading gif"
                    style={{
                      width: 150,
                      height: 70,
                      objectFit: "contain",
                    }}
                  />
                </>
              )}
            </div>
          </p>
          <a
            class="btn"
            href="mailto:support@getathelas.com"
            style={{
              display: this.state.fetchWeightRunCount > 90 ? "" : "none",
            }}
          >
            Not Seeing Your Results? Contact our support team at
            support@athelas.com
          </a>
          <hr></hr>

          <p style={{ textAlign: "left" }}>
            If the weight above is correct, click continue. If not, step on the
            scale again and{" "}
            <u onClick={this.fetchWeight}>click here to refresh.</u>
          </p>

          <button
            class="btn light-btn"
            onClick={this.nextStep}
            disabled={this.state.startWeight === -1}
          >
            Continue
          </button>
        </div>

        <ProgressBar percent={this.state.startWeight === -1 ? 12.5 : 30} />
      </div>
    );
  }
}
