import React from "react";
import { Image } from 'react-shimmer'
import useWindowDimensions from "./useWindowDimensions";

function ShimmerImage(props) {
  const {imgHeight, imgWidth, src, widthPercentage} = props;
  const { width } = useWindowDimensions();

  const widthInPx = (widthPercentage * width);
  const heightInPx = (widthInPx * imgHeight / imgWidth);

  const fallbackComponent = (
    <div style={{ width: widthInPx, height: heightInPx}} />
  );

  return ( 
      <Image
      src={src}
      style={{ marginLeft: "auto", marginRight: "auto" }}
      NativeImgProps={{ width: widthInPx }}
      fallback={fallbackComponent}
      fadeIn={true}
      />
  );
}

export default ShimmerImage;
