import React from "react";

import Success1 from "./pilltrack_assets/1check.webp";
import Success2 from "./pilltrack_assets/2check.webp";
import Success3 from "./pilltrack_assets/3check.webp";
import Success4 from "./pilltrack_assets/4check.webp";
import Success5 from "./pilltrack_assets/5check.webp";
import Success6 from "./pilltrack_assets/6check.webp";
import Success7 from "./pilltrack_assets/7check.webp";
import Success8 from "./pilltrack_assets/8check.webp";
import Log from "../../app/logging/Log.js";

import { useLocation, useNavigate } from "react-router-dom";
import { markAsOnboarded } from "./API";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import ShimmerImage from "../../app/utils/ShimmerImage";

function PillTrackSuccess(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const buttonText =
    JSON.parse(window.sessionStorage.getItem("numBottlesSetup")) < 8
      ? "Set Up Another Medication"
      : "Edit/Change a Medication";

  const instructions = `You’ve successfully set up 
  ${location.state.medicationName}`;

  const nextStep = () => {
    navigate("/pilltrack/alignment");
  };

  const finish = () => {
    markAsOnboarded(props.patientID, props.deviceString, props.token)
      .then((resp) => {
        if (resp === "OK") {
          navigate("/pilltrack/medication_list");
        }
      })
      .catch((error) => {
        console.log("Failed to mark as onboarded", error);
      });
  };

  const successAsset = (numBottles) => {
    switch (numBottles) {
      case 0:
        return Success1;
      case 1:
        return Success2;
      case 2:
        return Success3;
      case 3:
        return Success4;
      case 4:
        return Success5;
      case 5:
        return Success6;
      case 6:
        return Success7;
      case 7:
        return Success8;
      default:
        return Success1;
    }
  };

  return (
    <Log
      logImpression={true}
      patientID={props.patientID}
      action="view"
      tag="pilltrack_success"
    >
      <TopMargin>
        <SuccessImage>
          <ShimmerImage
            imgHeight={522}
            imgWidth={684}
            src={successAsset(location.state.slotNumber)}
            widthPercentage={0.5}
          />
        </SuccessImage>
        <Title>Success</Title>
        <StyledH5>{instructions}</StyledH5>
        <Center>
          <StyledButton variant="primary" size="lg" onClick={nextStep}>
            <StyledH4>{buttonText}</StyledH4>
          </StyledButton>
        </Center>
        <Center>
          <StyledWhiteButton variant="primary" size="lg" onClick={finish}>
            <StyledPinkH4>I'm All Done!</StyledPinkH4>
          </StyledWhiteButton>
        </Center>
      </TopMargin>
    </Log>
  );
}

const StyledButton = styled(Button)`
  background: #e85b81;
  :disabled {
    background: #d1d1d1;
    border-color: #d1d1d1;
  }
  :focus {
    background: #e85b81;
    border-color: #e85b81;
  }
  :active {
    background: #e85b81;
    border-color: #e85b81;
  }
  :hover {
    background: #e85b81;
    border-color: #e85b81;
  }
  border-radius: 10px;
  border-color: #e85b81;
  width: 260px;
  text-align: center;
`;

const StyledWhiteButton = styled(Button)`
  background: #ffffff;
  :disabled {
    background: #d1d1d1;
    border-color: #d1d1d1;
  }
  :focus {
    background: #ffffff;
    border-color: #e85b81;
  }
  :active {
    background: #ffffff;
    border-color: #e85b81;
  }
  :hover {
    background: #ffffff;
    border-color: #e85b81;
  }
  border-radius: 10px;
  border-color: #e85b81;
  width: 260px;
  text-align: center;
  margin-top: 24px;
`;

const Center = styled.div`
  text-align: center;
`;

const StyledH4 = styled.h4`
  font-family: "Manrope", sans-serif;
  text-align: center;
`;

const StyledPinkH4 = styled.h4`
  font-family: "Manrope", sans-serif;
  text-align: center;
  padding-top: 5px;
  color: #e85b81;
`;

const StyledH5 = styled.h5`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #666666;
  padding-top: 10px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 60px;
`;

const TopMargin = styled.div`
  margin-top: 60px;
`;

const Title = styled.h2`
  color: #e85b81;
  font-family: "Manrope", sans-serif;
  text-align: center;
  font-weight: bold;
  padding-left: 48px;
  padding-right: 48px;
`;

const SuccessImage = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
  max-height: 75%;
`;
export default PillTrackSuccess;
