//eslint-disable-next-line no-unused-vars
import { object, string, InferType } from "yup";
import moment from "moment";

export const patientLoginSchema = object({
  first_name: string().required("Please enter the patient's first name"),
  last_name: string().required("Please enter the patient's last name"),
  date_of_birth: string()
    .required("Please enter your date of birth")
    .test("date_of_birth", "Please enter a valid date of birth", (value) => {
      return moment().diff(moment(value), "years") >= 0;
    }).nullable(), 
});


export type PatientLogin = InferType<typeof patientLoginSchema>;