import React from 'react';
import Modal from 'react-modal';
import './GgpPaymentPlanOptions.css'; // Import the updated CSS file

const ConfirmGgpPlanModal = ({ isOpen, onConfirm, onCancel, planTitle }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      className="ggp-payment-options-modal-content"
      overlayClassName="ggp-payment-options-modal-overlay"
      ariaHideApp={false}
    >
      <div className="ggp-payment-options-modal-header">
        <h2 className="ggp-payment-options-modal-title">Confirm Your Selection</h2>
        <button 
          className="ggp-payment-options-modal-close-button" 
          onClick={onCancel}
        >
          &times;
        </button>
      </div>
      <div className="ggp-payment-options-modal-body">
        <p>Are you sure you want to select the <strong>{planTitle}</strong> plan?</p>
      </div>
      <div className="ggp-payment-options-modal-footer-select">
        <button onClick={onConfirm} className="ggp-payment-options-modal-close-button">
          Yes, Select Plan
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmGgpPlanModal;
