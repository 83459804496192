import React, { useEffect } from "react";

import Log from "../../app/logging/Log.js";
import { getLastBottle } from "./API";

import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import styled from "styled-components";

function PillTrackReset(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const instructions = `Looks like you've already set this bottle up with ${location.state.medicationName}. Would you like to reset it? `;

  const onReset = () => {
    navigate("/pilltrack/medication", {
      state: {
        bottleNumber: location.state.bottleNumber,
        deviceString: props.deviceString,
        token: props.token,
        slotNumber: location.state.slotNumber,
        forEdit: true,
        edit: false,
      },
    });
  };

  const onKeep = () => {
    navigate("/pilltrack/reset_return", {
      state: {
        bottleNumber: location.state.bottleNumber,
      },
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getLastBottle(props.deviceString, props.token)
        .then((response) => {
          const bottlesOut = response.bottles_out;
          if (bottlesOut.length === 0) {
            navigate("/pilltrack/alignment");
          }
          if (bottlesOut.length >= 2) {
            navigate("/pilltrack/bottles_out", {
              state: {
                bottlesOut: bottlesOut,
              },
            });
          }
        })
        .catch((error) => console.log(error));
    }, 250);

    return () => clearInterval(interval);
  });

  return (
    <Log
      logImpression={true}
      patientID={props.patientID}
      action="view"
      tag="pilltrack_reset"
    >
      <TopMargin>
        <Title>Bottle Already Set Up</Title>
        <StyledH5>{instructions}</StyledH5>
        <Center>
          <StyledButton variant="primary" size="lg" onClick={onReset}>
            <StyledH4>Yes, Reset Bottle</StyledH4>
          </StyledButton>
        </Center>
        <Center>
          <StyledWhiteButton variant="primary" size="lg" onClick={onKeep}>
            <StyledPinkH4>No, Keep Bottle</StyledPinkH4>
          </StyledWhiteButton>
        </Center>
      </TopMargin>
    </Log>
  );
}

const StyledButton = styled(Button)`
  background: #e85b81;
  :disabled {
    background: #d1d1d1;
    border-color: #d1d1d1;
  }
  :focus {
    background: #e85b81;
    border-color: #e85b81;
  }
  :active {
    background: #e85b81;
    border-color: #e85b81;
  }
  :hover {
    background: #e85b81;
    border-color: #e85b81;
  }
  border-radius: 10px;
  border-color: #e85b81;
  width: 260px;
  text-align: center;
`;

const StyledWhiteButton = styled(Button)`
  background: #ffffff;
  :disabled {
    background: #d1d1d1;
    border-color: #d1d1d1;
  }
  :focus {
    background: #ffffff;
    border-color: #e85b81;
  }
  :active {
    background: #ffffff;
    border-color: #e85b81;
  }
  :hover {
    background: #ffffff;
    border-color: #e85b81;
  }
  border-radius: 10px;
  border-color: #e85b81;
  width: 260px;
  text-align: center;
  margin-top: 24px;
`;

const Center = styled.div`
  text-align: center;
`;

const StyledH4 = styled.h4`
  font-family: "Manrope", sans-serif;
  text-align: center;
  padding-top: 5px;
`;

const StyledPinkH4 = styled.h4`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #e85b81;
  padding-top: 5px;
`;

const StyledH5 = styled.h5`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #666666;
  padding-top: 10px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 60px;
`;

const TopMargin = styled.div`
  margin-top: 50vw;
`;

const Title = styled.h2`
  color: #e85b81;
  font-family: "Manrope", sans-serif;
  text-align: center;
  font-weight: bold;
  padding-left: 48px;
  padding-right: 48px;
`;

export default PillTrackReset;
