import React, { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Gem1 from "./assets/AthelasPillTrack.jpg";
import axios from "axios";
import jwt from "jsonwebtoken";
import { saveUrlToken } from "../login/LoginSlice";
import { PUBLIC_KEY } from "../../app/constants";
import { API_SERVER_URL } from "../../app/constants";
import { useLocation } from "react-router-dom";
import Modal from "react-modal";
import { trackEvent } from "../../app/utils/logging.ts";
import styled from "styled-components";
import { useProdVar } from "../../app/utils/prodVars.ts";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function PillTrackEnrollment() {
  const [numMeds, setNumMeds] = useState(0);
  const [token, setToken] = useState(null);
  const [patientID, setPatientID] = useState(0);
  const [name, setName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [eligible, setEligible] = useState(false);
  const cohortNumber = useProdVar("pilltrack_cohort")

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  useEffect(() => {
    document.body.className = "gemBody";
    const token = query.get("token");
    if (token) {
      saveUrlToken(token);
    }
    const decodedToken = jwt.verify(token, PUBLIC_KEY, {
      algorithms: ["RS256"],
    });
    setToken(token);
    setName(decodedToken.first_name);
    setPatientID(decodedToken.sub);
  }, [query]);

  const onChange = (event) => {
    setNumMeds(parseInt(event.target.value));
  };

  const onEnroll = () => {
    trackEvent("click", patientID, "pilltrack_enrollment", name);
    const headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    return axios
      .post(
        `${API_SERVER_URL}/patients/${patientID}/enroll_in_pilltrack`,
        {
          cohort: cohortNumber,
          num_medications: numMeds,
        },
        {
          headers,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setShowModal(true);
          if (numMeds > 8 || numMeds < 2) {
            // modal saying we don't support yet
            setEligible(false);
          } else {
            // modal for success
            setEligible(true);
          }
        }
      });
  };

  const successModal = (success) => {
    const header = `Thank you ${name}!`;
    const body = success
      ? "We'll let you know once your Pilltrack is ready to ship."
      : "Our current device only supports up to 8 medications. You've been added to the waitlist for Pilltrack!";
    return (
      <div>
        <Modal
          isOpen={true}
          onAfterOpen={() => {}}
          onRequestClose={() => {
            setShowModal(false);
          }}
          style={modalStyles}
          contentLabel="Confirmation Modal"
        >
          <h5 className="gemOnboardModalSubHeader">{header}</h5>
          <p className="gemOnboardFont">{body}</p>
        </Modal>
      </div>
    );
  };

  const signUpText = `Hey ${name}! Claim Your PillTrack`;
  const pilltrackBody =
    "PillTrack gives you easy reminders for when to take your medications -- ensuring you never forget a dose. You can view all of your data on your PillTrack app, and everything is covered by insurance.";

  return (
    <div>
      <StyledH4 className="gemOnboardFontHeader">{signUpText}</StyledH4>
      <StyledP>{pilltrackBody}</StyledP>
      <PillTrackImage src={Gem1} alt="PillTrack" />
      <div>
        <StyledH5>
          Enter the <strong>number </strong>of medications you are
          currently taking:
        </StyledH5>
        <div class="input-group mb-3 px-4 py-2">
          <span class="input-group-text"># Medications</span>
          <input
            type="number"
            class="form-control"
            aria-label="Number of medications"
            onChange={onChange}
          />
        </div>
        <Center>
          <StyledButton
            disabled={!numMeds}
            variant="primary"
            size="lg"
            onClick={onEnroll}
          >
            <ButtonText>Enroll Now</ButtonText>
          </StyledButton>
        </Center>
      </div>
      <div>{showModal && successModal(eligible)}</div>
    </div>
  );
}

const StyledButton = styled(Button)`
  background: #e85b81;
  :disabled {
    background: #d1d1d1;
    border-color: #d1d1d1;
  }
  :focus {
    background: #e85b81;
    border-color: #e85b81;
  }
  :active {
    background: #e85b81;
    border-color: #e85b81;
  }
  :hover {
    background: #e85b81;
    border-color: #e85b81;
  }
  border-radius: 10px;
  border-color: #e85b81;
  width: 260px;
  text-align: center;
`;

const PillTrackImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
  max-height: 90%;
`;

const Center = styled.div`
  text-align: center;
`;

const StyledH5 = styled.h5`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #12334b;
  padding-left: 40px;
  padding-right: 40px;
`;

const ButtonText = styled.h5`
  font-family: "Manrope", sans-serif;
  text-align: center;
`;

const StyledH4 = styled.h4`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #12334b;
`;

const StyledP = styled.p`
  color: #12334b;
  font-family: "Manrope", sans-serif;
  text-align: center;
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
`;

export default PillTrackEnrollment;
