import "./Gem.css";
import React, { Component } from "react";
import gem_photo from "./gem.png";
import plug from "./plug.png";
import pills from "./pills.png";
import pen from "./pen.png";
import { trackEvent } from "../../app/utils/logging.ts";

class GemWelcome extends Component {
  constructor(ownProps) {
    super(ownProps);
    this.state = {
      first_name: ownProps.first_name,
      last_name: ownProps.last_name,
      patient_id: ownProps.patient_id,
    };
  }

  componentDidMount() {
    trackEvent(
      "view",
      this.state.patient_id,
      "gem_onboarding_welcome",
      this.state.first_name + " " + this.state.last_name
    );
  }

  render() {
    const { first_name } = this.state;

    return (
      <div class="form-container gem-container my-5">
        <div class="mt-2 p-4">
          <h1> Welcome </h1>
        </div>
        <div class="px-5 welcome-message">
          <p>
            {" "}
            Hi {first_name}! Let's set up your Athelas Gem - this should take 5
            minutes.
          </p>
        </div>
        <div class="pb-4">
          <img width="100px" height="149px" alt="Athelas Gem" src={gem_photo} />
        </div>
        <div class="px-5 welcome-message">
          <p> To begin, please do the following: </p>
          <img width="45px" height="45px" alt="plug" src={plug} />
          <p class="mt-2">Plug in your device. </p>
          <hr />
          <img width="45px" height="45px" alt="pills" src={pills} />
          <p class="mt-3">
            Gather between 1 and 4 medications you would like to use with the
            Gem.
          </p>
          <hr />
          <img width="45px" height="45px" alt="pen" src={pen} />
          <p class="mt-2">
            Take the pen from the Athelas Gem box and keep it nearby.
          </p>
        </div>
        <div class="pb-5 pt-4">
          <button
            onClick={this.props.nextStep}
            type="submit"
            class="btn light-btn"
          >
            I'm ready to go!
          </button>
        </div>
      </div>
    );
  }
}

export default GemWelcome;
