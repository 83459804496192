import { API_SERVER_URL } from "../../app/constants";

export function submitTotalCareOnboarding(
  token,
  patientId,
  currentWeight,
  goalWeight,
  height,
  dietaryInfo,
  exerciseInfo,
  allergies
) {
  return fetch(`${API_SERVER_URL}/cardiac_care/`, {
    method: "post",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      patient_id: patientId,
      start_weight: currentWeight,
      goal_weight: goalWeight,
      height: height,
      dietary_info: dietaryInfo,
      exercise_info: exerciseInfo,
      allergies: allergies,
    }),
  });
}

export function getTotalCareOnboarding(token, patientId) {
  return fetch(`${API_SERVER_URL}/cardiac_care/${patientId}`, {
    method: "get",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  });
}

export function getTotalCareNurseProfile(token, patientId) {
  return fetch(`${API_SERVER_URL}/cardiac_care/nurse_profile/${patientId}`, {
    method: "get",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  });
}

export function getTotalCareReport(token, patientId) {
  return fetch(`${API_SERVER_URL}/cardiac_care/report/${patientId}`, {
    method: "get",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  });
}

export function getTotalCareLastWeight(token, patientId) {
  return fetch(`${API_SERVER_URL}/cardiac_care/get_last_weight/${patientId}`, {
    method: "get",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  });
}
