import React from "react";

import SIMConnectedImage from "../PartnershipEmbed/assets/simConnected.svg";
import BetterPatientOutcomesImage from "../PartnershipEmbed/assets/betterPatient.svg";
import NursesImage from "../PartnershipEmbed/assets/nurses.svg";
import PreventativeImage from "../PartnershipEmbed/assets/preventative.svg";
import RemindersImage from "../PartnershipEmbed/assets/reminders.svg";
import VisibilityImage from "../PartnershipEmbed/assets/visibility.svg";
import RPMImage from "../PartnershipEmbed/assets/rpm_image.png";
import PillTrackImage from "../PartnershipEmbed/assets/pilltrack.png";

import { useMediaQuery } from "react-responsive";

type PropsType = {};

const styles: any = {
  container: {
    backgroundColor: "white",
    padding: 24,
    paddingTop: 48,
    paddingBottom: 48,
    color: "black",
    position: "relative",
  },
  detail: {
    textTransform: "uppercase",
    fontSize: "80%",
    fontWeight: "bold",
    color: "#FF6779",
    marginBottom: 8,
  },
  innerContainer: {
    maxWidth: 1000,
    margin: "0% auto",
    flexDirection: "row",
    display: "flex",
  },
  innerContainerMobile: {
    maxWidth: 1000,
    margin: "0% auto",
  },
  deviceName: {
    fontWeight: "bold",
    fontSize: "160%",
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 16,
  },
  icons: { width: 40, height: 40, objectFit: "contain" },
  proPoints: { marginTop: -4, marginLeft: 16, maxWidth: 350 },
  shadesTopRight: {
    position: "absolute",
    width: 150,
    height: 150,
    top: "0px",
    right: "0px",
  },
  shadesBottomLeft: {
    position: "absolute",
    width: 150,
    height: 150,
    bottom: "0px",
    left: "0px",
  },
};

export const RPMDevicesSection = (props: PropsType) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  return (
    <div style={styles.container}>
      <h3 style={{ textAlign: "center", marginTop: 12, marginBottom: 72 }}>
        <b>Get to Know Athelas Remote Monitoring Devices</b>
      </h3>

      <div
        style={
          isTabletOrMobile ? styles.innerContainerMobile : styles.innerContainer
        }
      >
        <div style={{ width: isTabletOrMobile ? "100%" : "40%" }}>
          <p style={styles.detail}>
            Blood Pressure Cuff & Weight Scale & Glucometer
          </p>
          <h2 style={styles.deviceName}>
            SIM-Connected Blood Pressure Monitor & Weight Scale
          </h2>

          <p style={{ marginTop: 16 }}>
            Athelas will ship a SIM-connected BP monitor and weight scale to
            your home. All results are reviewed by nurses and made available to
            your doctor to inform you of treatment.
          </p>
          <div style={{ marginTop: 24 }}>
            <div style={styles.flexBox}>
              <img
                src={SIMConnectedImage}
                alt="SIM Connected Devices"
                style={styles.icons}
              />
              <p style={styles.proPoints}>
                SIM-Connected: Easy set-up, no configuration required.
              </p>
            </div>

            <div style={styles.flexBox}>
              <img
                src={NursesImage}
                alt="SIM Connected Devices"
                style={styles.icons}
              />
              <p style={styles.proPoints}>
                Monitored by Nurses: Each measurement is reviewed by a nurse and
                escalated if the result is concerning.{" "}
              </p>
            </div>

            <div style={styles.flexBox}>
              <img
                src={PreventativeImage}
                alt="SIM Connected Devices"
                style={styles.icons}
              />
              <p style={styles.proPoints}>
                Preventative: Catches issues before they escalate.
              </p>
            </div>
          </div>
        </div>

        <div
          style={{
            width: isTabletOrMobile ? "100%" : "50%",
            marginLeft: isTabletOrMobile ? 0 : "10%",
          }}
        >
          <img
            src={RPMImage}
            alt="Blood Pressure cuff"
            style={{ width: "100%", marginTop: isTabletOrMobile ? 16 : 60 }}
          />
        </div>
      </div>

      <div
        style={
          isTabletOrMobile ? styles.innerContainerMobile : styles.innerContainer
        }
      >
        <div
          style={{
            width: isTabletOrMobile ? "100%" : "50%",
            marginRight: isTabletOrMobile ? 0 : "10%",
          }}
        >
          <img
            src={PillTrackImage}
            alt="Blood Pressure cuff"
            style={{ width: "100%", marginTop: isTabletOrMobile ? 16 : 100 }}
          />
        </div>

        <div style={{ width: isTabletOrMobile ? "100%" : "40%" }}>
          <p style={styles.detail}>Athelas PillTrack</p>
          <h2 style={styles.deviceName}>Keep on Track with Your Medications</h2>

          <p style={{ marginTop: 16 }}>
            Athelas PillTrack is deployed into your home to provide easy
            reminders for when to take your medications.
          </p>
          <div style={{ marginTop: 24 }}>
            <div style={styles.flexBox}>
              <img
                src={BetterPatientOutcomesImage}
                alt="SIM Connected Devices"
                style={styles.icons}
              />
              <p style={styles.proPoints}>
                Better Patient Outcomes - Non-adherence to chronic medications
                accounts for 50% of treatment failures and 125,000 deaths in the
                US annually. *{" "}
              </p>
            </div>

            <div style={styles.flexBox}>
              <img
                src={RemindersImage}
                alt="SIM Connected Devices"
                style={styles.icons}
              />
              <p style={styles.proPoints}>
                Innovative technology - Athelas uses modern technology to give you the best healthcare
                experience while giving your doctor the visibility they need.
              </p>
            </div>

            <div style={styles.flexBox}>
              <img
                src={VisibilityImage}
                alt="SIM Connected Devices"
                style={styles.icons}
              />
              <p style={styles.proPoints}>
                Visibility to Drive Interventions - All adherence data is
                tracked by nurses and surfaced to your doctor.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
