import React from "react";

import AthelasLogo from "../assets/AthelasLogo.png";
import AthelasDevicesImage from "../assets/AthelasDevices.png";
import SuccessImage from "../assets/success_check.png";

type PropsType = {
  showDeviceImages?: boolean;
  showSiteLogos?: boolean;
  showCornerImages?: boolean;
  site_logo?: null | string;
  site_name?: null | string;
  nextStep?: () => void;
};

const styles: any = {
  innerContainer: {
    maxWidth: 1000,
    width: "100%",
    margin: "0% auto",
  },
  logoContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    margin: "0% auto",
    marginBottom: 12,
  },
  logo: {
    maxWidth: "140px",
    maxHeight: "80px",
    margin: 12,
    objectFit: "contain",
  },
  flexContainer: {
    flexDirection: "row",
    display: "flex",
    maxWidth: 285,
    margin: "0% auto",
    marginBottom: 16,
    justifyContent: "left",
  },
  mobileFlexContainer: {
    flexDirection: "row",
    display: "flex",
    width: "100%",
    margin: "0% auto",
    marginBottom: 16,
    justifyContent: "left",
  },
  checkmark: {
    width: 36,
    height: 36,
    marginRight: 16,
  },
  explainer: {
    marginTop: 8,
  },
  buttonStyle: {
    width: "100%",
    maxWidth: "240px",
    margin: "0% auto",
    marginBottom: 24,
    fontStyle: "bold",
    padding: 8,
    borderRadius: 4,
    backgroundColor: "#FF6779",
    marginTop: 24,
    color: "white",
    cursor: "pointer",
  },
  checkLogo: {
    maxWidth: "140px",
    maxHeight: "80px",
    margin: 24,
    objectFit: "contain",
  },
};

export const CompletionScreen = (props: PropsType) => {
  const basicInformation = JSON.parse(
    localStorage.getItem("BASIC_INFORMATION") || "{}"
  );

  return (
    <div>
      <div style={styles.innerContainer}>
        {props.showSiteLogos && (
          <div style={styles.logoContainer}>
            <img src={AthelasLogo} style={styles.logo} alt="Athelas logo" />
            {props.site_logo && (
              <img src={props.site_logo} style={styles.logo} alt="Site logo" />
            )}
          </div>
        )}

        <img src={SuccessImage} style={styles.checkLogo} alt="check mark" />

        <h3>
          <b>Congrats, {basicInformation.first_name}!</b>
        </h3>

        <p style={{ fontSize: "100%", maxWidth: "400px", margin: "0% auto" }}>
          You've succesfully signed up! You will receive a text message
          confirming your participation and a tracking number once we've sent
          the devices.
        </p>

        {props.showDeviceImages && (
          <img
            src={AthelasDevicesImage}
            alt="Athelas devices"
            style={{ maxWidth: "300px" }}
          />
        )}
        <br />
      </div>
    </div>
  );
};
