import {
  login,
  updateConfirmationInformation,
  updateInsuranceInformation,
  updateShippingAddress,
} from "./api";
import { BasicInformationScreen } from "./BasicInformationScreen";
import { CompletionScreen } from "./CompletionScreen";
import { ConfirmationScreen } from "./ConfirmationScreen";
import { InitialScreen } from "./InitialScreen";
import { InsuranceScreen } from "./InsuranceScreen";
import { ShippingAddressScreen } from "./ShippingAddressScreen";

export enum OnboardingStepsType {
  INITIAL = "INITIAL",
  BASIC_INFORMATION = "BASIC_INFORMATION",
  SHIPPING_ADDRESS = "SHIPPING_ADDRESS",
  INSURANCE = "INSURANCE",
  CONFIRMATION = "CONFIRMATION",
  COMPLETION = "COMPLETION",
}

export const ONBOARDING_FLOW: any = {
  INITIAL: {
    previous_step: null,
    next_step: OnboardingStepsType.BASIC_INFORMATION,
    component: InitialScreen,
    action: () => {
      Object.keys(ONBOARDING_FLOW).map((key: string) => {
        localStorage.removeItem(key);
        return null;
      });

      return new Promise((resolve, reject) => {
        resolve(true);
      });
    },
    active: true,
  },
  BASIC_INFORMATION: {
    previous_step: OnboardingStepsType.INITIAL,
    next_step: OnboardingStepsType.SHIPPING_ADDRESS,
    component: BasicInformationScreen,
    active: true,
    action: (date_of_birth: string, first_name: string, last_name: string) => {
      return new Promise((resolve, reject) => {
        if (!first_name) {
          reject("First name missing");
        }
        if (!last_name) {
          reject("Last name missing");
        }
        if (!date_of_birth) {
          reject("Date of birth missing");
        }

        login(date_of_birth, first_name, last_name).then((token) => {
          if (!token) {
            reject("Token not found");
          } else {
            resolve(token);
          }
        }).catch((e: string) => {
          reject({error: "Unfortunately, we could not find your account. Please contact support@getathelas.com"})
        });
      });
    },
  },
  SHIPPING_ADDRESS: {
    previous_step: OnboardingStepsType.BASIC_INFORMATION,
    next_step: OnboardingStepsType.INSURANCE,
    component: ShippingAddressScreen,
    active: true,
    action: (address: string, city: string, state: string, zip: string) => {
      return new Promise((resolve, reject) => {
        if (!address) {
          reject("Address missing.");
        }
        if (!city) {
          reject("City missing");
        }
        if (!state) {
          reject("State missing");
        }
        if (!zip) {
          reject("Zipcode missing");
        }
        updateShippingAddress(address, city, state, zip);
        resolve("Continue");
      });
    },
  },
  INSURANCE: {
    previous_step: OnboardingStepsType.SHIPPING_ADDRESS,
    next_step: OnboardingStepsType.CONFIRMATION,
    component: InsuranceScreen,
    active: true,
    action: (insurance_company: string, policy_number: string) => {
      return new Promise((resolve, reject) => {
        if (!insurance_company) {
          reject("Insurance company missing");
        }
        if (!policy_number) {
          reject("Policy Number missing.");
        }
        updateInsuranceInformation(insurance_company, policy_number);
        resolve("Continue");
      });
    },
  },
  CONFIRMATION: {
    previous_step: OnboardingStepsType.INSURANCE,
    next_step: OnboardingStepsType.COMPLETION,
    component: ConfirmationScreen,
    active: true,
    action: (acknowledged: boolean, source: string) => {
      return new Promise((resolve, reject) => {
        if (!acknowledged) {
          reject("You must accept the terms to sign up for Athelas RPM.");
        }
        updateConfirmationInformation(acknowledged, source)
          .then((res) => {
            resolve("Continue");
          })
          .catch((e) => {
            alert(
              "There was an error whwen completing your onboarding. Please contact us at support@athelas.com"
            );
          });
      });
    },
  },
  COMPLETION: {
    previous_step: OnboardingStepsType.CONFIRMATION,
    next_step: null,
    component: CompletionScreen,
    active: true,
  },
};
