export const Testimonials = [
  {
    title: "Julie Sonders",
    subtitle: "BP Monitor User",
    testimonial:
      "The blood pressure device is so simple to use. Very simple clear instructions. I really like how you don't have to go to the doctor and the results are forwarded immediately to him and texted to me. If you have any questions/concerns, you can reply to the texts and someone from Athelas responds quickly. I also get calls from my own personal nurse every month to check-in. Overall great experience!",
    picture:
      "https://expertphotography.b-cdn.net/wp-content/uploads/2020/05/photo-of-woman-wearing-yellow.jpg",
  },
  {
    title: "Elaina Seebald",
    subtitle: "BP Monitor User",
    testimonial:
      "I don’t have to worry about how meds are affecting my blood pressure, or leave my house, thanks to her use of the Athelas home monitoring program. She has truly made my life easier and more balanced than ever. I’m so thankful my pathway led to her.",
    picture:
      "https://lh3.googleusercontent.com/a-/AOh14Ggx3k_mPZZMszWo08Wwo23X_hHpHtaKtmIpArg71A=w72-h72-p-rp-mo-br100",
  },
  {
    title: "Luna Sousa",
    subtitle: "Remote Monitoring Device User",
    testimonial:
      "We regularly receive a call from a nurse who follows us step by step, she transmitted the health information she got from that call to Doctor Krause. His program is fantastic, because it avoids so many visits to the doctor and it's worth using this new method of health control and contact with doctors. ",
    picture:
      "https://lh3.googleusercontent.com/a-/AOh14Gg0Wclf-KIuVfZfa20Kydl8J1X7lsyryMs6smKY=w72-h72-p-rp-mo-br100",
  },
];
