import { useState } from "react";
import { requestTotalCareOnboarding } from "./actions";

export function TotalCareOnboarding(props) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const onSubmitClicked = () => {
    const phone = phoneNumber
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll(" - ", "")
      .replaceAll("+", "");
    requestTotalCareOnboarding(phone).then((res) => {});
    setSubmitted(true);
  };

  if (submitted) {
    return (
      <div class="form-container mt-5" style={{ marginBottom: 70 }}>
        <div class="mt-2 p-4">
          <h1> Athelas Total Care </h1>
        </div>
        <div class=" p-4">
          <p>
            You will receive a new onboarding link in the next minute or two. If
            not, please contact{" "}
            <a href="mailto:support@getathelas.com">support@getathelas.com</a>{" "}
            for further assistance.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div class="form-container mt-5">
      <div class="mt-2 p-4">
        <h1> Athelas Total Care </h1>
      </div>
      <div class="px-5 pb-3">
        Welcome to Athelas Total Care. If you haven't received an onboarding
        link, type your phone number and we'll send you a new link.
      </div>
      <div class="text-left px-5 input-group-lg">
        {" "}
        <label style={{ marginTop: 15, color: "gray" }}>Phone Number</label>
        <input
          type="text"
          className={"form-control"}
          onChange={(e) => {
            let text = e.target.value;

            text = text
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(" - ", "")
              .replaceAll("+", "");

            if (text.length > 3) {
              var formatted = text.replace(
                /(\d{1,2})(\d{1})?(\d{1,3})?(\d{1,4})?/,
                function (_, p1, p2, p3, p4) {
                  let output = "";
                  if (p1) output = `(${p1}`;
                  if (p2) output += `${p2})`;
                  if (p3) output += ` - ${p3}`;
                  if (p4) output += ` - ${p4}`;
                  return output;
                }
              );
            }
            setPhoneNumber(formatted);
          }}
          placeholder="Phone Number"
          value={phoneNumber}
        />
      </div>
      <div class="pt-5 pb-5">
        <button class="btn light-btn" onClick={onSubmitClicked}>
          Send Onboarding Link
        </button>
      </div>
    </div>
  );
}
