// Ensure this matches PatientValidationError Enum in Athelas repo
export const backendValidationErrorMessages = {
  PATIENT_ID_REQUIRED: "patient_id not provided",
  MISSING_PATIENT: "patient_id does not exist in db",
  PATIENT_DOES_NOT_BELONG_TO_SITE: "patient does not belong to site",
  DATE_OF_BIRTH_INCORRECT_FORMAT: "date_of_birth not in YYYY-MM-DD format",
  DUPLICATE_PATIENT: "patient already exists in db",
  SITE_ID_DOES_NOT_EXIST: "site_id does not exist in db",
  PRESCRIBER_ID_DOES_NOT_EXIST: "prescriber_id does not exist in db",
  PRESCRIBER_DOES_NOT_BELONG_TO_SITE: "prescriber does not belong to site",
  PHONE_NUMBER_IS_INVALID:
    "phone_number is invalid, please make sure it only has 10 digits",
  DUPLICATE_PHONE_NUMBER: "duplicate phone number",
  GENDER_NOT_MALE_OR_FEMALE: "gender must be either Male or Female",
  EMAIL_FORMAT_IS_INVALID: "email is invalid",
  NO_VALID_DIAGNOSES: "no valid icd-10 conditions found in list",
  DIAGNOSES_MISSING: "missing icd-10 conditions",
  MULTIPLE_DIAGNOSES: "more than one icd-10 conditions found in list",
  FIRST_TEST_TYPE_INVALID: "first_test is an invalid Test Type",
  NO_TEST_TYPES_SELECTED: "no test types have been selected",
  REQUIRED_FIELD_MISSING: "field required",
  PAYER_ID_NOT_SUPPORTED:
    "insurance company is not supported by athelas billing",
  NO_ADDRESS_PROVIDED: "no address information was provided",
  ADDRESS_NOT_VERIFIED: "address could not be verified",
};
