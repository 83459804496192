import React, { useEffect } from "react";
import GrayPutBack from "./pilltrack_assets/GrayPutBack.webp";
import BeatLoader from "react-spinners/BeatLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { getLastBottle } from "./API";
import styled from "styled-components";
import Log from "../../app/logging/Log.js";
import ShimmerImage from "../../app/utils/ShimmerImage";

function PillTrackMoreThanOneBottleOut(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const instructions = `It looks like you have 
  ${location.state.bottlesOut.length} bottles out of their slots. Please return all bottles back. During this onboarding process, only one bottle should be out at a time.`;

  useEffect(() => {
    const interval = setInterval(() => {
      getLastBottle(props.deviceString, props.token)
        .then((response) => {
          const bottlesOut = response.bottles_out;
          if (bottlesOut.length === 0) {
            navigate("/pilltrack/alignment");
          }
        })
        .catch((error) => console.log(error));
    }, 250);
    return () => clearInterval(interval);
  });

  return (
    <Log
      logImpression={true}
      patientID={props.patientID}
      action="view"
      tag="pilltrack_more_than_one_bottle_out"
    >
      <TopMargin>
        <ReturnMedicationImage>
          <ShimmerImage
            imgHeight={522}
            imgWidth={684}
            src={GrayPutBack}
            widthPercentage={0.5}
          />
        </ReturnMedicationImage>
        <Title>Please Return All Bottles</Title>
        <StyledH5>{instructions}</StyledH5>
        <CenterLoader>
          <BeatLoader color="#E85B81" />
        </CenterLoader>
        <ItalicH5>Waiting for bottles to be returned...</ItalicH5>
      </TopMargin>
    </Log>
  );
}

const CenterLoader = styled.div`
  text-align: center;
  padding-top: 6vw;
`;

const ItalicH5 = styled.h5`
  font-style: italic;
  color: #808080;
  text-align: center;
  padding-top: 12vw;
  padding-left: 48px;
  padding-right: 48px;
`;

const StyledH5 = styled.h5`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #666666;
  padding-top: 10px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 60px;
`;

const TopMargin = styled.div`
  margin-top: 40px;
`;

const Title = styled.h2`
  color: #e85b81;
  font-family: "Manrope", sans-serif;
  text-align: center;
  font-weight: bold;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 60px;
`;

const ReturnMedicationImage = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
  max-height: 75%;
`;

export default PillTrackMoreThanOneBottleOut;
