import React, { Component } from "react";
import { ProgressBar } from "../../app/Atoms/ProgressBar/ProgressBar";
export class TotalCareIntro extends Component {
  nextStep = () => {
    this.props.setMode("nurse");
  };

  render() {
    const firstName = this.props.token?.first_name || "Hello";

    return (
      <div
        class="form-container mt-5"
        style={{ marginBottom: "48px", paddingBottom: "24px" }}
      >
        <div class="p-4">
          <h2>Athelas Total Care Program</h2>
          <p style={{ textAlign: "left" }}>
            Hi {firstName}, welcome to the Athelas Total Care program.
          </p>
          <p>
            <b>🕚 This set up will take 3 minutes.</b>
          </p>
          <p style={{ textAlign: "left" }}>
            As a part of the program, you get access to three things:
          </p>
          <hr></hr>
          <p
            style={{ textAlign: "left", display: "flex", flexDirection: "row" }}
          >
            <span style={{ fontSize: "200%", marginRight: "12px" }}>⚖️</span>
            <span style={{ marginTop: "8px" }}>
              1. A Smart Weight Scale + Companion App
            </span>
          </p>
          <hr></hr>
          <p
            style={{ textAlign: "left", display: "flex", flexDirection: "row" }}
          >
            <span style={{ fontSize: "200%", marginRight: "12px" }}>🩺</span>
            <span style={{ marginTop: "12px" }}>
              2. Automatic data connection with your doctor
            </span>
          </p>
          <hr></hr>
          <p
            style={{ textAlign: "left", display: "flex", flexDirection: "row" }}
          >
            <span style={{ fontSize: "200%", marginRight: "12px" }}>👩🏽‍⚕️</span>
            <span style={{ marginTop: "12px" }}>
              3. Your own, personal nutritionist
            </span>
          </p>
          <hr></hr>

          <button class="btn light-btn" onClick={this.nextStep}>
            Meet your Nutritionist
          </button>

          <p style={{ fontSize: "80%", marginTop: "16px" }}>
            <i>
              By setting up your scale, you consent to enrolling in the Total
              Care Program recommended by your doctor.
            </i>
          </p>
        </div>

        <ProgressBar percent={0} />
      </div>
    );
  }
}
