import React, { useState } from "react";

import AthelasLogo from "../assets/AthelasLogo.png";
import AthelasDevicesImage from "../assets/AthelasDevices.png";
import { TextInput } from "./components/TextInput";
import { getPatientOnboardingFromLocalStorage } from "./api";

type PropsType = {
  showDeviceImages?: boolean;
  showSiteLogos?: boolean;
  showCornerImages?: boolean;
  site_logo?: null | string;
  site_name?: null | string;
  palomar?: null | boolean;
  nextStep: (...args: any[]) => void;
};

const styles: any = {
  innerContainer: {
    maxWidth: 1000,
    width: "100%",
    margin: "0% auto",
  },
  logoContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    margin: "0% auto",
    marginBottom: 12,
  },
  logo: {
    maxWidth: "140px",
    maxHeight: "80px",
    margin: 12,
    objectFit: "contain",
  },
  flexContainer: {
    flexDirection: "row",
    display: "flex",
    maxWidth: 285,
    margin: "0% auto",
    marginBottom: 16,
    justifyContent: "left",
  },
  mobileFlexContainer: {
    flexDirection: "row",
    display: "flex",
    width: "100%",
    margin: "0% auto",
    marginBottom: 16,
    justifyContent: "left",
  },
  checkmark: {
    width: 36,
    height: 36,
    marginRight: 16,
  },
  explainer: {
    marginTop: 8,
  },
  buttonStyle: {
    width: "100%",
    maxWidth: "240px",
    margin: "0% auto",
    marginBottom: 24,
    fontStyle: "bold",
    padding: 8,
    borderRadius: 4,
    backgroundColor: "#FF6779",
    marginTop: 24,
    color: "white",
    cursor: "pointer",
  },
  palomarButtonStyle: {
    width: "100%",
    maxWidth: "240px",
    margin: "0% auto",
    marginBottom: 24,
    fontStyle: "bold",
    padding: 8,
    borderRadius: 4,
    backgroundColor: "#008CC1",
    marginTop: 24,
    color: "white",
    cursor: "pointer",
  },
};

export const ShippingAddressScreen = (props: PropsType) => {
  const [address, setAddress] = useState(
    getPatientOnboardingFromLocalStorage("address_line1")
  );
  const [city, setCity] = useState(
    getPatientOnboardingFromLocalStorage("city_locality")
  );
  const [state, setState] = useState(
    getPatientOnboardingFromLocalStorage("state_province")
  );
  const [zip, setZip] = useState(
    getPatientOnboardingFromLocalStorage("postal_code")
  );

  return (
    <div>
      <div style={styles.innerContainer}>
        {props.showSiteLogos && (
          <div style={styles.logoContainer}>
            <img src={AthelasLogo} style={styles.logo} alt="Athelas logo" />
            {props.site_logo && (
              <img src={props.site_logo} style={styles.logo} alt="Site logo" />
            )}
          </div>
        )}
        <h3>
          <b>Shipping Address</b>
        </h3>
        <p style={{ maxWidth: 450, margin: "0% auto", marginBottom: 16 }}>
          Please confirm your address in order to continue.
        </p>
        <TextInput
          placeholder="ex. 6015 Fairhope Pl"
          label="Address"
          value={address}
          onChange={(text) => {
            setAddress(text);
          }}
        />
        <TextInput
          placeholder="ex. San Jose"
          label="City"
          value={city}
          onChange={(text) => {
            setCity(text);
          }}
        />
        <TextInput
          placeholder="ex. California"
          label="State"
          value={state}
          onChange={(text) => {
            setState(text);
          }}
        />
        <TextInput
          placeholder="ex. 95123"
          label="Zip Code"
          value={zip}
          onChange={(text) => {
            setZip(text);
          }}
        />

        <div
          style={props.palomar? styles.palomarButtonStyle : styles.buttonStyle}
          onClick={() => props.nextStep(address, city, state, zip)}
        >
          Next Step
        </div>
        {props.showDeviceImages && (
          <img
            src={AthelasDevicesImage}
            alt="Athelas devices"
            style={{ maxWidth: "300px" }}
          />
        )}
        <br />
      </div>
    </div>
  );
};
