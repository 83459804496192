import axios from "axios";
import { API_SERVER_URL } from "../../app/constants";

type Payer = {
    payer_id: string,
    payer_name: string,
}

type MostCommonPayerQueryResult = {
  payers: Payer[];
}

export const queryMostCommonPayers = (authToken: string) => {
  return axios.get<MostCommonPayerQueryResult>(
    `${API_SERVER_URL}/insurance/get_most_common_payers`, { headers: { "Authorization": `Bearer ${authToken}`}}
  );
};