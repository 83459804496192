import React, { Component } from 'react';
import { getPatientInfo, updatePatientInfo, selectPatient } from './PatientInfoSlice'
import PatientInfo from './PatientInfo'
import { connect } from 'react-redux'
import _ from 'lodash'

const mapDispatch = { getPatientInfo, updatePatientInfo }
const mapStateToProps = (state) => {
  return {
    patient: selectPatient(state)
  }
}

class PatientInfoWrapper extends Component {

  componentDidMount() {
    const { getPatientInfo } = this.props
    getPatientInfo()
  }

  render() {
    const { patient } = this.props
    return (
      <PatientInfo key={_.size(patient)} {...this.props} />
    )
  }
}

export default connect(mapStateToProps, mapDispatch)(PatientInfoWrapper)
