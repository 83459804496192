import React, { useEffect } from "react";
import { createTheme } from "@mui/material/styles";
import { themeOptions } from "./ThemeOptionsPatientPay";
import { useLocation } from "react-router-dom";
import Check from "./assets/Check.png";
import { ThemeProvider, Box, CardMedia, Typography, Grid } from "@mui/material";
import { trackNormandy } from "../../app/utils/logging";

export default function PatientPayNoBalance(props) {
  const theme = createTheme(themeOptions);
  const location = useLocation();

  useEffect(() => {
    trackNormandy({
      action: "view",
      tag: "patient_pay_no_balance",
      data: {
        first_name: window.sessionStorage.getItem("first_name"),
        last_name: window.sessionStorage.getItem("last_name"),
        date_of_birth: window.sessionStorage.getItem("date_of_birth"),
        site_id: window.sessionStorage.getItem("site_id"),
        requested_prs: window.sessionStorage.getItem("requested_prs"),
        payment_intent_id: window.sessionStorage.getItem("payment_intent_id"),
      },
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "75vh" }}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CardMedia
              sx={{
                width: 0.3,
                height: 1 / 6,
              }}
              src={Check}
              component="img"
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Typography align="center" variant="h6" gutterBottom>
              {location.state.firstName}, your balance has been updated to $0
              since we last contacted you.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="body1"
            sx={{ mx: 8, mb: 2, color: "text.secondary" }}
            gutterBottom
          >
            No payment needed at the time! Please feel free to close out this
            window.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 10 }}>
          <Typography
            align="center"
            variant="subtitle2"
            sx={{ color: "text.secondary" }}
          >
            Powered by Athelas SmartPay
          </Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
