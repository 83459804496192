import React from "react";
import InteriorLight from "./pilltrack_assets/InsideLight.webp";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import BackButton from "./BackButton";
import styled from "styled-components";
import Log from "../../app/logging/Log.js";
import ShimmerImage from "../../app/utils/ShimmerImage";

function PillTrackOpened(props) {
  const navigate = useNavigate();
  const blueLight = "Blue Light = No Action Required";
  const noLight = "Light Off = Empty or Not in Use";
  const redLight = "Red Light = Take Medication";

  const instructions = "Interior Lights";
  const nextStep = () => {
    navigate("/pilltrack/closed");
  };

  return (
    <Log
      logImpression={true}
      patientID={props.patientID}
      action="view"
      tag="pilltrack_open"
    >
      <BackButton
        onClick={() => {
          navigate(-1);
        }}
      />
      <StyledGrid>
        <InteriorLightImage>
          <ShimmerImage
            imgHeight={393}
            imgWidth={309}
            src={InteriorLight}
            widthPercentage={0.3}
          />
        </InteriorLightImage>
        <Title>{instructions}</Title>
        <IconRow>
          <BlueDot />
          <StyledH5>{blueLight}</StyledH5>
        </IconRow>
        <IconRow>
          <RedDot />
          <StyledH5>{redLight}</StyledH5>
        </IconRow>
        <IconRow>
          <GrayDot />
          <StyledH5>{noLight}</StyledH5>
        </IconRow>
        <CenterButton>
          <StyledButton variant="primary" size="lg" onClick={nextStep}>
            <ButtonText>Continue</ButtonText>
          </StyledButton>
        </CenterButton>
      </StyledGrid>
    </Log>
  );
}

const StyledGrid = styled.div`
  display: grid;
  grid-row-gap: 27px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Title = styled.h2`
  color: #e85b81;
  font-family: "Manrope", sans-serif;
  text-align: center;
  font-weight: bold;
  padding-left: 48px;
  padding-right: 48px;
`;

const InteriorLightImage = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 35%;
  max-height: 35%;
  margin-top: 60px;
`;

const StyledButton = styled(Button)`
  background: #e85b81;
  border-radius: 10px;
  border-color: #e85b81;
  width: 260px;
  text-align: center;
  :disabled {
    background: #d1d1d1;
    border-color: #d1d1d1;
  }
  :focus {
    background: #e85b81;
    border-color: #e85b81;
  }
  :active {
    background: #e85b81;
    border-color: #e85b81;
  }
  :hover {
    background: #e85b81;
    border-color: #e85b81;
  }
`;

const IconRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`;

const CenterButton = styled.div`
  text-align: center;
`;

const ButtonText = styled.h4`
  font-family: "Manrope", sans-serif;
  text-align: center;
  padding-top: 5px;
`;

const StyledH5 = styled.h5`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #666666;
`;

const BlueDot = styled.div`
  background-color: #3ca0fd;
  height: 12px;
  min-width: 12px;
  border-radius: 6px;
  margin-top: 6px;
  margin-right: 6px;
`;

const RedDot = styled.div`
  background-color: #e85b81;
  height: 12px;
  min-width: 12px;
  border-radius: 6px;
  margin-top: 6px;
  margin-right: 6px;
`;

const GrayDot = styled.div`
  background-color: #d4d4d4;
  height: 12px;
  min-width: 12px;
  border-radius: 6px;
  margin-top: 6px;
  margin-right: 6px;
`;

export default PillTrackOpened;
