import React from "react";
import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { createTheme } from "@mui/material/styles";
import { themeOptions } from "./ThemeOptionsPatientPay";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  ThemeProvider,
  Button,
  Grid,
  Typography,
  CardMedia,
  Container,
} from "@mui/material";
import jwt from "jsonwebtoken";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Box from "@mui/material/Box";
import { PUBLIC_KEY } from "../../app/constants";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/react";
import { patientLoginSchema } from "./PatientPaySchema.ts";
import {
  getPatientResponsibility,
  getPaymentIntentInfoV1,
  getShouldRedirectToEasyPay,
  getSiteLogoUrl,
} from "./PatientPayAPI.ts";
import BeatLoader from "react-spinners/BeatLoader";
import { expiredLinkTokens } from "../../app/expiredLinkTokens";
import { trackNormandy } from "../../app/utils/logging";

export default function PatientPayLogin() {
  let [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let [firstName, setFirstName] = useState("");
  let [lastName, setLastName] = useState("");
  let [logoURL, setLogoURL] = useState("");
  let [siteName, setSiteName] = useState("");
  let [requestedPRs, setRequestedPRs] = useState([]);
  const [amountInitiallyRequested, setAmountInitiallyRequested] = useState();
  const [applyCredits, setApplyCredits] = useState();
  const [applyFees, setApplyFees] = useState(true);
  const [batchID, setBatchID] = useState();
  const [facilityIDToSendPaymentTo, setFacilityIDToSendPaymentTo] = useState();
  const [providerIDToSendPaymentTo, setProviderIDToSendPaymentTo] = useState();
  const [dependentPatientsInfo, setDependentPatientsInfo] = useState([]);
  const [usingFixedLink, setUsingFixedLink] = useState(false);
  const [isPayingForDependentPatients, setIsPayingForDependentPatients] =
    useState(false);
  // Test with Altos for now
  let [siteID, setSiteID] = useState(-1);
  let [loginError, setLoginError] = useState(false);
  const [noteId, setNoteId] = useState();
  const [expiredLink, setExpiredLink] = useState(false);
  const [appointmentId, setAppointmentId] = useState();
  const [outstandingBalanceCollected, setOutstandingBalanceCollected] =
    useState();
  const [sumOfLineItems, setSumOfLineItems] = useState();
  const [loadingRedirect, setLoadingRedirect] = useState(true);

  const {
    control,
    handleSubmit,
    watch,
    setFocus,
    reset,
    // eslint-disable-next-line no-unused-vars
    formState: { errors, isSubmitted, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(patientLoginSchema),
    defaultValues: {
      first_name: null,
      last_name: null,
      date_of_birth: null,
    },
  });

  useEffect(() => {
    try {
      const siteId = searchParams.get("site_id");
      if (siteId) {
        setUsingFixedLink(true);
        setSiteID(siteId);
        setApplyCredits(false);
        getSiteLogoUrl(siteId)
          .then((resp) => {
            setLogoURL(resp.data);
          })
          .catch((e) => Sentry.captureException(e));
      } else {
        const token = searchParams.get("token");
        if (expiredLinkTokens.includes(token)) {
          setExpiredLink(true);
        }
        const decodedToken = jwt.verify(token, PUBLIC_KEY, {
          algorithms: ["RS256"],
        });
        window.sessionStorage.setItem("token", token);
        setLogoURL(decodedToken.site_logo_url);
        setFirstName(decodedToken.first_name);
        setLastName(decodedToken.last_name);
        setSiteName(decodedToken.site_name);
        setSiteID(decodedToken.site_id ?? -1);
        setRequestedPRs(decodedToken.patient_responsibility_ids ?? []);
        setAmountInitiallyRequested(decodedToken.amount_due_cents ?? undefined);
        setDependentPatientsInfo(decodedToken.dependent_patients ?? []);
        setApplyCredits(decodedToken.apply_credits ?? true);
        setApplyFees(decodedToken.apply_fees ?? true);
        setBatchID(decodedToken.batch_id ?? undefined);
        setFacilityIDToSendPaymentTo(
          decodedToken.facility_id_to_send_payment_to ?? undefined
        );
        setProviderIDToSendPaymentTo(
          decodedToken.provider_id_to_send_payment_to ?? undefined
        );
        setAppointmentId(decodedToken.appointment_id ?? undefined);
        setOutstandingBalanceCollected(
          decodedToken.outstanding_balance_in_usd_cents ?? undefined
        );
        setSumOfLineItems(
          decodedToken.sum_of_line_items_in_usd_cents ?? undefined
        );
        setNoteId(decodedToken.note_id);
        trackNormandy({
          action: "view",
          tag: "patient_pay_login",
          data: {
            first_name: decodedToken.first_name,
            last_name: decodedToken.last_name,
            site_id: decodedToken.site_id ?? -1,
            requested_prs: decodedToken.patient_responsibility_ids ?? [],
            batch_id: decodedToken.batch_id ?? undefined,
          },
        });
        if (
          decodedToken.dependent_patients &&
          decodedToken.dependent_patients.length > 0
        )
          setIsPayingForDependentPatients(true);
      }
    } catch (e) {
      const token = searchParams.get("token");
      Sentry.captureException(e, {
        tags: ["PatientPayTokenError"],
        extra: { token, key: PUBLIC_KEY },
      });
    }

    getShouldRedirectToEasyPay({
      siteId: searchParams.get("site_id"),
      token: searchParams.get("token"),
    })
      .then((resp) => {
        if (resp.data.should_redirect) {
          window.location.href = resp.data.redirect_url;
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
      })
      .finally(() => {
        setLoadingRedirect(false);
      });
  }, [reset, searchParams]);

  useEffect(() => {
    const firstError = Object.keys(errors).reduce(
      (field, a) => (!!errors[field] ? field : a),
      null
    );
    if (firstError) setFocus(firstError);
  }, [errors, setFocus]);

  const helperTextDOB = () => {
    if (loginError) {
      return loginError;
    } else if (!!errors?.date_of_birth?.message) {
      return errors?.date_of_birth?.message;
    }
    return null;
  };

  const watchFirstName = watch("first_name");
  const watchLastName = watch("last_name");
  const watchDOB = watch("date_of_birth");

  const onSubmit = async (data) => {
    setIsLoadingResponse(true);
    data.date_of_birth = moment(data.date_of_birth).format("MM/DD/YYYY");
    if (siteID === -1) {
      navigate("/patient_pay/portal", {
        state: { firstName, lastName, siteName, siteID },
      });
    } else {
      getPatientResponsibility({
        first_name: data.first_name,
        last_name: data.last_name,
        dob: data.date_of_birth,
        site_id: siteID,
        patient_responsibility_ids: requestedPRs,
        ignore_prs_from_dependent_patients: isPayingForDependentPatients,
      })
        .then((resp) => resp.data)
        .catch((err) => {
          setIsLoadingResponse(false);
          let errorMessage = "Incorrect information, person not found.";
          if (
            err.response?.data?.detail?.includes(
              "Not all patient responsibility requests are for patient"
            )
          )
            errorMessage = "This link was generated for a different patient.";
          setLoginError(errorMessage);
          trackNormandy({
            action: "login_error",
            tag: "patient_pay_login",
            data: {
              first_name: firstName,
              last_name: lastName,
              site_id: siteID,
              requested_prs: requestedPRs,
              batch_id: batchID,
              error_message: errorMessage,
            },
          });
          throw new Error("Error Grabbing Patient Responsibility", err);
        })
        .then(async (patientResponsibility) => {
          if (patientResponsibility.total_balance <= 0)
            return navigate("/patient_pay/no_balance", {
              state: { firstName: data.first_name, siteName },
            });
          if (patientResponsibility.total_balance / 100.0 <= 0.5)
            return navigate("/patient_pay/under_required_balance", {
              state: { firstName: data.first_name, siteName },
            });
          if (
            patientResponsibility.prs_with_different_stripe_account_ids &&
            usingFixedLink
          ) {
            setIsLoadingResponse(false);
            setLoginError(
              "You have outstanding patient responsibility requests from different facilities or providers. Please contact the site to generate a specific payment link for you."
            );
            return;
          }
          setLoginError(false);
          getPaymentIntentInfoV1({
            patient_first_name: data.first_name,
            patient_last_name: data.last_name,
            patient_date_of_birth: data.date_of_birth,
            site_id: siteID,
            patient_responsibility_request_ids: isPayingForDependentPatients
              ? requestedPRs
              : patientResponsibility.patient_responsibility_request_ids,
            amount_due_cents: usingFixedLink
              ? patientResponsibility.total_balance
              : Math.min(
                  amountInitiallyRequested,
                  patientResponsibility.total_balance
                ),
            apply_credits: applyCredits,
            batch_id: batchID,
            facility_id_to_send_payment_to: facilityIDToSendPaymentTo,
            provider_id_to_send_payment_to: providerIDToSendPaymentTo,
            dependent_patients: dependentPatientsInfo,
            note_id: noteId,
            apply_fees: false,
            appointment_id: appointmentId,
            outstanding_balance_in_usd_cents: outstandingBalanceCollected,
            sum_of_line_items_in_usd_cents: sumOfLineItems,
          }).then(async (resp) => {
            if (resp.data.client_secret === "No Payment Necessary")
              return navigate("/patient_pay/no_balance", {
                state: { firstName: data.first_name, siteName },
              });

            const amountChargedInUSDCents = usingFixedLink
              ? patientResponsibility.total_balance
              : Math.min(
                  amountInitiallyRequested,
                  patientResponsibility.total_balance
                );

            let dependentPatientsOutstandingPRInfo = [];
            if (isPayingForDependentPatients) {
              const dependentPatientsResponsibilityPromises =
                dependentPatientsInfo.map((dependentPatientInfo) =>
                  getPatientResponsibility({
                    patient_id: dependentPatientInfo.patient_id,
                    site_id: siteID,
                  }).then((resp) => {
                    dependentPatientsOutstandingPRInfo = [
                      ...dependentPatientsOutstandingPRInfo,
                      {
                        ...resp.data,
                        patient_id: dependentPatientInfo.patient_id,
                      },
                    ];
                  })
                );

              await Promise.all(dependentPatientsResponsibilityPromises);
              dependentPatientsInfo.forEach((dependentPatientInfo) => {
                dependentPatientInfo.amount_due_cents = Math.min(
                  dependentPatientInfo.amount_due_cents,
                  dependentPatientsOutstandingPRInfo.find(
                    (prInfo) =>
                      prInfo.patient_id === dependentPatientInfo.patient_id
                  ).total_balance
                );
              });
            }

            getPatientResponsibility({
              first_name: data.first_name,
              last_name: data.last_name,
              dob: data.date_of_birth,
              site_id: siteID,
              patient_responsibility_ids: requestedPRs,
              ignore_prs_from_dependent_patients: isPayingForDependentPatients,
            })
              .then((resp) => resp.data)
              .catch((err) => {
                setIsLoadingResponse(false);
                let errorMessage = "Incorrect information, person not found.";
                if (
                  err.response?.data?.detail?.includes(
                    "Not all patient responsibility requests are for patient"
                  )
                )
                  errorMessage =
                    "This link was generated for a different patient.";

                setLoginError(errorMessage);
                trackNormandy({
                  action: "login_error",
                  tag: "patient_pay_login",
                  data: {
                    first_name: firstName,
                    last_name: lastName,
                    site_id: siteID,
                    requested_prs: requestedPRs,
                    batch_id: batchID,
                    error_message: errorMessage,
                  },
                });
                throw new Error("Error Grabbing Patient Responsibility", err);
              })
              .then(async (patientResponsibility) => {
                if (patientResponsibility.total_balance <= 0)
                  return navigate("/patient_pay/no_balance", {
                    state: { firstName: data.first_name, siteName },
                  });
                setLoginError(false);
                getPaymentIntentInfoV1({
                  patient_first_name: data.first_name,
                  patient_last_name: data.last_name,
                  patient_date_of_birth: data.date_of_birth,
                  site_id: siteID,
                  patient_responsibility_request_ids:
                    isPayingForDependentPatients
                      ? requestedPRs
                      : patientResponsibility.patient_responsibility_request_ids,
                  amount_due_cents: amountChargedInUSDCents,
                  apply_credits: applyCredits,
                  batch_id: batchID,
                  facility_id_to_send_payment_to: facilityIDToSendPaymentTo,
                  provider_id_to_send_payment_to: providerIDToSendPaymentTo,
                  dependent_patients: dependentPatientsInfo,
                  note_id: noteId,
                  apply_fees: false,
                  appointment_id: appointmentId,
                  outstanding_balance_in_usd_cents: outstandingBalanceCollected,
                  sum_of_line_items_in_usd_cents: sumOfLineItems,
                }).then((resp) => {
                  if (resp.data.client_secret === "No Payment Necessary")
                    return navigate("/patient_pay/no_balance", {
                      state: { firstName: data.first_name, siteName },
                    });
                  window.sessionStorage.setItem(
                    "stripe_connect_id",
                    resp.data.stripe_connect_account_id
                  );
                  window.sessionStorage.setItem(
                    "client_secret",
                    resp.data.client_secret
                  );
                  window.sessionStorage.setItem(
                    "payment_intent_id",
                    resp.data.intent_id
                  );
                  window.sessionStorage.setItem("first_name", data.first_name);
                  window.sessionStorage.setItem("last_name", data.last_name);
                  window.sessionStorage.setItem(
                    "date_of_birth",
                    data.date_of_birth
                  );
                  window.sessionStorage.setItem("site_id", siteID);
                  window.sessionStorage.setItem("requested_prs", requestedPRs);
                  window.sessionStorage.setItem(
                    "dependent_patients_info",
                    JSON.stringify(dependentPatientsInfo)
                  );
                  window.sessionStorage.setItem("apply_fees", applyFees);

                  const latestEncounter =
                    patientResponsibility.outstanding_encounters[0];

                  navigate("/patient_pay/portal", {
                    state: {
                      siteName,
                      firstName: data.first_name,
                      lastName: data.last_name,
                      applyCredits: applyCredits,
                      previousPaymentMethods:
                        resp.data.previous_payment_methods,
                      siteID,
                      stripeAccountId: resp.data.stripe_connect_account_id,
                      latestDate:
                        latestEncounter.encounter_date_of_service ||
                        latestEncounter.appointment_date_of_service,
                      outstandingEncounters:
                        patientResponsibility.outstanding_encounters,
                      supportNumber:
                        patientResponsibility.jive_customer_support_number,
                      amountChargedInUSDCents: isPayingForDependentPatients
                        ? amountChargedInUSDCents +
                          dependentPatientsInfo.reduce(
                            (acc, patient) => acc + patient.amount_due_cents,
                            0
                          )
                        : amountChargedInUSDCents,
                      amountInitiallyRequested,
                      usingFixedLink,
                      payingForDependentPatients: isPayingForDependentPatients,
                      dependentPatientsOutstandingPRInfo,
                      dependentPatientsInfo,
                    },
                  });
                });
              });
          });
        })
        .catch((e) => {
          setIsLoadingResponse(false);
          onError(errors, e);
        });
    }
  };

  const onError = (errors, e) => console.log("onError", errors, e);
  const theme = createTheme(themeOptions);

  if (loadingRedirect) {
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="sm">
          <Box sx={{ mx: 4, mb: 8, mt: 2 }}>
            <Grid
              style={{ minHeight: "100vh" }}
              alignItems="center"
              justifyContent="flex-start"
              container
              direction="row"
              align="stretch"
              spacing={2}
            >
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <BeatLoader color="#5A67E5" />
                <p>Verifying Information</p>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        {(!usingFixedLink && !amountInitiallyRequested) || expiredLink ? (
          <Box sx={{ mx: 4, mb: 8, mt: 2 }}>
            <Typography variant="title" align="center">
              This link is expired, please ask the site to generate a new one.
            </Typography>
          </Box>
        ) : (
          <Box sx={{ mx: 4, mb: 8, mt: 2 }}>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <Grid
                style={{ minHeight: "70vh" }}
                alignItems="center"
                justifyContent="flex-start"
                container
                direction="row"
                align="stretch"
                spacing={2}
              >
                <Grid item xs={12}>
                  {
                    // if we dont have a logo for the site dont render card media
                    logoURL && !logoURL.includes("-1") && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CardMedia
                          sx={{
                            width: 0.5,
                            height: 1 / 5,
                          }}
                          src={logoURL}
                          component="img"
                        />
                      </Box>
                    )
                  }
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{ color: "text.secondary" }}
                    >
                      To review and pay your balance, please login to review
                      details
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} container flexGrow>
                  <Controller
                    name="first_name"
                    control={control}
                    render={({ field: { onChange, value, ref, ...field } }) => (
                      <TextField
                        {...field}
                        onChange={onChange}
                        value={value}
                        label={"First Name"}
                        inputRef={ref}
                        sx={{ flex: 1 }}
                        error={!!errors?.first_name?.message}
                        helperText={errors?.first_name?.message}
                        inputProps={{ maxLength: 40 }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} container flexGrow>
                  <Controller
                    name="last_name"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        sx={{ flex: 1 }}
                        label={"Last Name"}
                        inputRef={ref}
                        error={!!errors?.last_name?.message}
                        helperText={errors?.last_name?.message}
                        inputProps={{ maxLength: 80 }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} container flexGrow>
                  <Controller
                    name="date_of_birth"
                    control={control}
                    defaultValue={null}
                    render={({ field: { ref, value, onChange, ...field } }) => (
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                          label="Date of Birth"
                          inputRef={ref}
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => (
                            <TextField
                              name={field["name"]}
                              sx={{ flex: 1 }}
                              {...params}
                              error={
                                !!errors?.date_of_birth?.message || loginError
                              }
                              helperText={helperTextDOB()}
                            />
                          )}
                          {...field}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                {isLoadingResponse && (
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <BeatLoader color="#5A67E5" />
                    <p>Verifying Information</p>
                  </Grid>
                )}
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={
                      isLoadingResponse ||
                      (!Boolean(watchFirstName) && !Boolean(firstName)) ||
                      (!Boolean(watchLastName) && !Boolean(lastName)) ||
                      !Boolean(watchDOB)
                    }
                    style={{
                      width: "100%",
                      height: "56px",
                      textAlign: "center",
                    }}
                  >
                    {"Log in"}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    variant="subtitle2"
                    sx={{ color: "text.secondary" }}
                  >
                    Powered by Athelas SmartPay
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </Box>
        )}
      </Container>
    </ThemeProvider>
  );
}
