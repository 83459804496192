import React, { useEffect } from "react";
import GrayPutBack from "./pilltrack_assets/GrayPutBack.webp";
import BeatLoader from "react-spinners/BeatLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { getLastPutBackBottle, getLastBottle } from "./API";
import BackButton from "./BackButton";
import styled from "styled-components";
import Log from "../../app/logging/Log.js";
import ShimmerImage from "../../app/utils/ShimmerImage";

function PillTrackReturnBottle(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const step = `${location.state.medicationName}: Step 3 of 3`;
  const instructions = `You’ve successfully set up 
  ${location.state.medicationName}. Now please put this pill bottle back into its slot.`;

  useEffect(() => {
    const interval = setInterval(() => {
      getLastPutBackBottle(props.deviceString, props.token)
        .then((number) => {
          getLastBottle(props.deviceString, props.token)
            .then((newNumber) => {
              const lastPutBack = number.last_bottle_number;
              const lastRemovedTime = newNumber.timestamp;
              const lastPutBackTime = number.timestamp;
              if (
                lastPutBack === location.state.bottleNumber &&
                lastRemovedTime < lastPutBackTime
              ) {
                navigate("/pilltrack/medication_list");
              }
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    }, 500);

    return () => clearInterval(interval);
  });

  return (
    <Log
      logImpression={true}
      patientID={props.patientID}
      action="view"
      tag="pilltrack_return"
    >
      <TopMargin>
        <BackButton
          onClick={() => {
            navigate(-1);
          }}
        />
        <ReturnMedicationImage>
          <ShimmerImage
            imgHeight={522}
            imgWidth={684}
            src={GrayPutBack}
            widthPercentage={0.5}
          />
        </ReturnMedicationImage>
        <StyledH5Step>{step}</StyledH5Step>
        <Title>Return Bottle Back to Slot</Title>
        <StyledH5>{instructions}</StyledH5>
        <CenterLoader>
          <BeatLoader color="#E85B81" />
        </CenterLoader>
        <ItalicH5>Waiting for bottle to be returned...</ItalicH5>
      </TopMargin>
    </Log>
  );
}

const CenterLoader = styled.div`
  text-align: center;
  padding-top: 6vw;
`;

const ItalicH5 = styled.h5`
  font-style: italic;
  color: #808080;
  text-align: center;
  padding-top: 12vw;
  padding-left: 48px;
  padding-right: 48px;
`;

const StyledH5 = styled.h5`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #666666;
  padding-top: 10px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 60px;
`;

const TopMargin = styled.div`
  margin-top: 40px;
`;

const Title = styled.h2`
  color: #e85b81;
  font-family: "Manrope", sans-serif;
  text-align: center;
  font-weight: bold;
  padding-left: 48px;
  padding-right: 48px;
`;

const ReturnMedicationImage = styled.div`
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
  max-height: 75%;
`;

const StyledH5Step = styled.h5`
  text-decoration: underline #666666;
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #666666;
  padding-top: 10px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 10px;
`;

export default PillTrackReturnBottle;
