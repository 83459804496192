import React from "react";
import NurseImage from "./nurse.png";
import NurseImageOne from "./nurse1.png";
import NurseImageTwo from "./nurse2.png";

export default class NurseProgram extends React.Component {
  render() {
    return (
      <div class="container p-3" style={{ marginBottom: 48 }}>
        <h1 class="m-5 step-header">Meet Your Personal Nurse</h1>
        <div class="row">
          <div
            class="col-md col-md-offset-4 col-md-4"
            style={{ marginBottom: 24 }}
          >
            <div class="card text-center h-100 step">
              <img
                src={NurseImage}
                class="card-img-top step-image"
                alt="..."
                style={{ maxHeight: 250, objectFit: "cover" }}
              />
              <div class="card-body">
                <h5 class="card-title step-title">Dedicated Nurse</h5>
                <p class="card-text step-text">
                  Athelas remote monitoring pairs you with a licensed, clinically certified
                  nurse that checks in with you once a month.
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-md col-md-offset-4 col-md-4"
            style={{ marginBottom: 24 }}
          >
            <div class="card text-center h-100 step">
              <img
                src={NurseImageOne}
                class="card-img-top step-image"
                alt="..."
                style={{ maxHeight: 250, objectFit: "cover" }}
              />
              <div class="card-body">
                <h5 class="card-title step-title">Regular Reviews</h5>
                <p class="card-text step-text">
                  Your nurse works with your physician to flag diagnostics that
                  may require medical intervention.
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-md col-md-offset-4 col-md-4"
            style={{ marginBottom: 24 }}
          >
            <div class="card text-center h-100 step">
              <img
                src={NurseImageTwo}
                class="card-img-top step-image"
                alt="..."
                style={{ maxHeight: 250, objectFit: "cover" }}
              />
              <div class="card-body">
                <h5 class="card-title step-title">Always Available</h5>
                <p class="card-text step-text">
                  Your nurse is available during business hours in your
                  respective timezone if you have any questions around your
                  health.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
