import React, { useEffect, useState } from "react";
import AllGraySlots from "./pilltrack_assets/AllGraySlots.webp";
import { getLastBottle, getSetUpBottles } from "./API";
import { useNavigate } from "react-router-dom";
import BackButton from "./BackButton";
import BeatLoader from "react-spinners/BeatLoader";
import moment from "moment";
import styled from "styled-components";
import Log from "../../app/logging/Log.js";
import ShimmerImage from "../../app/utils/ShimmerImage";

function PillTrackAlignment(props) {
  const setUpText = "Set Up Your Medication";
  const instructions = "Please pick a bottle out of its slot.";
  const waitingText = "Waiting for a bottle to be picked up...";
  const [bottleNums, setBottleNums] = useState([]);
  const [medNames, setMedNames] = useState([]);
  const [retreived, setRetrieved] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    if (!retreived) {
      getSetUpBottles(props.deviceString, props.token)
        .then((resp) => {
          if (isMounted) {
            setBottleNums(resp.bottle_numbers);
            setMedNames(resp.medication_names);
            setRetrieved(true);
          }
        })
        .catch((error) => console.log(error));
    } else {
      const interval = setInterval(() => {
        getLastBottle(props.deviceString, props.token)
          .then((number) => {
            const eventTime = moment(number.timestamp);
            const now = moment().subtract(3, "seconds");
            const lastPickedUp = number.last_bottle_number;
            const slotNumber = number.slot_number;
            const bottlesOut = number.bottles_out;
            if (
              lastPickedUp != null &&
              lastPickedUp !== -1 &&
              bottlesOut.length === 1 &&
              eventTime.isAfter(now)
            ) {
              navigate("/pilltrack/medication", {
                state: {
                  bottleNumber: lastPickedUp,
                  deviceString: props.deviceString,
                  token: props.token,
                  slotNumber: slotNumber,
                  edit: false,
                },
              });
            }
            if (bottlesOut.length >= 2) {
              navigate("/pilltrack/bottles_out", {
                state: {
                  bottlesOut: bottlesOut,
                },
              });
            }
            if (bottleNums.includes(lastPickedUp) && eventTime.isAfter(now)) {
              navigate("/pilltrack/reset", {
                state: {
                  bottleNumber: lastPickedUp,
                  deviceString: props.deviceString,
                  token: props.token,
                  slotNumber: slotNumber,
                  medicationName: medNames[bottleNums.indexOf(lastPickedUp)],
                },
              });
            }
          })
          .catch((error) => console.log(error));
      }, 500);
      return () => clearInterval(interval);
    }
    return () => {
      isMounted = false;
    };
  });

  return (
    <Log
      logImpression={true}
      patientID={props.patientID}
      action="view"
      tag="pilltrack_pickup"
    >
      <div>
        <BackButton
          onClick={() => {
            navigate(-1);
          }}
        />
        <Wrapper>
          <PillTrackImage>
            <ShimmerImage
              imgHeight={550}
              imgWidth={767}
              src={AllGraySlots}
              widthPercentage={0.75}
            />
          </PillTrackImage>
          <Title>{setUpText}</Title>
          <StyledH4>{instructions}</StyledH4>
          <CenterLoader>
            <BeatLoader color="#E85B81" />
          </CenterLoader>
          <StyledH5>{waitingText}</StyledH5>
        </Wrapper>
      </div>
    </Log>
  );
}

const Wrapper = styled.div`
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
`;
const PillTrackImage = styled.div`
  padding-top: 24px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 40px;
`;

const Title = styled.h2`
  color: #e85b81;
  font-family: "Manrope", sans-serif;
  text-align: center;
  font-weight: bold;
  padding-left: 48px;
  padding-right: 48px;
`;

const StyledH4 = styled.h4`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #666666;
  padding-top: 10px;
  padding-left: 48px;
  padding-right: 48px;
`;

const StyledH5 = styled.h5`
  font-style: italic;
  color: #808080;
  text-align: center;
  padding-top: 12vw;
  padding-left: 48px;
  padding-right: 48px;
`;

const CenterLoader = styled.div`
  padding-top: 12vw;
  text-align: center;
`;

export default PillTrackAlignment;
