import React from "react";
import { createTheme } from "@mui/material/styles";
import { themeOptions } from "./ThemeOptionsPatientEnrollment";
import { createSearchParams, useNavigate, useLocation } from "react-router-dom";
import {
  ThemeProvider,
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";

export default function PatientEnrollmentConfirmation(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const goToForm = () => {
    navigate({
      pathname: "/patient_enrollment",
      search: createSearchParams({
        // todo: fix token not being passed here
        token: location.state.token,
      }).toString(),
    });
  };

  const confirmationTitle = (enrolled) => {
    if (enrolled) {
      return "Patient Successfully Created and Enrolled!";
    }
    return "Patient Successfully Created!";
  };

  const confirmationText = (enrolled) => {
    if (enrolled) {
      return "Patient has been added and successfully enrolled. We will let them about their enrollment and kick off the shipping flow.";
    }
    return "Patient will receive a text to an onboarding link to complete their enrollment. Once they have confirmed their information and consented to the program, we will kick off the process for shipping a device to them.";
  };

  const theme = createTheme(themeOptions);

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <CheckIcon color="e85b81"></CheckIcon>
            <Typography align="center" variant="h5" gutterBottom>
              {confirmationTitle(location.state.enrolled)}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="body1"
            sx={{ mx: 8, mb: 2, color: "text.secondary" }}
            gutterBottom
          >
            {confirmationText(location.state.enrolled)}
          </Typography>
        </Grid>
        <Grid minWidth="50vw" item xs={12}>
          <Card raised>
            <CardContent>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  First Name:
                </Typography>
                <Typography variant="body1">
                  {location.state.firstName}
                </Typography>
              </Box>
            </CardContent>
            <CardContent>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  Last Name:
                </Typography>
                <Typography variant="body1">
                  {location.state.lastName}
                </Typography>
              </Box>
            </CardContent>
            <CardContent>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  Date of Birth:
                </Typography>
                <Typography variant="body1">{location.state.dob}</Typography>
              </Box>
            </CardContent>
            <CardContent>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  Phone Number:
                </Typography>
                <Typography variant="body1">
                  {location.state.phoneNumber}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Button
            onClick={goToForm}
            variant="contained"
            style={{ minWidth: "30vw", textAlign: "center" }}
          >
            Start New Form
          </Button>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
