import axios from "axios";
import { API_SERVER_URL } from "../../app/constants";

type Payer = {
    payer_id: string,
    payer_name: string,
}

type PayerSearchResult = {
  payers: Payer[];
}

export const queryPayers = (searchTerm: string) => {
  return axios.get<PayerSearchResult>(
    `${API_SERVER_URL}/insurance/search_insurance_by_name/${searchTerm}`
  );
};
