import { API_SERVER_URL } from "../../app/constants";

export function requestTotalCareOnboarding(phone_number) {
  return fetch(`${API_SERVER_URL}/cardiac_care/request_onboarding_link`, {
    method: "post",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      phone_number: phone_number,
    }),
  });
}
