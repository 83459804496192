import axios from "axios";
import { API_SERVER_URL } from "../../app/constants";

export const validateAndCreatePatient = (
  first_name: string,
  last_name: string,
  date_of_birth: string,
  site_id: number,
  prescriber_id: number,
  phone_number: string,
  gender: string,
  email: string,
  first_test: string,
  commercial_insurance_company: string,
  commercial_policy_number: string,
  payer_id: string,
  conditions: string,
  address_line1: string,
  address_line2: string,
  city_locality: string,
  state_province: string,
  postal_code: string,
  override_insurance_eligibility_check: boolean,
  create_patient: boolean,
  enroll_patient: boolean,
  complete_insurance_bypass: boolean,
  is_self_pay: boolean,
  bp_size: string
) => {
  const headers = {};
  return axios.post(
    `${API_SERVER_URL}/patients/${site_id}/create_patient`,
    {
      first_name: first_name,
      last_name: last_name,
      date_of_birth: date_of_birth,
      prescriber_id: prescriber_id,
      phone_number: phone_number,
      gender: gender,
      email: email,
      first_test: first_test,
      commercial_insurance_company: commercial_insurance_company,
      commercial_policy_number: commercial_policy_number,
      payer_id: payer_id,
      conditions: conditions,
      address_line1: address_line1,
      address_line2: address_line2,
      city_locality: city_locality,
      state_province: state_province,
      postal_code: postal_code,
      override_insurance_eligibility_check:
        override_insurance_eligibility_check,
      create_patient: create_patient,
      enroll_patient: enroll_patient,
      complete_insurance_bypass: complete_insurance_bypass,
      is_self_pay: is_self_pay,
      bp_size: bp_size,
    },
    {
      headers,
    }
  );
};

export const validateAndUpdatePatient = (
  patient_id: number,
  first_name: string,
  last_name: string,
  date_of_birth: string,
  site_id: number,
  prescriber_id: number,
  phone_number: string,
  gender: string,
  email: string,
  commercial_insurance_company: string,
  commercial_policy_number: string,
  payer_id: string,
  conditions: string,
  address_line1: string,
  address_line2: string,
  city_locality: string,
  state_province: string,
  postal_code: string,
  complete_insurance_bypass: boolean,
  is_self_pay: boolean
) => {
  const headers = {};
  return axios.post(
    `${API_SERVER_URL}/patients/${site_id}/update_patient`,
    {
      patient_id: patient_id,
      first_name: first_name,
      last_name: last_name,
      date_of_birth: date_of_birth,
      prescriber_id: prescriber_id,
      phone_number: phone_number,
      gender: gender,
      email: email,
      commercial_insurance_company: commercial_insurance_company,
      commercial_policy_number: commercial_policy_number,
      conditions: conditions,
      payer_id: payer_id,
      address_line1: address_line1,
      address_line2: address_line2,
      city_locality: city_locality,
      state_province: state_province,
      postal_code: postal_code,
      complete_insurance_bypass: complete_insurance_bypass,
      is_self_pay: is_self_pay,
    },
    {
      headers,
    }
  );
};
