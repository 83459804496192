import React, { useState } from "react";
import { getSetUpBottles } from "./API";
import moment from "moment";
import {
  View,
  ScrollView,
  Text,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { markAsOnboarded } from "./API";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import Slot1Image from "./assets_1.0/slot1.svg";
import Slot2Image from "./assets_1.0/slot2.svg";
import Slot3Image from "./assets_1.0/slot3.svg";
import Slot4Image from "./assets_1.0/slot4.svg";
import Slot5Image from "./assets_1.0/slot5.svg";
import Slot6Image from "./assets_1.0/slot6.svg";
import Slot7Image from "./assets_1.0/slot7.svg";
import Slot8Image from "./assets_1.0/slot8.svg";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../app/utils/useWindowDimensions";
import Log from "../../app/logging/Log.js";

function PillTrackSetUpMedications(props) {
  const [retrievedData, setRetrievedData] = useState(false);
  const [medicationNames, setMedicationNames] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [bottleNumbers, setBottleNumbers] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [slotNumbers, setSlotNumbers] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const { height, width } = useWindowDimensions();
  const [reminderTimes, setReminderTimes] = useState([]);

  const heightScrollView = Math.round(height * 0.68);
  const navigate = useNavigate();

  const addAnotherMedication = () => {
    navigate("/pilltrack/alignment");
  };

  const retrieveData = () => {
    getSetUpBottles(props.deviceString, props.token)
      .then((res) => {
        console.log(res);
        setReminderTimes(res["reminder_times"]);
        setMedicationNames(res["medication_names"]);
        setSchedule(res["schedule"]);
        setSlotNumbers(res["slot_numbers"]);
        setBottleNumbers(res["bottle_numbers"]);
      })
      .catch((error) => console.log(error));
  };

  const nextStep = () => {
    navigate("/pilltrack/alignment");
  };

  const finish = () => {
    markAsOnboarded(props.patientID, props.deviceString, props.token)
      .then((resp) => {
        if (resp === "OK") {
          navigate("/pilltrack/open");
        }
      })
      .catch((error) => {
        console.log("Failed to mark as onboarded", error);
      });
  };

  if (!retrievedData) {
    retrieveData();
    setRetrievedData(true);
  }

  const transformScheduleToArray = (scheduleName) => {
    switch (scheduleName) {
      case "TID":
        return ["DAILY_AM", "DAILY_PM", "AFTERNOON"];
      case "DAILY_AM":
        return ["DAILY_AM"];
      case "DAILY_PM":
        return ["DAILY_PM"];
      case "AFTERNOON":
        return ["AFTERNOON"];
      case "AM_PM":
        return ["DAILY_AM", "DAILY_PM"];
      case "AM_NOON":
        return ["DAILY_AM", "AFTERNOON"];
      case "NOON_EVENING":
        return ["DAILY_PM", "AFTERNOON"];
      default:
        return ["DAILY_AM"];
    }
  };

  const TimeLabel = ({ index, schedule }) => {
    const reminder_time = reminderTimes[index][schedule];
    const standard_reminder_time = moment(reminder_time, "HH:mm:ss").format(
      "h:mm A"
    );
    return (
      <View style={[styles.label, { backgroundColor: "#FDF5F5" }]}>
        <Text
          style={[styles.subtext, { color: "#E85B81" }]}
        >{`${standard_reminder_time}`}</Text>
      </View>
    );
  };

  const getSlotImage = (slotNumber) => {
    switch (slotNumber) {
      case 0:
        return Slot1Image;
      case 1:
        return Slot2Image;
      case 2:
        return Slot3Image;
      case 3:
        return Slot4Image;
      case 4:
        return Slot5Image;
      case 5:
        return Slot6Image;
      case 6:
        return Slot7Image;
      case 7:
        return Slot8Image;
      default:
        return null;
    }
  };

  const MedicationList = () => {
    return (
      <View style={[{ paddingTop: 12 }]}>
        {medicationNames.map((medication_name, index) => (
          <TouchableOpacity
            key={index}
            style={[{ alignItems: "center" }]}
            onPress={() => {
              navigate("/pilltrack/medication", {
                state: {
                  medicationName: medication_name,
                  bottleNumber: bottleNumbers[index],
                  schedules: transformScheduleToArray(schedule[index]),
                  edit: true,
                },
              });
            }}
          >
            <View style={styles.card}>
              <View style={[styles.row]}>
                <SlotImage src={getSlotImage(slotNumbers[index])}></SlotImage>
                <Text style={styles.medication_name}>{medication_name}</Text>
                <View style={[styles.row, styles.arrow]}>
                  <View style={[styles.row]}>
                    {schedule[index] === "BI_DAILY" ? (
                      <>
                        <TimeLabel index={index} schedule={"DAILY_AM"} />
                        <TimeLabel index={index} schedule={"DAILY_PM"} />
                      </>
                    ) : null}
                    {schedule[index] === "DAILY_PM" ? (
                      <TimeLabel index={index} schedule={"DAILY_PM"} />
                    ) : null}
                    {schedule[index] === "DAILY_AM" ? (
                      <TimeLabel index={index} schedule={"DAILY_AM"} />
                    ) : null}
                    {schedule[index] === "AFTERNOON" ? (
                      <TimeLabel index={index} schedule={"AFTERNOON"} />
                    ) : null}
                    {schedule[index] === "TID" ? (
                      <>
                        <TimeLabel index={index} schedule={"DAILY_AM"} />
                        <TimeLabel index={index} schedule={"AFTERNOON"} />
                        <TimeLabel index={index} schedule={"DAILY_PM"} />
                      </>
                    ) : null}
                    {schedule[index] === "AM_NOON" ? (
                      <>
                        <TimeLabel index={index} schedule={"DAILY_AM"} />
                        <TimeLabel index={index} schedule={"AFTERNOON"} />
                      </>
                    ) : null}
                    {schedule[index] === "NOON_EVENING" ? (
                      <>
                        <TimeLabel index={index} schedule={"AFTERNOON"} />
                        <TimeLabel index={index} schedule={"DAILY_PM"} />
                      </>
                    ) : null}
                  </View>
                  <IconButton
                    onClick={() => {
                      navigate("/pilltrack/medication", {
                        state: {
                          medicationName: medication_name,
                          bottleNumber: bottleNumbers[index],
                          schedules: transformScheduleToArray(schedule[index]),
                          edit: true,
                        },
                      });
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </View>
              </View>
            </View>
          </TouchableOpacity>
        ))}
      </View>
    );
  };

  return (
    <Log
      logImpression={true}
      patientID={props.patientID}
      action="view"
      tag="pilltrack_medication_list"
    >
      <View style={styles.innerContainer}>
        <Text style={styles.title}>Success!</Text>
        <Text style={styles.subtitle}>Review Your Medications so far</Text>
        <View style={{ height: heightScrollView }}>
          <ScrollView style={{ flexGrow: 0 }}>
            <MedicationList />
          </ScrollView>
        </View>

        <View
          style={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            paddingTop: "15px",
            backgroundColor: "white",
          }}
        >
          {medicationNames.length < 8 && (
            <TouchableOpacity onPress={addAnotherMedication}>
              <Center>
                <StyledButton variant="primary" size="lg" onClick={nextStep}>
                  <StyledH4>Set Up Another Medication</StyledH4>
                </StyledButton>
              </Center>
            </TouchableOpacity>
          )}
          <TouchableOpacity onPress={finish}>
            <Center>
              {medicationNames.length < 8 ? (
                <StyledWhiteButton variant="primary" size="lg" onClick={finish}>
                  <StyledPinkH4>I'm All Done!</StyledPinkH4>
                </StyledWhiteButton>
              ) : (
                <StyledLastButton variant="primary" size="lg" onClick={finish}>
                  <StyledH4>I'm All Done!</StyledH4>
                </StyledLastButton>
              )}
            </Center>
          </TouchableOpacity>
        </View>
      </View>
    </Log>
  );
}

const styles = StyleSheet.create({
  backButton: {
    fontWeight: "bold",
    color: "#FF6182",
    marginBottom: 12,
  },
  scrollContainer: {
    maxHeight: "50%",
  },
  innerContainer: {
    // width: "85%",
    height: "100vh",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: 16,
    paddingBottom: 64,
  },
  medication_name: {
    fontSize: 20,
    fontFamily: "Manrope",
    color: "#453D3F",
  },
  buttonText: {
    fontSize: 20,
    fontFamily: "Manrope",
    color: "white",
  },
  card: {
    width: "85%",
    backgroundColor: "white",
    borderRadius: 12,
    shadowColor: "#666699",
    shadowOpacity: 0.3,
    shadowRadius: 8,
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 12,
    marginBottom: 12,
    elevation: 4,
  },
  cardButton: {
    backgroundColor: "#e85b81",
    borderRadius: 12,
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 24,
    elevation: 4,
  },
  pilltrack: {
    backgroundColor: "white",
    borderRadius: 16,
    shadowColor: "grey",
    shadowOpacity: 0.5,
    shadowRadius: 8,
    paddingTop: 18,
    paddingBottom: 18,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 16,
  },
  pillbottle: {
    backgroundColor: "#E85B81",
    borderRadius: 1000,
    height: 60,
    width: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  buttonRow: {
    textAlign: "center",
  },
  title: {
    fontSize: 24,
    fontFamily: "Manrope",
    color: "#666666",
    textAlign: "center",
    paddingTop: 20,
  },
  subtitle: {
    fontSize: 20,
    fontFamily: "Manrope",
    color: "#453D3F",
    textAlign: "center",
    paddingBottom: 10,
  },
  subtext: {
    fontSize: 14,
    color: "#453D3F",
    fontFamily: "Manrope",
  },
  arrow: {
    position: "absolute",
    right: 0,
  },
  label: {
    borderRadius: 100,
    padding: 8,
    paddingLeft: 12,
    paddingRight: 12,
    alignItems: "center",
    marginRight: 6,
  },
  textInput: {
    backgroundColor: "#F5F5F5",
    paddingLeft: 12,
    paddingTop: 16,
    paddingBottom: 16,
    borderRadius: 8,
    fontSize: 24,
    fontFamily: "Manrope",
    color: "#808080",
    marginTop: 24,
  },
  checkboxContainer: {
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  checkbox: {
    padding: 8,
    borderColor: "#E85B81",
    height: 32,
    width: 32,
    color: "#E85B81",
  },
  primaryButton: {
    backgroundColor: "#E85B81",
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 32,
    paddingRight: 32,
    borderRadius: 8,
  },
  primaryButtonText: {
    color: "white",
    textAlign: "center",
    fontSize: 24,
    fontFamily: "Manrope_400Regular",
  },
  secondaryButton: {
    backgroundColor: "white",
    borderColor: "#E85B81",
    borderWidth: 1,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 32,
    paddingRight: 32,
    borderRadius: 8,
  },
  secondaryButtonText: {
    color: "#E85B81",
    textAlign: "center",
    fontSize: 24,
    fontFamily: "Manrope",
  },
  pillbottleText: {
    textAlign: "center",
    fontSize: 24,
    fontFamily: "Manrope",
  },
  textContainer: {
    marginTop: 50,
    textAlign: "center",
    width: "85%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  verticalAlign: {
    justifyContent: "center",
    flex: 1,
    flexDirection: "column",
  },
  instructions: {
    fontSize: 24,
    fontFamily: "Manrope",
    color: "#808080",
  },
});

const StyledButton = styled(Button)`
  background: #e85b81;
  :disabled {
    background: #d1d1d1;
    border-color: #d1d1d1;
  }
  :focus {
    background: #e85b81;
    border-color: #e85b81;
  }
  :active {
    background: #e85b81;
    border-color: #e85b81;
  }
  :hover {
    background: #e85b81;
    border-color: #e85b81;
  }
  border-radius: 10px;
  border-color: #e85b81;
  width: 85%;
  text-align: center;
`;

const StyledWhiteButton = styled(Button)`
  background: #ffffff;
  :disabled {
    background: #d1d1d1;
    border-color: #d1d1d1;
  }
  :focus {
    background: #ffffff;
    border-color: #e85b81;
  }
  :active {
    background: #ffffff;
    border-color: #e85b81;
  }
  :hover {
    background: #ffffff;
    border-color: #e85b81;
  }
  border-radius: 10px;
  border-color: #e85b81;
  width: 85%;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const StyledLastButton = styled(Button)`
  background: #e85b81;
  :disabled {
    background: #d1d1d1;
    border-color: #d1d1d1;
  }
  :focus {
    background: #e85b81;
    border-color: #e85b81;
  }
  :active {
    background: #e85b81;
    border-color: #e85b81;
  }
  :hover {
    background: #e85b81;
    border-color: #e85b81;
  }
  border-radius: 10px;
  border-color: #e85b81;
  width: 85%;
  text-align: center;
  margin-bottom: 40px;
`;

const Center = styled.div`
  text-align: center;
`;

const StyledH4 = styled.h4`
  font-family: "Manrope", sans-serif;
  text-align: center;
  padding-top: 5px;
`;

const StyledPinkH4 = styled.h4`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #e85b81;
`;

const SlotImage = styled.img`
  margin-right: 8px;
`;

export default PillTrackSetUpMedications;
