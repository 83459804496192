import React, { Component } from "react";
import bottle_photo from "./bottle.png";
import { BottleStatus } from "./Bottles";
import { trackEvent } from "../../app/utils/logging.ts";

class Success extends Component {
  componentDidMount() {
    trackEvent(
      "click",
      this.props.patient_id,
      "gem_onboarding_set_up_medication",
      this.props.first_name + " " + this.props.last_name
    );
  }

  render() {
    let bottle_placed_back = this.props.bottles_present[this.props.tray];
    let num_bottles_present = this.props.bottles_present.reduce(
      (a, b) => a + b,
      0
    );

    return (
      <div class="form-container gem-container my-5">
        {!bottle_placed_back && num_bottles_present >= 3 && (
          <div>
            <div class="mt-2 p-4">
              <h1> Success! </h1>
            </div>
            <div class="px-5">
              <p>
                {" "}
                You entered <b>{this.props.medicationName}</b> for this bottle.{" "}
              </p>
            </div>
            <div class="px-5">
              <div>
                <img
                  width="100px"
                  height="212px"
                  alt="Bottle"
                  src={bottle_photo}
                />
                <p class="bottle-label">
                  {" "}
                  <b> {this.props.medicationName} </b>{" "}
                </p>
              </div>
              {num_bottles_present < 4 ? (
                <div>
                  <p>
                    {" "}
                    <b>
                      {" "}
                      Please label your bottle containing{" "}
                      {this.props.medicationName} with the provided pen,
                      including dosage. For example, Aspirin : (1){" "}
                    </b>{" "}
                  </p>
                  <p> Then return your bottle back to the correct slot. </p>
                </div>
              ) : (
                <div class="pt-3 pb-4">
                  <button
                    onClick={this.props.nextStep}
                    type="submit"
                    class="btn light-btn"
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        {((bottle_placed_back && num_bottles_present < 4) ||
          num_bottles_present < 3) && (
          <div class="pt-3 px-5">
            <BottleStatus bottles_present={this.props.bottles_present} />
            <p class="pt-3">
              {" "}
              <b>
                {" "}
                Some bottles are missing. Please insert all 4 included bottles
                to continue.
              </b>{" "}
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default Success;
