import { API_SERVER_URL } from "../../app/constants";
import axios from "axios";

export function getLastBottle(device_string, token) {
  const headers = {};
  headers["Authorization"] = `Bearer ${token}`;
  const bottle_number = axios
    .get(
      `${API_SERVER_URL}/pilltrack_devices/${device_string}/get_last_bottle_taken_out`,
      {
        headers,
      }
    )
    .then((response) => response.data);
  return bottle_number;
}

export function getLastPutBackBottle(device_string, token) {
  const headers = {};
  headers["Authorization"] = `Bearer ${token}`;
  const bottle_number = axios
    .get(
      `${API_SERVER_URL}/pilltrack_devices/${device_string}/get_last_bottle_put_back`,
      {
        headers,
      }
    )
    .then((response) => response.data);
  return bottle_number;
}

export function isOnline(device_string, token) {
  const headers = {};
  headers["Authorization"] = `Bearer ${token}`;
  const online = axios
    .get(
      `${API_SERVER_URL}/pilltrack_devices/${device_string}/is_online`,
      {
        headers,
      }
    )
    .then((response) => response.data);
  return online;
}

export function getSetUpBottles(device_string, token) {
  const headers = {};
  headers["Authorization"] = `Bearer ${token}`;
  const resp = axios
    .get(
      `${API_SERVER_URL}/pilltrack_devices/${device_string}/get_set_up_bottles`,
      {
        headers,
      }
    )
    .then((response) => response.data);
  return resp;
}

export function submitMedication(
  device_string,
  token,
  bottle,
  medication_name,
  schedule,
  text_reminder_time
) {
  const headers = {};
  headers["Authorization"] = `Bearer ${token}`;
  const resp = axios
    .post(
      `${API_SERVER_URL}/pilltrack_devices/${device_string}/set_pilltrack_bottle_onboarding`,
      {
        bottle: bottle,
        medication_name: medication_name,
        schedule: schedule,
        text_reminder_time: text_reminder_time,
      },
      {
        headers,
      }
    )
    .then((response) => response.data);
  return resp;
}

export function markAsOnboarded(patient_id, device_string, token) {
  const headers = {};
  headers["Authorization"] = `Bearer ${token}`;
  const resp = axios
    .post(
      `${API_SERVER_URL}/pilltrack_devices/set_pilltrack_onboarding_complete`,
      {
        patient_id: patient_id,
        device_string: device_string,
      },
      {
        headers,
      }
    )
    .then((response) => response.data);
  return resp;
}

export function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
      return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
  }

  return "unknown";
}