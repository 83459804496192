import { useState, useEffect } from "react";
import styled from "styled-components";

const PillTrackEnterMedicationError = ({
  timePickerOptions,
  value,
  reminderTimes,
  checked,
  setInvalidOptions,
  invalidOptions,
}) => {
  const [isTimeValid, setIsTimeValid] = useState(true);

  useEffect(
    () => {
      if (checked.includes(value)) {
        if (
          // this if checks if the time is invalid
          reminderTimes.get(value).format("HH") <
            timePickerOptions[value].min.format("HH") ||
          reminderTimes.get(value).format("HH") >
            (timePickerOptions[value].max.format("HH") === "00"
              ? 24
              : timePickerOptions[value].max.format("HH"))
        ) {
          // this is what happens when invalid time
          setIsTimeValid(false);
          if (invalidOptions && !invalidOptions.includes(value)) {
            // If new section not in the state
            setInvalidOptions((prevState) => [...prevState, value]);
          }
        } else {
          // checked but valid
          setIsTimeValid(true);

          setInvalidOptions((prevState) =>
            prevState.filter((ele) => {
              return ele !== value;
            })
          );
        }
      } else {
        // given time period is not checked
        setInvalidOptions((prevState) => {
          prevState.filter((time) => {
            return checked.includes(time);
          });
        });
      }
    }, // eslint-disable-next-line
    [value, reminderTimes, checked]
  );

  return (
    !isTimeValid && (
      <ErrorText>
        {`Invalid time: Enter a time between ${timePickerOptions[
          value
        ].min.format("h A")} and ${timePickerOptions[value].max.format("h A")}`}
      </ErrorText>
    )
  );
};

const ErrorText = styled.h6`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #ff0000;
  padding-left: 48px;
  padding-right: 48px;
`;

export default PillTrackEnterMedicationError;
