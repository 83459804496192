import { configureStore } from '@reduxjs/toolkit';
import loginReducer from '../features/login/LoginSlice';
import patientInfoReducer from '../features/patientInfo/PatientInfoSlice'

export default configureStore({
  reducer: {
    login: loginReducer,
    patientInfo: patientInfoReducer
  },
});
