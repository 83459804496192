import React from "react";

export default function PatientPayUnauthorized() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <div style={{ marginBottom: "30px" }}>
          <h1>Oops...</h1>
        </div>
        <div style={{ marginLeft: "50px", marginRight: "50px" }}>
          <p>
            It seems like you have accessed the Patient Pay Portal without
            signing in through a link texted to you. To complete your payment,
            please access the Patient Pay Portal through the link that was
            texted to you.
          </p>
        </div>
      </div>
    </div>
  );
}
