import React from "react";
import { useState, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { createTheme } from "@mui/material/styles";
import { themeOptions } from "./ThemeOptionsPatientEnrollment";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  ThemeProvider,
  Select,
  FormControl,
  FormLabel,
  MenuItem,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Checkbox,
  FormGroup,
  InputLabel,
  FormHelperText,
  Typography,
  Alert,
  CardMedia,
  Autocomplete,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import jwt from "jsonwebtoken";
import InputMask from "react-input-mask";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { PUBLIC_KEY } from "../../app/constants";
import enrollmentBanner from "./assets/PatientEnrollmentBanner.png";
import updateBanner from "./assets/PatientUpdateBanner.png";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { patientSchema } from "./PatientValidationSchema.ts";
import {
  validateAndCreatePatient,
  validateAndUpdatePatient,
} from "./PatientEnrollmentAPI.ts";
import { queryMostCommonPayers } from "./MostCommonPayersAPI";
import { backendValidationErrorMessages } from "./BackendValidationConstants";
import { mapShipGateStatusToText } from "./ShipGatingResponse";
import BeatLoader from "react-spinners/BeatLoader";
import {
  providerPrescription,
  patientConsent,
  bypassInsuranceCheck,
} from "./LegalText.js";
import throttle from "lodash/throttle";
import { queryPayers } from "./PayerSearchAPI";

const SHOULD_CREATE_PATIENT = true; // For now until this is complete

export default function PatientEnrollment(props) {
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState("");
  const [prescriberID, setPrescriberID] = useState(0);
  const [orgID, setOrgID] = useState(0);
  const [siteID, setSiteID] = useState(0);
  const [prescriberFirstName, setPrescriberFirstName] = useState("");
  const [prescriberLastName, setPrescriberLastName] = useState("");
  const [prescriberTitle, setPrescriberTitle] = useState("");
  const [prescriberEmail, setPrescriberEmail] = useState("");
  const [activeInsurancePolicyFound, setActiveInsurancePolicyFound] =
    useState(true);
  const [insuranceIneligiblityReason, setInsuranceIneligiblityReason] =
    useState("");
  const [athelasInsurancePolicyCheck, setAthelasInsurancePolicyCheck] =
    useState(true);
  const [shipGateStatus, setShipGateStatus] = useState("");
  const [backendValidationErrors, setBackEndValidationErrors] = useState([]);
  const [formData, setFormData] = useState({});
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const [isEnterprise, setIsEnterprise] = useState(true);
  const [isOnAthelasBilling, setIsOnAthelasBilling] = useState(false);
  const [currentInsuranceNameQuery, setCurrentInsuranceNameQuery] =
    useState("");
  const [defaultPayers, setDefaultPayers] = useState([]);
  const [payerSearchResult, setPayerSearchResult] = useState([]);

  // For updating patient
  const [patientId, setPatientId] = useState(0);
  const [prescriberOptions, setPrescriberOptions] = useState([]);
  const [patientUpdateSuccess, setPatientUpdateSuccess] = useState(false);
  const [patientUpdateError, setPatientUpdateError] = useState(false);

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    watch,
    setFocus,
    getValues,
    setValue,
    reset,
    resetField,
    // eslint-disable-next-line no-unused-vars
    formState: { errors, isSubmitted, isSubmitSuccessful },
  } = useForm({ resolver: yupResolver(patientSchema) });

  useEffect(() => {
    try {
      const token = searchParams.get("token");
      const decodedToken = jwt.verify(token, PUBLIC_KEY, {
        algorithms: ["RS256"],
      });
      setToken(token);
      setSiteID(decodedToken.site_id);
      setIsEnterprise(decodedToken.is_enterprise);
      setIsOnAthelasBilling(decodedToken.on_athelas_billing);
      queryMostCommonPayers(token).then((response) => {
        setDefaultPayers(response.data.payers);
        setPayerSearchResult(response.data.payers);
      });

      if (props.update) {
        setPatientId(decodedToken.patient_id);
        setPrescriberOptions(decodedToken.prescriber_options);
        setPrescriberFirstName(
          decodedToken.prescriber_options[decodedToken.current_prescriber_index]
            .first_name
        );
        setPrescriberLastName(
          decodedToken.prescriber_options[decodedToken.current_prescriber_index]
            .last_name
        );
        setPrescriberTitle(
          decodedToken.prescriber_options[decodedToken.current_prescriber_index]
            .title
        );
        reset({
          prescriber:
            decodedToken.prescriber_options[
              decodedToken.current_prescriber_index
            ].id,
          first_name: decodedToken.patient_first_name,
          last_name: decodedToken.patient_last_name,
          date_of_birth: decodedToken.patient_dob,
          gender: decodedToken.patient_gender,
          phone_number: decodedToken.patient_phone_number,
          email: Boolean(decodedToken.patient_email)
            ? decodedToken.patient_email
            : undefined,
          address_line1: decodedToken.patient_address_line1,
          address_line2: Boolean(decodedToken.patient_address_line2)
            ? decodedToken.patient_address_line2
            : "",
          city_locality: decodedToken.patient_city_locality,
          state_province: decodedToken.patient_state_province,
          postal_code: decodedToken.patient_postal_code,
          commercial_insurance_company:
            decodedToken.patient_insurance_company.payer_name === "" &&
            decodedToken.patient_insurance_company.payer_id === ""
              ? null
              : decodedToken.patient_insurance_company,
          commercial_policy_number: decodedToken.patient_policy_number,
          is_self_pay: decodedToken.patient_is_self_pay,
          complete_insurance_bypass: false,
          is_update: true,
          conditions: decodedToken.patient_icd10_code,
        });
      } else {
        setPrescriberID(decodedToken.prescriber_id);
        setOrgID(decodedToken.org_id);
        setPrescriberEmail(decodedToken.email);
        setPrescriberFirstName(decodedToken.first_name);
        setPrescriberLastName(decodedToken.last_name);
        setPrescriberTitle(decodedToken.title);
      }
    } catch (e) {
      console.log("No valid token");
    }
  }, [searchParams, props.update, reset]);

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);
    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);

  const searchPayers = (query, defaultPayers) => {
    const trimmedQuery = query.trim();
    if (trimmedQuery === "") {
      setPayerSearchResult(defaultPayers);
    } else {
      queryPayers(trimmedQuery).then((response) => {
        const currVal = getValues("commercial_insurance_company");
        if (currVal !== "" && currVal) response.data.payers.unshift(currVal);

        const dedupedPayers = [
          ...new Map(
            response.data.payers.map((v) => [
              `${v.payer_name}-${v.payer_id}`,
              v,
            ])
          ).values(),
        ];
        setPayerSearchResult(dedupedPayers);
      });
    }
  };

  const searchPayersThrottled = useMemo(() => throttle(searchPayers, 500), []);

  const watchFirstName = watch("first_name");
  const watchLastName = watch("last_name");
  const watchEnrollPatient = watch("enroll_patient");
  const watchCompleteInsuranceBypass = watch("complete_insurance_bypass");
  const watchFirstTest = watch("first_test");

  useEffect(() => {
    if (watchCompleteInsuranceBypass) {
      setCurrentInsuranceNameQuery("");
      setPayerSearchResult([]);
      setValue("commercial_insurance_company", null);
      setValue("commercial_policy_number", "");
    } else {
      resetField("commercial_insurance_company");
      resetField("commercial_policy_number");
    }
  }, [watchCompleteInsuranceBypass, setValue, resetField]);

  const onSubmit = (data) => {
    data.site_id = siteID;
    data.date_of_birth = moment(data.date_of_birth).format("YYYY-MM-DD");
    data.address_line2 = Boolean(data.address_line2) ? data.address_line2 : "";
    data.email = Boolean(data.email) ? data.email : undefined;

    setIsLoadingResponse(true);
    if (!props.update) {
      data.prescriber_id = prescriberID;
      data.prescriber_email = prescriberEmail;
      data.org_id = orgID;
      data.prescriber_first_name = prescriberFirstName;
      data.prescriber_last_name = prescriberLastName;
      data.prescriber_title = prescriberTitle;
      setFormData(data);

      validateAndCreatePatient(
        data.first_name,
        data.last_name,
        data.date_of_birth,
        siteID,
        prescriberID,
        data.phone_number,
        data.gender,
        data.email,
        data.first_test,
        data.commercial_insurance_company?.payer_name ?? "",
        data.commercial_policy_number,
        data.commercial_insurance_company?.payer_id ?? "",
        data.conditions,
        data.address_line1,
        data.address_line2,
        data.city_locality,
        data.state_province,
        data.postal_code,
        data.override_insurance_eligibility_check == null
          ? false
          : data.override_insurance_eligibility_check,
        SHOULD_CREATE_PATIENT,
        data.enroll_patient,
        data.complete_insurance_bypass,
        data.is_self_pay,
        data.bp_size
      )
        .then((resp) => {
          setIsLoadingResponse(false);
          setActiveInsurancePolicyFound(
            resp.data.active_insurance_policy_found
          );
          setAthelasInsurancePolicyCheck(
            resp.data.does_insurance_policy_pass_athelas_billing_check
          );
          setShipGateStatus(
            resp.data.insurance_company_rpm_billing_payor_record
          );
          setInsuranceIneligiblityReason(
            resp.data.insurance_policy_athelas_billing_ineligibility_reason
          );
          setBackEndValidationErrors(
            resp.data.patient_information_validation_errors
          );
          if (resp.data.patient_created_started_successfully) {
            // redirect to confirmation page
            navigate("/patient_enrollment/confirmation", {
              state: {
                firstName: data.first_name,
                lastName: data.last_name,
                dob: data.date_of_birth,
                phoneNumber: data.phone_number,
                address: data.address_line1,
                enrolled: data.enroll_patient,
                token: token,
              },
            });
          }
        })
        .catch((err) => {
          setIsLoadingResponse(false);
        });
    } else {
      setPatientUpdateSuccess(false);
      setPatientUpdateError(false);
      setFormData(data);
      validateAndUpdatePatient(
        patientId,
        data.first_name,
        data.last_name,
        data.date_of_birth,
        siteID,
        data.prescriber,
        data.phone_number,
        data.gender,
        data.email,
        data.commercial_insurance_company?.payer_name ?? "",
        data.commercial_policy_number,
        data.commercial_insurance_company?.payer_id ?? "",
        data.conditions,
        data.address_line1,
        data.address_line2,
        data.city_locality,
        data.state_province,
        data.postal_code,
        data.complete_insurance_bypass,
        data.is_self_pay
      )
        .then((resp) => {
          setIsLoadingResponse(false);
          setActiveInsurancePolicyFound(
            resp.data.active_insurance_policy_found
          );
          setBackEndValidationErrors(
            resp.data.patient_information_validation_errors
          );
          if (resp.data.patient_updated_successfully) {
            setPatientUpdateSuccess(true);
          } else {
            setPatientUpdateError(true);
          }
        })
        .catch((err) => {
          setIsLoadingResponse(false);
          setPatientUpdateError(true);
        });
    }
  };

  const helperTextInsuranceCompanyName = () => {
    if (!athelasInsurancePolicyCheck && backendValidationErrors.length === 0) {
      return insuranceIneligiblityReason;
    } else if (!!errors?.commercial_insurance_company?.message) {
      return errors?.commercial_insurance_company?.message;
    } else {
      return null;
    }
  };

  const helperTextInsurancePolicyNumber = () => {
    if (!activeInsurancePolicyFound && backendValidationErrors.length === 0) {
      return "No active plan found under this policy number";
    } else if (!!errors?.commercial_policy_number?.message) {
      return errors?.commercial_policy_number?.message;
    } else {
      return null;
    }
  };

  const helperTextFirstName = () => {
    if (
      backendValidationErrors.includes(
        backendValidationErrorMessages.DUPLICATE_PATIENT
      )
    ) {
      return "Patient already exists";
    } else if (!!errors?.first_name?.message) {
      return errors?.first_name?.message;
    }
    return null;
  };

  const helperTextLastName = () => {
    if (
      backendValidationErrors.includes(
        backendValidationErrorMessages.DUPLICATE_PATIENT
      )
    ) {
      return "Patient already exists";
    } else if (!!errors?.last_name?.message) {
      return errors?.last_name?.message;
    }
    return null;
  };

  const helperTextDOB = () => {
    if (
      backendValidationErrors.includes(
        backendValidationErrorMessages.DUPLICATE_PATIENT
      )
    ) {
      return "Patient already exists";
    } else if (!!errors?.date_of_birth?.message) {
      return errors?.date_of_birth?.message;
    }
    return null;
  };

  const helperTextPhoneNumber = () => {
    if (
      backendValidationErrors.includes(
        backendValidationErrorMessages.DUPLICATE_PHONE_NUMBER
      )
    ) {
      return "Duplicate phone number found";
    } else if (!!errors?.phone_number?.message) {
      return errors?.phone_number?.message;
    }
    return null;
  };

  const helperTextAddressVerification = () => {
    if (
      backendValidationErrors.includes(
        backendValidationErrorMessages.ADDRESS_NOT_VERIFIED
      )
    ) {
      return "Address could not be verified, please make sure street, city, state, and zip code are entered correctly.";
    } else if (!!errors?.address_line1?.message) {
      return errors?.address_line1?.message;
    }
    return null;
  };

  const helperTextConditions = () => {
    if (
      backendValidationErrors.includes(
        backendValidationErrorMessages.DIAGNOSES_MISSING
      ) ||
      backendValidationErrors.includes(
        backendValidationErrorMessages.NO_VALID_DIAGNOSES
      )
    ) {
      return "Please enter one valid ICD 10 code (eg. I10)";
    } else if (
      backendValidationErrors.includes(
        backendValidationErrorMessages.MULTIPLE_DIAGNOSES
      )
    ) {
      return "Please enter only one valid icd10 code";
    } else if (!!errors?.conditions?.message) {
      return errors?.conditions?.message;
    } else {
      return null;
    }
  };

  const onError = (errors, e) => console.log("onError", errors, e);
  const theme = createTheme(themeOptions);

  const complete_insurance_bypass_tooltip_text =
    "By checking this box, you will forgo validating that the patient's insurance policy is currently active and will reimburse for Remote Patient Monitoring codes.";

  return (
    <ThemeProvider theme={theme}>
      <CardMedia
        sx={{ width: 1, height: 1 / 5 }}
        src={props.update ? updateBanner : enrollmentBanner}
        component="img"
      />
      <Box sx={{ mx: 8, mb: 8, mt: 2 }}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid
            style={{ minHeight: props.update ? "75vh" : "100vh" }}
            alignItems="center"
            justifyContent="flex-start"
            container
            direction="row"
            align="stretch"
            spacing={2}
          >
            <Grid item xs={12}>
              <Box sx={{ display: "flex" }}>
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  Hello, &nbsp;
                </Typography>
                <Typography variant="body1">
                  {prescriberTitle} {prescriberFirstName} {prescriberLastName}
                </Typography>
              </Box>
            </Grid>
            {props.update && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    Change Patient's Prescriber
                  </Typography>
                </Grid>
                <Grid item xs={6} md={4} container flexGrow>
                  <Controller
                    name="prescriber"
                    control={control}
                    defaultValue=""
                    render={({ field: { ref, ...field } }) => (
                      <FormControl
                        error={!!errors?.prescriber?.message}
                        //style={{ minWidth: 100 }}
                        sx={{ flex: 1 }}
                      >
                        <InputLabel id="prescriber-select-label">
                          Prescriber
                        </InputLabel>
                        <Select
                          id="prescriber-select"
                          labelId="prescriber-select-label"
                          label="Prescriber"
                          inputRef={ref}
                          className="Select"
                          sx={{ flex: 1 }}
                          {...field}
                        >
                          {prescriberOptions.map((prescriber, index) => (
                            <MenuItem key={index} value={prescriber.id}>
                              {prescriber.title} {prescriber.first_name}{" "}
                              {prescriber.last_name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {errors?.prescriber?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography variant="h5">01 Patient Info</Typography>
            </Grid>
            <Grid item xs={6} md={4} container flexGrow>
              <Controller
                name="first_name"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    label={"Patient First Name"}
                    inputRef={ref}
                    sx={{ flex: 1 }}
                    error={
                      !!errors?.first_name?.message ||
                      backendValidationErrors.includes(
                        backendValidationErrorMessages.DUPLICATE_PATIENT
                      )
                    }
                    helperText={helperTextFirstName()}
                    inputProps={{ maxLength: 40 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={4} container flexGrow>
              <Controller
                name="last_name"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    sx={{ flex: 1 }}
                    label={"Patient Last Name"}
                    inputRef={ref}
                    error={
                      !!errors?.last_name?.message ||
                      backendValidationErrors.includes(
                        backendValidationErrorMessages.DUPLICATE_PATIENT
                      )
                    }
                    helperText={helperTextLastName()}
                    inputProps={{ maxLength: 80 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={4} container flexGrow>
              <Controller
                name="date_of_birth"
                control={control}
                defaultValue={moment()}
                render={({ field: { ref, ...field } }) => (
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="Patient Date of Birth"
                      inputRef={ref}
                      renderInput={(params) => (
                        <TextField
                          //required={true}
                          sx={{ flex: 1 }}
                          {...params}
                          error={
                            !!errors?.date_of_birth?.message ||
                            backendValidationErrors.includes(
                              backendValidationErrorMessages.DUPLICATE_PATIENT
                            )
                          }
                          helperText={helperTextDOB()}
                        />
                      )}
                      {...field}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={6} md={4} container flexGrow>
              <Controller
                name="gender"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <FormControl
                    error={!!errors?.gender?.message}
                    //style={{ minWidth: 100 }}
                    sx={{ flex: 1 }}
                  >
                    <InputLabel id="sex-select-label">Gender</InputLabel>
                    <Select
                      id="gender-select"
                      labelId="gender-select-label"
                      label="Gender"
                      inputRef={ref}
                      className="Select"
                      sx={{ flex: 1 }}
                      {...field}
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                    </Select>
                    <FormHelperText>{errors?.gender?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={6} md={4} container flexGrow>
              <Controller
                name="phone_number"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <InputMask
                    mask="9999999999"
                    value={value}
                    onChange={onChange}
                  >
                    {(inputProps) => (
                      <TextField
                        error={
                          !!errors?.phone_number?.message ||
                          backendValidationErrors.includes(
                            backendValidationErrorMessages.DUPLICATE_PHONE_NUMBER
                          )
                        }
                        helperText={helperTextPhoneNumber()}
                        label="Patient Phone Number"
                        type="text"
                        inputRef={ref}
                        sx={{ flex: 1 }}
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                )}
              />
            </Grid>
            <Grid item xs={6} md={4} container flexGrow>
              <Controller
                name="email"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    label={"Patient Email"}
                    error={!!errors?.email?.message}
                    helperText={errors?.email?.message}
                    inputRef={ref}
                    sx={{ flex: 1 }}
                    inputProps={{ maxLength: 80 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="address_line1"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    label={"Patient Address Line 1"}
                    error={
                      !!errors?.address_line1?.message ||
                      backendValidationErrors.includes(
                        backendValidationErrorMessages.ADDRESS_NOT_VERIFIED
                      )
                    }
                    helperText={helperTextAddressVerification()}
                    inputRef={ref}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="address_line2"
                defaultValue=""
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    label={"Patient Address Line 2"}
                    fullWidth
                    inputRef={ref}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} container flexGrow>
              <Controller
                name="state_province"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    label={"State"}
                    sx={{ flex: 1 }}
                    error={!!errors?.state_province?.message}
                    helperText={errors?.state_province?.message}
                    inputRef={ref}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} container flexGrow>
              <Controller
                name="city_locality"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    label={"City"} //required={true}
                    error={!!errors?.city_locality?.message}
                    helperText={errors?.city_locality?.message}
                    inputRef={ref}
                    sx={{ flex: 1 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} container flexGrow>
              <Controller
                name="postal_code"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    label={"Zip Code"} //required={true}
                    error={!!errors?.postal_code?.message}
                    helperText={errors?.postal_code?.message}
                    inputRef={ref}
                    sx={{ flex: 1 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">02 Insurance Info</Typography>
            </Grid>

            {(isEnterprise || !isOnAthelasBilling) && siteID != 2195 && (
              <Grid item xs={6}>
                <Controller
                  name="complete_insurance_bypass"
                  control={control}
                  defaultValue={false}
                  render={({ field: { ref, ...field } }) => (
                    <Tooltip
                      title={complete_insurance_bypass_tooltip_text}
                      placement="top-start"
                    >
                      <FormGroup>
                        <FormControlLabel
                          sx={{ alignItems: "flex-start" }}
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value}
                              inputRef={ref}
                              sx={{
                                marginTop: -1,
                              }}
                            />
                          }
                          label="Bypass insurance check completely"
                        />
                      </FormGroup>
                    </Tooltip>
                  )}
                />
              </Grid>
            )}
            {(isEnterprise || !isOnAthelasBilling) && (
              <Grid item xs={6}>
                <Controller
                  name="is_self_pay"
                  control={control}
                  defaultValue={false}
                  render={({ field: { ref, ...field } }) => (
                    <FormGroup>
                      <FormControlLabel
                        sx={{ alignItems: "flex-start" }}
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            inputRef={ref}
                            sx={{
                              marginTop: -1,
                            }}
                          />
                        }
                        label="Patient will be self-pay"
                      />
                    </FormGroup>
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} md={8} container flexGrow>
              <Controller
                name="commercial_insurance_company"
                defaultValue=""
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Autocomplete
                    sx={{ flex: 1 }}
                    options={payerSearchResult}
                    getOptionLabel={(option) =>
                      option
                        ? `${option.payer_name} [Payer ID: ${option.payer_id}]`
                        : ""
                    }
                    renderOption={(props, option) => (
                      <Box
                        {...props}
                        key={`${option.payer_name}-${option.payer_id}`}
                      >
                        {option.payer_name} [Payer ID: {option.payer_id}]
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputRef={ref}
                        label="Patient Insurance Company"
                        helperText={helperTextInsuranceCompanyName()}
                        error={
                          ((!athelasInsurancePolicyCheck ||
                            !!errors?.commercial_insurance_company?.message) &&
                            backendValidationErrors.length === 0) ||
                          backendValidationErrors.includes(
                            backendValidationErrorMessages.PAYER_ID_NOT_SUPPORTED
                          )
                        }
                      />
                    )}
                    disabled={watchCompleteInsuranceBypass}
                    inputValue={currentInsuranceNameQuery}
                    onInputChange={(_, newInputValue) => {
                      setCurrentInsuranceNameQuery(newInputValue);
                      searchPayersThrottled(newInputValue, defaultPayers);
                    }}
                    filterOptions={(x) => x}
                    value={field.value}
                    defaultValue={""}
                    onChange={(_, newValue) => {
                      field.onChange(newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.payer_name === value.payer_name &&
                      option.payer_id === value.payer_id
                    }
                    openOnFocus={true}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={4} container flexGrow>
              <Controller
                name="commercial_policy_number"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={
                      (!activeInsurancePolicyFound ||
                        !!errors?.commercial_insurance_company?.message) &&
                      backendValidationErrors.length === 0
                    }
                    helperText={helperTextInsurancePolicyNumber()}
                    label={"Patient Insurance Policy Number"}
                    disabled={watchCompleteInsuranceBypass}
                    inputRef={ref}
                    sx={{ flex: 1 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="conditions"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={
                      backendValidationErrors.includes(
                        backendValidationErrorMessages.DIAGNOSES_MISSING
                      ) ||
                      backendValidationErrors.includes(
                        backendValidationErrorMessages.NO_VALID_DIAGNOSES
                      ) ||
                      !!errors?.conditions?.message ||
                      backendValidationErrors.includes(
                        backendValidationErrorMessages.MULTIPLE_DIAGNOSES
                      )
                    }
                    helperText={helperTextConditions()}
                    label={"ICD 10 Code (Please enter only one ICD10 code)"}
                    inputRef={ref}
                  />
                )}
              />
            </Grid>
            {!props.update && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h5">03 RPM Prescription</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="first_test"
                    control={control}
                    defaultValue="BLOOD_PRESSURE"
                    render={({ field }) => (
                      <FormControl error={!!errors?.first_test?.message}>
                        <FormLabel id="first-device-radio-group-label">
                          What remote monitoring device would you like to
                          prescribe to the patient?
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="first-device-radio-group-label"
                          defaultValue="BLOOD_PRESSURE"
                          name="first-device-radio-group"
                          {...field}
                        >
                          <FormControlLabel
                            value="BLOOD_PRESSURE"
                            control={<Radio />}
                            label="Blood pressure/Heart Rate Cuff"
                          />
                          <FormControlLabel
                            value="WEIGHT"
                            control={<Radio />}
                            label="Weight Scale"
                          />
                          <FormControlLabel
                            value="GLUCOSE"
                            control={<Radio />}
                            label="Glucose Monitor"
                          />
                        </RadioGroup>
                        <FormHelperText>
                          {errors?.first_test?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
                {watchFirstTest === "BLOOD_PRESSURE" && (
                  <Grid item xs={6} md={4} container flexGrow>
                    <Controller
                      name="bp_size"
                      control={control}
                      defaultValue="REGULAR"
                      render={({ field: { ref, ...field } }) => (
                        <FormControl sx={{ flex: 1 }}>
                          <InputLabel id="bp-size-select-label">
                            Blood Pressure/Heart Rate Cuff Size
                          </InputLabel>
                          <Select
                            id="bp-size-select"
                            labelId="bp-size-select-label"
                            label="Blood Pressure/Heart Rate Cuff Size"
                            inputRef={ref}
                            className="Select"
                            sx={{ flex: 1 }}
                            {...field}
                          >
                            <MenuItem value={"SMALL"}>Small (15-24cm)</MenuItem>
                            <MenuItem value={"REGULAR"}>
                              Regular (22-45cm)
                            </MenuItem>
                            <MenuItem value={"XL"}>XL (22-52cm)</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Controller
                    name="enroll_patient"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <FormGroup>
                        <FormControlLabel
                          sx={{ alignItems: "flex-start" }}
                          control={
                            <Checkbox
                              {...field}
                              sx={{
                                marginTop: -1,
                              }}
                            />
                          }
                          label={patientConsent(watchFirstName, watchLastName)}
                        />
                      </FormGroup>
                    )}
                  />
                </Grid>
              </>
            )}
            {(shipGateStatus === "BAD" || shipGateStatus === "UNSURE") && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  {mapShipGateStatusToText(
                    shipGateStatus,
                    formData.first_name,
                    formData.last_name,
                    isEnterprise
                  )}
                </Alert>
              </Grid>
            )}
            {((shipGateStatus === "BAD" && isEnterprise) ||
              shipGateStatus === "UNSURE") && (
              <Grid item xs={12}>
                <Controller
                  name="override_insurance_eligibility_check"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormGroup>
                      <FormControlLabel
                        sx={{ alignItems: "flex-start" }}
                        control={
                          <Checkbox
                            {...field}
                            sx={{
                              marginTop: -1,
                            }}
                          />
                        }
                        label={bypassInsuranceCheck()}
                      />
                    </FormGroup>
                  )}
                />
              </Grid>
            )}
            {isLoadingResponse && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <BeatLoader color="#E85B81" />
                <p>Verifying Information</p>
              </Grid>
            )}
            {patientUpdateSuccess && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <CheckCircleOutlineIcon
                  fontSize="large"
                  sx={{ color: "#E85B81" }}
                />
                <p>Patient updated successfully</p>
              </Grid>
            )}
            {patientUpdateError && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <ErrorOutlineIcon fontSize="large" sx={{ color: "#E85B81" }} />
                <p>
                  Sorry, an error occurred when trying to update the patient.
                  Please try again.
                </p>
              </Grid>
            )}
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                type="submit"
                disabled={isLoadingResponse}
                style={{ minWidth: "30vw", textAlign: "center" }}
              >
                {props.update
                  ? "Save Changes"
                  : watchEnrollPatient
                  ? "Add and Enroll Patient"
                  : "Add Patient"}
              </Button>
            </Grid>
            {!props.update && (
              <Grid item xs={12}>
                <Typography sx={{ color: "text.secondary" }}>
                  {providerPrescription(
                    prescriberFirstName,
                    prescriberLastName
                  )}
                </Typography>
              </Grid>
            )}
          </Grid>
        </form>
      </Box>
    </ThemeProvider>
  );
}
