import { API_SERVER_URL } from "../../app/constants";
import axios from "axios";

export const unsubscribeFromOnboardingEmails = (email) => {
  return axios.post(
    `${API_SERVER_URL}/email_campaign/unsubscribe_from_onboarding_email`,
    {
      email: email,
    }
  );
};
