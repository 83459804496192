import { useEffect, useState } from "react";

// import jwt from "jsonwebtoken";
import { Routes, Route, useSearchParams } from "react-router-dom";
import PatientPayLogin from "./PatientPayLogin";
import PatientPayPortal from "./PatientPayPortal";
import PatientPayConfirmation from "./PatientPayConfirmation";
import PatientPayBreakdown from "./PatientPayBreakdown";
import PatientPayNoBalance from "./PatientPayNoBalance";
import PatientPayUnderRequiredBalance from "./PatientPayUnderRequiredBalance";
import PatientPayUnauthorized from "./PatientPayUnauthorized";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PatientPayDetails from "./PatientPayDetails";
import { STRIPE_PUBLIC_KEY } from "../../app/constants";

function PatientPayRouter() {
  let [searchParams] = useSearchParams();
  let [token, setToken] = useState("");
  const options = {
    // passing the client secret obtained from the server
    clientSecret:
      window.sessionStorage.getItem("client_secret") ??
      "pi_3LuPa2EuHed9ludM170B4wmZ_secret_qQpsuHLggAeIY17ImN32r3Bnq",
  };

  const STRIPE_CONNECT_ID = window.sessionStorage.getItem("stripe_connect_id");

  let stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
  if (Boolean(STRIPE_CONNECT_ID)) {
    stripePromise = loadStripe(STRIPE_PUBLIC_KEY, {
      stripeAccount: STRIPE_CONNECT_ID,
    });
  }

  useEffect(() => {
    try {
      const token = searchParams.get("token");
      setToken(token);
    } catch (e) {
      console.log("No patient enrollment token");
    }
  }, [searchParams]);

  // Uncomment to Surpress Zendesk Help
  // window.zESettings = {
  //   webWidget: {
  //     chat: {
  //       suppress: true,
  //     },
  //     contactForm: {
  //       suppress: true,
  //     },
  //     helpCenter: {
  //       suppress: true,
  //     },
  //     talk: {
  //       suppress: true,
  //     },
  //     answerBot: {
  //       suppress: true,
  //     },
  //   },
  // };

  return (
    <Routes>
      <Route exact path="/" element={<PatientPayLogin />} />
      <Route
        path="portal"
        element={
          <div>
            <Elements stripe={stripePromise} options={options}>
              <PatientPayPortal />
            </Elements>
          </div>
        }
      />
      <Route
        exact
        path="confirmation"
        element={
          <Elements stripe={stripePromise} options={options}>
            <PatientPayConfirmation />
          </Elements>
        }
      />
      <Route exact path="breakdown" element={<PatientPayBreakdown />} />
      <Route exact path="no_balance" element={<PatientPayNoBalance />} />
      <Route exact path="under_required_balance" element={<PatientPayUnderRequiredBalance />} />
      <Route exact path="details" element={<PatientPayDetails />} />
      <Route exact path="unauthorized" element={<PatientPayUnauthorized />} />
    </Routes>
  );
}
export default PatientPayRouter;
