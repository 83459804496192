import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { getReceiptFromTransaction } from "./PatientPayAPI";
import { validEmail } from "../AthelasOneOnboarding/ValidateSubmission";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";

enum ReceiptViaOptions {
  Download = "Download Receipt",
  Email = "Send Receipt via Email",
}
export function EmailReceipt() {
  const [selectedOption, setSelectedOption] = useState<ReceiptViaOptions>(
    ReceiptViaOptions.Email
  );
  const [email, setEmail] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [emailReceiptSent, setEmailReceiptSent] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const stripeIntentId = searchParams.get('payment_intent');

  const handleOptionChange = (event: SelectChangeEvent) => {
    setSelectedOption(event.target.value as ReceiptViaOptions);
    setEmailReceiptSent(false);
  };

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value as string);
    setEmailReceiptSent(false);
  };

  const getReceipt = async () => {
    setLoading(true);
    const response = await getReceiptFromTransaction(
      stripeIntentId,
      selectedOption === ReceiptViaOptions.Email ? email : undefined
    );
    if (selectedOption === ReceiptViaOptions.Download) {
      window.open(response.data, "_blank");
    } else if (selectedOption === ReceiptViaOptions.Email || response.status === 200) {
      setEmailReceiptSent(true);
    }
    setLoading(false);
  };

  return (
    <Grid
      container
      item
      flexDirection="column"
      xs={12}
      md={6}
      alignItems="center"
      justifyContent="center"
    >
      <FormControl style={{}}>
        <InputLabel>Please select</InputLabel>
        <Select
          value={selectedOption}
          label="Please select"
          onChange={handleOptionChange}
          fullWidth
        >
          {Object.values(ReceiptViaOptions).map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedOption === ReceiptViaOptions.Email && (
        <TextField
          style={{
            marginTop: DefaultMargin,
          }}
          label="Email"
          variant="outlined"
          value={email}
          onChange={handleEmailChange}
        />
      )}
      {loading ? (
        <CircularProgress
          style={{
            marginTop: DefaultMargin,
          }}
        />
      ) : (
        <Button
          variant="contained"
          style={{
            marginTop: DefaultMargin,
          }}
          onClick={getReceipt}
          disabled={
            selectedOption === ReceiptViaOptions.Email &&
            (!email || !validEmail(email))
          }
        >
          {selectedOption === ReceiptViaOptions.Email
            ? "Send Receipt"
            : "Download Receipt"}
        </Button>
      )}
      {
        emailReceiptSent && (
          <Typography
          align="center"
          variant="body1"
          sx={{ mx: 8, mb: 2, color: "text.secondary", paddingTop:2 }}
          gutterBottom
          >
              Receipt Successfully Sent!
          </Typography>
        )
      }
    </Grid>
  );
}

const DefaultMargin = 20;
