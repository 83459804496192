import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { createTheme } from "@mui/material/styles";
import { themeOptions } from "./ThemeOptionsPatientPay";
import { useNavigate, useLocation } from "react-router-dom";
import {
  ThemeProvider,
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  IconButton,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  InputLabel,
  FormControl,
  Divider,
  Container,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Modal from "react-modal";

import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

import {
  applyPaymentFees,
  getAllowPartialPayment,
  getPaymentFees,
  removePaymentIntentFutureUse,
} from "./PatientPayAPI.ts";
import { Close } from "@mui/icons-material";
import { trackNormandy } from "../../app/utils/logging";
import PartialPayment from "./components/PartialPayment/index";

const track = (action, data) => {
  trackNormandy({
    action: action,
    tag: "patient_pay_portal",
    data: {
      first_name: window.sessionStorage.getItem("first_name"),
      last_name: window.sessionStorage.getItem("last_name"),
      date_of_birth: window.sessionStorage.getItem("date_of_birth"),
      site_id: window.sessionStorage.getItem("site_id"),
      requested_prs: window.sessionStorage.getItem("requested_prs"),
      payment_intent_id: window.sessionStorage.getItem("payment_intent_id"),
      ...data,
    },
  });
};

export default function PatientPayPortal() {
  const navigate = useNavigate();
  const stripe = useStripe();
  const location = useLocation();
  const state = useMemo(() => location?.state ?? {}, [location?.state]);
  const elements = useElements();

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loadingAllowPartialPayment, setLoadingAllowPartialPayment] =
    useState(true);
  const [showPartialPayment, setShowPartialPayment] = useState(false);
  const [feeData, setFeeData] = useState({
    old_amount: state.amountChargedInUSDCents,
    new_amount: state.amountChargedInUSDCents,
    fee_amount_in_usd_cents: 0,
    fee_type: "",
  });
  const [saveCard, setSaveCard] = useState(true);
  const [applyFees, setApplyFees] = useState(true);

  useEffect(() => {
    setApplyFees(window.sessionStorage.getItem("apply_fees") === "true");
  }, []);

  useEffect(() => {
    (async () => {
      setLoadingAllowPartialPayment(true);
      try {
        const { data: allowPartialPayment } = await getAllowPartialPayment(
          state.siteID
        );
        setShowPartialPayment(allowPartialPayment);
      } catch (error) {
        setShowPartialPayment(false);
      }
      setLoadingAllowPartialPayment(false);
    })();
  }, [state.siteID]);

  const {
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (
      state.siteID !== -1 &&
      window.sessionStorage.getItem("client_secret") === null
    )
      navigate("/patient_pay/unauthorized", { replace: true });
  }, [state.siteID, navigate]);

  useEffect(() => {
    const firstError = Object.keys(errors).reduce(
      (field, a) => (!!errors[field] ? field : a),
      null
    );

    if (firstError) setFocus(firstError);
  }, [errors, setFocus]);

  const getReturnURL = useCallback(() => {
    const isLocalhost =
      window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/.test(
        window.location.hostname
      );

    const isStaging =
      window.location.hostname === "staging-onboarding.athelas.com";

    let baseUrl;
    if (isLocalhost) baseUrl = "http://localhost:3000";
    else if (isStaging) baseUrl = "https://staging-onboarding.athelas.com";
    else baseUrl = "https://onboarding.athelas.com";
    return `${baseUrl}/patient_pay/confirmation?first_name=${state.firstName}`;
  }, [state.firstName]);

  const openModal = () => {
    setShowConfirmModal(true);
    track("open_confirm_modal");
  };

  const closeModal = () => {
    setShowConfirmModal(false);
    track("close_confirm_modal");
  };

  const modalStyles = {
    overlay: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    content: {
      width: "80%",
      maxWidth: "300px",
      padding: "16px",
      inset: "unset",
    },
  };

  const ConfirmModal = () => {
    return (
      <Modal
        isOpen={showConfirmModal}
        onAfterOpen={() => {}}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Confirm Payment"
      >
        <IconButton
          aria-label="close"
          style={{
            position: "absolute",
            right: "12px",
            top: "12px",
            padding: "0",
          }}
          onClick={closeModal}
        >
          <Close fontSize="small" />
        </IconButton>
        <Typography variant="h6" align="center" mb={1.5}>
          Confirm Payment
        </Typography>

        {feeData?.fee_amount > 0 && (
          <>
            <Typography
              variant="body1"
              color="text.secondary"
              style={{ textAlign: "right", margin: "4px 0" }}
            >
              Amount Due: {displayTotalBalance(feeData.old_amount)}
            </Typography>{" "}
            <Typography
              variant="body1"
              color="text.secondary"
              style={{ textAlign: "right", margin: "4px 0" }}
            >
              {feeData?.fee_type === "card" && "Credit Card"}
              {feeData?.fee_type === "affirm" && "Affirm"} Fee:{" "}
              {displayTotalBalance(feeData.fee_amount)}
            </Typography>
          </>
        )}
        <Divider style={{ margin: "8px 0" }} />
        <Typography variant="body1" style={{ textAlign: "right" }}>
          Total Amount Due: {displayTotalBalance(feeData.new_amount)}
        </Typography>
        <Divider style={{ margin: "8px 0" }} />
        <Grid display="grid" gridTemplateColumns="1fr 1fr" mt={2.5} gap={1}>
          <Button variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!stripe || loading}
            onClick={onSubmit}
          >
            Confirm
          </Button>
        </Grid>
      </Modal>
    );
  };

  const onSubmitConfirmPayment = useCallback(async () => {
    // validate payment form for card payments
    if (paymentMethod === "card") {
      const res = await elements.submit();
      if (res?.error) return;
    }
    setLoading(true);
    // get fees here
    const paymentIntentId = window.sessionStorage.getItem("payment_intent_id");

    if (applyFees) {
      const fees = await getPaymentFees(
        state.stripeAccountId,
        state.siteID,
        paymentMethod,
        paymentIntentId
      );
      setFeeData(fees.data);
    }

    openModal();
    setLoading(false);
  }, [elements, state.siteID, state.stripeAccountId, paymentMethod]);

  const onSubmit = useCallback(async () => {
    track("confirm_payment");
    setLoading(true);
    // Patient pay demo flow if siteID is -1
    if (state.siteID === -1) {
      navigate("/patient_pay/confirmation", {
        state: { firstName: state.firstName, lastName: state.lastName },
      });
    } else {
      // Ensure stripe.js has loaded in
      if (!stripe || !elements) return;
      // apply fees here
      if (showConfirmModal) {
        const paymentIntentId =
          window.sessionStorage.getItem("payment_intent_id");
        try {
          if (!saveCard) {
            await removePaymentIntentFutureUse(
              state.stripeAccountId,
              paymentIntentId
            );
          }
          if (applyFees) {
            await applyPaymentFees(
              state.stripeAccountId,
              state.siteID,
              paymentMethod,
              paymentIntentId
            );
          }
          elements.update({
            clientSecret:
              window.sessionStorage.getItem("client_secret") ??
              "pi_3LuPa2EuHed9ludM170B4wmZ_secret_qQpsuHLggAeIY17ImN32r3Bnq",
          });
          await elements.fetchUpdates();
        } catch (error) {
          console.error(error);
          return;
        }
      }
      const result =
        selectedPaymentMethod !== ""
          ? await stripe.confirmCardPayment(
              window.sessionStorage.getItem("client_secret"),
              {
                payment_method: selectedPaymentMethod,
                return_url: getReturnURL(),
              }
            )
          : await stripe.confirmPayment({
              elements,
              confirmParams: { return_url: getReturnURL() },
            });

      if (result.error) {
        console.log("ERROR CONFIRMING PAYMENT", result.error.message);
        closeModal();
        setErrorMessage(result.error.message);
        setShowErrorMessage(true);
      } else if (selectedPaymentMethod)
        //redirect to return url
        navigate(
          `/patient_pay/confirmation?first_name=${state.firstName}&last_name=${state.lastName}&payment_intent=${result.paymentIntent.id}`
        );
    }
    setLoading(false);
    console.log("ERRORS", errors);
  }, [
    elements,
    errors,
    getReturnURL,
    state,
    navigate,
    paymentMethod,
    selectedPaymentMethod,
    showConfirmModal,
    stripe,
  ]);

  const displayTotalBalance = (balance) =>
    ((balance || 22000) / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });

  const getDueDateTemp = () => moment().add(1, "M").format("MMM Do YYYY");

  const latestEncounterDate = () =>
    moment(state.latestDate || undefined).format("MMM Do YYYY");

  // set if paying with card or affirm
  const handlePaymentSelection = (selectedPaymentMethod) => {
    if (selectedPaymentMethod.value.type !== paymentMethod) {
      track("switch_payment_method", {
        payment_method: selectedPaymentMethod.value.type,
      });
      setPaymentMethod(selectedPaymentMethod.value.type);
    }
  };
  const onError = (errors, e) => console.log("onError", errors, e);
  const theme = createTheme(themeOptions);

  useEffect(() => {
    track("view");
  }, [state]);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Box sx={{ mb: 8, mt: 2 }}>
          <Grid container direction="column" spacing={4}>
            <Grid item xs={12}>
              <Box
                bgcolor="#FAFAFA"
                sx={{ display: "flex", p: 1, borderRadius: "10px" }}
              >
                <Typography
                  variant="body1"
                  align="center"
                  sx={{ color: "text.secondary" }}
                >
                  To: {state.siteName}
                </Typography>
              </Box>
            </Grid>
            <Grid width="100%" item xs={12}>
              {(true ||
                (!state.usingFixedLink &&
                  !state.payingForDependentPatients &&
                  state.amountChargedInUSDCents !==
                    state.amountInitiallyRequested)) && (
                <Box
                  sx={{
                    borderStyle: "solid",
                    marginBottom: "20px",
                    padding: "0 5px",
                    borderColor: "#f2995f",
                    backgroundColor: "#fef3e6",
                    borderRadius: "4px",
                  }}
                >
                  <Typography variant="body1" color="text.secondary" sx={{}}>
                    Your balance has changed since we last contacted you. The
                    following is what you owe as of today.
                  </Typography>
                </Box>
              )}
              <Card
                raised
                onClick={() => {
                  state.siteID === -1
                    ? navigate("/patient_pay/breakdown")
                    : navigate("/patient_pay/details", {
                        state: {
                          firstName: state.firstName,
                          lastName: state.lastName,
                          outstandingEncounters: state.outstandingEncounters,
                          dependentPatientsOutstandingPRInfo:
                            state.dependentPatientsOutstandingPRInfo,
                          amountDue: state.amountChargedInUSDCents,
                          payingForDependentPatients:
                            state.payingForDependentPatients,
                          dependentPatientsInfo: state.dependentPatientsInfo,
                        },
                      });
                }}
              >
                <CardActionArea>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body1" color="text.secondary">
                        Amount Due:
                      </Typography>
                      <Typography variant="body1">
                        {displayTotalBalance(state.amountChargedInUSDCents)}
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ color: "text.secondary" }}
                      >
                        View Breakdown
                      </Typography>
                      <IconButton aria-label="more about payment">
                        <ArrowForwardIosIcon />
                      </IconButton>
                    </Box>
                  </CardContent>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ color: "text.secondary" }}
                      >
                        Date of Service: {latestEncounterDate()}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ color: "text.secondary" }}
                      >
                        Due Date: {getDueDateTemp()}
                      </Typography>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            {loadingAllowPartialPayment ? (
              <Grid xs={12} item>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    my: "20px",
                  }}
                >
                  <CircularProgress sx={{ alignSelf: "center" }} />
                </Box>
              </Grid>
            ) : (
              <>
                {showPartialPayment && !state.chose_custom_amount ? (
                  <PartialPayment
                    setShowPartialPayment={setShowPartialPayment}
                  />
                ) : (
                  <Grid item xs={12}>
                    <form
                      onSubmit={handleSubmit(onSubmitConfirmPayment, onError)}
                    >
                      <Grid container spacing={4} direction="column">
                        {state.previousPaymentMethods && (
                          <Grid item xs={12}>
                            {!showConfirmModal && (
                              <FormControl sx={{ width: "100%" }}>
                                <InputLabel id="pay-card-on-file">
                                  Pay with card on file
                                </InputLabel>
                                <Select
                                  labelId="pay-card-on-file"
                                  label="Pay with card on file"
                                  onChange={(e) => {
                                    setSelectedPaymentMethod(e.target.value);
                                    track("select_saved_card");
                                  }}
                                  style={{ width: "100%" }}
                                >
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  {state.previousPaymentMethods.map(
                                    (paymentOption, index) => (
                                      <MenuItem
                                        key={index}
                                        value={paymentOption.id}
                                      >
                                        **** **** ****{" "}
                                        {paymentOption.last_four_digits}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            )}
                          </Grid>
                        )}
                        {!selectedPaymentMethod && (
                          <>
                            <Grid item xs={12}>
                              <PaymentElement
                                options={{ readOnly: loading }}
                                onChange={handlePaymentSelection}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={saveCard}
                                    onChange={(e) => {
                                      setSaveCard(e.target.checked);
                                    }}
                                  />
                                }
                                color={"text.secondary"}
                                label="Save my payment information for future use"
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                          <Button
                            variant="contained"
                            type="submit"
                            disabled={!stripe || loading}
                            style={{
                              width: "100%",
                              height: "56px",
                              textAlign: "center",
                            }}
                          >
                            Confirm Payment
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                )}
              </>
            )}
            {state.supportNumber && (
              <Grid item xs={12}>
                <Typography
                  align="center"
                  variant="subtitle2"
                  sx={{ color: "text.secondary" }}
                >
                  Call {state.supportNumber} for questions or support
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography
                align="center"
                variant="subtitle2"
                sx={{ color: "text.secondary" }}
              >
                Powered by Athelas SmartPay
              </Typography>
            </Grid>
          </Grid>
          <Snackbar
            open={showErrorMessage}
            autoHideDuration={6000}
            onClose={() => setShowErrorMessage(false)}
          >
            <Alert
              onClose={() => setShowErrorMessage(false)}
              severity="error"
              sx={{ width: "100%" }}
            >
              {errorMessage}
            </Alert>
          </Snackbar>
        </Box>
      </Container>
      <ConfirmModal />
    </ThemeProvider>
  );
}
