import React from "react";
import FillMedication from "./assets_1.0/Funnel.webp";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import BackButton from "./BackButton";
import styled from "styled-components";
import Log from "../../app/logging/Log.js";
import ShimmerImage from "../../app/utils/ShimmerImage";

function PillTrackFillMedication(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const step = `${location.state.medicationName}: Step 1 of 3`;
  const instructions = `Now, please fill this empty bottle with ${location.state.medicationName}. Optionally, you can use the funnel in the box to help. Additionally, feel free to use the non-child proof lids included in the box.
  `;
  const nextStep = () => {
    navigate("/pilltrack/label", {
      state: {
        medicationName: location.state.medicationName,
        bottleNumber: location.state.bottleNumber,
      },
    });
  };
  return (
    <Log
      logImpression={true}
      patientID={props.patientID}
      action="view"
      tag="pilltrack_fill_medication"
    >
      <TopMargin>
        <BackButton
          onClick={() => {
            navigate(-1);
          }}
        />
        <FillMedicationImage>
          <ShimmerImage
            imgHeight={543}
            imgWidth={560}
            src={FillMedication}
            widthPercentage={0.5}
          />
        </FillMedicationImage>
        <StyledH5Step>{step}</StyledH5Step>
        <Title>Add Medication</Title>
        <StyledH5>{instructions}</StyledH5>
        <Center>
          <StyledButton variant="primary" size="lg" onClick={nextStep}>
            <StyledH4>Next Step</StyledH4>
          </StyledButton>
        </Center>
      </TopMargin>
    </Log>
  );
}

const StyledButton = styled(Button)`
  background: #e85b81;
  :disabled {
    background: #d1d1d1;
    border-color: #d1d1d1;
  }
  :focus {
    background: #e85b81;
    border-color: #e85b81;
  }
  :active {
    background: #e85b81;
    border-color: #e85b81;
  }
  :hover {
    background: #e85b81;
    border-color: #e85b81;
  }
  border-radius: 10px;
  border-color: #e85b81;
  width: 260px;
  text-align: center;
`;

const Center = styled.div`
  text-align: center;
`;

const StyledH4 = styled.h4`
  font-family: "Manrope", sans-serif;
  text-align: center;
  padding-top: 5px;
`;

const StyledH5 = styled.h5`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #666666;
  padding-top: 10px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 30px;
`;

const TopMargin = styled.div`
  margin-top: 40px;
`;
const Title = styled.h2`
  color: #e85b81;
  font-family: "Manrope", sans-serif;
  text-align: center;
  font-weight: bold;
  padding-left: 48px;
  padding-right: 48px;
`;

const FillMedicationImage = styled.div`
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
  max-height: 75%;
`;

const StyledH5Step = styled.h5`
  text-decoration: underline #666666;
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #666666;
  padding-top: 10px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 10px;
`;

export default PillTrackFillMedication;
