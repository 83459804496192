import React, { Component } from "react";
import BlueGemStatus from "./BlueGemStatus.jpeg";
import RedGemStatus from "./RedGemStatus.jpeg";
import OrangeGemStatus from "./OrangeGemStatus.jpeg";

class Instruction1 extends Component {
  render() {
    return (
      <div class="form-container gem-container my-5">
        <div class="pt-4 pb-4">
          <h2> Get Started! </h2>
        </div>
        <img
          class="instruction-photo"
          width="auto"
          height="auto"
          alt="Athelas Gem"
          src={BlueGemStatus}
        />
        <div class="px-5">
          <p class="pt-5">
            Blue lights near pill bottles means you have taken the medication
            on schedule and don't need to do anything.
          </p>
        </div>
        <div class="pt-3 pb-4">
          <button
            type="submit"
            class="btn light-btn"
            onClick={this.props.nextStep}
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}

class Instruction2 extends Component {
  render() {
    return (
      <div class="form-container gem-container my-5">
        <div class="pt-4 pb-4">
          <h2> Get Started! </h2>
        </div>
        <img
          class="instruction-photo"
          width="auto"
          height="auto"
          alt="Athelas Gem"
          src={RedGemStatus}
        />
        <div class="px-5">
          <p class="pt-5">
            Red lights near pill bottles means you have not taken this yet
            according to the set schedule and should take the medication. For
            example, if the schedule for this medication is AM and PM, it will
            turn red in the morning and afternoon and remain red until you take
            the pill.
          </p>
        </div>
        <div class="pt-3 pb-4">
          <button
            type="submit"
            class="btn light-btn"
            onClick={this.props.nextStep}
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}

class Instruction3 extends Component {
  render() {
    return (
      <div class="form-container gem-container my-5">
        <div class="pt-4 pb-4">
          <h2> Get Started! </h2>
        </div>
        <img
          class="instruction-photo"
          width="auto"
          height="auto"
          alt="Athelas Gem"
          src={OrangeGemStatus}
        />
        <div class="px-5">
          <p class="pt-5">
            Orange lights means your pill bottle is missing! Return your pill
            bottle back to the correct location to make the light turn blue.
          </p>
        </div>
        <div class="pt-3 pb-4">
          <button
            type="submit"
            class="btn light-btn"
            onClick={this.props.nextStep}
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}

export { Instruction1, Instruction2, Instruction3 };
