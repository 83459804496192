import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import PatientConsentDocument from "./PatientConsentDocument.pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PatientConsent = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div style={{ maxWidth: "100%" }}>
      <Document
        file={PatientConsentDocument}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
      >
        <Page
          pageNumber={pageNumber}
          width={Math.min(parseInt(window.innerWidth / 1.2), 450)}
          style={{ margin: "0% auto" }}
        />
      </Document>
      <p style={{ textAlign: "center", width: "100%" }}>
        <span
          style={{ marginRight: 24, cursor: "pointer" }}
          onClick={() => {
            setPageNumber(Math.max(1, pageNumber - 1));
          }}
        >
          ← Previous
        </span>
        <span style={{ fontWeight: "bold" }}>
          Page {pageNumber} of {numPages}
        </span>
        <span
          style={{ marginLeft: 24, cursor: "pointer" }}
          onClick={() => {
            setPageNumber(Math.min(numPages, pageNumber + 1));
          }}
        >
          Next →
        </span>
      </p>
    </div>
  );
};
