import React from "react";
import Button from "react-bootstrap/Button";
import plugin from "./assets/Plug.svg";
import medications from "./assets/Med.svg";
import pilltrack from "./assets_1.0/PillTrack 1.0.webp";
import pen from "./assets/PenIcon.svg";
import styled from "styled-components";
import Log from "../../app/logging/Log.js";
import ShimmerImage from "../../app/utils/ShimmerImage.js";
import { useNavigate } from "react-router-dom";

function PillTrackWelcome(props) {
  const setupText = "Set Up Your PillTrack";
  const pluginText = "Plug In Your Device";
  const gatherText = "Gather Medications";
  const penText = "Gather Pen and Stickers";

  const navigate = useNavigate();

  const goToConnect = () => {
    navigate("connect");
  };

  return (
    <Log
      logImpression={true}
      patientID={props.patientID}
      action="view"
      tag="pilltrack_welcome"
    >
      <StyledGrid>
        <ImgWrapper>
          <ShimmerImage
            imgHeight={1229}
            imgWidth={2235}
            src={pilltrack}
            widthPercentage={0.75}
          />
        </ImgWrapper>
        <Title>{setupText}</Title>
        <IconRow>
          <IconImage src={plugin} alt="Plugin" />
          <StyledH4>{pluginText}</StyledH4>
        </IconRow>
        <IconRow>
          <IconImage src={medications} alt="Medications" />
          <StyledH4>{gatherText}</StyledH4>
        </IconRow>
        <IconRow>
          <IconImage src={pen} alt="Pen" />
          <StyledH4>{penText}</StyledH4>
        </IconRow>
        <CenterButton>
          <StyledButton variant="primary" size="lg" onClick={goToConnect}>
            <ButtonText>Start Now</ButtonText>
          </StyledButton>
        </CenterButton>
      </StyledGrid>
    </Log>
  );
}
const StyledGrid = styled.div`
  display: grid;
  grid-row-gap: 27px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Title = styled.h2`
  color: #e85b81;
  font-family: "Manrope", sans-serif;
  text-align: center;
  font-weight: bold;
  padding-left: 48px;
  padding-right: 48px;
`;

const ImgWrapper = styled.div`
  padding-top: 24px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  margin-top: 60px;
  margin-bottom: 40px;
`;

const StyledButton = styled(Button)`
  background: #e85b81;
  border-radius: 10px;
  border-color: #e85b81;
  width: 260px;
  text-align: center;
  :disabled {
    background: #d1d1d1;
    border-color: #d1d1d1;
  }
  :focus {
    background: #e85b81;
    border-color: #e85b81;
  }
  :active {
    background: #e85b81;
    border-color: #e85b81;
  }
  :hover {
    background: #e85b81;
    border-color: #e85b81;
  }
`;

const IconRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`;

const CenterButton = styled.div`
  text-align: center;
`;

const ButtonText = styled.h4`
  font-family: "Manrope", sans-serif;
  text-align: center;
  padding-top: 5px;
`;

const StyledH4 = styled.h4`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #666666;
`;

const IconImage = styled.img`
  display: inline-block;
  padding-right: 12px;
  max-width: 40px;
  max-height: 40px;
`;

export default PillTrackWelcome;
