import React, { useEffect } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { getLastPutBackBottle } from "./API";
import styled from "styled-components";
import Log from "../../app/logging/Log.js";
import moment from "moment";

function PillTrackResetReturn(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const instructions = 'If you want to keep this bottle with the current medication, please return it back to its slot.';

  useEffect(() => {
    const interval = setInterval(() => {
      getLastPutBackBottle(props.deviceString, props.token)
        .then((number) => {
          const lastPutBack = number.last_bottle_number;
          const eventTime = moment(number.timestamp)
          const now = moment().subtract(3, "seconds");
          if (
            lastPutBack === location.state.bottleNumber && eventTime.isAfter(now)
          ) {
            navigate("/pilltrack/alignment");
          }
        })
        .catch((error) => console.log(error));
    }, 500);

    return () => clearInterval(interval);
  });

  return (
    <Log
      logImpression={true}
      patientID={props.patientID}
      action="view"
      tag="pilltrack_reset_return"
    >
      <TopMargin>
        <Title>Return Bottle to Slot</Title>
        <StyledH5>{instructions}</StyledH5>
        <CenterLoader>
          <BeatLoader color="#E85B81" />
        </CenterLoader>
        <ItalicH5>Waiting for bottle to be returned...</ItalicH5>
      </TopMargin>
    </Log>
  );
}

const CenterLoader = styled.div`
  text-align: center;
  padding-top: 6vw;
`;

const ItalicH5 = styled.h5`
  font-style: italic;
  color: #808080;
  text-align: center;
  padding-top: 12vw;
  padding-left: 48px;
  padding-right: 48px;
`;

const StyledH5 = styled.h5`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #666666;
  padding-top: 10px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 60px;
`;

const TopMargin = styled.div`
  margin-top: 50vw;
`;

const Title = styled.h2`
  color: #e85b81;
  font-family: "Manrope", sans-serif;
  text-align: center;
  font-weight: bold;
  padding-left: 48px;
  padding-right: 48px;
`;

export default PillTrackResetReturn;
