import "./Gem.css";
import React, { Component } from "react";
import { trackEvent } from "../../app/utils/logging.ts";

class Medication extends Component {
  constructor(ownProps) {
    super(ownProps);
    this.state = {
      errors: {
        medication_name: false,
      },
      medication_name: "",
      schedule: "DAILY_AM",
    };
  }

  componentDidMount() {
    trackEvent(
      "view",
      this.props.patient_id,
      "gem_onboarding_enter_medication",
      this.props.first_name + " " + this.props.last_name
    );
  }

  setMedicationName = (medication_name) => {
    this.setState({
      medication_name: medication_name,
    });
  };

  setSchedule = (schedule) => {
    this.setState({
      schedule: schedule,
    });
  };

  submitMedicationName = () => {
    let hasError = false;
    let errors = {};
    if (!this.state.medication_name) {
      errors["medication_name"] = true;
      hasError = true;
    }
    this.setState({
      errors,
    });
    if (!hasError) {
      this.props.nextStep(this.state.medication_name, this.state.schedule);
    }
  };

  render() {
    let num_bottles_absent =
      4 - this.props.bottles_present.reduce((a, b) => a + b, 0);
    let num_bottles_filled = 0;
    for (let name of this.props.medicationNames) {
      if (name !== "disabled") {
        num_bottles_filled += 1;
      }
    }

    const render_bottle = (bottle_num) => {
      let classes = "bottle";
      let bottle_filled_classes = [
        " bottle-one-filled",
        " bottle-filled",
        " bottle-three-filled",
        " bottle-filled",
      ];

      if (bottle_num === 1) {
        classes += " bottle-two";
      } else if (bottle_num === 2) {
        classes += " mt-3";
      } else if (bottle_num === 3) {
        classes += " bottle-four";
      }

      let bottle_absent = !this.props.bottles_present[bottle_num];
      let bottle_filled = this.props.medicationNames[bottle_num] !== "disabled";
      if (bottle_absent) {
        classes += " absent";
      } else if (bottle_filled) {
        classes += bottle_filled_classes[bottle_num];
      }

      return classes;
    };

    const bottles_filled = () => {
      if (num_bottles_absent === 0) {
        if (num_bottles_filled !== 4) {
          return (
            <p>
              {" "}
              You have filled {num_bottles_filled}{" "}
              {num_bottles_filled === 1 ? "bottle" : "bottles"} so far!{" "}
            </p>
          );
        } else {
          return <p> You have filled all bottles! </p>;
        }
      }
    };

    return (
      <div class="form-container gem-container my-5">
        <div class="pt-4 pb-2">
          <h1>Setup</h1>
        </div>
        {num_bottles_absent === 0 && <div class="px-4">{bottles_filled()}</div>}
        <div class="bottles pb-3">
          <div class={render_bottle(0)}>
            <div class={render_bottle(1)}></div>
          </div>
          <div class={render_bottle(2)}>
            <div class={render_bottle(3)}></div>
          </div>
        </div>
        {num_bottles_absent === 0 && (
          <p>
            {" "}
            <b> Pick up an empty bottle. </b>{" "}
          </p>
        )}
        {num_bottles_absent === 1 && (
          <div>
            <div class="px-5 pb-3">
              <p>
                {" "}
                <b>Fill the bottle with the medication of your choice.</b>{" "}
              </p>
              <p> Once you are finished, enter the name of the medication:</p>
              <input
                className={
                  this.state.errors["medication_name"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
                type="text"
                placeholder="Medication Name"
                value={this.state.medication_name}
                onChange={(e) => this.setMedicationName(e.target.value)}
              />
            </div>
            <div class="px-5 pb-3">
              <p> Select when you take this medication: </p>
              <div
                onChange={(e) => {
                  console.log(e.target.value);
                  this.setSchedule(e.target.value);
                }}
                class="d-flex flex-column align-items-center"
              >
                <div>
                  <input
                    id="am"
                    type="radio"
                    value="DAILY_AM"
                    name="schedule"
                  />
                  <label class="ms-3" for="am">
                    Every Morning
                  </label>
                </div>
                <div>
                  <input
                    id="pm"
                    type="radio"
                    value="DAILY_PM"
                    name="schedule"
                  />
                  <label class="ms-3" for="pm">
                    Every Evening
                  </label>
                </div>
                <div>
                  <input
                    id="ampm"
                    type="radio"
                    value="BI_DAILY"
                    name="schedule"
                  />
                  <label class="ms-3" for="ampm">
                    Every Morning & Evening
                  </label>
                </div>
              </div>
            </div>
            <div class="pt-2 pb-3">
              <button
                type="submit"
                class="btn light-btn"
                onClick={this.submitMedicationName}
              >
                Next
              </button>
            </div>
          </div>
        )}
        {num_bottles_absent > 1 && (
          <div class="px-5 pb-3">
            <p>
              <b>Please only pick up one bottle!</b>
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default Medication;
