import React, { useState } from "react";
import styled, { css } from "styled-components";

import { Testimonials } from "./TrustedBySectionConstants";

import MapImage from "../revampedOnboarding/assets/GradientMap.png";
import { useMediaQuery } from "react-responsive";
import { TrustedBySectionProgressBar } from "./TrustedBySectionProgressBar";

type PropsType = {
  blueBackground?: boolean;
  palomar?: null | boolean;
};

export const TrustedBySection = (props: PropsType) => {
  const [currentStep, setCurrentStep] = useState(0);
  const maxSteps = Testimonials.length;

  const nextStep = () => {
    if (currentStep === maxSteps - 1) {
      setCurrentStep(0);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  const ContainerComponent = Container(props.blueBackground ? "F4F8FD" : "fff");

  const obj: any = Testimonials[currentStep];

  return (
    <ContainerComponent>
      <h3 style={{ marginBottom: 48 }}>
        <b>Trusted by 60,000+ Patients Across the Country</b>
      </h3>

      <div style={isTabletOrMobile ? {} : styles.flexBox}>
        <div
          style={{
            backgroundColor: "white",
            padding: 36,
            maxWidth: "350px",
            marginRight: isTabletOrMobile ? 0 : 36,
            marginLeft: isTabletOrMobile ? 0 : 36,
            boxShadow: "0px 0px 10px #CCC",
            borderRadius: "16px",
            cursor: "pointer",
          }}
          onClick={nextStep}
        >
          <div style={styles.flexBox}>
            <div style={{ width: "20%", justifyContent: "left" }}>
              <ProfileImage src={obj.picture} alt="Portrait" />
            </div>
            <div style={{ width: "80%" }}>
              <TitleP>{obj.title}</TitleP>
              <p
                style={{
                  textAlign: "right",
                  margin: 0,
                  fontSize: isTabletOrMobile ? "80%" : "90%",
                }}
              >
                {obj.subtitle}
              </p>
            </div>
          </div>

          <p
            style={{
              marginTop: "24px",
              textAlign: "left",
              height: isTabletOrMobile ? "" : "280px",
            }}
          >
            "{obj.testimonial}"
          </p>

          <TrustedBySectionProgressBar
            currentStep={currentStep}
            numSteps={maxSteps}
            palomar={props.palomar}
          />
        </div>

        <MapImageDiv
          src={MapImage}
          alt="Map"
          isTabletOrMobile={isTabletOrMobile}
          onClick={nextStep}
        />
      </div>
    </ContainerComponent>
  );
};

const Container = (backgroundColor: string) => styled.div`
  padding: 48px;
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
  background-color: #${backgroundColor};
`;

const ProfileImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 100%;
`;

const MapImageDiv: any = styled.img`
  width: 50%;
  object-fit: contain;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 36px;
  cursor: pointer;

  ${(props: any) =>
    props.isTabletOrMobile &&
    css`
      width: 80%;
    `}
`;

const TitleP: any = styled.p`
  text-align: right;
  margin: 0px;
  font-weight: bold;
  width: 100%;
`;

const styles: any = {
  flexBox: {
    flexDirection: "row",
    display: "flex",
    maxWidth: 1000,
    justifyContent: "center",
    margin: "0% auto",
  },
};
