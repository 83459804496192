import { useEffect, useState } from "react";

// import jwt from "jsonwebtoken";
// import { PUBLIC_KEY } from "../../app/constants";

import { Routes, Route, useSearchParams } from "react-router-dom";
import PatientEnrollment from "./PatientEnrollment";
import PatientEnrollmentConfirmation from "./PatientEnrollmentConfirmation";

function PatientEnrollmentRouter(props) {
  let [searchParams] = useSearchParams();
  let [token, setToken] = useState("");

  useEffect(() => {
    try {
      const token = searchParams.get("token");
      setToken(token);
    } catch (e) {
      console.log("No patient enrollment token");
    }
  }, [searchParams]);

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={<PatientEnrollment update={props.update} />}
      />
      <Route
        path="confirmation"
        element={<PatientEnrollmentConfirmation token={token} />}
      />
    </Routes>
  );
}
export default PatientEnrollmentRouter;
