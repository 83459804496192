import React, { Component } from "react";
import { AtomChart } from "../../app/Atoms/Chart.js";
import { getToken, rawToken } from "../../app/utils/token.js";
import { getTotalCareReport } from "./actions.js";
import moment from 'moment';
import "./TotalCareReport.css";

const FLEX_BOX_STYLE = {
  display: "flex",
  flexDirection: "row",
  marginLeft: "16px",
};

export class TotalCareReport extends Component {
  constructor(props) {
    super(props);

    const token = getToken() || null;
    if (!token) {
      window.location = "/";
    }

    this.state = {
      token: token,
      rawToken: rawToken(),
      last_track_date: "-",
      categories: [],
      data: [],
      onboarding: {},
      all_time_change: "-",
      current_weight: "-",
    };
  }

  componentDidMount() {
    const patientId = this.state.token.sub;
    getTotalCareReport(this.state.rawToken, patientId)
      .then((j) => j.json())
      .then((res) => {
        this.setState({
          last_track_date: moment(res["last_track_date"]).format("MMM D, YYYY"),
          categories: res["categories"].map((date) =>
            moment(date).format("MMM D, YY")
          ),
          data: (res["data"] || []).map((x) => Math.round(parseFloat(x), 2)),
          onboarding: res["onboarding_info"],
          all_time_change: -res["all_time_change"],
          current_weight: Math.round(res["current_weight"] * 10, 1) / 10,
        });
      });
  }

  render() {
    if (this.state.categories.length < 1) {
      return null;
    }

    const BMI =
      Math.round(
        (this.state.current_weight /
          (this.state.onboarding.height * this.state.onboarding.height)) *
          703 *
          100
      ) / 100;

    return (
      <div className="reportContainer">
        <h2>Total Care Report</h2>
        <p>
          This is your Weight Management Report. Last time you tracked your
          weight was: <br></br>
          {this.state.last_track_date}.
        </p>
        <hr></hr>

        <div className="flexBox">
          <div style={{ width: "50%" }}>
            <p
              style={{
                marginLeft: "16px",
                textAlign: "left",
                fontSize: "80%",
              }}
            >
              Current Weight
            </p>
            <h2
              style={{
                textAlign: "left",
                marginLeft: "16px",
                fontWeight: "bold",
                marginTop: "-16px",
              }}
            >
              {this.state.current_weight} lb
            </h2>
          </div>

          <div>
            <p
              style={{
                width: "100%",
                textAlign: "left",
                fontSize: "80%",
                fontWeight: "bold",
                marginTop: "16px",
                color: "#4287f5",
                marginBottom: 0,
              }}
            >
              ⬆ {this.state.all_time_change > 0 ? "+" : ""}
              {this.state.all_time_change} lb (all time Δ)
            </p>
            <p
              style={{
                width: "100%",
                textAlign: "left",
                fontSize: "80%",
                fontWeight: "bold",
                marginTop: 0,
                color: "#4287f5",
              }}
            >
              BMI: {BMI}
            </p>
          </div>
        </div>

        <AtomChart
          categories={this.state.categories}
          data={this.state.data}
          goal={this.state.onboarding.goal_weight}
        />

        <hr></hr>

        <div style={FLEX_BOX_STYLE}>
          <div style={{ width: "35%", textAlign: "left" }}>
            <p style={{ color: "#999" }}>Start Weight</p>
            <h4>{this.state.onboarding.start_weight} lb</h4>
          </div>
          {this.state.onboarding.goal_weight && (
            (
            <div style={{ width: "35%", textAlign: "left" }}>
                <p style={{ color: "#999" }}>Goal Weight</p>
                <h4>{this.state.onboarding.goal_weight} lb</h4>
              </div>
          )
          )}
          <div style={{ width: "30%", textAlign: "left" }}>
            <p style={{ color: "#999" }}>Height</p>
            <h4>{this.state.onboarding.height} in.</h4>
          </div>
        </div>

        <hr></hr>

        <p
          style={{
            marginTop: "24px",
            textAlign: "left",
            marginLeft: "16px",
            marginRight: "16px",
            color: "#999",
          }}
        >
          Dietary Info
        </p>
        <h5
          style={{
            marginLeft: "16px",
            marginRight: "16px",
            textAlign: "left",
          }}
        >
          {this.state.onboarding.dietary_info}
        </h5>

        <p
          style={{
            marginTop: "24px",
            textAlign: "left",
            marginLeft: "16px",
            marginRight: "16px",
            color: "#999",
          }}
        >
          Exercise Info
        </p>
        <h5
          style={{
            marginLeft: "16px",
            marginRight: "16px",
            textAlign: "left",
          }}
        >
          {this.state.onboarding.exercise_info}
        </h5>
      </div>
    );
  }
}
