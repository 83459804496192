import React from "react";
import { useState, useEffect } from "react";
import { createTheme } from "@mui/material/styles";
import { themeOptions } from "./ThemeOptionsPatientPay";
import { useLocation, useSearchParams } from "react-router-dom";
import Check from "./assets/Check.png";
import {
  ThemeProvider,
  Box,
  CardMedia,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";
import { EmailReceipt } from "./EmailReceipt";
import { useStripe } from "@stripe/react-stripe-js";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export default function PatientPayConfirmation(props) {
  const theme = createTheme(themeOptions);
  const location = useLocation();
  let [searchParams] = useSearchParams();
  let [firstName, setFirstName] = useState("");
  const stripe = useStripe();
  const stripeIntentClientSecret = searchParams.get(
    "payment_intent_client_secret"
  );
  const [paymentStatus, setPaymentStatus] = useState();

  const checkPaymentStatus = async () => {
    if (stripeIntentClientSecret) {
      const result = await stripe?.retrievePaymentIntent(
        stripeIntentClientSecret
      );
      setPaymentStatus(result?.paymentIntent?.status);
    } else {
      console.warn("stripeIntentClientSecret is null");
    }
  };

  useEffect(() => {
    checkPaymentStatus();
  }, [stripe]);

  useEffect(() => {
    if (!Boolean(location.state?.firstName)) {
      setFirstName(searchParams.get("first_name"));
    } else {
      setFirstName(location.state.firstName);
    }
  }, [searchParams]);

  const PaymentSummary = () => {
    if (paymentStatus === "succeeded" || !stripeIntentClientSecret) {
      return (
        <>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardMedia
                sx={{
                  width: 0.3,
                  height: 1 / 6,
                }}
                src={Check}
                component="img"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Typography align="center" variant="h6" gutterBottom>
                Thanks for your payment {firstName}!
              </Typography>
            </div>
          </Grid>
          <EmailReceipt />
          <Grid item xs={12} sx={{ mt: 10 }}>
            <Typography
              align="center"
              variant="subtitle2"
              sx={{ color: "text.secondary" }}
            >
              Powered by Athelas SmartPay
            </Typography>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ErrorOutlineIcon sx={{ fontSize: 100, color: "red" }} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Typography align="center" variant="h6" gutterBottom>
                Something went wrong with your payment {firstName}!
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sx={{ mt: 5 }}>
            <Typography
              align="center"
              variant="subtitle2"
              sx={{ color: "text.secondary" }}
            >
              Powered by Athelas SmartPay
            </Typography>
          </Grid>
        </>
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "75vh" }}
      >
        {paymentStatus || !stripeIntentClientSecret ? (
          <PaymentSummary />
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </ThemeProvider>
  );
}
