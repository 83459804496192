export const mapShipGateStatusToText = (
  status: string,
  first_name: string,
  last_name: string,
  is_enterprise: boolean
): string => {
  if (status === "BAD") {
    if (is_enterprise) {
      return `Unfortunately after reviewing the insurance information for ${first_name} ${last_name}, we have determined that RPM services would not be fully covered, and would result in a high patient contribution.\n If you still wish to add the patient, please check the insurance eligibility override box and submit again.`;
    } else {
      return `Unfortunately after reviewing the insurance information for ${first_name} ${last_name}, we have determined that RPM services would not be fully covered, and would result in a high patient contribution. As a result, the patient cannot be added`;
    }
  } else if (status === "UNSURE") {
    return `${first_name} ${last_name} was found to have valid insurance, but is covered under a payer that has highly variable coverage for Remote Patient Monitoring. While we want to welcome this patient into the program, we also want to ensure that they will not be liable for high out of pocket costs for participating. If you wish to add the patient, please check the box below and submit again.`;
  }
  return "";
};
