import React from 'react'
import step1 from './steps_1.jpg';
import step2 from './steps_2.jpg';
import step3 from './steps_3.jpg';
import './Steps.css'

export default class Steps extends React.Component {

    render() {
        return (
            <div class="container p-3">
                <h1 class="m-5 step-header">How it works</h1>
                <div class="row">
                    <div class="col-md mb-5">
                        <div class="card text-center h-100 step">
                            <img src={step1} class="card-img-top step-image" alt="..."/>
                            <div class="card-body">
                                <h5 class="card-title step-title">STEP 01</h5>
                                <p class="card-text step-text">You receive medical devices in the mail with instructions on getting started. </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md mb-5">
                        <div class="card text-center h-100 step">
                            <img src={step2} class="card-img-top step-image" alt="..."/>
                            <div class="card-body">
                                <h5 class="card-title step-title">STEP 02</h5>
                                <p class="card-text step-text">You test at home and your results are automatically transmitted to your doctor.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md mb-5">
                        <div class="card text-center h-100 step">
                            <img src={step3} class="card-img-top step-image" alt="..."/>
                            <div class="card-body">
                                <h5 class="card-title step-title">STEP 03</h5>
                                <p class="card-text step-text">Your doctor analyzes your results and makes sure your side effects are under control.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}