import React, { Component } from 'react';
import { trackEvent } from "../../app/utils/logging.ts";

class Failure extends Component {

  componentDidMount() {
    trackEvent(
      "view",
      this.props.patient_id,
      "gem_onboarding_failure",
      this.props.first_name + " " + this.props.last_name
    );
  }

  render() {
    return (
      <div class="form-container gem-container my-5">
        <div class="mt-2 p-4">
          <h1> Failure </h1>
        </div>
        <div class="px-5">
          <p> Unable to submit medication names.</p>
          <p> If this problem persists, please call or text us at 1-877-324-4332 or email us at support@athelas.com. </p>
        </div>
          <div class="mt-2 p-4">
            <button type="submit" class="btn light-btn" onClick={this.props.nextStep}>Try Again</button>
          </div>
      </div>
    )
  }

}

export default Failure;
