import React, { useState } from "react";

import AthelasLogo from "../assets/AthelasLogo.png";
import AthelasDevicesImage from "../assets/AthelasDevices.png";
import { TextInput } from "./components/TextInput";

type PropsType = {
  showDeviceImages?: boolean;
  showSiteLogos?: boolean;
  showCornerImages?: boolean;
  site_logo?: null | string;
  site_name?: null | string;
  palomar?: null | boolean;
  nextStep: (...args: any[]) => void;
};

const styles: any = {
  innerContainer: {
    maxWidth: 1000,
    width: "100%",
    margin: "0% auto",
  },
  logoContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    margin: "0% auto",
    marginBottom: 12,
  },
  logo: {
    maxWidth: "140px",
    maxHeight: "80px",
    margin: 12,
    objectFit: "contain",
  },
  flexContainer: {
    flexDirection: "row",
    display: "flex",
    maxWidth: 285,
    margin: "0% auto",
    marginBottom: 16,
    justifyContent: "left",
  },
  mobileFlexContainer: {
    flexDirection: "row",
    display: "flex",
    width: "100%",
    margin: "0% auto",
    marginBottom: 16,
    justifyContent: "left",
  },
  checkmark: {
    width: 36,
    height: 36,
    marginRight: 16,
  },
  explainer: {
    marginTop: 8,
  },
  buttonStyle: {
    width: "100%",
    maxWidth: "240px",
    margin: "0% auto",
    marginBottom: 24,
    fontStyle: "bold",
    padding: 8,
    borderRadius: 4,
    backgroundColor: "#FF6779",
    marginTop: 24,
    color: "white",
    cursor: "pointer",
  },
  palomarButtonStyle: {
    width: "100%",
    maxWidth: "240px",
    margin: "0% auto",
    marginBottom: 24,
    fontStyle: "bold",
    padding: 8,
    borderRadius: 4,
    backgroundColor: "#008CC1",
    marginTop: 24,
    color: "white",
    cursor: "pointer",
  },
};

export const BasicInformationScreen = (props: PropsType) => {
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [dob, setDOB] = useState("");

  return (
    <div>
      <div style={styles.innerContainer}>
        {props.showSiteLogos && (
          <div style={styles.logoContainer}>
            <img src={AthelasLogo} style={styles.logo} alt="Athelas logo" />
            {props.site_logo && (
              <img src={props.site_logo} style={styles.logo} alt="Site logo" />
            )}
          </div>
        )}
        <h3>
          <b>Your Information</b>
        </h3>
        <p style={{ maxWidth: 450, margin: "0% auto", marginBottom: 16 }}>
          Please review your information. If any information need to be updated,
          simply click and update.
        </p>
        <TextInput
          placeholder="ex. Julia"
          label="First Name"
          onChange={(text) => {
            setFirstName(text);
          }}
        />
        <TextInput
          placeholder="ex. Smith"
          label="Last Name"
          onChange={(text) => {
            setLastName(text);
          }}
        />
        <TextInput
          placeholder="ex. 12/11/1995"
          label="Date of Birth (MM / DD / YYYY )"
          onChange={(text) => {
            setDOB(text);
          }}
          maskDate={true}
        />

        <div
          style={props.palomar? styles.palomarButtonStyle : styles.buttonStyle}
          onClick={() => props?.nextStep(dob, first_name, last_name)}
        >
          Update Shipping Information
        </div>
        {props.showDeviceImages && (
          <img
            src={AthelasDevicesImage}
            alt="Athelas devices"
            style={{ maxWidth: "300px" }}
          />
        )}
        <br />
      </div>
    </div>
  );
};
