import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_SERVER_URL } from "../../app/constants";
import Header from '../../components/Header/Header';
import './GgpSelectPaymentPlan.css';
import MedicationManagementModal from './MedicationManagementModal';

const GgpSelectPaymentPlan = () => {
  const { first_name, last_name, plan_choice } = useParams();
  const [message, setMessage] = useState('Processing your selection...');
  const [isError, setIsError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Mapping plan_choice to the titles for user feedback
  const planTitleMap = {
    'Pharmacy Preferred': 'Athelas Home with CLOZARIL Co-Pay Assistance Program',
    'Pharmacy Choice': 'Athelas Home with Medication Management',
    'Athelas Preferred': 'Athelas Home without Medication Management',
  };

  useEffect(() => {
    const selectPaymentPlan = async () => {
      try {
        const encodedFirstName = encodeURIComponent(first_name);
        const encodedLastName = last_name.split(' ').map(part => encodeURIComponent(part)).join('+');
        const formattedPlanChoice = plan_choice.replace(' ', '_').toUpperCase();
        const planTitle = planTitleMap[plan_choice] || 'Unknown Plan';
        const encodedPlanChoice = encodeURIComponent(formattedPlanChoice);

        const response = await axios.get(
          `${API_SERVER_URL}/ggp_prospective_patient/select_payment_plan/${encodedFirstName}/${encodedLastName}/${encodedPlanChoice}`
        );

        if (response.data.error) {
          setMessage('Error: ' + response.data.error);
          setIsError(true);
        } else {
          setMessage(
            <span>Thank you for picking the <strong>{planTitle}</strong> service plan. A confirmation email has been sent.</span>
          );
          setIsError(false);
        }
      } catch (error) {
        setMessage('An error occurred while selecting the service plan. Please reach out to mailto:athelasdevice@ggprx.com for assistance.');
        setIsError(true);
      }
    };

    selectPaymentPlan();
  }, [first_name, last_name, plan_choice]);

  
  const renderPlanDetails = () => {
    switch (plan_choice) {
      case 'Pharmacy Preferred':
        return (
          <div className="ggp-payment-options-plan-confirmation" key={plan_choice}>
            <h3 className="ggp-payment-options-title">
              Athelas Home with CLOZARIL <br />
              <span className="ggp-payment-options-highlight copay">Co-Pay Assistance Program</span>
            </h3>
            <div className="ggp-payment-options-pricing">
              <span className="ggp-payment-options-price">$500</span>
              <span className="ggp-payment-options-per"> Athelas Home Device</span>
            </div>
            <div className="ggp-payment-options-pricing-strip">
              <span className="ggp-payment-options-strip-price">$5</span>
              <span className="ggp-payment-options-per"> per Athelas WBC Test Strip <span className="ggp-payment-options-subtle-text">(10 per box)</span></span>
            </div>
            <span className="ggp-payment-options-subtle-text">+ shipping</span>
            <div className="ggp-payment-options-refill-info">
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">Fill medications with Golden Gate Pharmacy Services</span>
              </div>
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">$0 Clozaril Co-Pays* (specific rules apply)</span>
              </div>
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">
                  Medication Management and Synchronization
                  <button onClick={() => setIsModalOpen(true)} style={{ marginLeft: '0px', fontSize: '12px', color: '#007bff', background: 'none', border: 'none', cursor: 'pointer' }}>
                    Learn More
                  </button>
                </span>
              </div>
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">Complimentary Pill Packing Services once dosage is stabilized</span>
              </div>
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">Complimentary 2-day FedEx delivery for all medications</span>
              </div>
            </div>
          </div>
        );
      case 'Pharmacy Choice':
        return (
          <div className="ggp-payment-options-plan-confirmation" key={plan_choice}>
            <h3 className="ggp-payment-options-title">
              Athelas Home with <br />
              <span className="ggp-payment-options-highlight management">Medication Management</span>
            </h3>
            <div className="ggp-payment-options-pricing">
              <span className="ggp-payment-options-price">$1000</span>
              <span className="ggp-payment-options-per"> Athelas Home Device</span>
            </div>
            <div className="ggp-payment-options-pricing-strip">
              <span className="ggp-payment-options-strip-price">$5</span>
              <span className="ggp-payment-options-per"> per Athelas WBC Test Strip <span className="ggp-payment-options-subtle-text">(10 per box)</span></span>
            </div>
            <span className="ggp-payment-options-subtle-text">+ shipping</span>
            <div className="ggp-payment-options-refill-info">
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">Fill medications with Golden Gate Pharmacy Services</span>
              </div>
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">
                  Medication Management and Synchronization
                  <button onClick={() => setIsModalOpen(true)} style={{ marginLeft: '0px', fontSize: '12px', color: '#007bff', background: 'none', border: 'none', cursor: 'pointer' }}>
                    Learn More
                  </button>
                </span>
              </div>
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">Complimentary Pill Packing Services once dosage is stabilized</span>
              </div>
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">Complimentary 2-day FedEx delivery for all medications</span>
              </div>
            </div>
          </div>
        );
      case 'Athelas Preferred':
        return (
          <div className="ggp-payment-options-plan-confirmation" key={plan_choice}>
            <h3 className="ggp-payment-options-title">
              Athelas Home <br />
              <span className="ggp-payment-options-highlight">without Medication Management</span>
            </h3>
            <div className="ggp-payment-options-pricing">
              <span className="ggp-payment-options-price">$1500</span>
              <span className="ggp-payment-options-per"> Athelas Home Device</span>
            </div>
            <div className="ggp-payment-options-pricing-strip">
              <span className="ggp-payment-options-strip-price">$20</span>
              <span className="ggp-payment-options-per"> per Athelas WBC Test Strip <span className="ggp-payment-options-subtle-text">(10 per box)</span></span>
            </div>
            <span className="ggp-payment-options-subtle-text">+ shipping</span>
            <div className="ggp-payment-options-refill-info">
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">Fill medication with the pharmacy of your choice</span>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Header />
      <div className="ggp-select-plan-container">
        <div className="ggp-select-plan-content">
          <h2 className="ggp-select-plan-header">Service Plan Selection</h2>
          {isError ? (
            <p>An error occurred while selecting the service plan. Please reach out to <a href="mailto:athelasdevice@ggprx.com">athelasdevice@ggprx.com</a> for assistance.</p>
          ) : (
            <>
              <p>{message}</p>
              <p>You will receive a confirmation email shortly confirming your plan selection, and will receive your onboarding email within 3 business days!</p>
            </>
          )}
          <p className="ggp-best-regards">Best regards,<br /><strong>The Athelas & Golden Gate Pharmacy Teams</strong></p>
        </div>
        <div className="ggp-select-plan-content-confirmation">
          {renderPlanDetails()}
        </div>
      </div>
      <MedicationManagementModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default GgpSelectPaymentPlan;