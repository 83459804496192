type ValidFieldsType = {
    ok: boolean;
    message: string;
  };
  
  function validNumDevices(num: number) {
    return num>0
  }

  function validNumStrips(num: number) {
    return num > 0 && num%10 === 0
  }

  function validBillingName(text: string) {
    return /^[a-zA-Z]+$/.test(text)
  }

  function validPassword(text: string) {
      const min10 = text.length >=10;
      const oneNumber = /\d/.test(text);
      const oneUpperCase = text !== text.toLowerCase()
      const oneSpecialChar = (text.includes('!') || text.includes('@') || text.includes('#') || text.includes('$') || text.includes('%') || text.includes('^') || text.includes('*'))
      return min10 && oneNumber && oneUpperCase && oneSpecialChar
  }

  export function validEmail(text: string) {
    const re = /^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/im;
    return text.match(re) !== null;
  }

  function passwordsMatch(password: string, confirmPassword: string) {
    return password === confirmPassword
  }
  
  export const validateFields = (
    password: string,
    confirmPassword: string,
    numDevices: number,
    numStrips: number,
    agreeToContract: boolean,
    billingContactFirstName: string,
    billingContactLastName: string,
    billingContactEmail: string
  ): ValidFieldsType => {
    if (!agreeToContract) {
        return { ok: false, message: "Please agree to Athelas Terms of Service and BAA." };
    }  
    if (!validPassword(password)) {
      return { ok: false, message: "Invalid password. Password must have at Least 10 characters, 1 number, 1 upper case character, and 1 special character (!@#$%^*)" };
    }
    if (!passwordsMatch(password, confirmPassword)) {
      return {ok: false, message: "Passwords do not match. Please ensure both passwords match."}
    }
    if (!validNumDevices(numDevices)) {
      return { ok: false, message: "Invalid number of devices. Please select more than 0 devices." };
    }
    if (!validNumStrips(numStrips)) {
        return { ok: false, message: "Invalid number of strips. Must be more than 0 and a multiple of 10." };
    }   
    if (!validBillingName(billingContactFirstName)) {
      return { ok: false, message: "Invalid billing contact first name. Please enter a name with letters only." };
    }
    if (!validBillingName(billingContactLastName)) {
      return { ok: false, message: "Invalid billing contact last name. Please enter a name with letters only." };   
    }
    if (!validEmail(billingContactEmail)) {
      return { ok: false, message: "Invalid billing contact email. Please enter a valid email address." };   
    }
    return { ok: true, message: "OK" };
  };
  