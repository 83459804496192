import BPCuffImage from "../revampedOnboarding/assets/bp_cuff.png";
import SIMConnectedImage from "../revampedOnboarding/assets/SIMConnected.png";
import MeasurementImage from "../revampedOnboarding/assets/Measurement.png";
import PreventativeImage from "../revampedOnboarding/assets/Preventative.png";

import PillTrack from "../revampedOnboarding/assets/PillTrack.png";
import Giving from "../revampedOnboarding/assets/Giving.png";
import Visibility from "../revampedOnboarding/assets/Visibility.svg";
import Reminder from "../revampedOnboarding/assets/Reminder.svg";

import TotalCare from "../revampedOnboarding/assets/TotalCare.png";
import Intervention from "../revampedOnboarding/assets/Intervention.svg";
import DriveResult from "../revampedOnboarding/assets/DriveResult.svg";
import Approach from "../revampedOnboarding/assets/Approach.svg";

import AthelasOne from "../revampedOnboarding/assets/AthelasOne.png";
import MachineLearning from "../revampedOnboarding/assets/MachineLearning.png";
import BloodDrop from "../revampedOnboarding/assets/BloodDrop.png";
import FDACleared from "../revampedOnboarding/assets/FDACleared.png";

export const RPMDeviceConstants: any = {
  BLOOD_PRESSURE: {
    title: "BP Monitor & Glucometer",
    img: BPCuffImage,
    bullets: [
      {
        icon: SIMConnectedImage,
        text: "SIM-Connected: Easy set-up, no configuration required.",
      },
      {
        icon: MeasurementImage,
        text: "Each measurement is reviewed by a nurse and escalated if the result is concerning. ",
      },
      {
        icon: PreventativeImage,
        text: "Catches issues early before they require hospitalization.",
      },
    ],
  },
  PILLTRACK: {
    title: "Athelas PillTrack",
    img: PillTrack,
    bullets: [
      {
        icon: Giving,
        text: "Athelas uses modern technology (AI + ML) to give you the best healthcare experience.",
      },
      {
        icon: Reminder,
        text: "Innovative reminder lights - Increase your medication adherence to 100%.",
      },
      {
        icon: Visibility,
        text: "All adherence data is tracked by nurses and surfaced to your doctor. ",
      },
    ],
  },
  TOTAL_CARE: {
    title: "Athelas Total Care",
    img: TotalCare,
    bullets: [
      {
        icon: Approach,
        text: "Nutrition & exercise guide to help patients with weight loss (designed by a Stanford researcher).",
      },
      {
        icon: Intervention,
        text: "Bi-weekly nurse check-ins to keep patients on track.",
      },
      {
        icon: DriveResult,
        text: "Patients on this program lose an average of 0.9lbs per week.",
      },
    ],
  },
  ATHELAS_ONE: {
    title: "Athelas One Blood Diagnostics",
    img: AthelasOne,
    bullets: [
      {
        icon: BloodDrop,
        text: "Just 1 drop of blood from the patient on a proprietary test strip.",
      },
      {
        icon: MachineLearning,
        text: "The Machine Learning algorithms replace costly hardware to achieve a dramatically lower price point with equivalent results.",
      },
      {
        icon: FDACleared,
        text: "Clinically validated and used by thousands nationally.",
      },
    ],
  },
};
