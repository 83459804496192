import React from "react";
import PillTrackSlots from "./assets_1.0/Return to Any Slot.webp";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import BackButton from "./BackButton";
import styled from "styled-components";
import Log from "../../app/logging/Log.js";
import ShimmerImage from "../../app/utils/ShimmerImage";

function PillTrackAnySlot(props) {
  const navigate = useNavigate();
  const title = "Returning Bottles";
  const instructions =
    "After taking a medication, you can return the bottle to any slot.";
  const nextStep = () => {
    navigate("/pilltrack/download");
  };
  return (
    <Log
      logImpression={true}
      patientID={props.patientID}
      action="view"
      tag="pilltrack_any_slots"
    >
      <BackButton
        onClick={() => {
          navigate(-1);
        }}
      />
      <StyledGrid>
        <PillTrackSlotsImage>
          <ShimmerImage
            imgHeight={561}
            imgWidth={684}
            src={PillTrackSlots}
            widthPercentage={0.75}
          />
        </PillTrackSlotsImage>
        <Title>{title}</Title>
        <StyledH5>{instructions}</StyledH5>
        <Center>
          <StyledButton variant="primary" size="lg" onClick={nextStep}>
            <StyledH4 className="gemOnboardButtonFont">Complete!</StyledH4>
          </StyledButton>
        </Center>
      </StyledGrid>
    </Log>
  );
}

const Title = styled.h2`
  color: #e85b81;
  font-family: "Manrope", sans-serif;
  text-align: center;
  font-weight: bold;
  padding-left: 48px;
  padding-right: 48px;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-row-gap: 27px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const PillTrackSlotsImage = styled.div`
  padding-top: 24px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
`;

const StyledH4 = styled.h4`
  font-family: "Manrope", sans-serif;
  text-align: center;
  padding-top: 5px;
`;

const StyledH5 = styled.h5`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #666666;
  padding-top: 10px;
  padding-left: 48px;
  padding-right: 48px;
`;

const StyledButton = styled(Button)`
  background: #e85b81;
  :disabled {
    background: #d1d1d1;
    border-color: #d1d1d1;
  }
  :focus {
    background: #e85b81;
    border-color: #e85b81;
  }
  :active {
    background: #e85b81;
    border-color: #e85b81;
  }
  :hover {
    background: #e85b81;
    border-color: #e85b81;
  }
  border-radius: 10px;
  border-color: #e85b81;
  width: 260px;
  text-align: center;
`;

const Center = styled.div`
  text-align: center;
`;

export default PillTrackAnySlot;
