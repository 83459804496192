import { useEffect, useState } from "react";
import axios from "axios";
import jwt from "jsonwebtoken";
import { PUBLIC_KEY } from "../../app/constants";
import { API_SERVER_URL } from "../../app/constants";
import { useLocation } from "react-router-dom";
import { trackEventAthelasOne } from "../../app/utils/logging.ts";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Modal from "react-modal";
import {Contract} from "./Contract"
import TextField from "@mui/material/TextField";
import { validateFields } from "./ValidateSubmission";
import Spinner from "react-bootstrap/Spinner"




function AthelasOneOnboarding() {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const [showContractModal, setShowContractModal] = useState(false);
    const [token, setToken] = useState(null);
    const [decodedTokenState, setDecodedTokenState] = useState(null);
    const [allDetails, setAllDetails] = useState(null);
    const [agreeToContract, setAgreeToContract] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [numDevices, setNumDevices] = useState(0);
    const [numStrips, setNumStrips] = useState(0);
    const [billingContactFirstName, setBillingContactFirstName] = useState("");
    const [billingContactLastName, setBillingContactLastName] = useState("");
    const [billingContactEmail, setBillingContactEmail] = useState("");
    const [siteID, setSiteID] = useState("");
    const [tokenFailed, setTokenFailed] = useState(false);
    const [devicePrice, setDevicePrice] = useState(0);
    const [stripPrice, setStripPrice] = useState(0);
    const [implementationFee, setImplementationFee] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [mrpeasyShippingInformationID, setMRPeasyShippingInformationID] = useState(null);

    const textFieldStyle = {
      backgroundColor: "#E1E1E1",
      fontSize: "20px",
      borderRadius: "1px",
      fontWeight: 400,
      lineHeight: "normal",
      paddingLeft: '1%'
    }
    const modalStyles = {
      content: {
        outerWidth: "30%"
      },
    }

    function checkBlacklist(token) {
      const headers = {};
      axios.get(
        `${API_SERVER_URL}/athelas_one/is_token_blacklisted/${token}`,
        {},
        {
          headers,
        }
        )
        .then((response) => {
          setTokenFailed(false);
        })
        .catch((e) => {
          if (e.response.status === 401 && e.response.data === 'Unauthorized') {
            setTokenFailed(true);
          }
        });
    }

    function fetchAllOnboardingDetails(siteID) {
      const headers = {};
      headers['Authorization'] = 'Bearer ' + token;
      axios.get(
        `${API_SERVER_URL}/athelas_one/fetch_onboarding_details/${siteID}`,
        {},
        {
          headers,
        }
        )
        .then((response) => {
          const details = response.data.details
          setAllDetails(details)
          setBillingContactFirstName(details.billing_contact_first_name)
          setBillingContactLastName(details.billing_contact_last_name)
          setBillingContactEmail(details.billing_contact_email)
          setDevicePrice(details.athelas_one_device_price)
          setStripPrice(details.athelas_one_strip_price)
          setImplementationFee(details.athelas_one_implementation_fee)
          setNumStrips(details.num_strips)
          setNumDevices(details.num_devices)
          setMRPeasyShippingInformationID(details.mrpeasy_shipping_information_id)
          return
        });
    }

    useEffect(() => {
        Modal.setAppElement('body')
        const tokenFromURL = query.get("token");
        checkBlacklist(tokenFromURL)
        if (!tokenFailed) {
          setToken(tokenFromURL)
          if (decodedTokenState === null) {
            try {
              const decodedToken = jwt.verify(tokenFromURL, PUBLIC_KEY, {
                  algorithms: ["RS256"],
              })
              setDecodedTokenState(decodedToken)
              setSiteID(decodedToken.site_id)
              setUserEmail(decodedToken.user_email)
              fetchAllOnboardingDetails(decodedToken.site_id)
            } catch(e) {
              setTokenFailed(true);
            }
          }
        }
    // eslint-disable-next-line
    }, [query, decodedTokenState, tokenFailed]);

    function toggleModal() {
      setShowContractModal(!showContractModal)
    }

    function toggleAgreeCheckBox() {
      setAgreeToContract(!agreeToContract)
    }

    function handlePasswordChange(e) {
      setNewPassword(e.target.value)
    }

    function handleConfirmPasswordChange(e) {
      setConfirmPassword(e.target.value)
    }

    function handleBillingContactFirstNameChange(e) {
      setBillingContactFirstName(e.target.value)
    }

    function handleBillingContactLastNameChange(e) {
      setBillingContactLastName(e.target.value)
    }

    function handleBillingContactEmailChange(e) {
      setBillingContactEmail(e.target.value)
    }

    function handleSubmission() {
      setIsLoading(true)
      const validation = validateFields(newPassword, confirmPassword, numDevices, numStrips, agreeToContract, billingContactFirstName, billingContactLastName, billingContactEmail)
      if (validation.ok) {
        trackEventAthelasOne("click", siteID, "athelas_one_omboarding", "agree_to_contract")
        const headers = {};
        headers['Authorization'] = 'Bearer ' + token;
        axios.post(
          `${API_SERVER_URL}/athelas_one/complete_onboarding`,
          {
            site_id: siteID,
            new_password: newPassword.trim(),
            num_devices: parseInt(numDevices),
            num_strips: parseInt(numStrips),
            billing_contact_first_name: billingContactFirstName.trim(),
            billing_contact_last_name: billingContactLastName.trim(),
            billing_contact_email: billingContactEmail.trim(),
            mrpeasy_shipping_information_id: mrpeasyShippingInformationID
          },
          {
            headers,
          }
          )
          .then((response) => {
            setIsLoading(false)
            if (response.status === 200) {
                alert("Success")
                window.open(
                  "https://clinic.athelas.com"
                )
            }

          }).catch((e) => {
            if (e.response.status === 400 && e.response.data.message === "ROUTABLE CREATE ERROR") {
              alert("Please try using a different Billing Contact Email: " + billingContactEmail + " may already be used. If this issue persists, please contact Athelas Support")
            } else {
              alert("There was an issue submitting your order. Please contact Athelas Support.")
            }
            setIsLoading(false)
          });
      } else {
        alert(validation.message)
        setIsLoading(false)
      }
    }

    const contractModal = () => {
        const header = `Athelas Home Service Agreement`;
        return (
          <div>
            <Modal
              isOpen={showContractModal}
              onAfterOpen={() => {}}
              onRequestClose={toggleModal}
              style={modalStyles}
              contentLabel="Contract Modal"
            >
              <button onClick = {toggleModal} style = {{float: 'right'}}>X</button>
              <h5>{header}</h5>
              <Contract 
                allDetails={allDetails}
              />
            </Modal>
          </div>
        );
      };
    
    return tokenFailed ? <Center>Your onboarding session has expired.<br></br>If you have any questions, please contact Athelas Support.</Center> :
        <Center>
          <h2 style={{textDecoration: 'underline', textDecorationThickness: '2px'}}>Athelas Home Onboarding</h2>
          <p></p>
          <button onClick = {toggleModal}>Click To Read Service Agreement</button>
          <p></p>
          <div>{contractModal()}</div>
          <Row>
            <input
                type="checkbox"
                checked={agreeToContract}
                onChange={toggleAgreeCheckBox}
            />
            <p> Check here to indicate you have read and agree to the Business Associate Agreement and the Athelas Terms of Service detailed above.</p>
          </Row>
          <hr></hr>
          <h4 style={{textDecoration: 'underline'}}>Set Password</h4>
          <p>New Username: {userEmail}</p>
          <TextField
            required
            id="user_password"
            type="password"
            variant="standard"
            placeholder="New Password"
            onChange={handlePasswordChange}
            color="error"
            value={newPassword}
            InputProps={{
              sx: {
                shrink: true
              },
            }}
            sx={textFieldStyle}
          />
          <p></p>
          <TextField
            required
            id="user_confirm_password"
            type="password"
            variant="standard"
            placeholder="Confirm Password"
            onChange={handleConfirmPasswordChange}
            color="error"
            value={confirmPassword}
            InputProps={{
              sx: {
                shrink: true
              },
            }}
            sx={textFieldStyle}
          />
          <p></p>
          <hr></hr>
          <h4 style={{textDecoration: 'underline'}}>Order Details</h4>
          <table className={`table table-bordered`}>
            <thead className='thead-light'>
              <tr>
                <th></th>
                <th>Amount</th>
                <th>Cost</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Fee</th>
                <td>1</td>
                <td>${implementationFee.toLocaleString('en-US')}</td>
                <td>${implementationFee.toLocaleString('en-US')}</td>
              </tr>
              <tr>
                <th scope="row">Devices</th>
                <td>{numDevices}</td>
                <td>${devicePrice.toLocaleString('en-US')}</td>
                <td>${(numDevices*devicePrice).toLocaleString('en-US')}</td>
              </tr>
              <tr>
                <th scope="row">Strips</th>
                <td>{numStrips}</td>
                <td>${stripPrice.toLocaleString('en-US')}</td>
                <td>${(numStrips*stripPrice).toLocaleString('en-US')}</td>
              </tr>
              <tr>
                <th scope="row">Order Total</th>
                <td>-</td>
                <td>-</td>
                <td>${((numDevices*devicePrice) + (numStrips*stripPrice) + (implementationFee)).toLocaleString('en-US')}</td>
              </tr>
            </tbody>
          </table>
          <p>Billing Contact First Name</p>
          <TextField
            autoFocus
            id="billing_contact_first_name"
            type="text"
            variant="standard"
            color="error"
            value={billingContactFirstName}
            onChange={handleBillingContactFirstNameChange}
            InputProps={{
              sx: {
              },
            }}
            sx={textFieldStyle}
          />
          <p></p>
          <p>Billing Contact Last Name</p>
          <TextField
            autoFocus
            id="billing_contact_last_name"
            type="text"
            variant="standard"
            color="error"
            value={billingContactLastName}
            onChange={handleBillingContactLastNameChange}
            InputProps={{
              sx: {
              },
            }}
            sx={textFieldStyle}
          />
          <p></p>
          <p>Billing Contact Email</p>
          <TextField
            autoFocus
            id="billing_contact_email"
            type="text"
            variant="standard"
            color="error"
            value={billingContactEmail}
            onChange={handleBillingContactEmailChange}
            InputProps={{
              sx: {
              },
            }}
            sx={textFieldStyle}
          />
          <p></p>
          <p></p>
          <StyledButton onClick={handleSubmission} disabled={isLoading}>
              <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              hidden={!isLoading}
            /> 
            {isLoading ? "" : "Set Password and Place Order"}</StyledButton>
        </Center>
    
}

const Center = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 1%;
  margin-right: 30%; 
  margin-left: 30%;
  margin-bottom: 5%;
`;

const Row = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  background: #1C4F73;
  :disabled {
    background: #d1d1d1;
    border-color: #d1d1d1;
  }
  :focus {
    background: #1C4F73;
    border-color: #1C4F73;
  }
  :active {
    background: #1C4F73;
    border-color: #1C4F73;
  }
  :hover {
    background: #12334B;
    border-color: #12334B;
  }
  border-radius: 4px;
  border-color: #1C4F73;
  text-align: center;
`;


export default AthelasOneOnboarding;