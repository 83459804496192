import React from "react";

type PropsType = {
  palomar: boolean;
  site_logo?: null | string;
};

const styles: any = {
  container: {
    width: "100%",
    minHeight: 200,
  },
  innerContainer: {
    backgroundColor: "#F4F8FD",
    maxWidth: "1000px",
    margin: "0% auto",
    textAlign: "center",
    padding: 60,
    borderRadius: 20,
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 24,
  },
  getStartedButton: {
    backgroundColor: "#FF6779",
    border: "solid 3px #FF6779",
    padding: 24,
    paddingTop: 16,
    paddingBottom: 16,
    color: "white",
    fontWeight: "bold",
    marginRight: 24,
    borderRadius: 8,
    cursor: "pointer",
  },
  getStartedButtonPalomar: {
    backgroundColor: "#008CC1",
    border: "solid 3px #008CC1",
    padding: 24,
    paddingTop: 16,
    paddingBottom: 16,
    color: "white",
    fontWeight: "bold",
    marginRight: 24,
    borderRadius: 8,
    cursor: "pointer",
  },
  visitAthelasButton: {
    backgroundColor: "rgba(0,0,0,0)",
    border: "solid 3px #FF6779",
    padding: 24,
    paddingTop: 16,
    paddingBottom: 16,
    color: "#FF6779",
    fontWeight: "bold",
    borderRadius: 8,
    cursor: "pointer",
  },
  visitAthelasButtonPalomar: {
    backgroundColor: "rgba(0,0,0,0)",
    border: "solid 3px #008CC1",
    padding: 24,
    paddingTop: 16,
    paddingBottom: 16,
    color: "#008CC1",
    fontWeight: "bold",
    borderRadius: 8,
    cursor: "pointer",
  },
  logo: {
    maxWidth: "140px",
    maxHeight: "80px",
    margin: 16,
    marginRight: 24,
    marginLeft: 24,
    objectFit: "contain",
  },
  logoContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    marginBottom: 12,
    marginTop: -30,
  },
};

export const GetStarted = (props: PropsType) => {
  const fontColor = props.palomar ? "#008CC1" : "#FF6779";
  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        {props.site_logo && (
          <div style={styles.logoContainer}>
            <img src={props.site_logo} style={styles.logo} alt="Site logo" />
          </div>
        )}
        <h1>
          Ready to <span style={{ color: fontColor }}>Get Started?</span>
        </h1>
        <p
          style={{
            fontSize: "120%",
            fontWeight: "bold",
            maxWidth: "500px",
            margin: "0% auto",
          }}
        >
          Join thousands of patients across the country who use Athelas Remote
          Patient Monitoring!
        </p>

        <div style={styles.flexBox}>
          <div
            style={
              props.palomar
                ? styles.getStartedButtonPalomar
                : styles.getStartedButton
            }
            onClick={() => window.open("https://onboarding.athelas.com/")}
          >
            Get Started Today
          </div>
          <div
            style={
              props.palomar
                ? styles.visitAthelasButtonPalomar
                : styles.visitAthelasButton
            }
            onClick={() => window.open("http://athelas.com/")}
          >
            Visit Athelas Website
          </div>
        </div>
      </div>
    </div>
  );
};
