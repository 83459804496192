import React, { Component } from 'react';

function BottleStatus(props) {

    let bottle_one_present = props.bottles_present[0]
    let bottle_two_present = props.bottles_present[1]
    let bottle_three_present = props.bottles_present[2]
    let bottle_four_present = props.bottles_present[3]

	return (
        <div class="bottles">
          <div class={bottle_one_present ? "bottle present" : "bottle"}>
            <div class={bottle_two_present ? "bottle bottle-two present" : "bottle bottle-two"}>

            </div>
          </div>
          <div class={bottle_three_present ? "bottle present mt-3" : "bottle mt-3"}>
            <div class={bottle_four_present ? "bottle bottle-four present" : "bottle bottle-four"}>

            </div>
          </div>
        </div>
	)
}

class Bottles extends Component {

  render() {
    let num_bottles_present = this.props.bottles_present.reduce((a,b) => a + b, 0)

    const missing_bottles = () => {
      return (
        <div>
          <p> <b> Please connect the remaining {4 - num_bottles_present} {4 - num_bottles_present === 1 ? 'bottle' : 'bottles'} to the device. </b></p>
          <p> If a bottle is incorrectly shown as missing, please pick up and re-insert the bottle.</p>
        </div>

      )
    }

    return (
      <div class="form-container gem-container my-5">
        <div class="pt-4 pb-4">
          <h1> Pill Bottles </h1>
        </div>
        {num_bottles_present < 4 &&
          <p> We detected some missing bottles! </p>
        }
		<BottleStatus bottles_present={this.props.bottles_present} />
        <div class="px-5 pt-3">
          {num_bottles_present < 4
            ? missing_bottles()
            : <p> All bottles are connected! </p>
          }
        </div>
        {num_bottles_present === 4 && this.props.online && 
          <div class="pt-3 pb-4">
            <button onClick={this.props.nextStep} type="submit" class="btn light-btn">Next</button>
          </div>
        }
      </div>
    )
  }

}

export {Bottles, BottleStatus}
