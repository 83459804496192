import React, { Component } from "react";
import { unsubscribeFromOnboardingEmails } from "./UnsubscribeApi";

class Unsubscribe extends Component {
  constructor(ownProps) {
    super(ownProps);

    this.state = { email: "" };
  }

  componentDidMount() {}

  unsubscribe(email) {
    unsubscribeFromOnboardingEmails(email)
      .then((res) => {
        alert("Successfully unsubscribed from Athelas emails.");
      })
      .catch((err) => {
        alert(
          "We could not find a patient with the specified email. Please contact Athelas support for further assistance."
        );
      });
  }

  setEmail(email) {
    this.setState({ email: email });
  }

  render() {
    return (
      <div class="form-container mt-5" style={{ marginBottom: 250 }}>
        {this.props.practiceLogo && (
          <img
            src={this.props.practiceLogo}
            alt="practice logo"
            style={{
              width: 150,
              height: 150,
              objectFit: "contain",
              marginTop: 16,
            }}
          />
        )}
        <div class="mt-2 p-4">
          <h1> Unsubscribe </h1>
        </div>
        <div class="px-5 pb-1" style={{ marginTop: "-16px" }}>
          <p>
            Enter your email below to unsubscribe from all Athelas email
            correspondence regarding remote patient monitoring.
          </p>
        </div>

        <div class="px-5 pt-4 input-group-lg">
          <input
            type="text"
            className={"form-control"}
            placeholder="Email Address"
            value={this.state.email}
            onChange={(e) => this.setEmail(e.target.value)}
          />
        </div>

        <div class="pt-3 pb-3">
          <button
            class="btn light-btn"
            onClick={() => this.unsubscribe(this.state.email)}
          >
            Unsubscribe
          </button>
        </div>
        <p>
          Have any questions? <br />
          Send us a text or call at 1-833-524-1318
        </p>
      </div>
    );
  }
}

export default Unsubscribe;
