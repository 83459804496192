import React from 'react'
import logo from '../../logo.svg';

export default class Header extends React.Component {

    render() {
        return (
            <nav class="navbar navbar-light">
                <div class="container-fluid">
                    <img 
                        src={logo} 
                        alt="logo" 
                        style={{cursor: 'pointer'}} 
                        onClick={() => window.location = '/'}
                    />
                    <div class="d-flex" style={{marginBottom: 3}}>
                        <a href="#faq"><button class="btn dark-btn" type="submit">FAQ</button></a>
                    </div>
                </div>
            </nav>
        );
    }

}