import { PUBLIC_KEY } from '../../app/constants'
import jwt from 'jsonwebtoken'

export function getToken() {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token')

    if (!token) {
        return null
    }
    
    try {
        const decodedToken = jwt.verify(token, PUBLIC_KEY, {
            algorithms: ['RS256']
        })
        return decodedToken;
    } catch {
        return null
    }

}

export function rawToken() { 
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token')
    return token;
}
