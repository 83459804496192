import React, { useState } from "react";
import DownloadApp from "./assets_1.0/med adherence.webp";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import BackButton from "./BackButton";
import styled from "styled-components";
import { getMobileOperatingSystem } from "./API";
import Log from "../../app/logging/Log.js";

function PillTrackDownloadApp(props) {
  const navigate = useNavigate();
  const [loadedImage, setLoadedImage] = useState(false);
  const title = "You're all set!";
  const instructions =
    "After taking a medication, use the Athelas Patient Portal to monitor or edit your health outcomes. Additionally, please leave the device plugged in.";

  const goToHome = () => {
    navigate("/pilltrack");
  };
  const nextStep = () => {
    const system = getMobileOperatingSystem();
    let link =
      "https://apps.apple.com/us/app/athelas/id1610811347?platform=iphone";
    switch (system) {
      case "Windows Phone":
        link =
          "https://play.google.com/store/apps/details?id=com.athelas.patientportal";
        break;
      case "Android":
        link =
          "https://play.google.com/store/apps/details?id=com.athelas.patientportal";
        break;
      case "iOS":
        link =
          "https://apps.apple.com/us/app/athelas/id1610811347?platform=iphone";
        break;
      default:
        link =
          "https://apps.apple.com/us/app/athelas/id1610811347?platform=iphone";
        break;
    }
    window.open(link, "_blank");
  };
  return (
    <Log
      logImpression={true}
      patientID={props.patientID}
      action="view"
      tag="pilltrack_download_app"
    >
      <div className={!loadedImage ? "hidden" : undefined}>
        <BackButton
          onClick={() => {
            navigate(-1);
          }}
        />
        <StyledGrid>
          <DownloadAppImage
            className="gemImage"
            onLoad={() => {
              setLoadedImage(true);
            }}
            src={DownloadApp}
            alt="PillTrackSlots"
          />
          <Title>{title}</Title>
          <StyledH5>{instructions}</StyledH5>
          <Center>
            <StyledButton variant="primary" size="lg" onClick={nextStep}>
              <StyledH4 className="gemOnboardButtonFont">Download App</StyledH4>
            </StyledButton>
            <StyledWhiteButton variant="primary" size="lg" onClick={goToHome}>
              <StyledPinkH4 className="gemOnboardButtonFont">
                Go Back to Home
              </StyledPinkH4>
            </StyledWhiteButton>
          </Center>
        </StyledGrid>
      </div>
    </Log>
  );
}

const Title = styled.h2`
  color: #e85b81;
  font-family: "Manrope", sans-serif;
  text-align: center;
  font-weight: bold;
  padding-left: 48px;
  padding-right: 48px;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-row-gap: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const DownloadAppImage = styled.img`
  padding-top: 24px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 35%;
  width: 35%;
  margin-bottom: 10px;
`;

const StyledH4 = styled.h4`
  font-family: "Manrope", sans-serif;
  text-align: center;
  padding-top: 5px;
`;

const StyledPinkH4 = styled.h4`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #e85b81;
  padding-top: 5px;
`;

const StyledH5 = styled.h5`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #666666;
  padding-left: 48px;
  padding-right: 48px;
`;

const StyledButton = styled(Button)`
  background: #e85b81;
  :disabled {
    background: #d1d1d1;
    border-color: #d1d1d1;
  }
  :focus {
    background: #e85b81;
    border-color: #e85b81;
  }
  :active {
    background: #e85b81;
    border-color: #e85b81;
  }
  :hover {
    background: #e85b81;
    border-color: #e85b81;
  }
  border-radius: 10px;
  border-color: #e85b81;
  width: 260px;
  text-align: center;
`;

const StyledWhiteButton = styled(Button)`
  background: #ffffff;
  :disabled {
    background: #d1d1d1;
    border-color: #d1d1d1;
  }
  :focus {
    background: #ffffff;
    border-color: #e85b81;
  }
  :active {
    background: #ffffff;
    border-color: #e85b81;
  }
  :hover {
    background: #ffffff;
    border-color: #e85b81;
  }
  border-radius: 10px;
  border-color: #e85b81;
  width: 260px;
  text-align: center;
  margin-top: 24px;
`;

const Center = styled.div`
  text-align: center;
`;

export default PillTrackDownloadApp;
