import React from 'react'

export default class Result extends React.Component {

    render() {
        return (
            <div class="form-container">
                <div class="mt-2 p-4">
                    <h1>Failed!</h1>
                </div>
                <div class="px-5 pb-3">
                    <p>Your information failed to save. Please try again or contact support@athelas.com if this message persists.</p>
                </div>
                <div class="pb-5">
                    <a href="/updatePatientInfo"><button class="btn light-btn" type="submit">Retry</button></a>
                </div>
            </div>
        );
    }

}