import React, { Component } from "react";

import { saveUrlToken } from "../login/LoginSlice";
import { withRouter } from "../../wrappers/withRouter";
import { connect } from "react-redux";

const mapDispatch = { saveUrlToken };

class CareCampaign extends Component {
  render() {
    let body = document.getElementsByTagName("body")[0];
    body.style.backgroundImage = "none";

    return (
      <div className="container mb-5">
        <div class="row">
          <div
            class="col-md"
            style={{ paddingTop: 300, height: 1000, textAlign: "center" }}
          >
            <h2>Sorry, the Care Campaign has been discontinued.</h2>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, mapDispatch)(withRouter(CareCampaign));
