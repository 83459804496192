import React, { Component } from "react";
import { ProgressBar } from "../../app/Atoms/ProgressBar/ProgressBar.js";
import { getToken, rawToken } from "../../app/utils/token.js";
import { submitTotalCareOnboarding } from "./actions.js";

const TOTAL_CARE_ONBOARDING_REQUIRED_FIELDS = [
  {
    key: "currentWeight",
    name: "Current Weight (in pounds)",
    placeholder: "ex. 173",
    type: "number",
  },
  {
    key: "dietaryInfo",
    name: "Dietary Info",
    placeholder: "ex. vegetarian",
    type: "text",
  },
  {
    key: "allergies",
    name: "Allergy / Food Restrictions",
    placeholder: "ex. no eggs",
    type: "text",
  },
  {
    key: "exerciseInfo",
    name: "Exercise Info",
    placeholder: "ex. I play tennis and jog sometimes",
    type: "text",
  },
];
export class TotalCareSubmit extends Component {
  constructor(props) {
    super(props);

    const token = getToken() || null;

    if (!token) {
      window.location = "/";
    }

    this.state = {
      currentWeight: this.props.currentWeight,
      goalWeight: this.props.goalWeight,
      feet: parseFloat(this.props.feet),
      inches: parseFloat(this.props.inches),
      dietaryInfo: this.props.dietaryInfo,
      exerciseInfo: this.props.exerciseInfo,
      allergies: this.props.allergies,
      token: token,
      rawToken: rawToken(),
      onboarded: false,
    };
  }

  submit = () => {
    const patientId = this.state.token.sub;
    const height =
      parseFloat(this.state.feet) * 12 + parseFloat(this.state.inches);

    submitTotalCareOnboarding(
      this.state.rawToken,
      patientId,
      this.state.currentWeight,
      this.state.goalWeight,
      height,
      this.state.dietaryInfo,
      this.state.exerciseInfo,
      this.state.allergies
    ).then((res) => {
      if (res.status === 200) {
        this.props.setMode("complete");
      }
    });
  };

  updateValue = (key, value) => {
    const update = {};
    update[key] = value;
    this.setState(update);
  };

  render() {
    const firstName = this.state.token?.first_name || "Hello";

    return (
      <div
        class="form-container mt-5"
        style={{ marginBottom: "48px", paddingBottom: "48px" }}
      >
        <div class="p-4">
          <h2>Athelas Total Care Program</h2>

          <span>
            Great {firstName}! Double check this data and then let's get
            started.
          </span>
        </div>

        {TOTAL_CARE_ONBOARDING_REQUIRED_FIELDS.map(
          ({ key, name, placeholder, type }) => {
            return (
              <div class="px-5 pt-4 input-group-lg">
                <label style={{ textAlign: "left", width: "100%" }}>
                  {name}
                </label>
                <input
                  type={type}
                  className={"form-control"}
                  placeholder={placeholder}
                  value={this.state[key]}
                  key={key}
                  onChange={(e) => this.updateValue(key, e.target.value)}
                  disabled={this.state.onboarded}
                />
              </div>
            );
          }
        )}

        <div class="px-5 pt-4">
          <div
            style={{ flexDirection: "row", display: "flex" }}
            className="input-group-lg"
          >
            <div>
              <span>Feet</span>
              <input
                type="number"
                className={"form-control"}
                style={{ width: "90%" }}
                placeholder={"Feet"}
                value={this.state.feet}
                onChange={(e) => this.updateValue("feet", e.target.value)}
                disabled={this.state.onboarded}
              />
            </div>
            <div>
              <span>Inches</span>
              <input
                type="number"
                className={"form-control"}
                placeholder={"Inches"}
                style={{ width: "90%", marginLeft: '5%' }}
                value={this.state.inches}
                onChange={(e) => this.updateValue("inches", e.target.value)}
                disabled={this.state.onboarded}
              />
            </div>
          </div>
        </div>

        {!this.state.onboarded && (
          <div class="pt-5 pb-5">
            <p style={{ width: "80%", marginLeft: "10%" }}>
              After clicking this button, you will receive a text message from
              us with next steps.
            </p>
            <button class="btn light-btn" onClick={this.submit}>
              Begin Program
            </button>
          </div>
        )}

        <ProgressBar percent={89} />
      </div>
    );
  }
}
