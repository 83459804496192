import axios from "axios";
import { API_SERVER_URL } from "../../../app/constants";
import { ONBOARDING_FLOW } from "./constants";

export const EXISTING_ONBOARDING_KEY = "EXISTING_ONBOARDING";

export const getPatientOnboardingFromLocalStorage = (attribute: string) => {
  const onboarding = localStorage.getItem(EXISTING_ONBOARDING_KEY);
  if (!onboarding) {
    return {};
  }

  const onboardingDict = JSON.parse(onboarding);
  return onboardingDict[attribute];
};

const updatePatientLocalStorage = (key: string, payload: any) => {
  let patientString = localStorage.getItem(key);
  if (!patientString) {
    patientString = "{}";
  }
  let patient: any = JSON.parse(patientString);
  Object.keys(payload).map((key) => {
    patient[key] = payload[key];
    return null;
  });
  localStorage.setItem(key, JSON.stringify(payload));
};

export const login = (
  date_of_birth: string,
  first_name: string,
  last_name: string
) => {
  let transformed_date_of_birth = date_of_birth;
  let match: any = date_of_birth.match(/(\d{2})\/(\d{2})\/(\d{4})/);

  transformed_date_of_birth = `${match[3]}-${match[1]}-${match[2]}`;

  return new Promise((resolve, reject) => {
    axios
      .post(
        `${API_SERVER_URL}/patients/onboarding_sign_in`,
        {
          first_name: first_name,
          last_name: last_name,
          date_of_birth: transformed_date_of_birth,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "",
          },
        }
      )
      .then((response) => {
        const token = response.data.token;

        axios
          .get(`${API_SERVER_URL}/patients/get_onboarding_info`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            const onboardingInfo = res.data;
            updatePatientLocalStorage(EXISTING_ONBOARDING_KEY, onboardingInfo);
            return true;
          })
          .then((status) => {
            updatePatientLocalStorage("BASIC_INFORMATION", {
              token: token,
              date_of_birth: transformed_date_of_birth,
              first_name: first_name,
              last_name: last_name,
            });

            resolve(token);
          });
      })
      .catch((x) => {
        reject(x);
      });
  });
};

export const updateShippingAddress = (
  address: string,
  city: string,
  state: string,
  zip: string
) => {
  updatePatientLocalStorage("SHIPPING_ADDRESS", {
    address_line1: address,
    address_line2: "",
    city_locality: city,
    state_province: state,
    postal_code: zip,
  });
};

export const updateInsuranceInformation = (
  insurance_company: string,
  policy_number: string
) => {
  updatePatientLocalStorage("INSURANCE", {
    insurance_company: insurance_company,
    insurance_number: policy_number,
  });
};

export const updateConfirmationInformation = (
  acknowledged: boolean,
  source: string
) => {
  updatePatientLocalStorage("CONFIRMATION", {
    acknowledged: acknowledged,
    acknowledged_by: "PATIENT",
  });

  const totalOnboardingData: any = {};

  Object.keys(ONBOARDING_FLOW).map((key: string) => {
    const dataString = localStorage.getItem(key);
    if (dataString) {
      const onboardingStepData = JSON.parse(dataString);

      Object.keys(onboardingStepData).map((attr: string) => {
        totalOnboardingData[attr] = onboardingStepData[attr];

        return null;
      });
    }

    return null;
  });

  const token = totalOnboardingData["token"];

  return axios.post(
    `${API_SERVER_URL}/patients/update_onboarding_info`,
    {
      patient: totalOnboardingData,
      source: source,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
