import React from "react";
import PillTrack from "./assets_1.0/PillTrack 1.0.webp";
import BackButton from "./BackButton";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Log from "../../app/logging/Log.js";
import ShimmerImage from "../../app/utils/ShimmerImage";

function PillTrackComplete(props) {
  const instructions =
    "Your PillTrack is all set and ready to be used. If you have any questions, we encourage you to contact us at ";

  const navigate = useNavigate();

  return (
    <Log
      logImpression={true}
      patientID={props.patientID}
      action="view"
      tag="pilltrack_complete"
    >
      <BackButton
        onClick={() => {
          navigate(-1);
        }}
      />
      <StyledGrid>
        <PillTrackImage>
          <ShimmerImage imgHeight={1229} imgWidth={2235} src={PillTrack} widthPercentage={0.75} />
        </PillTrackImage>
        <Title>Enjoy your PillTrack</Title>
        <div>
          <StyledH5>{instructions}</StyledH5>
          <StyledPinkH5 className="pinkText">833-524-1318</StyledPinkH5>
        </div>
      </StyledGrid>
    </Log>
  );
}

const Title = styled.h2`
  color: #e85b81;
  font-family: "Manrope", sans-serif;
  text-align: center;
  font-weight: bold;
  padding-left: 48px;
  padding-right: 48px;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-row-gap: 27px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const PillTrackImage = styled.div`
  padding-top: 24px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  margin-top: 40px;
`;

const StyledH5 = styled.h5`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #666666;
  padding-top: 10px;
  padding-left: 48px;
  padding-right: 48px;
`;

const StyledPinkH5 = styled.h5`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #e85b81;
  padding-left: 48px;
  padding-right: 48px;
`;

export default PillTrackComplete;
