import React, { Component } from "react";
import { ProgressBar } from "../../app/Atoms/ProgressBar/ProgressBar";
import CheckmarkImage from "./assets/checkmark.png";
export class TotalCareComplete extends Component {
  nextStep = () => {
    this.props.setMode("form");
  };

  render() {
    return (
      <div
        class="form-container mt-5"
        style={{ marginBottom: "48px", paddingBottom: "16px" }}
      >
        <div class="p-4">
          <h2>Athelas Total Care Program</h2>
          <br />
          <img
            src={CheckmarkImage}
            style={{
              width: 80,
              height: 80,
              margin: "0% auto",
              marginBottom: 16,
            }}
            alt="checkmark"
          />

          <ProgressBar percent={100} color={"#29a322"} />

          <p>
            You have succesfully onboarded to our Total Care Program! Here's
            what's next:
          </p>
          <hr></hr>
          <p
            style={{ textAlign: "left", display: "flex", flexDirection: "row" }}
          >
            <span style={{ fontSize: "200%", marginRight: "12px" }}>🥝</span>
            <span style={{ marginTop: "0px" }}>
              1. Your nutritionist will work with you on building a customized,
              personal nutrition plan.
            </span>
          </p>
          <hr></hr>

          <p
            style={{ textAlign: "left", display: "flex", flexDirection: "row" }}
          >
            <span style={{ fontSize: "200%", marginRight: "12px" }}>✨</span>
            <span style={{ marginTop: "0px" }}>
              2. We'll remind you to measure your weight daily so you stay on
              track towards your goal
            </span>
          </p>
          <hr></hr>
          <p
            style={{ textAlign: "left", display: "flex", flexDirection: "row" }}
          >
            <span style={{ fontSize: "200%", marginRight: "12px" }}>🩺</span>
            <span style={{ marginTop: "0px" }}>
              3. We'll share your progress with your doctor so they are in the
              loop.
            </span>
          </p>

          <hr></hr>

          <p>
            Please contact us at support@athelas.com if you have any questions.
          </p>
        </div>
      </div>
    );
  }
}
