import React, { useState } from "react";
import ShadesTopRight from "../revampedOnboarding/assets/shades.png";
import ShadesBottomLeft from "../revampedOnboarding/assets/shadesBottomLeft.png";
import ArrowLeft from "../revampedOnboarding/assets/arrow_left.png";
import ArrowRight from "../revampedOnboarding/assets/arrow_right.png";

import { RPMDeviceConstants } from "./AthelasRPMDeviceConstants";

import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

type PropsType = {};

const styles: any = {
  container: {
    backgroundColor: "#70869B",
    padding: 24,
    paddingTop: 48,
    paddingBottom: 48,
    color: "white",
    position: "relative",
  },
  detail: {
    textTransform: "uppercase",
    fontSize: "110%",
    marginBottom: 8,
  },
  innerContainer: {
    maxWidth: 1000,
    margin: "0% auto",
    flexDirection: "row",
    display: "flex",
  },
  innerContainerMobile: {
    maxWidth: 1000,
    margin: "0% auto",
  },
  deviceName: {
    fontWeight: "bold",
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 16,
  },
  icons: { width: 40, height: 40, objectFit: "contain" },
  proPoints: { marginTop: -4, marginLeft: 16, maxWidth: 350 },
  shadesTopRight: {
    position: "absolute",
    width: 150,
    height: 150,
    top: "0px",
    right: "0px",
  },
  shadesBottomLeft: {
    position: "absolute",
    width: 150,
    height: 150,
    bottom: "0px",
    left: "0px",
  },
};

export const AthelasRPMDevice = (props: PropsType) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  const [index, setIndex] = useState(0);
  const maxIndex = Object.keys(RPMDeviceConstants).length;

  const nextIndex = () => {
    if (index >= maxIndex - 1) {
      setIndex(0);
      return;
    }
    setIndex(index + 1);
  };

  const previousIndex = () => {
    if (index === 0) {
      setIndex(maxIndex - 1);
      return;
    }
    setIndex(index - 1);
  };

  const key: string = Object.keys(RPMDeviceConstants)[index];
  const obj: any = RPMDeviceConstants[key];

  const title: string = obj["title"];
  const img: any = obj["img"];
  const bullets: any = obj["bullets"];

  return (
    <div style={styles.container}>
      <img src={ShadesTopRight} alt="shades" style={styles.shadesTopRight} />
      <img
        src={ShadesBottomLeft}
        alt="shades"
        style={styles.shadesBottomLeft}
      />
      <>
        <div
          style={
            isTabletOrMobile
              ? styles.innerContainerMobile
              : styles.innerContainer
          }
        >
          <div style={{ width: isTabletOrMobile ? "100%" : "50%" }}>
            <p style={styles.detail}>Athelas RPM Device</p>
            <h2 style={styles.deviceName}>{title}</h2>
            <div style={{ marginTop: 24 }}>
              {bullets.map(({ icon, text }: any) => {
                return (
                  <>
                    <div style={styles.flexBox}>
                      <img
                        src={icon}
                        alt="SIM Connected Devices"
                        style={styles.icons}
                      />
                      <p style={styles.proPoints}>{text}</p>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          <div
            style={{
              width: isTabletOrMobile ? "100%" : "50%",
              flexDirection: "row",
              display: "flex",
            }}
          >
            <ArrowStyling
              src={ArrowLeft}
              alt="right arrow"
              onClick={previousIndex}
            />

            <img
              src={img}
              alt="Blood Pressure cuff"
              style={{ width: "90%", objectFit: "contain" }}
            />

            <ArrowStyling
              src={ArrowRight}
              alt="right arrow"
              onClick={nextIndex}
            />
          </div>
        </div>
      </>
    </div>
  );
};

const ArrowStyling = styled.img`
  height: auto;
  max-width: 36px;
  max-height: 36px;
  margin-top: 25%;
  objectfit: contain;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;
