import axios from "axios";
import { NORMANDY_API_SERVER_URL } from "../../app/constants";
import { IPaymentIntentInfoPayload } from "./types";

export const getPatientResponsibility = (getPRPayload: {
  site_id: number;
  patient_responsibility_ids?: number[];
  first_name?: string;
  last_name?: string;
  dob?: string;
  patient_id?: number;
  ignore_prs_from_dependent_patients?: boolean;
}) => {
  const headers = {};
  let payload = {};
  if (
    !getPRPayload.patient_responsibility_ids ||
    getPRPayload.patient_responsibility_ids.length === 0
  ) {
    payload = {
      patient_first_name: getPRPayload.first_name,
      patient_last_name: getPRPayload.last_name,
      patient_date_of_birth: getPRPayload.dob,
      patient_id: getPRPayload.patient_id,
      site_id: getPRPayload.site_id,
      ignore_prs_from_dependent_patients:
        getPRPayload.ignore_prs_from_dependent_patients,
    };
  } else {
    payload = {
      patient_first_name: getPRPayload.first_name,
      patient_last_name: getPRPayload.last_name,
      patient_date_of_birth: getPRPayload.dob,
      patient_id: getPRPayload.patient_id,
      site_id: getPRPayload.site_id,
      pr_request_ids: getPRPayload.patient_responsibility_ids,
      ignore_prs_from_dependent_patients:
        getPRPayload.ignore_prs_from_dependent_patients,
    };
  }
  return axios.post(
    `${NORMANDY_API_SERVER_URL}/patient_pay/get_outstanding_patient_responsibility_v2`,
    payload,
    { headers }
  );
};

export const getPaymentIntentInfoV1 = (payload: IPaymentIntentInfoPayload) => {
  const headers = {};
  return axios.post(
    `${NORMANDY_API_SERVER_URL}/patient_pay/create_patient_pay_payment_intent`,
    {
      is_ach_debit_payment: true,
      use_multiple_payment_methods: true,
      ...payload,
    },
    {
      headers,
    }
  );
};

export async function getSignedReceiptUrl(url: string) {
  const headers = {};
  return axios.get<string>(
    `${NORMANDY_API_SERVER_URL}/patient_pay/sign_receipt_url`,
    {
      headers,
      params: { url },
    }
  );
}

export async function getReceiptFromTransaction(
  transaction_id: string | null,
  send_receipt_to_email?: string
) {
  const headers = {};
  const response = await axios.get<string>(
    `${NORMANDY_API_SERVER_URL}/patient_pay/payment/transaction/${transaction_id}/receipt`,
    {
      headers,
      params: { send_receipt_to_email },
    }
  );
  return await getSignedReceiptUrl(response.data);
}

// Fee calculation functions

export const getPaymentFees = (
  stripe_connect_account_id: string,
  site_id: number,
  payment_method: string,
  payment_intent_id: string
) => {
  const headers = {};

  return axios.get<string>(`${NORMANDY_API_SERVER_URL}/patient_pay/get_fees`, {
    headers,
    params: {
      stripe_connect_account_id,
      site_id,
      payment_method,
      payment_intent_id,
    },
  });
};

export const applyPaymentFees = (
  stripe_connect_account_id: string,
  site_id: number,
  payment_method: string,
  payment_intent_id: string
) => {
  const headers = {};
  return axios.post(
    `${NORMANDY_API_SERVER_URL}/patient_pay/apply_fees`,
    {
      stripe_connect_account_id: stripe_connect_account_id,
      site_id: site_id,
      payment_method: payment_method,
      payment_intent_id: payment_intent_id,
    },
    {
      headers,
    }
  );
};

export const removePaymentIntentFutureUse = (
  stripe_connect_account_id: string,
  payment_intent_id: string
) => {
  const headers = {};
  return axios.post(
    `${NORMANDY_API_SERVER_URL}/patient_pay/remove_payment_intent_future_use`,
    {
      stripe_connect_account_id,
      payment_intent_id,
    },
    {
      headers,
    }
  );
};

export const getSiteLogoUrl = (site_id: number) => {
  return axios.get<string>(
    `${NORMANDY_API_SERVER_URL}/sense/patient_responsibility/site_logo_url/${site_id}`
  );
};

export const getAllowPartialPayment = (site_id: number) => {
  return axios.get<boolean>(
    `${NORMANDY_API_SERVER_URL}/sites/${site_id}/allow_onboarding_partial_payment`
  );
};

export const getShouldRedirectToEasyPay = (payload: {
  siteId?: number;
  token?: number;
}) => {
  const { siteId, token } = payload;

  return axios.post<{
    should_redirect: boolean;
    redirect_url?: string;
  }>(
    `${NORMANDY_API_SERVER_URL}/patient_portal/easy_pay/should_redirect_to_easy_pay`,
    {
      site_id: siteId,
      t: token,
    }
  );
};
