import React from "react";
import { useState, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { createTheme } from "@mui/material/styles";
import { themeOptions } from "../PatientEnrollment/ThemeOptionsPatientEnrollment";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  ThemeProvider,
  Select,
  FormControl,
  FormLabel,
  MenuItem,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Checkbox,
  FormGroup,
  InputLabel,
  FormHelperText,
  Typography,
  Alert,
  CardMedia,
} from "@mui/material";
import InputMask from "react-input-mask";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Box from "@mui/material/Box";
import enrollmentBanner from "../GgpPatientApplication/assets/ggp-main-banner.png";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { ggpPatientSchema } from "./GgpPatientValidationSchema.ts";
import { validateAndCreateGgpProspectivePatient } from "./GgpPatientApplicationAPI.ts";
import SignatureCanvas from "react-signature-canvas";
import "./GgpPatientApplication.css";
import FormData from "form-data";
import html2pdf from "html2pdf.js";
import BeatLoader from "react-spinners/BeatLoader";

export default function GgpPatientApplication(props) {
  const [backendValidationErrors, setBackEndValidationErrors] = useState([]);
  const [formData, setFormData] = useState({});
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false); // State for the checkbox
  const [insuranceSections, setInsuranceSections] = useState([
    { has_insurance_card: "" }
  ]);
  const [uploadedPhoto, setUploadedPhoto] = useState(null);
  const [acknowledgements, setAcknowledgements] = useState({
    ack1: false,
    ack3: false,
    ack5: false,
    ack6: false,
    ack7: false,
  });
  const [medRecordsExceptions, setMedRecordsExceptions] = useState({
    mentalHealthRecords: false,
    drugAlcoholAbuseRecords: false,
    otherRecords: false,
  });
  const [labFrequencySelectedValue, setLabFrequencySelectedValue] =
    useState("weekly");
  const [pdfError, setPdfError] = useState(false);

  const unlicensedStates =["Alabama", "Arkansas", "Kansas", "Kentucky", "Louisiana", "Mississippi", "Oklahoma", "Tennessee", "West Virginia"];
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    watch,
    setFocus,
    getValues,
    setValue,
    reset,
    resetField,
    clearErrors,
    // eslint-disable-next-line no-unused-vars
    formState: { errors, isSubmitted, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(ggpPatientSchema),
    defaultValues: {
      in_hospital: 'NO',
      clozapine_history: 'TAKING_CLOZAPINE', // Add default value here
      // Add other default values if needed
    },
  });

  // Watch the 'in_hospital' field
  const watchInHospital = watch('in_hospital', 'NO'); // Ensure default value is 'NO'

  const watchFirstName = watch("first_name");
  const watchLastName = watch("last_name");
  const watchDob = watch("date_of_birth");
  const watchClozapineHistory = watch("clozapine_history");
  const watchPharmacyFill = watch("fill_medication");
  const watchAllergies = watch("allergies");
  const watchHIPPA = watch("hippa", "YES_HIPPA");
  const watchEffectivePeriod = watch("effective_period", "ALL");
  const watchException = watch("release_of_health_records");
  const watchGgpInterest = watch("ggp_interest");

  const generateFormData = async (data) => {
    data.date_of_birth = moment(data.date_of_birth).format("YYYY-MM-DD");
    data.address_line2 = Boolean(data.address_line2) ? data.address_line2 : "";
    data.shipping_address_2 = Boolean(data.shipping_address_2)
      ? data.shipping_address_2
      : "";
    data.insurance_info = data.sections;
    const formData = new FormData();
    
    for (let i = 0; i < data.sections.length; i++) {
      if (data.sections[i].frontFiles) {
        var insurancePhotoFrontInput = document.getElementById("front-file-" + i);
        formData.append("insurance_photos", insurancePhotoFrontInput.files[0], `insurance_front_${i}.jpg`)
      }
      if (data.sections[i].backFiles) {
        var insurancePhotoBackInput = document.getElementById("back-file-" + i);
        formData.append("insurance_photos", insurancePhotoBackInput.files[0], `insurance_back_${i}.jpg`);
      }
    }

    data.address = {
      address_line1: data.address_line1,
      address_line2: data.address_line2,
      city_locality: data.city_locality,
      state_province: data.state_province,
      postal_code: data.postal_code,
      country_code: data.country_code,
    };

    data.shipping_address = {
      address_line1: data.shipping_address_1,
      address_line2: data.shipping_address_2,
      city_locality: data.shipping_city,
      state_province: data.shipping_state,
      postal_code: data.shipping_postal_code,
      country_code: data.shipping_country_code,
    };
    setIsLoadingResponse(true);
    setFormData(data);

    const formElement = document.getElementById("ggp-patient-application-form");
    
    try {
      // Ensure form is fully rendered
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Wait for all images to load
      await Promise.all(
        Array.from(formElement.getElementsByTagName('img'))
          .map(img => {
            if (img.complete) return Promise.resolve();
            return new Promise(resolve => {
              img.onload = resolve;
              img.onerror = resolve;
            });
          })
      );

      const opt = {
        margin: [0.25, 0.25, 0.25, 0.25],
        image: { 
          type: 'jpeg',
          quality: 0.8  // Reduce from 0.98 to 0.8
        },
        html2canvas: { 
          scale: 1.5,   // Reduce from 2 to 1.5
          scrollY: -window.scrollY,
          useCORS: true,
          allowTaint: true,
          logging: false, // Disable logging
          windowWidth: formElement.scrollWidth,
          windowHeight: formElement.scrollHeight,
          onclone: function(clonedDoc) {
            const clonedElement = clonedDoc.getElementById('ggp-patient-application-form');
            if (clonedElement) {
              // Keep address fields together
              const addressSections = clonedElement.querySelectorAll('[name^="address_"], [name^="shipping_"]');
              addressSections.forEach(section => {
                const parentGrid = section.closest('.MuiGrid-container');
                if (parentGrid) {
                  parentGrid.classList.add('address-section');
                  parentGrid.classList.add('avoid-break');
                }
              });

              // Ensure acknowledgments are captured
              const acknowledgmentSection = clonedElement.querySelector('.acknowledgements');
              if (acknowledgmentSection) {
                const checkboxes = acknowledgmentSection.querySelectorAll('.MuiFormControlLabel-root');
                checkboxes.forEach(checkbox => {
                  checkbox.classList.add('acknowledgment-item');
                  checkbox.classList.add('avoid-break');
                });
              }
            }
          }
        },
        jsPDF: { 
          unit: 'in', 
          format: 'letter', 
          orientation: 'portrait',
          compress: true,
          optimization: {
            compress: true,
            maxResolution: 150 // Add resolution limit
          }
        },
        pagebreak: {
          mode: 'avoid-all',
          before: ['.major-section', '.page-break-before'],
          after: ['.page-break-after'],
          avoid: [
            '.expandable-section',
            '.avoid-break',
            '.MuiFormControl-root',
            '.address-section',
            '.acknowledgment-item',
            'Typography',
            'p',
            'label'
          ]
        }
      };

      // Add classes to important sections
      const acknowledgmentsSection = formElement.querySelector('.acknowledgements');
      if (acknowledgmentsSection) {
        acknowledgmentsSection.classList.add('avoid-break');
        acknowledgmentsSection.classList.add('acknowledgement-section');
      }

      // Optimize images before PDF generation
      const images = formElement.getElementsByTagName('img');
      for (let img of images) {
        if (img.src.startsWith('data:image')) {
          // Reduce inline image quality
          img.src = await compressImage(img.src, 0.8);
        }
      }

      // Generate PDF with optimized settings
      const pdf = await html2pdf()
        .from(formElement)
        .set(opt)
        .outputPdf('blob');

      if (pdf.size < 50000) {
        console.error('PDF generation may have failed - small file size');
        setPdfError(true);
        throw new Error('PDF generation failed - output too small');
      }

      const filename = `${data.last_name}_${data.first_name}_${data.date_of_birth}_completed_form.pdf`;
      formData.append("completed_form_pdf", pdf, filename);

    } catch (error) {
      console.error("Error generating PDF:", error);
      setIsLoadingResponse(false);
      throw error;
    }

    // Append properties as fields to the FormData object
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("date_of_birth", data.date_of_birth);
    formData.append("phone_number", data.phone_number);
    formData.append("gender", data.gender);
    formData.append("race", data.race);
    formData.append("email", data.email);
    formData.append("clozapine_history", data.clozapine_history || '');
    formData.append("in_hospital", data.in_hospital || 'NO');

    // Handle doctor information based on hospital status
    if (data.in_hospital === 'YES') {
      formData.append("anticipated_discharge_date", 
        data.anticipated_discharge_date ? moment(data.anticipated_discharge_date).format("YYYY-MM-DD") : 'N/A');
      formData.append("doctor_first_name", data.hospital_doctor_first_name || 'N/A');
      formData.append("doctor_last_name", data.hospital_doctor_last_name || 'N/A');
      formData.append("doctor_email", data.hospital_doctor_email || 'N/A');
      formData.append("doctor_phone", data.hospital_doctor_phone || 'N/A');
      
      // Also include outpatient doctor if provided
      if (data.outpatient_doctor_name) {
        formData.append("outpatient_doctor_name", data.outpatient_doctor_name);
        formData.append("outpatient_doctor_email", data.outpatient_doctor_email || 'N/A');
      }
    } else {
      formData.append("doctor_first_name", data.doctor_first_name || 'N/A');
      formData.append("doctor_last_name", data.doctor_last_name || 'N/A');
      formData.append("doctor_email", data.doctor_email || 'N/A');
      formData.append("doctor_phone", data.doctor_phone_number || 'N/A');
    }

    // Add business information
    formData.append("is_business", data.is_business || 'NO');

    // Add business details only if is_business is YES
    if (data.is_business === 'YES') {
      formData.append("business_name", data.business_name || 'Not Included');
      formData.append("attn", data.attn || 'Not Included');
    }

    // Add current medication method
    formData.append("current_medication_method", data.current_medication_method || '');
    formData.append("ggp_interest", data.ggp_interest || 'NO');

    // Add allergies information
    formData.append("allergies", data.allergies || 'NO_ALLERGIES');
    if (data.allergies === 'YES_ALLERGIES') {
      formData.append("allergies_list", data.allergies_list || 'Not Specified');
    }

    formData.append("insurance_info", JSON.stringify(data.insurance_info)); 
    formData.append("address", JSON.stringify(data.address));
    formData.append("shipping_address", JSON.stringify(data.shipping_address));
    formData.append("state_province", data.state_province);

    // Add HIPPA information
    formData.append("hippa", data.hippa || 'NO_HIPPA'); // Add the HIPPA question response

    // Add HIPPA file if it exists (existing code)
    const hippaFileInput = document.getElementById("hippa-file-input");
    if(hippaFileInput) {
      const file = hippaFileInput.files[0];
      formData.append("hippa_file", file);
    }

    // Add case manager information
    formData.append("care_agency_name", data.care_agency_name || 'Not Included');
    formData.append("case_manager_name", data.case_manager_name || 'Not Included');
    formData.append("case_manager_phone", data.case_manager_phone || 'Not Included');

    // Add acknowledgements section
    const acknowledgementQuestions = {
      ack6: "I agree to Golden Gate Pharmacy Services Terms of Services",
      ack7: "I have read and acknowledged Golden Gate Pharmacy Services HIPAA Privacy Policy",
      ack5: "I authorize Golden Gate Pharmacy Services to share HIPAA Information with Athelas",
      ack1: "I have read and acknowledged Golden Gate Pharmacy Services Automatic Refill Program (if applicable)",
      ack3: "I am requesting Golden Gate Pharmacy to fill Clozaril Branded medication for my prescriptions (if applicable)"
    };

    // Add each acknowledgement with its question and response
    Object.entries(acknowledgements).forEach(([key, value]) => {
      formData.append(`acknowledgement_${key}_question`, acknowledgementQuestions[key]);
      formData.append(`acknowledgement_${key}_response`, value ? 'YES' : 'NO');
    });

    // Add section number based on hospital status
    formData.append("acknowledgements_section", watchInHospital === 'NO' ? '09' : '08');

    // Add name and relationship of person filling form
    formData.append("patient_name", data.patient_name || 'Not Included');
    formData.append("relationship_type", data.relationship_type || 'Not Specified');

    return formData
  }

  // Helper function to compress images
  const compressImage = async (dataUrl, quality = 0.8) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL('image/jpeg', quality));
      };
      img.src = dataUrl;
    });
  };

  const onSubmit = async (data) => {
    try {
      setIsLoadingResponse(true);
      setPdfError(false); // Reset error state
      const formData = await generateFormData(data);

      validateAndCreateGgpProspectivePatient(formData)
        .then((resp) => {
          setIsLoadingResponse(false);
          // setBackEndValidationErrors(
          //   resp.data.patient_information_validation_errors,
          // );
          if (resp.data.patient_created_successfully) {
            // redirect to confirmation page
            navigate("/ggp_patient_application/confirmation", {
              state: {
                firstName: data.first_name,
                lastName: data.last_name,
                dob: data.date_of_birth,
                phoneNumber: data.phone_number,
                enrolled: resp.data.patient_created_successfully,
              },
            });
          }
        })
        .catch((err) => {
          setIsLoadingResponse(false);
        });
    } catch (err) {
      // This catch block handles PDF generation errors
      setIsLoadingResponse(false);
      console.error("Form generation error:", err);
    }
  };

  const onError = (errors, e) => console.log("onError", errors, e);
  const theme = createTheme(themeOptions);

  const shipping_address_tooltip_text =
    "Shipping address is the same as patient address";

  const handleTooltipChange = () => {
    setShowTooltip(!showTooltip);
    if (!showTooltip) {
      const address1 = watch("address_line1");
      const address2 = watch("address_line2");
      const state = watch("state_province");
      const city = watch("city_locality");
      const postal = watch("postal_code");
      const country = watch("country_code");
      setValue("shipping_address_1", address1);
      setValue("shipping_address_2", address2);
      setValue("shipping_state", state);
      setValue("shipping_city", city);
      setValue("shipping_postal_code", postal);
      setValue("shipping_country_code", country);
    } else {
      setValue("shipping_address_1", "");
      setValue("shipping_address_2", "");
      setValue("shipping_state", "");
      setValue("shipping_city", "");
      setValue("shipping_postal_code", "");
      setValue("shipping_country_code", "");
    }
  };

  const handleAddSection = () => {
    setInsuranceSections([...insuranceSections, { has_insurance_card: "" }]);
  };

  const handleEffectivePeriodChange = (newValue) => {
    setValue("effective_period", newValue);
  };

  const handleRemoveSection = (index) => {
    if (index > 0) {
      const updatedSections = [...insuranceSections];
      updatedSections.splice(index, 1);
      setInsuranceSections(updatedSections);

      // Update the form state
      const currentSections = getValues('sections');
      currentSections.splice(index, 1);
      setValue('sections', currentSections);
    }
  };

  const handlePhotoUpload = (event) => {
    const photoFile = event.target.files[0];
    setUploadedPhoto(photoFile);
  };

  const handleAcknowledgementChange = (event) => {
    const { name, checked } = event.target;
    setAcknowledgements({
      ...acknowledgements,
      [name]: checked,
    });
  };

  const personalRefSignature = useRef(null);

  const handleClearRefSignature = () => {
    if (personalRefSignature.current) {
      personalRefSignature.current.clear();
      setValue("hippa_signature", "");
    }
  };

  const personalSignature = useRef(null);

  const handleClearPersonalSignature = () => {
    if (personalSignature.current) {
      personalSignature.current.clear();
      setValue("personal_signature", "");
    }
  };

  const handlePersonalSignatureEnd = () => {
    const signatureData = personalSignature.current.toDataURL();
    setValue("personal_signature", signatureData);
  };

  const handleMedRecordsExceptionsChange = (event) => {
    const { name, checked } = event.target;
    setMedRecordsExceptions((prevMedRecordsExceptions) => ({
      ...prevMedRecordsExceptions,
      [name]: checked,
    }));
  };

  const handleLabFrequencyChange = (event) => {
    setLabFrequencySelectedValue(event.target.value);
  };

  const handleSignatureEnd = () => {
    const signatureData = personalRefSignature.current.toDataURL();
    setValue("hippa_signature", signatureData);
  };

  useEffect(() => {
    if (watch('in_hospital') === 'NO') {
      // Clear hospital-related fields
      setValue('anticipated_discharge_date', null);
      setValue('hospital_doctor_first_name', '');
      setValue('hospital_doctor_last_name', '');
      setValue('hospital_doctor_email', '');
      setValue('hospital_doctor_phone', '');
      setValue('outpatient_doctor_name', '');
      setValue('outpatient_doctor_email', '');
      
      // Clear validation errors for these fields
      clearErrors([
        'anticipated_discharge_date',
        'hospital_doctor_first_name',
        'hospital_doctor_last_name',
        'hospital_doctor_email',
        'hospital_doctor_phone',
        'outpatient_doctor_name',
        'outpatient_doctor_email'
      ]);
    }
  }, [watch('in_hospital')]);

  return (
    <ThemeProvider theme={theme}>
      <CardMedia
        sx={{ width: 1, height: 1 / 5 }}
        src={enrollmentBanner}
        component="img"
      />
      <Box sx={{ mx: 8, mb: 8, mt: 2 }}>
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          id="ggp-patient-application-form"
          encType="multipart/form-data"
        >
          <Grid
            style={{ minHeight: "100vh" }}
            alignItems="center"
            justifyContent="flex-start"
            container
            direction="row"
            align="stretch"
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                Hello, thank you for your interest in the Clozaril/Clozapine
                program. Please note all fields in the form below are required
                to submit the application.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                01 Clozapine/Clozaril Information
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="clozapine_history"
                control={control}
                defaultValue="TAKING_CLOZAPINE"  // Match the default value here
                render={({ field: { ref, ...field } }) => (
                  <FormControl 
                    fullWidth 
                    error={!!errors?.clozapine_history}
                    ref={ref}
                  >
                    <FormLabel id="clozapine-info-radio-group-label">
                      Please select your Clozapine status:
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="clozapine-info-radio-group-label"
                      {...field}
                    >
                      <FormControlLabel
                        value="TAKING_CLOZAPINE"
                        control={<Radio />}
                        label="I am taking Clozapine"
                      />
                      <FormControlLabel
                        value="NOT_TAKING_CLOZAPINE"
                        control={<Radio />}
                        label="I am not taking Clozapine yet"
                      />
                      <FormControlLabel
                        value="ONLY_ATHELAS_DEVICE"
                        control={<Radio />}
                        label="I am only interested in the Athelas Home finger stick blood testing device"
                      />
                    </RadioGroup>
                    <FormHelperText>{errors?.clozapine_history?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">02 Patient Information</Typography>
            </Grid>
            <Grid item xs={6} md={4} container flexGrow>
              <Controller
                name="first_name"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    label={"Patient First Name"}
                    inputRef={ref}
                    sx={{ flex: 1 }}
                    inputProps={{ maxLength: 40 }}
                    error={!!errors?.first_name?.message}
                    helperText={errors?.first_name?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={4} container flexGrow>
              <Controller
                name="last_name"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    sx={{ flex: 1 }}
                    label={"Patient Last Name"}
                    error={!!errors?.last_name?.message}
                    inputRef={ref}
                    inputProps={{ maxLength: 80 }}
                    helperText={errors?.last_name?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={4} container flexGrow>
              <Controller
                name="date_of_birth"
                control={control}
                defaultValue={moment()}
                render={({ field: { ref, ...field } }) => (
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="Patient Date of Birth"
                      inputRef={ref}
                      renderInput={(params) => (
                        <TextField
                          sx={{ flex: 1 }}
                          {...params}
                          error={!!errors?.date_of_birth?.message}
                        />
                      )}
                      helperText={errors?.date_of_birth?.message}
                      {...field}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={3} md={3} container flexGrow>
              <Controller
                name="gender"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <FormControl
                    error={!!errors?.gender?.message}
                    //style={{ minWidth: 100 }}
                    sx={{ flex: 1 }}
                  >
                    <InputLabel id="gender-select-label">Gender</InputLabel>
                    <Select
                      id="gender-select"
                      labelId="gender-select-label"
                      label="Gender"
                      inputRef={ref}
                      className="Select"
                      sx={{ flex: 1 }}
                      {...field}
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                    <FormHelperText>{errors?.gender?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3} md={3} container flexGrow>
              <Controller
                name="race"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <FormControl
                    error={!!errors?.race?.message}
                    //style={{ minWidth: 100 }}
                    sx={{ flex: 1 }}
                  >
                    <InputLabel id="race-select-label">Race</InputLabel>
                    <Select
                      id="race-select"
                      labelId="race-select-label"
                      label="Race"
                      inputRef={ref}
                      className="Select"
                      sx={{ flex: 1 }}
                      {...field}
                    >
                      <MenuItem value={"Caucasian"}>Caucasian</MenuItem>
                      <MenuItem value={"African American"}>
                        African American
                      </MenuItem>
                      <MenuItem value={"Asian"}>Asian</MenuItem>
                      <MenuItem value={"Hispanic"}>
                        Hispanic
                      </MenuItem>
                      <MenuItem value={"Other"}>
                        Other
                      </MenuItem>
                    </Select>
                    <FormHelperText>{errors?.race?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={3} container flexGrow>
              <Controller
                name="phone_number"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <InputMask
                    mask="9999999999"
                    value={value}
                    onChange={onChange}
                  >
                    {(inputProps) => (
                      <TextField
                        label="Patient Phone Number"
                        type="text"
                        error={!!errors?.phone_number?.message}
                        inputRef={ref}
                        sx={{ flex: 1 }}
                        helperText={errors?.phone_number?.message}
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                )}
              />
            </Grid>
            <Grid item xs={12} md={3} container flexGrow>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    label={"Patient Email"}
                    type="text"
                    error={!!errors?.email?.message}
                    helperText={errors?.email?.message}
                    inputRef={ref}
                    sx={{ flex: 1 }}
                    inputProps={{ maxLength: 80 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="address_line1"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    label={"Patient Address Line 1"}
                    error={!!errors?.address_line1?.message}
                    helperText={errors?.address_line1?.message}
                    inputRef={ref}
                    fullWidth
                    onChange={(e) => {
                      field.onChange(e);
                      if (showTooltip) {
                        setValue("shipping_address_1", e.target.value); // Set second field's value
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="address_line2"
                defaultValue=""
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    label={"Patient Address Line 2"}
                    fullWidth
                    inputRef={ref}
                    onChange={(e) => {
                      field.onChange(e);
                      if (showTooltip) {
                        setValue("shipping_address_2", e.target.value); // Set second field's value
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item container xs={12} className="address-section avoid-break">
              {/* State */}
              <Grid item xs={3} container flexGrow>
                <Controller
                  name="state_province"
                  control={control}
                  defaultValue=""
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      label={"State"}
                      sx={{ flex: 1 }}
                      error={!!errors?.state_province?.message}
                      helperText={errors?.state_province?.message}
                      inputRef={ref}
                      onChange={(e) => {
                        field.onChange(e);
                        if (showTooltip) {
                          setValue("shipping_state", e.target.value);
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              {/* City */}
              <Grid item xs={3} container flexGrow>
                <Controller
                  name="city_locality"
                  control={control}
                  defaultValue=""
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      label={"City"}
                      error={!!errors?.city_locality?.message}
                      helperText={errors?.city_locality?.message}
                      inputRef={ref}
                      sx={{ flex: 1 }}
                      onChange={(e) => {
                        field.onChange(e);
                        if (showTooltip) {
                          setValue("shipping_city", e.target.value);
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              {/* Zip Code */}
              <Grid item xs={3} container flexGrow>
                <Controller
                  name="postal_code"
                  control={control}
                  defaultValue=""
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      label={"Zip Code"}
                      error={!!errors?.postal_code?.message}
                      helperText={errors?.postal_code?.message}
                      inputRef={ref}
                      sx={{ flex: 1 }}
                      onChange={(e) => {
                        field.onChange(e);
                        if (showTooltip) {
                          setValue("shipping_postal_code", e.target.value);
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              {/* Country Code */}
              <Grid item xs={3} container flexGrow>
                <Controller
                  name="country_code"
                  control={control}
                  defaultValue="USA"
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      label={"Country Code"}
                      error={!!errors?.country_code?.message}
                      helperText={errors?.country_code?.message}
                      inputRef={ref}
                      onChange={(e) => {
                        field.onChange(e);
                        if (showTooltip) {
                          setValue("shipping_country_code", e.target.value); // Set second field's value
                        }
                      }}
                      sx={{ flex: 1 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Is the patient currently in the hospital?</FormLabel>
                <Controller
                  name="in_hospital"
                  control={control}
                  defaultValue="NO"
                  render={({ field }) => (
                    <RadioGroup {...field} row>
                      <FormControlLabel value="YES" control={<Radio />} label="Yes" />
                      <FormControlLabel value="NO" control={<Radio />} label="No" />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>
            {watch('in_hospital') === 'YES' && (
              <>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="anticipated_discharge_date"
                    control={control}
                    defaultValue={null}
                    render={({ field: { ref, ...field } }) => (
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="Anticipated Discharge Date"
                          inputRef={ref}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                          {...field}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
              
                <Grid item xs={12} md={6}>
                  <Controller
                    name="hospital_doctor_first_name"
                    control={control}
                    defaultValue=""
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Current Doctor's First Name"
                        inputRef={ref}
                        error={!!errors.hospital_doctor_first_name}
                        helperText={errors.hospital_doctor_first_name?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="hospital_doctor_last_name"
                    control={control}
                    defaultValue=""
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Current Doctor's Last Name"
                        inputRef={ref}
                        error={!!errors.hospital_doctor_last_name}
                        helperText={errors.hospital_doctor_last_name?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="hospital_doctor_email"
                    control={control}
                    defaultValue=""
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={"Current Doctor's Email"}
                        inputRef={ref}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="hospital_doctor_phone"
                    control={control}
                    defaultValue=""
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={"Current Doctor's Phone Number"}
                        inputRef={ref}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="outpatient_doctor_name"
                    control={control}
                    defaultValue=""
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={"Expected Outpatient Doctor (If applicable)"}
                        inputRef={ref}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="outpatient_doctor_email"
                    control={control}
                    defaultValue=""
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={"Expected Outpatient Doctor's Email (If applicable)"}
                        inputRef={ref}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
                
            
            <Grid item xs={12}>
              <Typography variant="h5">03 Shipping Information</Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="auto_fill_shipping_fields"
                control={control}
                defaultValue={false}
                render={({ field: { ref, ...field } }) => (
                  <FormGroup>
                    <FormControlLabel
                      sx={{ alignItems: "flex-start" }}
                      control={
                        <Checkbox
                          {...field}
                          checked={showTooltip}
                          onChange={handleTooltipChange}
                          sx={{
                            marginTop: -1,
                          }}
                        />
                      }
                      label={shipping_address_tooltip_text}
                    />
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="shipping_address_1"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    label={"Shipping Address Line 1"}
                    value={field.value}
                    error={!!errors?.shipping_address_1?.message}
                    helperText={errors?.shipping_address_1?.message}
                    onChange={field.onChange}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="shipping_address_2"
                defaultValue=""
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    label={"Shipping Address Line 2"}
                    value={field.value}
                    onChange={field.onChange}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} container flexGrow>
              <Controller
                name="shipping_state"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    label={"State"}
                    error={!!errors?.shipping_state?.message}
                    helperText={errors?.shipping_state?.message}
                    value={field.value}
                    onChange={field.onChange}
                    sx={{ flex: 1 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} container flexGrow>
              <Controller
                name="shipping_city"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    label={"City"}
                    error={!!errors?.shipping_city?.message}
                    helperText={errors?.shipping_city?.message}
                    value={field.value}
                    onChange={field.onChange}
                    sx={{ flex: 1 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} container flexGrow>
              <Controller
                name="shipping_postal_code"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    label={"Zip Code"} //required={true}
                    error={!!errors?.shipping_postal_code?.message}
                    helperText={errors?.shipping_postal_code?.message}
                    inputRef={ref}
                    sx={{ flex: 1 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} container flexGrow>
              <Controller
                name="shipping_country_code"
                control={control}
                defaultValue="USA"
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    label={"Country Code"} //required={true}
                    error={!!errors?.country_code?.message}
                    helperText={errors?.country_code?.message}
                    inputRef={ref}
                    onChange={(e) => {
                      field.onChange(e);
                      if (showTooltip) {
                        setValue("shipping_country_code", e.target.value); // Set second field's value
                      }
                    }}
                    sx={{ flex: 1 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Business Information</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Are you a business (e.g., group home, nursing home)?</FormLabel>
                <Controller
                  name="is_business"
                  control={control}
                  defaultValue="NO"
                  render={({ field }) => (
                    <RadioGroup {...field} row>
                      <FormControlLabel value="YES" control={<Radio />} label="Yes" />
                      <FormControlLabel value="NO" control={<Radio />} label="No" />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>
            {watch('is_business') === 'YES' && (
              <>
                <Grid item xs={3}>
                  <Controller
                    name="business_name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Business Name"
                        fullWidth
                        error={!!errors.business_name}
                        helperText={errors.business_name?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    name="attn"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Attention to"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography variant="h5">04 Insurance Information</Typography>
            </Grid>
            {insuranceSections.map((section, index) => (
              <Grid item xs={12} container spacing={2} key={index}>
                <Grid item xs={12}>
                  <Controller
                    name={`sections[${index}].has_insurance_card`}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Do you have an insurance card to upload?</FormLabel>                        <RadioGroup {...field}>
                          <FormControlLabel value="YES" control={<Radio />} label="Yes" />
                          <FormControlLabel value="NO" control={<Radio />} label="No" />
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                </Grid>
                
                <Grid item xs={12} md={4}>
                  <Controller
                    name={`sections[${index}].insurance_type`}
                    control={control}
                    defaultValue=""
                    render={({ field: { ref, ...field } }) => (
                      <FormControl
                        fullWidth
                        error={!!errors?.sections?.[index]?.insurance_type?.message}
                      >
                        <InputLabel id={`insurance-type-select-label-${index}`}>Insurance Type</InputLabel>
                        <Select
                          labelId={`insurance-type-select-label-${index}`}
                          label="Insurance Type"
                          inputRef={ref}
                          {...field}
                        >
                          <MenuItem value="commercial-private">Commercial/Private</MenuItem>
                          <MenuItem value="medicare">Medicare</MenuItem>
                          <MenuItem value="medicaid">Medicaid</MenuItem>
                          <MenuItem value="other-insurance">Other</MenuItem>
                        </Select>
                        <FormHelperText>{errors?.sections?.[index]?.insurance_type?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
                
                {watch(`sections[${index}].has_insurance_card`) === 'YES' && (
                  <>
                    <Grid item xs={12}>
                      <Typography>Upload a picture of the insurance card (front). File type must be jpeg/jpg:
                        
                      </Typography>
                      <Controller
                        name={`sections[${index}].frontFiles`}
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <input id={`front-file-${index}`} type="file" accept="image/jpeg,image/jpg" {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>Upload a picture of the insurance card (back). File type must be jpeg/jpg:

                      </Typography>
                      <Controller
                        name={`sections[${index}].backFiles`}
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <input id={`back-file-${index}`} type="file" accept="image/jpeg,image/jpg" {...field} />
                        )}
                      />
                    </Grid>
                  </>
                )}
                
                {watch(`sections[${index}].has_insurance_card`) === 'NO' && (
                  <>
                    <Grid item xs={6} md={4}>
                      <Controller
                        name={`sections[${index}].insurance_name`}
                        defaultValue=""
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <TextField
                            label="Insurance Company Name"
                            inputRef={ref}
                            error={!!errors?.sections?.[index]?.insurance_name?.message}
                            helperText={errors?.sections?.[index]?.insurance_name?.message}
                            fullWidth
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Controller
                        name={`sections[${index}].insurance_id`}
                        control={control}
                        defaultValue=""
                        render={({ field: { ref, ...field } }) => (
                          <TextField
                            label="Insurance ID"
                            inputRef={ref}
                            error={!!errors?.sections?.[index]?.insurance_id?.message}
                            helperText={errors?.sections?.[index]?.insurance_id?.message}
                            fullWidth
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Controller
                        name={`sections[${index}].insurance_group_number`}
                        control={control}
                        defaultValue=""
                        render={({ field: { ref, ...field } }) => (
                          <TextField
                            label="Group Number"
                            inputRef={ref}
                            error={!!errors?.sections?.[index]?.insurance_group_number?.message}
                            helperText={errors?.sections?.[index]?.insurance_group_number?.message}
                            fullWidth
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Controller
                        name={`sections[${index}].insurance_pcn_number`}
                        control={control}
                        defaultValue=""
                        render={({ field: { ref, ...field } }) => (
                          <TextField
                            label="PCN Number"
                            inputRef={ref}
                            error={!!errors?.sections?.[index]?.insurance_pcn_number?.message}
                            helperText={errors?.sections?.[index]?.insurance_pcn_number?.message}
                            fullWidth
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                
                <Grid item xs={12}>
                  {index > 0 && (
                    <Button type="button" onClick={() => handleRemoveSection(index)}>
                      Remove Insurance
                    </Button>
                  )}
                  <Button type="button" onClick={handleAddSection}>
                    Add Another Insurance
                  </Button>
                </Grid>
              </Grid>
            ))}
            {watchInHospital === 'NO' && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h5">05 Doctor Information</Typography>
                </Grid>
                <Grid item container xs={6} md={4} flexGrow>
                  <Controller
                    name="doctor_first_name"
                    control={control}
                    defaultValue=""
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!errors?.doctor_first_name?.message}
                        helperText={errors?.doctor_first_name?.message}
                        label="Doctor First Name"
                        inputRef={ref}
                        sx={{ flex: 1 }}
                      />
                    )}
                  />
                </Grid>
                <Grid item container xs={6} md={4} flexGrow>
                  <Controller
                    name="doctor_last_name"
                    control={control}
                    defaultValue=""
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!errors?.doctor_last_name?.message}
                        helperText={errors?.doctor_last_name?.message}
                        label="Doctor Last Name"
                        inputRef={ref}
                        sx={{ flex: 1 }}
                      />
                    )}
                  />
                </Grid>
                <Grid item container xs={6} md={4} flexGrow>
                  <Controller
                    name="doctor_phone_number"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value, ref } }) => (
                      <InputMask
                        mask="9999999999"
                        value={value}
                        onChange={onChange}
                      >
                        {(inputProps) => (
                          <TextField
                            label="Doctor Phone Number"
                            error={!!errors?.doctor_phone_number?.message}
                            helperText={errors?.doctor_phone_number?.message}
                            type="text"
                            inputRef={ref}
                            sx={{ flex: 1 }}
                            {...inputProps}
                          />
                        )}
                      </InputMask>
                    )}
                  />
                </Grid>
                <Grid item container xs={6} md={4} flexGrow>
                  <Controller
                    name="doctor_email"
                    control={control}
                    defaultValue=""
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={"Doctor Email"}
                        error={!!errors?.doctor_email?.message}
                        helperText={errors?.doctor_email?.message}
                        inputRef={ref}
                        sx={{ flex: 1 }}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography variant="h5">
                {watchInHospital === 'NO' ? '06' : '05'} Medication Information
              </Typography>
            </Grid>
            <Grid item container xs={12} md={12} flexGrow>
              <Controller
                name="current_medication_method"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <FormControl fullWidth error={!!errors?.current_medication_method?.message}>
                    <InputLabel id="current-medication-method-label">How do you currently receive your medication?</InputLabel>
                    <Select
                      {...field}
                      labelId="current-medication-method-label"
                      label="How do you currently receive your medication?"
                      inputRef={ref}
                    >
                      <MenuItem value="local_pharmacy">Local Pharmacy</MenuItem>
                      <MenuItem value="mail_order">Mail Order</MenuItem>
                      <MenuItem value="hospital">Hospital</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                    <FormHelperText>{errors?.current_medication_method?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="ggp_interest"
                control={control}
                defaultValue="NO"
                render={({ field: { ref, ...field } }) => (
                  <FormControl>
                    <FormLabel component="legend">If eligible, I would like to hear about Golden Gate Pharmacy Services</FormLabel>
                    <RadioGroup
                      aria-label="ggp-interest"
                      name="ggp-interest-radio-group"
                      {...field}
                    >
                      <FormControlLabel value="YES" control={<Radio />} label="Yes" />
                      <FormControlLabel value="NO" control={<Radio />} label="No" />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item container xs={12}>
              <FormLabel>Do you have any medical allergies?</FormLabel>
            </Grid>
            <Grid item container xs={12}>
              <Controller
                name="allergies"
                control={control}
                defaultValue="NO_ALLERGIES"
                render={({ field: { ...field } }) => (
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="allerges-group-label"
                      defaultValue="NO_ALLERGIES"
                      name="allergies-radio-group"
                      {...field}
                    >
                      <FormControlLabel
                        value="YES_ALLERGIES"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="NO_ALLERGIES"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>
            {watchAllergies === "YES_ALLERGIES" && (
              <Grid item container>
                <Controller
                  name="allergies_list"
                  control={control}
                  defaultValue=""
                  render={({ field: { ...field } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={"List of allergies"}
                      sx={{ flex: 1 }}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item container xs={12}>
              <FormLabel>
                I am interested in getting information about the Athelas Home
                finger stick blood testing device:
              </FormLabel>
            </Grid>
            <Grid item container xs={12}>
              <Controller
                name="ahome_interest"
                control={control}
                defaultValue="YES_AHOME"
                render={({ field: { ref, ...field } }) => (
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="ahome_interest-group-label"
                      defaultValue="NONE"
                      name="ahome_interest-radio-group"
                      {...field}
                    >
                      <FormControlLabel
                        value="YES_AHOME"
                        control={<Radio />}
                        label="Yes (We will contact Athelas on your behalf and request that they get in touch once eligibility is determined)"
                      />
                      <FormControlLabel
                        value="NO_AHOME"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                {watchInHospital === 'NO' ? '07' : '06'} Additional Information (If Applicable Only)
              </Typography>
            </Grid>
            <Grid item container xs={4} md={4} flexGrow>
              <Controller
                name="care_agency_name"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={"Care Agency Name"}
                    inputRef={ref}
                    sx={{ flex: 1 }}
                  />
                )}
              />
            </Grid>
            <Grid item container xs={4} md={4} flexGrow>
              <Controller
                name="case_manager_name"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={"Case Manager Name"}
                    inputRef={ref}
                    sx={{ flex: 1 }}
                  />
                )}
              />
            </Grid>
            <Grid item container xs={4} md={4} flexGrow>
              <Controller
                name="case_manager_phone"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <InputMask
                    mask="9999999999"
                    value={value}
                    onChange={onChange}
                  >
                    {(inputProps) => (
                      <TextField
                        label="Case Manager Phone Number"
                        type="text"
                        inputRef={ref}
                        sx={{ flex: 1 }}
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                {watchInHospital === 'NO' ? '08' : '07'} HIPPA
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>
                Do you already have an authorization form to release protected
                health information (PHI) to Golden Gate Pharmacy Services?
              </FormLabel>
            </Grid>
            <Grid item container xs={12}>
              <Controller
                name="hippa"
                control={control}
                defaultValue="YES_HIPPA"
                render={({ field: { ref, ...field } }) => (
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="hippa-group-label"
                      defaultValue="YES_HIPPA"
                      name="hippa-radio-group"
                      {...field}
                    >
                      <FormControlLabel
                        value="YES_HIPPA"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="NO_HIPPA"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>
            {watchHIPPA === "YES_HIPPA" && (
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <p>Please upload file:</p>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="hippa_file"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <div>
                        <input
                          type="file"
                          name="hippa_file"
                          accept=".pdf"
                          ref={control.register()}
                          id="hippa-file-input"
                          {...field}
                        />
                        {errors.hippa_file && (
                          <span style={{ color: "red" }}>
                            {errors.hippa_file.message}
                          </span>
                        )}
                      </div>
                    )}
                  />
                </Grid>
              </Grid>
            )}
            {watchHIPPA === "NO_HIPPA" && (
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" item container xs={12}>
                    AUTHORIZATION FORM TO RELEASE PROTECTED HEALTH INFORMATION
                    (PHI).
                  </Typography>
                  <FormLabel>
                    (Required by the Health Insurance Portability and
                    Accountability Act, 45 C.F.R. Parts 160 and 164)
                  </FormLabel>
                  <FormLabel>
                    Purpose: For release of protected health information to a
                    third party not involved with the payment, treatment or
                    health care operation of the patient.
                  </FormLabel>
                  <FormLabel>
                    To: Golden Gate Pharmacy, 8 Digital Drive #200, Novato Ca
                    94949
                  </FormLabel>
                </Grid>
                <Grid item container xs={4}>
                  <Controller
                    name="first_name"
                    defaultValue={watchFirstName}
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={"Patient First Name"}
                        error={!!errors?.first_name?.message}
                        helperText={errors?.first_name?.message}
                        inputRef={ref}
                        sx={{ flex: 1 }}
                      />
                    )}
                  />
                </Grid>
                <Grid item container xs={4}>
                  <Controller
                    name="last_name"
                    defaultValue={watchLastName}
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={"Patient Last Name"}
                        error={!!errors?.last_name?.message}
                        helperText={errors?.last_name?.message}
                        inputRef={ref}
                        sx={{ flex: 1 }}
                      />
                    )}
                  />
                </Grid>
                <Grid item container xs={4}>
                  <Controller
                    name="date_of_birth"
                    defaultValue={watchDob}
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="Patient Date of Birth"
                          inputRef={ref}
                          renderInput={(params) => (
                            <TextField sx={{ flex: 1 }} {...params} />
                          )}
                          {...field}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h7">I. Authorization</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel>
                      I authorize Golden Gate Pharmacy to use and disclose the
                      protected health information described below
                    </FormLabel>
                  </Grid>
                  <Grid item container xs={4}>
                    <Controller
                      name="rep_first_name"
                      defaultValue=""
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label={"Representative First Name"}
                          error={!!errors?.rep_first_name?.message}
                          helperText={errors?.rep_first_name?.message}
                          inputRef={ref}
                          sx={{ flex: 1 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item container xs={4}>
                    <Controller
                      name="rep_last_name"
                      defaultValue=""
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label={"Representative Last Name"}
                          error={!!errors?.rep_last_name?.message}
                          helperText={errors?.rep_last_name?.message}
                          inputRef={ref}
                          sx={{ flex: 1 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item container xs={4}>
                    <Controller
                      name="rep_capacity"
                      defaultValue=""
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label={
                            "Representative Capacity (Family member, Power of Attorney etc.)"
                          }
                          inputRef={ref}
                          error={!!errors?.rep_capacity?.message}
                          helperText={errors?.rep_capacity?.message}
                          sx={{ flex: 1 }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="rep_address_1"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        error={!!errors?.rep_address_1?.message}
                        helperText={errors?.rep_address_1?.message}
                        label={"Street Address Line 1"}
                        inputRef={ref}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="rep_address_2"
                    defaultValue=""
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        label={"Street Address Line 2"}
                        fullWidth
                        inputRef={ref}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} container flexGrow>
                  <Controller
                    name="rep_state_province"
                    control={control}
                    defaultValue=""
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        label={"State"}
                        sx={{ flex: 1 }}
                        error={!!errors?.rep_state_province?.message}
                        helperText={errors?.rep_state_province?.message}
                        inputRef={ref}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} container flexGrow>
                  <Controller
                    name="rep_city_locality"
                    control={control}
                    defaultValue=""
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        label={"City"} //required={true}
                        error={!!errors?.rep_city_locality?.message}
                        helperText={errors?.rep_city_locality?.message}
                        inputRef={ref}
                        sx={{ flex: 1 }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} container flexGrow>
                  <Controller
                    name="rep_postal_code"
                    control={control}
                    defaultValue=""
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        label={"Zip Code"} //required={true}
                        error={!!errors?.rep_postal_code?.message}
                        helperText={errors?.rep_postal_code?.message}
                        inputRef={ref}
                        sx={{ flex: 1 }}
                      />
                    )}
                  />
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h7">II. Effective Period</Typography>
                  </Grid>
                  <Grid item container xs={12}>
                    <Controller
                      name="effective_period"
                      control={control}
                      defaultValue="ALL"
                      render={({ field: { ref, ...field } }) => (
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="effective-period-group-label"
                            defaultValue="ALL"
                            name="effective-period-radio-group"
                            {...field}
                          >
                            <FormControlLabel
                              value="EFFECTIVE_PERIOD"
                              control={<Radio />}
                              label="This authorization shall be in force and in effect until (date or event) at which time this authorization expires."
                              onChange={() =>
                                handleEffectivePeriodChange("EFFECTIVE_PERIOD")
                              }
                            />
                            <FormControlLabel
                              value="ALL"
                              control={<Radio />}
                              label="All past, present, and future periods."
                              onChange={() =>
                                handleEffectivePeriodChange("ALL")
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
                {watchEffectivePeriod === "EFFECTIVE_PERIOD" && (
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        name="effective_until_date"
                        control={control}
                        defaultValue={null}
                        render={({ field: { ref, ...field } }) => (
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              label="Effective Until"
                              inputRef={ref}
                              renderInput={(params) => (
                                <TextField sx={{ flex: 1 }} {...params} />
                              )}
                              {...field}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {errors.effective_until_date && (
                        <Typography variant="body2" color="error">
                          Please select a date
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                )}
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h7">
                      III. Extent of Authorization
                    </Typography>
                  </Grid>
                  <Grid item container xs={12}>
                    <Controller
                      name="release_of_health_records"
                      control={control}
                      defaultValue="ALL_HEALTH_RECORDS"
                      render={({ field: { ref, ...field } }) => (
                        <FormControl defaultValue="ALL_HEALTH_RECORDS">
                          <RadioGroup
                            aria-labelledby="release_of_health_records-group-label"
                            defaultValue="NONE"
                            name="release_of_health_records-radio-group"
                            {...field}
                          >
                            <FormControlLabel
                              value="ALL_HEALTH_RECORDS"
                              control={<Radio />}
                              label="I authorize the release of my complete health record (including records related to mental healthcare, and treatment of alcohol or drug abuse)."
                            />
                            <FormControlLabel
                              value="EXCEPTION"
                              control={<Radio />}
                              label="I authorize the release of my complete health record with the exception of the following:"
                            />
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  {watchException === "EXCEPTION" && (
                    <Grid item container xs={12}>
                      <Controller
                        name="mental_health_records_exception"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  medRecordsExceptions.mentalHealthRecords
                                }
                                onChange={handleMedRecordsExceptionsChange}
                                name="mentalHealthRecords"
                              />
                            }
                            label="Mental health records"
                          />
                        )}
                      />
                      <Controller
                        name="drug_alcohol_abuse_records_exception"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  medRecordsExceptions.drugAlcoholAbuseRecords
                                }
                                onChange={handleMedRecordsExceptionsChange}
                                name="drugAlcoholAbuseRecords"
                              />
                            }
                            label="Alcohol/drug abuse treatment"
                          />
                        )}
                      />
                      <Controller
                        name="other_records_exception"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={medRecordsExceptions.otherRecords}
                                onChange={handleMedRecordsExceptionsChange}
                                name="otherRecords"
                              />
                            }
                            label="Other"
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormLabel>
                    2. This medical information may be used by the person I
                    authorize to receive this information for medical treatment
                    or consultation, billing or claims payment, or other
                    purposes as I may direct.
                  </FormLabel>
                  <FormLabel>
                    3. I understand that I have the right to revoke this
                    authorization, in writing, at any time by sending a letter
                    to: Golden Gate Pharmacy, 8 Digital Drive #200, Novato, Ca
                    94949. I understand that a revocation is not effective to
                    the extent that any person or entity has already acted in
                    reliance on my authorization or if my authorization was
                    obtained as a condition of obtaining insurance coverage and
                    the insurer has a legal right to contest a claim.
                  </FormLabel>
                  <FormLabel>
                    4. I understand I may refuse to sign this Authorization and
                    my refusal to sign will not affect my ability to obtain
                    treatment from the Pharmacy.
                  </FormLabel>
                  <FormLabel>
                    5. I understand if the person or entity that receives my PHI
                    is not required to comply with the federal privacy
                    regulations, the information described above may be
                    redisclosed and may no longer be protected by federal or
                    state law.
                  </FormLabel>
                </Grid>
                <Grid item xs={12}>
                  <FormLabel>
                    Signature of patient or personal representative:{" "}
                  </FormLabel>
                  <Grid item xs={12}>
                    <Controller
                      name="hippa_signature"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <div className="signatureContainer">
                          <SignatureCanvas
                            ref={personalRefSignature}
                            penColor="black"
                            canvasProps={{
                              width: 600,
                              height: 150,
                              className: "signatureCanvas",
                            }}
                            onEnd={handleSignatureEnd}
                          />
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {errors.hippa_signature && (
                      <Typography variant="body2" color="error">
                        {errors.hippa_signature.message}
                      </Typography>
                    )}
                  </Grid>
                  <Button onClick={handleClearRefSignature}>
                    Clear Signature
                  </Button>
                </Grid>
                <Grid item container xs={12}>
                  <Controller
                    name="signature_date"
                    control={control}
                    defaultValue={moment()}
                    render={({ field: { ref, ...field } }) => (
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="Date"
                          inputRef={ref}
                          renderInput={(params) => (
                            <TextField sx={{ flex: 1 }} {...params} />
                          )}
                          {...field}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item container xs={6}>
                  <Controller
                    name="rep_first_name"
                    defaultValue=""
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!errors?.rep_first_name?.message}
                        helperText={errors?.rep_first_name?.message}
                        label={"First Name of Person Signing"}
                        inputRef={ref}
                        sx={{ flex: 1 }}
                      />
                    )}
                  />
                </Grid>
                <Grid item container xs={6}>
                  <Controller
                    name="rep_last_name"
                    defaultValue=""
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!errors?.rep_last_name?.message}
                        helperText={errors?.rep_last_name?.message}
                        label={"Last Name of Person Signing"}
                        inputRef={ref}
                        sx={{ flex: 1 }}
                      />
                    )}
                  />
                </Grid>
                <Grid item container xs={12}>
                  <FormLabel>
                    If signed by the patient's representative, explain your
                    authority to act on behalf of the patient:{" "}
                  </FormLabel>
                </Grid>
                <Grid item container xs={12}>
                  <Controller
                    name="rep_authority_explanation"
                    defaultValue=""
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!errors?.rep_authority_explanation?.message}
                        helperText={errors?.rep_authority_explanation?.message}
                        label={"Authority explanation"}
                        inputRef={ref}
                        sx={{ flex: 1 }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="h5">
                {watchInHospital === 'NO' ? '09' : '08'} Acknowledgements
              </Typography>
            </Grid>
            <Grid item container xs={6} md={6} flexGrow>
              <Controller
                name="patient_name"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!errors?.patient_name?.message}
                    helperText={errors?.patient_name?.message}
                    label={"Name of Individual Filling Out Form"}
                    inputRef={ref}
                    sx={{ flex: 1 }}
                  />
                )}
              />
            </Grid>
            <Grid item container xs={6} md={6} flexGrow>
              <Controller
                name="relationship_type"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <FormControl sx={{ flex: 1 }}>
                    <InputLabel id="relationship-type-select-label">
                      Relationship
                    </InputLabel>
                    <Select
                      id="relationship-type-select"
                      labelId="relationship-type-select-label"
                      label="Relationship"
                      inputRef={ref}
                      className="Select"
                      sx={{ flex: 1 }}
                      {...field}
                    >
                      <MenuItem value={"self"}>Self</MenuItem>
                      <MenuItem value={"parent"}>Parent/Guardian</MenuItem>
                      <MenuItem value={"other"}>Other</MenuItem>
                    </Select>
                    {errors.relationship_type && (
                      <Typography variant="body2" color="error">
                        {errors.relationship_type.message}
                      </Typography>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item container xs={12} className="acknowledgements avoid-break acknowledgement-section">
              {/* Your acknowledgment checkboxes */}
            </Grid>
            <Grid item container xs={12} flexGrow>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={acknowledgements.ack6}
                      required
                      onChange={handleAcknowledgementChange}
                      name="ack6"
                    />
                  }
                  label={
                    <span>
                      I agree to Golden Gate Pharmacy Services{" "}
                      <a
                        href="https://ggprx.com/terms-of-services/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Services
                      </a>
                    </span>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={acknowledgements.ack7}
                      required
                      onChange={handleAcknowledgementChange}
                      name="ack7"
                    />
                  }
                  label={
                    <span>
                      I have read and acknowledged Golden Gate Pharmacy Services{" "}
                      <a
                        href="https://ggprx.com/hipaa-privacy-policy/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        HIPAA Privacy Policy
                      </a>
                    </span>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={acknowledgements.ack5}
                      required
                      onChange={handleAcknowledgementChange}
                      name="ack5"
                    />
                  }
                  label="I authorize Golden Gate Pharmacy Services to share HIPAA Information with Athelas"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={acknowledgements.ack1}
                      onChange={handleAcknowledgementChange}
                      name="ack1"
                    />
                  }
                  label={
                    <span>
                      I have read and acknowledged{" "}
                      <a
                        href="https://ggprx.com/automatic-refill-program-2/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Golden Gate Pharmacy Services Automatic Refill Program
                      </a>
                      {" "}(if applicable)
                    </span>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={acknowledgements.ack3}
                      onChange={handleAcknowledgementChange}
                      name="ack3"
                    />
                  }
                  label="I am requesting Golden Gate Pharmacy to fill Clozaril Branded medication for my prescriptions (if applicable)"
                />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <FormLabel>Sign below: </FormLabel>
                <Grid item xs={12}>
                  <Controller
                    name="personal_signature"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <div className="signatureContainer">
                        <SignatureCanvas
                          ref={personalSignature}
                          penColor="black"
                          canvasProps={{
                            width: 600,
                            height: 150,
                            className: "signatureCanvas",
                          }}
                          onEnd={handlePersonalSignatureEnd}
                        />
                      </div>
                    )}
                  />
                </Grid>
                <Button onClick={handleClearPersonalSignature}>
                  Clear Signature
                </Button>
              </Grid>
              <Grid item xs={12}>
                {errors.personal_signature && (
                  <Typography variant="body2" color="error">
                    {errors.personal_signature.message}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container xs={12} md={12} flexGrow>
              <Controller
                name="referral_type"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <FormControl sx={{ flex: 1 }}>
                    <InputLabel id="referral-type-select-label">
                      How did you hear about this program?
                    </InputLabel>
                    <Select
                      id="referral-type-select"
                      labelId="referral-type-select-label"
                      label="How did you hear about this program?"
                      inputRef={ref}
                      className="Select"
                      sx={{ flex: 1 }}
                      {...field}
                    >
                      <MenuItem value={"friend"}>Friend/Family/Coworker</MenuItem>
                      <MenuItem value={"healthcare-professional"}>Healthcare Professional</MenuItem>
                      <MenuItem value={"linked-in"}>LinkedIn</MenuItem>
                      <MenuItem value={"blog"}>Blog/Publication</MenuItem>
                      <MenuItem value={"social"}>Social Media: Facebook/Instagram</MenuItem>
                      <MenuItem value={"ggp"}>Golden Gate Pharmacy</MenuItem>
                      <MenuItem value={"athelas"}>Athelas</MenuItem>
                      <MenuItem value={"other"}>Other</MenuItem>
                    </Select>
                    {errors.referral_type && (
                      <Typography variant="body2" color="error">
                        {errors.referral_type.message}
                      </Typography>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            {pdfError && (
              <Grid item xs={12}>
                <Alert severity="error" sx={{ mb: 2 }}>
                  <Typography variant="body1">
                    We've detected an issue with completing your application submission. This is a known issue with our new version. 
                    Please try the following:
                    <ol>
                      <li>Refresh the page</li>
                      <li>Fill out the form again</li>
                      <li>If the issue persists, please contact support at{' '}
                        <a href="mailto:athelasdevice@ggprx.com">athelasdevice@ggprx.com</a>
                      </li>
                    </ol>
                  </Typography>
                </Alert>
              </Grid>
            )}
            {isLoadingResponse && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <BeatLoader color="#5A67E5" />
                <p>Verifying Information</p>
              </Grid>
            )}
            <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isLoadingResponse}
                  style={{ minWidth: "30vw", textAlign: "center" }}
                >
                  Submit Application
                </Button>
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center", marginTop: "10px" }}>
                <Typography variant="body2" color="textSecondary">
                  If you have any issues submitting your form, please reach out to 
                  <a href="mailto:athelasdevice@ggprx.com" style={{ color: '#5A67E5', textDecoration: 'none', marginLeft: '5px' }}>
                    athelasdevice@ggprx.com
                  </a>
                </Typography>
              </Grid>
          </Grid>
        </form>
      </Box>
    </ThemeProvider>
  );
}