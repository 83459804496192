import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { SiteSetupType } from "../PartnershipEmbed/PartnershipEmbedPage";
import { AthelasRPMDevice } from "./AthelasRPMDevice";
import { AthelasRPMVideo } from "./AthelasRPMVideo";
import { FAQSection } from "./FAQSection";
import { FooterSection } from "./FooterSection";
import { HowItWorksSection } from "./HowItWorks";
import { OnboardingRouter } from "./RevampedOnboardingFlow/OnboardingRouter";
import { TrustedBySection } from "./TrustedBySection";

type PropsType = {};

export const CustomizedPage = (props: PropsType) => {
  const [fetchedSiteSetup, setFetchedSiteSetup]: [boolean, any] =
    useState(false);
  const [siteSetup, setSiteSetup]: [SiteSetupType, any] = useState({});
  let { slug } = useParams();

  if (!fetchedSiteSetup) {
    fetch(
      `https://api.athelas.com/sites/get_onboarding_setup_via_slug?slug=${slug}`
    )
      .then((j) => j.json())
      .then((res) => setSiteSetup(res));

    setFetchedSiteSetup(true);
  }

  return (
    <>
      <OnboardingRouter
        showDeviceImages
        showSiteLogos
        site_logo={siteSetup.site_logo_url}
        site_name={siteSetup.name}
      />
      <HowItWorksSection />
      <AthelasRPMVideo />
      <TrustedBySection />
      <AthelasRPMDevice />
      <FAQSection />
      <FooterSection />
    </>
  );
};
