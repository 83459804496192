
// Athelas Theme for Patient Enrollment Page
export const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#e85b81',
    },
    secondary: {
      main: '#f50057',
    },
  },
  typography: {
    fontFamily: '"Manrope", sans-serif',
  },
  spacing: 8,
  shape: {
    borderRadius: 8,
  },
};