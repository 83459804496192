import styled from "styled-components";

type PropsType = {
  currentStep: number;
  numSteps: number;
  palomar?: null | boolean;
};

export const TrustedBySectionProgressBar = (props: PropsType) => {
  return (
    <Container>
      <FlexBox>
        {Array(props.numSteps)
          .fill(0)
          .map((x: number, i: number) => {
            if (i === props.currentStep) {
              if (props.palomar){
                return <PalomarBar/>;
              }
              else{
                return <Bar/>;
              }
            }
            if (props.palomar){
              return <PalomarDot/>;
            }
            else{
              return <Dot />;
            }
          })}
      </FlexBox>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const FlexBox = styled.div`
  flex-direction: row;
  display: flex;
  margin: 0% auto;
  justify-content: center;
  cursor: pointer;
`;

const Bar = styled.div`
  width: 26px;
  height: 6px;
  background-color: red;
  border-radius: 20%;
  margin-right: 6px;
`;

const Dot = styled.div`
  height: 6px;
  width: 6px;
  background-color: red;
  border-radius: 100%;
  margin-right: 6px;
  opacity: 0.4;
`;

const PalomarBar = styled.div`
  width: 26px;
  height: 6px;
  background-color: #008CC1;
  border-radius: 20%;
  margin-right: 6px;
`;

const PalomarDot = styled.div`
  height: 6px;
  width: 6px;
  background-color: #008CC1;
  border-radius: 100%;
  margin-right: 6px;
  opacity: 0.4;
`;