import React from "react";
import { createTheme } from "@mui/material/styles";
import { themeOptions } from "./ThemeOptionsPatientPay";
import { useNavigate } from "react-router-dom";
import {
  ThemeProvider,
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function PatientPayBreakdown() {
  const navigate = useNavigate();

  const theme = createTheme(themeOptions);

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "75vh" }}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <Typography variant="h5">$220</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="body1"
            sx={{ mx: 8, mb: 2, color: "text.secondary" }}
            gutterBottom
          >
            Date of Visit: October 31st, 2022
          </Typography>
        </Grid>
        <Grid minWidth="75vw" item xs={12}>
          <Card raised>
            <CardContent>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  Blood Test:
                </Typography>
                <Typography variant="body1">$200</Typography>
              </Box>
            </CardContent>
            <CardContent>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  Physical:
                </Typography>
                <Typography variant="body1">$220</Typography>
              </Box>
            </CardContent>
            <CardContent>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  Copay:
                </Typography>
                <Typography variant="body1">$40</Typography>
              </Box>
            </CardContent>
            <CardContent>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  Insurance Covered:
                </Typography>
                <Typography variant="body1">-$240</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
