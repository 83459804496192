import React, { useEffect } from "react";
import pilltrack from "./assets_1.0/PillTrack 1.0.webp";
import BeatLoader from "react-spinners/BeatLoader";
import { isOnline } from "./API";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Log from "../../app/logging/Log.js";
import ShimmerImage from "../../app/utils/ShimmerImage";

function PillTrackConnect(props) {
  const bootupText = "Booting Up...";
  const instructions = "Connecting to your PillTrack... Sit tight!";

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      isOnline(props.deviceString, props.token)
        .then((online) => {
          if (online.is_online) {
            navigate("/pilltrack/alignment");
          }
        })
        .catch((error) => console.log(error));
    }, 1000);

    return () => clearInterval(interval);
  });

  return (
    <Log
    logImpression={true}
    patientID={props.patientID}
    action="view"
    tag="pilltrack_connect"
  > 
    <StyledGrid>
      <PillTrackImage>
        <ShimmerImage imgHeight={1229} imgWidth={2235} src={pilltrack} widthPercentage={0.75} />
      </PillTrackImage>
      <Title className="pinkText boldFont paddingForText">{bootupText}</Title>
      <StyledH4 className="gemOnboardFont paddingForText">
        {instructions}
      </StyledH4>
      <CenterLoader>
        <BeatLoader color="#E85B81" />
      </CenterLoader>
    </StyledGrid>
    </Log>
  );
}

const Title = styled.h2`
  color: #e85b81;
  font-family: "Manrope", sans-serif;
  text-align: center;
  font-weight: bold;
  padding-left: 48px;
  padding-right: 48px;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-row-gap: 27px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const PillTrackImage = styled.div`
  padding-top: 24px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  margin-bottom: 40px;
`;

const StyledH4 = styled.h4`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #666666;
`;

const CenterLoader = styled.div`
  text-align: center;
`;

export default PillTrackConnect;
