import { useSearchParams } from "react-router-dom";

import "../../App.css";

export default function Result(props) {

  const [searchParams] = useSearchParams();
  const from_doctor_flow = searchParams.get("doc");

  return (
    <div class="form-container">
      <div class="mt-2 p-4">
        <h1>Confirmation</h1>
      </div>
      <div class="px-5 pb-2">
        <p>
          You've succesfully signed {from_doctor_flow ? "them" : ""} up!{" "}
          {from_doctor_flow ? "They" : "You"} will receive a text message
          confirming {from_doctor_flow ? "their" : "your"} participation and a
          tracking number once we've sent the devices.
        </p>
      </div>

      <hr class="px-5 pb-2" />

      <div class="px-5 pb-2" style={{ marginBottom: 24 }}>
        Please reach out to support@athelas.com if you have any questions.
      </div>
    </div>
  );
}