export function trackEvent(action: "view" | "click", tag: string, info: any) {
  const payload = {
    action: action,
    data: info,
    tag: tag,
    application: "onboarding_patient",
  };

  if (window.location.href.includes('localhost')) {
    console.log("In Local Env. Payload below: ");
    console.log(payload);
    return;
  }

  return fetch(`https://api.athelas.com/tracking/track`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((res) => {
    console.log(res);
  });
};