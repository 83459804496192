import React from "react";
import ExteriorLight from "./pilltrack_assets/OutsideLight.webp";
import BlueRectangle from "./assets_1.0/BlueRectangle.webp";
import RedRectangle from "./assets_1.0/RedRectangle.webp";
import OrangeRectangle from "./assets_1.0/OrangeRectangle.webp";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import BackButton from "./BackButton";
import styled from "styled-components";
import Log from "../../app/logging/Log.js";
import ShimmerImage from "../../app/utils/ShimmerImage";

function PillTrackClosed(props) {
  const navigate = useNavigate();
  const blueLight = "Blue Light = No Action Required";
  const orangeLight = "Orange Light = Bottle is Missing";
  const redLight = "Red Light = Take Medication";

  const instructions = "Exterior Light";
  const nextStep = () => {
    navigate("/pilltrack/slots");
  };
  return (
    <Log
      logImpression={true}
      patientID={props.patientID}
      action="view"
      tag="pilltrack_closed"
    >
      <BackButton
        onClick={() => {
          navigate(-1);
        }}
      />
      <StyledGrid>
        <PillTrackImage>
          <ShimmerImage
            imgHeight={237}
            imgWidth={678}
            src={ExteriorLight}
            widthPercentage={0.75}
          />
        </PillTrackImage>
        <Title>{instructions}</Title>
        <IconRow>
          <IconImage src={BlueRectangle} alt="BlueRectangle" />
          <StyledH5 className="gemOnboardFont">{blueLight}</StyledH5>
        </IconRow>
        <IconRow>
          <IconImage src={RedRectangle} alt="RedRectangle" />
          <StyledH5 className="gemOnboardFont">{redLight}</StyledH5>
        </IconRow>
        <IconRow>
          <IconImage src={OrangeRectangle} alt="OrangeRectangle" />
          <StyledH5 className="gemOnboardFont">{orangeLight}</StyledH5>
        </IconRow>
        <CenterButton>
          <StyledButton variant="primary" size="lg" onClick={nextStep}>
            <ButtonText>Continue</ButtonText>
          </StyledButton>
        </CenterButton>
      </StyledGrid>
    </Log>
  );
}

const StyledGrid = styled.div`
  display: grid;
  grid-row-gap: 27px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Title = styled.h2`
  color: #e85b81;
  font-family: "Manrope", sans-serif;
  text-align: center;
  font-weight: bold;
  padding-left: 48px;
  padding-right: 48px;
`;

const PillTrackImage = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  margin-bottom: 40px;
`;

const StyledButton = styled(Button)`
  background: #e85b81;
  :disabled {
    background: #d1d1d1;
    border-color: #d1d1d1;
  }
  :focus {
    background: #e85b81;
    border-color: #e85b81;
  }
  :active {
    background: #e85b81;
    border-color: #e85b81;
  }
  :hover {
    background: #e85b81;
    border-color: #e85b81;
  }
  border-radius: 10px;
  border-color: #e85b81;
  width: 260px;
  text-align: center;
`;

const IconRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`;

const CenterButton = styled.div`
  text-align: center;
`;

const ButtonText = styled.h4`
  font-family: "Manrope", sans-serif;
  text-align: center;
  padding-top: 5px;
`;

const StyledH5 = styled.h5`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #666666;
`;

const IconImage = styled.img`
  display: inline-block;
  padding-right: 12px;
  padding-top: 10px;
  max-width: 40px;
  max-height: 40px;
`;

export default PillTrackClosed;
