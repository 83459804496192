import React, { useState } from "react";
import { AthelasRPMVideo } from "../revampedOnboarding/AthelasRPMVideo";
import { HowItWorksSection } from "../revampedOnboarding/HowItWorks";
import { TrustedBySection } from "../revampedOnboarding/TrustedBySection";
import { RPMDevicesSection } from "./RPMDevicesSection";
import { WelcomeSection } from "./WelcomeSection";
import { GetStarted } from "./GetStarted";
import { useParams } from "react-router-dom";
import { FooterSection } from "../revampedOnboarding/FooterSection";
import { OnboardingRouter } from "../revampedOnboarding/RevampedOnboardingFlow/OnboardingRouter";

type PropsType = {};

export type SiteSetupType = {
  site_logo_url?: null | string;
  slug?: null | string;
  name?: null | string;
};

export const PartnershipEmbed = (props: PropsType) => {
  const [fetchedSiteSetup, setFetchedSiteSetup]: [boolean, any] =
    useState(false);
  const [siteSetup, setSiteSetup]: [SiteSetupType, any] = useState({});
  let { slug } = useParams();

  if (!fetchedSiteSetup) {
    fetch(
      `https://api.athelas.com/sites/get_onboarding_setup_via_slug?slug=${slug}`
    )
      .then((j) => j.json())
      .then((res) => setSiteSetup(res));

    setFetchedSiteSetup(true);
  }

  // alter site name if site is Ascend Medical - Texas PA
  if (siteSetup.name == 'Ascend Medical - Texas PA') {
    const newDetails = { ...siteSetup }
    newDetails.name = 'Ascend Medical'
    setSiteSetup(newDetails)
  }

  return (
    <div>
      <WelcomeSection
        site_logo={siteSetup.site_logo_url}
        site_name={siteSetup.name}
        palomar={siteSetup.slug === "phmg"}
      ></WelcomeSection>
      <OnboardingRouter 
        showCornerImages 
        site_name={siteSetup.name}
        palomar={siteSetup.slug === "phmg"} />
      <TrustedBySection 
        blueBackground
        palomar={siteSetup.slug === "phmg"}  />
      <HowItWorksSection 
        whiteBackground 
        palomar={siteSetup.slug === "phmg"}/>
      <AthelasRPMVideo />
      <RPMDevicesSection />
      <GetStarted
        palomar={siteSetup.slug === "phmg"}
        site_logo={siteSetup.site_logo_url}
      />
      <FooterSection />
    </div>
  );
};
