import { useState, useEffect } from "react";
import { API_SERVER_URL } from "../constants";

export function prodVarFetch(variable_name: string, onChange: Function) {
  const payload = {
    application: "ONBOARDING",
    variable_name: variable_name,
  };

  return fetch(
    `${API_SERVER_URL}/prod_vars/get_prodvar_by_name_and_application`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  )
    .then((j) => j.json())
    .then((response) => {
      const variable_value = Object.entries(response)[0][1];
      onChange(variable_value);
    });
}

export function useProdVar(variable_name: string) {
  const [prodVarValue, setProdVar] = useState("");

  useEffect(() => {
    function toggleProdVarValue(variable_value: string) {
      setProdVar(variable_value);
    }
    prodVarFetch(variable_name, toggleProdVarValue);
  });

  return prodVarValue;
}
