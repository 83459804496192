import React, { Component } from "react";
import { linkClicked, login, saveUrlToken } from "./LoginSlice";
import { connect } from "react-redux";
import { withRouter } from "../../wrappers/withRouter";
import _ from "lodash";
import jwt from "jsonwebtoken";
import { API_SERVER_URL, PUBLIC_KEY } from "../../app/constants";
import { trackEvent } from "../../app/utils/tracking";
import InputMask from 'react-input-mask';


const mapDispatch = { linkClicked, login, saveUrlToken };

class SignUp extends Component {
  constructor(ownProps) {
    super(ownProps);
    const params = new URLSearchParams(ownProps.router.location.search);
    const token = params.get("token");
    if (token) {
      ownProps.saveUrlToken(token);
    }
    try {
      const decodedToken = jwt.verify(token, PUBLIC_KEY, {
        algorithms: ["RS256"],
      });
      this.state = {
        first_name: decodedToken.first_name,
        last_name: decodedToken.last_name,
        prescriber_name: decodedToken.prescriber_name,
        prescriber_avatar_url: decodedToken.prescriber_avatar_url,
        id: decodedToken.sub,
        date_of_birth: "",
        showFailedAlert: null,
        errors: {},
      };
    } catch (e) {
      this.state = {
        first_name: "",
        last_name: "",
        date_of_birth: "",
        prescriber_name: null,
        prescriber_avatar_url: null,
        showFailedAlert: null,
        errors: {},
      };
    }
    fetch(`${API_SERVER_URL}/patients/onboarding_setup?token=${token}`)
      .then((j) => j.json())
      .then((res) => {
        this.setState({
          onboardingSetup: res,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  componentDidMount() {
    this.props.linkClicked(this.state.id);
    console.log(
      "Tracked Onboarding Patient -- on Login component view render."
    );
    trackEvent("view", "onboarding_login_page_viewed", {
      patient_id: this.state.id,
    });
  }

  setFirstName = (first_name) => {
    this.setState({
      first_name,
    });
  };

  setLastName = (last_name) => {
    this.setState({
      last_name,
    });
  };

  setDob = (date_of_birth) => {
    this.setState({
      date_of_birth,
    });
  };

  signIn = (e) => {
    const { first_name, last_name, date_of_birth } = this.state;
    e.preventDefault();
    const errors = {
      first_name: !first_name,
      last_name: !last_name,
      date_of_birth: !date_of_birth,
    };
    let transformed_date_of_birth = date_of_birth;
    let match = date_of_birth.match(/(\d{2})\/(\d{2})\/(\d{4})/);
    if (!date_of_birth.match(/\d{2}\/\d{2}\/\d{4}/)) {
      errors.date_of_birth = true;
    } else {
      transformed_date_of_birth = `${match[3]}-${match[1]}-${match[2]}`;
    }
    this.setState({
      errors,
    });
    if (!_.some(errors)) {
      console.log("first name", first_name);
      this.props
        .login(first_name, last_name, transformed_date_of_birth)
        .then(() => {
          if (this.props.doctor_enrollment) {
            this.props.router.navigate("/doctorFlow");
          } else {
            this.props.router.navigate("/updatePatientInfo");
          }
        })
        .catch(() => {
          this.setState({
            showFailedAlert: true,
          });
        });
    }
    console.log("Tracked Onboarding Patient -- on Login 'Next' button click.");
    trackEvent("click", "onboarding_next_btn_clicked", {
      patient_id: this.state.id,
    });
  };

  render() {
    const siteLogo = this.state?.onboardingSetup?.site_logo_url;
    const {
      first_name,
      last_name,
      prescriber_name,
      prescriber_avatar_url,
      date_of_birth,
      showFailedAlert,
      errors,
    } = this.state;
    var welcomeMessage = "";
    if (this.props.doctor_enrollment) {
      welcomeMessage =
        "Thank you for partnering with Athelas to help monitor your patients' health. To enroll them in remote patient monitoring, please fill out this form.";
    } else {
      const hello = first_name ? "Hi " + first_name + "! " : "";
      const practiceName = this.props.practiceName
        ? this.props.practiceName
        : "Your healthcare provider";
      welcomeMessage =
        hello +
        practiceName +
        " has partnered with Athelas to help monitor your health preventatively at home. Sign in to acquire your devices.";
    }
    if (showFailedAlert) {
      return (
        <div class="form-container mt-5">
          <div class="mt-2 p-4">
            <h1> We're Sorry </h1>
          </div>
          <div class="px-5 pb-3">
            <p>
              Looks like we are not able to find your information in our Athelas
              records. Please reach out to your doctor to discuss if you are
              eligible for the Athelas Remote Patient Monitoring program.
            </p>
          </div>
          <div class="pt-3 pb-3">
            <button
              class="btn light-btn"
              onClick={() => window.location.reload(false)}
            >
              Go Back
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div class="form-container mt-5">
          {this.props.practiceLogo && (
            <img
              src={this.props.practiceLogo}
              alt="practice logo"
              style={{
                width: 150,
                height: 150,
                objectFit: "contain",
                marginTop: 16,
              }}
            />
          )}
          <div class="mt-2 p-4">
            <h1> Welcome </h1>
          </div>
          <div class="px-5 pb-1" style={{ marginTop: "-16px" }}>
            <p> {welcomeMessage}</p>
          </div>
          {prescriber_avatar_url && (
            <div>
              <figure className="figure pb-3">
                <img
                  style={{ marginBottom: 5, width: 100, height: 100 }}
                  className="rounded-circle border"
                  src={prescriber_avatar_url}
                  alt="prescriber"
                />
                {prescriber_name && (
                  <figcaption class="text-center figure-caption">
                    {prescriber_name}
                  </figcaption>
                )}
              </figure>
            </div>
          )}
          <form class="p3">
            <div class="px-5 input-group-lg">
              <input
                type="text"
                className={
                  errors["first_name"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
                placeholder="First Name"
                value={first_name}
                onChange={(e) => this.setFirstName(e.target.value)}
              />
            </div>
            <div class="px-5 pt-4 input-group-lg">
              <input
                type="text"
                className={
                  errors["last_name"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
                placeholder="Last Name"
                value={last_name}
                onChange={(e) => this.setLastName(e.target.value)}
              />
            </div>
            <div class="text-left px-5 pt-4 input-group-lg">
              <InputMask mask="99/99/9999" maskPlaceholder="dd/mm/yyyy"
                type="text"
                className={
                  errors["date_of_birth"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
                value={date_of_birth}
                onChange={(e) => this.setDob(e.target.value)}
                placeholder="Date of Birth" />
              {errors["date_of_birth"] ? (
                <div className="invalid-feedback">
                  Date of Birth must be in form mm/dd/yyyy
                </div>
              ) : null}
              <label style={{ marginTop: 15, color: "gray" }}>
                {" "}
                Format: mm/dd/yyyy{" "}
              </label>
            </div>
            <div class="pt-3 pb-3">
              <button type="submit" class="btn light-btn" onClick={this.signIn}>
                Next
              </button>
            </div>
          </form>
          <p>
            Have any questions? <br />
            Send us a text or call at 1-833-524-1318
          </p>
          <div class="px-5 pb-1">
            <p style={{ fontSize: '0.9rem', color: '#6c757d' }}>
              Athelas will never sell your phone number to third parties. We will not text you without your consent.
            </p>
          </div>

          {siteLogo && (
            <img
              src={siteLogo}
              alt="Site logo"
              style={{
                maxHeight: "80px",
                width: "100%",
                objectFit: "contain",
                marginBottom: "10px",
              }}
            />
          )}
        </div>
      );
    }
  }
}

export default connect(null, mapDispatch)(withRouter(SignUp));
