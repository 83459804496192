type PropsType = {
  currentStep: number;
  numSteps: number;
  palomar?: null | boolean;
};

const styles: any = {
  flexBox: {
    flexDirection: "row",
    display: "flex",
    width: "100%",
    marginBottom: 24,
  },
  activeProgressElement: {
    width: "100%",
    height: 5,
    borderRadius: 20,
    margin: 4,
    backgroundColor: "#FF6779",
  },
  palomarActiveProgressElement: {
    width: "100%",
    height: 5,
    borderRadius: 20,
    margin: 4,
    backgroundColor: "#008CC1",
  },
  inactiveProgressElement: {
    width: "100%",
    height: 5,
    borderRadius: 20,
    margin: 4,
    backgroundColor: "#E0E0E0",
  },
};

export const OnboardingProgressBar = (props: PropsType) => {
  return (
    <div>
      <p
        style={{
          width: "100%",
          textAlign: "right",
          margin: 0,
          padding: 0,
          paddingRight: 4,
          fontSize: "90%",
          color: (props.palomar ? "#008CC1" : "#FF6779"), 
          fontWeight: "bold",
        }}
      >
        Step {props.currentStep} / {props.numSteps}
      </p>
      <div style={styles.flexBox}>
        {Array(props.numSteps)
          .fill(0)
          .map((x, i) => {
            return (
              <div
                style={
                  i < props.currentStep
                    ? (props.palomar ? styles.palomarActiveProgressElement :
                        styles.activeProgressElement)
                        : styles.inactiveProgressElement
                }
              ></div>
            );
          })}
      </div>
    </div>
  );
};
