const TERMS_OF_AGREEMENT_URL =
  "https://assets.website-files.com/606afb6ff04916f7d332ee53/61283a1b68ba9d99e66b61b5_Athelas%20Terms%20of%20Agreement%20(Effective%208-26-21).pdf";
const CLINICAL_PROTOCOL_URL =
  "https://app.athelas.com/static/media/clinic_protocol.a8acca51.pdf";
const PATIENT_CONSENT_URL =
  "https://drive.google.com/file/d/1_ujVn3csDd6tnZ2aYe3dEIQ5oQkL9g3A/view";

export const providerPrescription = (first_name, last_name) => {
  const legal = (
    <p>
      As a Physician or Certified Healthcare professional with the ability to
      order relevant RPM services, I confirm Remote Patient Monitoring orders
      for the following patient(s) pursuant to the current Athelas 
      <a
        href={TERMS_OF_AGREEMENT_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Agreement
      </a>
      . These patient(s) have relevant conditions that necessitate monitoring.
      By submitting this form, I, {first_name} {last_name}, authorize Athelas to
      provide remote monitoring services on my behalf in a manner consistent
      with the clinical protocol set forth here. I further understand that I
      may, in my sole professional judgment, change or substitute the default{" "}
      <a href={CLINICAL_PROTOCOL_URL} target="_blank" rel="noopener noreferrer">
        clinical protocol
      </a>{" "}
      by notifying Athelas.
    </p>
  );
  return legal;
};

export const patientConsent = (first_name, last_name) => {
  const legal = (
    <p>
      By checking this box, {first_name} {last_name} consents to receive remote
      monitoring services provided by Athelas and to the terms set forth in the 
      <a href={PATIENT_CONSENT_URL} target="_blank" rel="noopener noreferrer">
        patient consent
      </a>{" "}
      document.
    </p>
  );
  return legal;
};

export const bypassInsuranceCheck = () => {
  const legal = (
    <p>
      If you attempt to bill this insurance for this patient you may get a high
      rate of denials and/or the patient may receive a substantial co-pay from
      their insurer. By checking this box, you are acknowledging that they
      should be added regardless of these of conditions.
    </p>
  );
  return legal;
};
