import React, { useState } from "react";

import AthelasLogo from "../assets/AthelasLogo.png";
import AthelasDevicesImage from "../assets/AthelasDevices.png";

type PropsType = {
  showDeviceImages?: boolean;
  showSiteLogos?: boolean;
  showCornerImages?: boolean;
  site_logo?: null | string;
  site_name?: null | string;
  palomar?: null | boolean;
  nextStep: (...args: any[]) => void;
  source: string;
};

const styles: any = {
  innerContainer: {
    maxWidth: 1000,
    width: "100%",
    margin: "0% auto",
  },
  logoContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    margin: "0% auto",
    marginBottom: 12,
  },
  logo: {
    maxWidth: "140px",
    maxHeight: "80px",
    margin: 12,
    objectFit: "contain",
  },
  flexContainer: {
    flexDirection: "row",
    display: "flex",
    maxWidth: 285,
    margin: "0% auto",
    marginBottom: 16,
    justifyContent: "left",
  },
  mobileFlexContainer: {
    flexDirection: "row",
    display: "flex",
    width: "100%",
    margin: "0% auto",
    marginBottom: 16,
    justifyContent: "left",
  },
  checkmark: {
    width: 36,
    height: 36,
    marginRight: 16,
  },
  explainer: {
    marginTop: 8,
  },
  buttonStyle: {
    width: "100%",
    maxWidth: "240px",
    margin: "0% auto",
    marginBottom: 24,
    fontStyle: "bold",
    padding: 8,
    borderRadius: 4,
    backgroundColor: "#FF6779",
    marginTop: 24,
    color: "white",
    cursor: "pointer",
  },
  palomarButtonStyle: {
    width: "100%",
    maxWidth: "240px",
    margin: "0% auto",
    marginBottom: 24,
    fontStyle: "bold",
    padding: 8,
    borderRadius: 4,
    backgroundColor: "#008CC1",
    marginTop: 24,
    color: "white",
    cursor: "pointer",
  },
};

export const ConfirmationScreen = (props: PropsType) => {
  const [checked, setChecked] = useState(false);

  const basicInformation = JSON.parse(
    localStorage.getItem("BASIC_INFORMATION") || "{}"
  );
  const shippingInformation = JSON.parse(
    localStorage.getItem("SHIPPING_ADDRESS") || "{}"
  );

  return (
    <div>
      <div style={styles.innerContainer}>
        {props.showSiteLogos && (
          <div style={styles.logoContainer}>
            <img src={AthelasLogo} style={styles.logo} alt="Athelas logo" />
            {props.site_logo && (
              <img src={props.site_logo} style={styles.logo} alt="Site logo" />
            )}
          </div>
        )}
        <h3>
          <b>Confirmation</b>
        </h3>
        <p
          style={{
            maxWidth: 450,
            margin: "0% auto",
            marginBottom: 16,
            color: "#888",
            fontSize: "100%",
            marginTop: 16,
          }}
        >
          Ship to:
        </p>
        <p style={{ fontSize: "110%" }}>
          {basicInformation.first_name} {basicInformation.last_name} <br></br>
          {shippingInformation.address_line1}, <br></br>
          {shippingInformation.city_locality},{" "}
          {shippingInformation.state_province},{" "}
          {shippingInformation.postal_code}
        </p>

        <p style={{ fontSize: "120%", fontWeight: "bold" }}>Acknowledgement</p>

        <label style={{ fontSize: "80%", maxWidth: "400px" }}>
          <input
            type="checkbox"
            onChange={(text) => setChecked(text.target.checked)}
          />
          <span style={{ marginLeft: "8px" }}>
            By clicking "Submit," I, {basicInformation.first_name}{" "}
            {basicInformation.last_name}, consent to receive remote monitoring
            services provided by Athelas under the direction and supervision of
            my healthcare provider. I consent to the terms set forth in the
            patient informed consent, which requires that I actively participate
            in remote monitoring, talk with my healthcare team about my program
            and results at least monthly, and not attempt to tamper with,
            resell, or redistribute the devices I receive from Athelas.
            Depending on your insurance, there may be a monthly copay associated
            with enrollment in this program.
          </span>
        </label>

        <div
          style={props.palomar ? styles.palomarButtonStyle : styles.buttonStyle}
          onClick={() => props.nextStep(checked, props.source)}
        >
          Get Started
        </div>
        {props.showDeviceImages && (
          <img
            src={AthelasDevicesImage}
            alt="Athelas devices"
            style={{ maxWidth: "300px" }}
          />
        )}
        <br />
      </div>
    </div>
  );
};
