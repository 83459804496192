import React, { Component } from "react";
import { MultipleChoice } from "../../app/Atoms/MultipleChoice";
import { ProgressBar } from "../../app/Atoms/ProgressBar/ProgressBar";

export class TotalCareDiet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dietaryInfo: null,
      dietaryInfoDetails: "",
    };
  }

  nextStep = () => {
    this.props.setMode("submit");
  };

  render() {
    return (
      <div
        class="form-container mt-5"
        style={{ marginBottom: "48px", paddingBottom: "24px" }}
      >
        <div class="p-4">
          <h2>Diet</h2>
          <p style={{ textAlign: "left" }}>
            How would you characterize your diet?
          </p>

          <MultipleChoice
            values={[
              "Vegetarian",
              "Vegan",
              "Pescatarian",
              "No Restrictions",
              "Other",
            ]}
            labels={[
              "Vegetarian",
              "Vegan",
              "Pescatarian",
              "No Restrictions",
              "Other",
            ]}
            selectValue={(val) => {
              if ("" === val) {
                return;
              }
              this.setState({ dietaryInfo: val });
              this.props.setDiet(val);
            }}
          />

          <p style={{ textAlign: "left" }}>
            Do you have any allergies and/or food restrictions? Please share
            details below
          </p>
          <input
            style={{ padding: 8, width: "100%" }}
            placeholder={'Example. "I do not eat eggs."'}
            onChange={(e) => {
              if ("" === e.target.value) {
                return;
              }
              this.setState({ dietaryInfoDetails: e.target.value });
              this.props.setAllergies(e.target.value);
            }}
          />

          <hr></hr>

          <button
            class="btn light-btn"
            onClick={this.nextStep}
            disabled={this.state.dietaryInfo === null}
          >
            Wrap Up
          </button>
        </div>

        <ProgressBar percent={this.state.dietaryInfo === null ? 70 : 80} />
      </div>
    );
  }
}
