import React, { Component } from "react";
import { MultipleChoice } from "../../app/Atoms/MultipleChoice";
import { ProgressBar } from "../../app/Atoms/ProgressBar/ProgressBar";

export class TotalCareExercise extends Component {
  constructor(props) {
    super(props);

    this.state = {
      exerciseInfo: "",
      excerciseDetails: "",
    };
  }
  nextStep = () => {
    this.props.setMode("diet");
  };

  render() {
    return (
      <div
        class="form-container mt-5"
        style={{ marginBottom: "48px", paddingBottom: "24px" }}
      >
        <div class="p-4">
          <h2>Exercise</h2>
          <p style={{ textAlign: "left" }}>
            How would you categorize your current level of exercise?
          </p>

          <MultipleChoice
            values={[
              "Infrequent (0-1 times a week)",
              "Moderate (2-3 times a week)",
              "Frequent (4+ times a week)",
            ]}
            labels={[
              "Infrequent (0-1 times a week)",
              "Moderate (2-3 times a week)",
              "Frequent (4+ times a week)",
            ]}
            selectValue={(val) => {
              this.props.setExercise(val + " - " + this.state.excerciseDetails);
              this.setState({ exerciseInfo: val });
            }}
          />

          <p style={{ textAlign: "left" }}>
            Describe the type and duration of your typical exercise
          </p>
          <input
            style={{ padding: 8, width: "100%" }}
            placeholder={
              'Example. "I jog twice a week for 30 minutes and play occasionally tennis."'
            }
            onChange={(e) => {
              const val = e.target.value;
              if ("" === val) {
                return;
              }
              this.setState({ excerciseDetails: val });
              this.props.setExercise(this.state.exerciseInfo + " - " + val);
            }}
          />

          <hr></hr>

          <button
            class="btn light-btn"
            onClick={this.nextStep}
            disabled={this.state.exerciseInfo === null}
          >
            Final Question
          </button>
        </div>

        <ProgressBar percent={this.state.exerciseInfo === null ? 50 : 70} />
      </div>
    );
  }
}
