import React, { Component } from 'react';
import gem_photo from './gem.png'

class Alignment extends Component {

  render() {
    return (
      <div class="form-container gem-container my-5">
        <div class="pt-4 pb-4">
          <h1> Alignment </h1>
        </div>
        <div class="px-5">
          <img width="100px" height="149px" alt="Athelas Gem" src={gem_photo} />
          <p class="pt-3">Rotate your device into the position shown above, with the shortest bottle directly in front of you.</p>
        </div>
        <div class="pt-3 pb-4">
          <button type="submit" class="btn light-btn" onClick={this.props.nextStep}>Next</button>
        </div>
      </div>
    )
  }

}

export default Alignment;
