import React, { useState } from 'react';
import '../../components/Footer/Footer.css';
import './GgpPaymentPlanOptions.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import MedicationManagementModal from './MedicationManagementModal';

const GgpPaymentPlanComparison = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const plans = ['Pharmacy Preferred', 'Pharmacy Choice', 'Athelas Preferred'];

  const renderPlan = (plan) => {
    switch(plan) {
      case 'Pharmacy Preferred':
        return (
          <div className="ggp-payment-options-plan" key={plan}>
            <h3 className="ggp-payment-options-title">
              Athelas Home with CLOZARIL <span className="ggp-payment-options-highlight copay">Co-Pay Assistance Program</span>
            </h3>
            <div className="ggp-payment-options-pricing">
              <span className="ggp-payment-options-price">$500</span>
              <span className="ggp-payment-options-per"> Athelas Home Device</span>
            </div>
            <div className="ggp-payment-options-pricing-strip">
              <span className="ggp-payment-options-strip-price">$5</span>
              <span className="ggp-payment-options-per"> per Athelas WBC Test Strip <span className="ggp-payment-options-subtle-text">(10 per box)</span></span>
            </div>
            <span className="ggp-payment-options-subtle-text">+ shipping</span>
            <div className="ggp-payment-options-refill-info">
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">Fill medications with Golden Gate Pharmacy Services</span>
              </div>
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">$0 Clozaril Co-Pays* (specific rules apply)</span>
              </div>
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">
                  Medication Management and Synchronization
                  <button onClick={() => setIsModalOpen(true)} style={{ marginLeft: '0px', fontSize: '12px', color: '#007bff', background: 'none', border: 'none', cursor: 'pointer' }}>
                    Learn More
                  </button>
                </span>
              </div>
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">Complimentary Pill Packing Services once dosage is stabilized</span>
              </div>
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">Complimentary 2-day FedEx delivery for all medications</span>
              </div>
            </div>
          </div>
        );
      case 'Pharmacy Choice':
        return (
          <div className="ggp-payment-options-plan" key={plan}>
            <h3 className="ggp-payment-options-title">
              Athelas Home with <span className="ggp-payment-options-highlight management">Medication Management</span>
            </h3>
            <div className="ggp-payment-options-pricing">
              <span className="ggp-payment-options-price">$1000</span>
              <span className="ggp-payment-options-per"> Athelas Home Device</span>
            </div>
            <div className="ggp-payment-options-pricing-strip">
              <span className="ggp-payment-options-strip-price">$5</span>
              <span className="ggp-payment-options-per"> per Athelas WBC Test Strip <span className="ggp-payment-options-subtle-text">(10 per box)</span></span>
            </div>
            <span className="ggp-payment-options-subtle-text">+ shipping</span>
            <div className="ggp-payment-options-refill-info">
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">Fill medications with Golden Gate Pharmacy Services</span>
              </div>
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">
                  Medication Management and Synchronization
                  <button onClick={() => setIsModalOpen(true)} style={{ marginLeft: '0px', fontSize: '12px', color: '#007bff', background: 'none', border: 'none', cursor: 'pointer' }}>
                    Learn More
                  </button>
                </span>
              </div>
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">Complimentary Pill Packing Services once dosage is stabilized</span>
              </div>
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">Complimentary 2-day FedEx delivery for all medications</span>
              </div>
            </div>
          </div>
        );
      case 'Athelas Preferred':
        return (
          <div className="ggp-payment-options-plan" key={plan}>
            <h3 className="ggp-payment-options-title">
              Athelas Home without Medication Management
            </h3>
            <div className="ggp-payment-options-pricing">
              <span className="ggp-payment-options-price">$1500</span>
              <span className="ggp-payment-options-per"> Athelas Home Device</span>
            </div>
            <div className="ggp-payment-options-pricing-strip">
              <span className="ggp-payment-options-strip-price">$20</span>
              <span className="ggp-payment-options-per"> per Athelas WBC Test Strip <span className="ggp-payment-options-subtle-text">(10 per box)</span></span>
            </div>
            <span className="ggp-payment-options-subtle-text">+ shipping</span>
            <div className="ggp-payment-options-refill-info">
              <div className="ggp-payment-options-refill-item">
                <span className="ggp-payment-options-refill-mark">&#10003;</span>
                <span className="ggp-payment-options-refill-text">Fill medication with the pharmacy of your choice</span>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="ggp-payment-options-container">
      <Header />
      <div className="ggp-payment-options-content">
        <h2 className="ggp-payment-options-header">Service Plan Comparison</h2>
        <div className="ggp-payment-options-plans">
          {plans.map(plan => renderPlan(plan))}
        </div>
        <div className="ggp-payment-options-note">
          <p>This is a comparison of our available service plans. For selection or more information, please contact our support team at <a href="mailto:athelasdevice@ggprx.com">athelasdevice@ggprx.com</a></p>
        </div>
      </div>
      <MedicationManagementModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default GgpPaymentPlanComparison;
