export const onboardingQuestions = {
  "What is Athelas?":
    "Athelas is a biotechnology company that has partnered with your prescriber to provide you with remote health devices. Our program allows your prescriber to take an active role in your care to help ensure that you do not experience any unforeseen health issues.",
  "How does the Athelas Nurse Program work?":
    "Athelas pairs you with a clinically certified nurse who regularly reviews your medical information and calls/texts you on a monthly basis to check in on your health. Our nurses are available during business hours in your respective timezone to answer any questions you may have about your health.",
  "Why was I identified for this program?":
    "Based on your care from your provider, they have identified you as being at higher than normal risk and therefore a candidate for the Athelas program.",
  "Who pays for this?":
    "Athelas has already pre-screened your insurance information to ensure that your insurance covers this program.",
  "I'm having trouble with my device - who can help?":
    "If you are having any issues with your device, or have any questions at all, you can call or text us at 408-359-4783 or email us at support@athelas.com. We will get back to you within 24 hours.",
  "Is my information secure?":
    "Always - your privacy and security are our #1 priority. Everything we do is protected by HIPAA.",
};

export const gemOnboardingQuestions = {
  "How does the Athelas Gem help with my medications?":
    "The Athelas Gem helps you and your doctor track your medication program and ensure you are receiving the best care possible.",
  "Does this device need to be plugged in?":
    "Yes, please keep your Athelas Gem plugged in at all times.",
  "Lights not changing as expected or experieincing network connectivity issues?":
    "Unplug and then plug back in the Pilltrack. Make sure the device is used in a room with good celluar reception",
  "Where should I put my Athelas Gem?":
    "Place your device anywhere you feel comfortable in your home where you can ensure its plugged in at all times.",
  "I need to switch out a medication, how do I do that?":
    "If you need to switch out a medication, please email support@athelas.com.",
  "My Gem won't allow me to add a second medication.":
    "During set up, only one bottle may be removed from the Gem at a time. If you are still having issues, please contact support@athelas.com.",
};

export const pilltrackSurveyQuestions = {
  "How Does this Help?":
    "PillTrack helps you stay on-track with your medications, so you never wonder if you accidentally missed a dose.",
  "Why was I identified for this program?":
    "Based on your care from your provider, they have identified you as being someone who would benefit from improved medical adherence and reminders.",
  "How do I view my medication adherence history?":
    "You can view your entire medication history by visiting pilltrack.athelas.com.",
  "What if I need to take medication away from my PillTrack?":
    "Don’t worry! Just text (833) 524-1318 and let us know.",
  "I’m having trouble with my device... who can help?":
    "If you are having any issues with your device, or have any questions at all, you can call or text us at (833)524-1318 or email us at support@athelas.com. We will get back to you within 24 hours.",
  "Is my information secure?":
    "Always - your privacy and security are our #1 priority. Everything we do is protected under HIPAA.",
  "Lights not changing as expected or experieincing network connectivity issues?":
    "Unplug and then plug back in the Pilltrack Device. Make sure the device is used in a room with good celluar reception",
};

export const totalCareOnboardingQuestions = {
  "What is Athelas?":
    "Athelas is a biotechnology company that has partnered with your prescriber to provide you with remote health devices. Our program allows your prescriber to take an active role in your care to help ensure that you do not experience any unforeseen health issues.",
  "What is the Total Care Program?":
    "The Total Care Program is meant to help you achieve your weight goals through daily check-ins, nutritionist conversations, and other resources.",
  "How does this work?":
    "We have or will ship you a weight sensor which is automatically and securely connected to your doctors and nutritionist portal. Any time you measure your weight, we will transmit your weight results to your doctor and nutritionist directly.",
  "Who pays for this?":
    "Athelas has already pre-screened your insurance information to ensure that your insurance covers this program.",
  "I'm having trouble with my device - who can help?":
    "If you are having any issues with your device, or have any questions at all, you can call or text us at 408-359-4783 or email us at support@athelas.com. We will get back to you within 24 hours.",
  "Is my information secure?":
    "Always - your privacy and security are our #1 priority. Everything we do is protected by HIPAA.",
};
