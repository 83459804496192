import React, { Component } from "react";

class End extends Component {
  render() {
    return (
      <div class="form-container gem-container my-5">
        <div class="pt-4">
          <h1> Finished! </h1>
        </div>
        <div class="px-5">
          <p class="pt-2">You are ready to start using your Athelas Gem!</p>
          <p>
            For support, please email support@athelas.com or call
            1-877-324-4332.
          </p>
        </div>
      </div>
    );
  }
}

export default End;
