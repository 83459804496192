import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import jwt from 'jsonwebtoken'
import { API_SERVER_URL, PUBLIC_KEY } from '../../app/constants';
import { trackEvent } from '../../app/utils/tracking';

export const patientInfoSlice = createSlice({
  name: "patientInfo",
  initialState: {
    patient: null,
  },
  reducers: {
    savePatient: (state, action) => {
      state.patient = action.payload;
    },
  },
});

export const { savePatient } = patientInfoSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const getPatientInfo = () => (dispatch) => {
  return axios
    .get(`${API_SERVER_URL}/patients/get_onboarding_info`)
    .then((response) => {
      return dispatch(savePatient(response.data));
    });
};

export const updateAdditionalQuestions = (patient) => (dispatch, getState) => {
  return axios.post(`${API_SERVER_URL}/patients/update_onboarding_additional_questions`, {
      patient
    }).then((response) => {
      if (response.status === 200) {
        console.log("Onboarding Patient -- 'update_onboarding_additional_questions' endpoint hit, success");
      }
    });
};

export const updatePatientInfo = (patient) => (dispatch, getState) => {
  return axios
    .post(`${API_SERVER_URL}/patients/update_onboarding_info`, {
      patient,
    })
    .then((response) => {
      const token = selectUrlToken(getState())
      const decodedToken = jwt.verify(token, PUBLIC_KEY, {
        algorithms: ['RS256']
      });
      const patient_id = decodedToken.sub;

      if (response.status === 200) {
        console.log(
          "Tracked Onboarding Patient -- 'update_onboarding_info' endpoint hit, success."
        );
        trackEvent("click", "onboarding_info_submit_successful", {
          status_code: response.status,
          patient_id: patient_id
        });
      } else {
        console.log(
          "Tracked Onboarding Patient -- 'update_onboarding_info' endpoint hit, failure."
        );
        trackEvent("click", "onboarding_info_submit_failure", {
          status_code: response.status,
          patient_id: patient_id
        });
      }
    });

  // return Promise.resolve({data: {patient: {
  //   first_name: 'Bob',
  //   last_name: 'Smith',
  //   date_of_birth: '06/23/1991',
  //   insurance_company: null,
  //   insurance_number: null,
  //   shipping_address: null,
  //   acknowledged: null
  // }}})
  // .then((response) => {
  //   return dispatch(savePatient(response.data.patient));
  // })
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectPatient = (state) => state.patientInfo.patient;
export const selectUrlToken = state => state.login.urlToken;

export default patientInfoSlice.reducer;
