import React from "react";
import { useMediaQuery } from "react-responsive";

import HowItWorks1 from "../revampedOnboarding/assets/howItWorks1.png";
import HowItWorks2 from "../revampedOnboarding/assets/howItWorks2.png";
import HowItWorks3 from "../revampedOnboarding/assets/howItWorks3.png";
import PalomarHowItWorks1 from "../revampedOnboarding/assets/palomarHowItWorks1.png";
import PalomarHowItWorks2 from "../revampedOnboarding/assets/palomarHowItWorks2.png";
import PalomarHowItWorks3 from "../revampedOnboarding/assets/palomarHowItWorks3.png";

type PropsType = {
  whiteBackground?: boolean;
  palomar?: null | boolean;
};

const styles: any = {
  outerContainer: {
    width: "100%",
    textAlign: "center",
    backgroundColor: "#F4F8FD",
    padding: 36,
  },
  outerContainerWhite: {
    width: "100%",
    textAlign: "center",
    backgroundColor: "white",
    padding: 36,
  },
  container: {
    maxWidth: 1000,
    margin: "0% auto",
  },
  flexBox: {
    flexDirection: "row",
    display: "flex",
    marginTop: 48,
  },
  reasonContainerMobile: {
    width: "100%",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "16px",
  },
  reasonContainer: {
    width: "33%",
    paddingLeft: "36px",
    paddingRight: "36px",
    paddingTop: "16px",
  },
  miniDescription: { fontSize: "95%" },
  stepHeaderStyle: {
    fontSize: "90%",
    textTransform: "uppercase",
    fontWeight: "bold",
    color: "#FF6779",
  },
  palomarStepHeaderStyle: {
    fontSize: "90%",
    textTransform: "uppercase",
    fontWeight: "bold",
    color: "#008CC1",
  },
  howItWorksImage: {
    maxWidth: 120,
    marginBottom: 16,
  },
};

export const HowItWorksSection = (props: PropsType) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  return (
    <div
      style={
        props.whiteBackground
          ? styles.outerContainerWhite
          : styles.outerContainer
      }
    >
      <div style={styles.container}>
        <h3>
          <b>How it Works</b>
        </h3>

        <div style={isTabletOrMobile ? {} : styles.flexBox}>
          <div
            style={
              isTabletOrMobile
                ? styles.reasonContainerMobile
                : styles.reasonContainer
            }
          >
            <img
              src={props.palomar ? PalomarHowItWorks1 : HowItWorks1}
              style={styles.howItWorksImage}
              alt={"How it works"}
            />
            <h5 style={props.palomar ? styles.palomarStepHeaderStyle : styles.stepHeaderStyle}>Step 1</h5>
            <p>
              <b>Devices Delivered to Your Home</b>
            </p>
            <p style={styles.miniDescription}>
              Once you sign up for Athelas, your remote monitoring device will arrive within a
              few days along with instructions.
            </p>
          </div>

          <div
            style={
              isTabletOrMobile
                ? styles.reasonContainerMobile
                : styles.reasonContainer
            }
          >
            <img
              src={props.palomar ? PalomarHowItWorks2 : HowItWorks2}
              style={styles.howItWorksImage}
              alt={"How it works"}
            />
            <h5 style={props.palomar ? styles.palomarStepHeaderStyle : styles.stepHeaderStyle}>Step 2</h5>
            <p>
              <b>Athelas Nurse Check-In</b>
            </p>
            <p style={styles.miniDescription}>
              You’ll be paired with a professional nurse who will regularly
              check-in with you.
            </p>
          </div>

          <div
            style={
              isTabletOrMobile
                ? styles.reasonContainerMobile
                : styles.reasonContainer
            }
          >
            <img
              src={props.palomar ? PalomarHowItWorks3 : HowItWorks3}
              style={styles.howItWorksImage}
              alt={"How it works"}
            />
            <h5 style={props.palomar ? styles.palomarStepHeaderStyle : styles.stepHeaderStyle}>Step 3</h5>
            <p>
              <b>Results Reviewed by Your Doctor</b>
            </p>
            <p style={styles.miniDescription}>
              Your health metrics will be transmitted to your doctor, who will
              analyze the results and contact you if anything looks concerning.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
