import { useState, useEffect } from "react";
import { API_SERVER_URL } from "../constants";

/*
    Feature Flags

    Use feature flags to gate features for slow rollout.
    You can use the feature flag Retool to create feature flags:
        https://athelas.retool.com/apps/Experiments/Feature%20Flags?_releaseVersion=latest

    Feature flags can either be ENABLED, DISABLED, or TARGETED.

    Enabled = Enabled for everyone. This is an override gate.
    Disabled = Disabled for everyone. This is an override gate.
    Targeted = Enabled only for subjects in the list of targeted subject ids.

    There is a function where you can pass a flag, subject, and onchange handler.
    There is also a React hook so you can use it seemlessly in functional components.
*/

export function is_feature_enabled(
  flag_identifier: string,
  subject_id: number,
  onChange: (enabled: boolean) => void
) {
  return fetch(
    `${API_SERVER_URL}/feature_flag/is_enabled/${flag_identifier}?subject_id=${subject_id}`
  )
    .then((j) => {
      return j.json();
    })
    .then((res) => {
      onChange(res);
    });
}

export function useFeatureFlag(flag_identifier: string, subject_id: number) {
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    function handleStatusChange(status: boolean) {
      setIsEnabled(status);
    }

    is_feature_enabled(flag_identifier, subject_id, handleStatusChange);
  });

  return isEnabled;
}
