import React, { Component } from "react";
import { trackEvent } from "../utils/logging";

const Context = React.createContext("");
export const LogContext = Context;

class Log extends Component {
  constructor(props) {
    super(props);

    this.logDOMElementRef = React.createRef();
    this.state = { isInViewport: false };
    this.hasImpressionAlreadyBeenLogged = false;
    this.observerCallback = this.observerCallback.bind(this);
  }

  componentDidMount() {
    if (this.props.logImpression) {
      this.setupObserver();
    }
    trackEvent(
      this.props.action,
      this.props.patientID,
      this.props.tag,
      this.props.info
    );
  }

  componentDidUpdate() {
    if (
      this.props.logImpression &&
      this.state.isInViewport &&
      !this.hasImpressionAlreadyBeenLogged
    ) {
      trackEvent("view", this.props.patientID, this.props.tag, this.props.info);
      this.hasImpressionAlreadyBeenLogged = true;
    }
  }

  setupObserver() {
    this.observer = new IntersectionObserver(this.observerCallback, {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    });

    const wrappedDOMElements = this.logDOMElementRef?.current?.childNodes;
    let firstVisibleElement;
    if (Array.isArray(wrappedDOMElements)) {
      firstVisibleElement = wrappedDOMElements.find(
        (el) => el.offsetParent !== null
      );
    }

    if (firstVisibleElement) {
      this.observer.observe(firstVisibleElement);
    }
  }

  observerCallback(entries) {
    const entry = entries[0];

    if (
      entry !== undefined &&
      this.state.isInViewport !== entry.isIntersecting
    ) {
      this.setState(() => ({
        isInViewport: entry.isIntersecting,
      }));
    }
  }

  render() {
    const { children, logImpression, ...directProps } = this.props;

    return (
      <LogContext.Consumer>
        {(consumedProps) => {
          this.combinedProps = { ...consumedProps, ...directProps };

          return (
            <Context.Provider value={this.combinedProps}>
              <div style={{ display: "contents" }} ref={this.logDOMElementRef}>
                {children}
              </div>
            </Context.Provider>
          );
        }}
      </LogContext.Consumer>
    );
  }
}

export default Log;
