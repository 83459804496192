import axios from "axios";
import { API_SERVER_URL } from "../../app/constants";


export const verifyShippingAddress = (
  patient_id: number,
  address_line1: string,
  address_line2: string,
  city: string,
  state_province: string,
  postal_code: string
) => {
  const headers = {};
   return axios
    .post(
      `${API_SERVER_URL}/patients/verify_patient_address_onboarding`,
      {
        patient_id: patient_id,
        address_line1: address_line1,
        address_line2: address_line2,
        city_locality: city,
        state_province: state_province,
        postal_code: postal_code,
      },
      {
        headers,
      }
    )
};